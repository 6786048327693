// @flow
import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { toastr } from 'react-redux-toastr';
import { from } from 'rxjs';
import { every, map } from 'rxjs/operators';

import { productItemsTableConstants } from './table.constants';
import { ProductItem, ProductKitItem } from '../../../../../models';
import { itemService } from '../../../../../_shared/services';
import { errorParser } from '../../../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  items: ProductItem[],
  kitItems: ProductKitItem[],
  productCreationDate: Date,
  startLoading(): void,
  stopLoading(): void,
  getProductDetails(): void,
  bindSubmit(fun: any): void,
  t: i18next.TFunction
};

type State = {
  originalItems: any[],
  items: any[],
  columns: any
};

class ProductItems extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Date change handler */
  setDate = (date: Date, itemId: string) => {
    let newItems = [...this.state.items];
    newItems = newItems.map((item: any) => {
      if (item._id === itemId) {
        return {
          ...item,
          expiryDate: date
        };
      }
      return item;
    });
    this.setState((curr: State) => ({ ...curr, items: newItems }));
  };
  /**Lotnumber change handler */
  setLotNumber = (lotNumber: string, itemId: string) => {
    let newItems = [...this.state.items];
    newItems = newItems.map(item => {
      if (item._id === itemId) {
        item.lotNumber = lotNumber;
      }
      return item;
    });
    this.setState(curr => ({ ...curr, items: newItems }));
  };
  /**Toggle editing */
  toggleEdit = (itemId: string) => {
    let newItems = [...this.state.originalItems];
    newItems = newItems.map((item: any) => {
      if (item._id === itemId) {
        return {
          ...item,
          editProductItems: !this.state.items.filter(
            _item => _item._id === itemId
          )[0].editProductItems
        };
      }
      return item;
    });
    this.setState((curr: State) => ({ ...curr, items: newItems }));
  };
  checkLotNumbers = () => {
    /**let's map true/false based on input value */
    const items = this.state.items.map(item => {
      return {
        ...item,
        isLotNumberInvalid: !item.lotNumber
          ? this.translation('formValidations.required')
          : item.lotNumber.indexOf(' ') > -1
          ? this.translation('formValidations.spacesAreNotAllowed')
          : ''
      };
    });
    this.setState((curr: State) => ({ ...curr, items }));
    /**create observable stream from the same array */
    return from(items).pipe(
      // we just need to check true/false from whole object, so map that only
      map(item => item.isLotNumberInvalid),
      every(value => {
        // return value !== true;
        return value === '';
      })
    );
  };
  /**On Submit */
  onSubmit = (itemId: string) => {
    this.props.startLoading();
    this.checkLotNumbers().subscribe({
      next: (res: boolean) => {
        if (res) {
          const item = this.state.items.filter(
            _item => _item._id === itemId
          )[0];
          const updateProductItem$ = itemService
            .updateProductItem(itemId, {
              lotNumber: item.lotNumber,
              ...(item.expiryDate && { expiryDate: item.expiryDate })
            })
            .subscribe({
              next: (response: { data: { message: string } }) => {
                this.props.stopLoading();
                toastr.success(
                  this.translation('itemsAdded'),
                  response.data.message
                );
                this.props.getProductDetails();
              },
              error: (errorResponse: any) => {
                if (this.cleanup) {
                  this.props.stopLoading();
                  toastr.error(
                    this.translation('error'),
                    errorParser(errorResponse)
                  );
                }
              }
            });
          this.cleanup = () => {
            updateProductItem$.unsubscribe();
          };
        } else {
          this.props.stopLoading();
        }
      },
      error: (errorResponse: any) => {
        this.props.stopLoading();
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    });
  };
  /**State of component */
  state = {
    items: [],
    originalItems: [],
    columns: productItemsTableConstants.itemColumns(
      this.setDate,
      this.setLotNumber,
      this.toggleEdit,
      this.onSubmit,
      this.props.productCreationDate
    )
  };
  /**Change the item structure for table*/
  constructor(props: Props) {
    super(props);
    let items = this.props.items;
    const kitItems = this.props.kitItems;
    items = items.map((item: ProductItem, index: number) => {
      return {
        ...item,
        name: kitItems[index].name,
        number: kitItems[index].number,
        imageSrc: kitItems[index].imageSrc,
        isLotNumberInvalid: false,
        editProductItems: false
      };
    });
    this.state.items = items;
    this.state.originalItems = items;
    this.props.bindSubmit(this.onSubmit);
  }
  render() {
    const translation = this.props.t;
    return (
      <BootstrapTable
        classes="with-gap"
        bordered={false}
        bootstrap4
        keyField="_id"
        data={this.state.items}
        columns={this.state.columns}
        noDataIndication={translation('noData')}
        rowClasses="colored-background"
      />
    );
  }
}

export default withTranslation()(ProductItems);
