import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Button, Form, Row } from 'react-bootstrap';
import { forkJoin } from 'rxjs';
import { toastr } from 'react-redux-toastr';

import { FormGroup, DatePicker } from '../../../_shared/components';
import { ProductService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation, Translation } from 'react-i18next';

/**Validation schema for formik */
const AddByRangeFormSchema = Yup.object().shape({
  prefix: Yup.string(),
  // .test(
  //   'valid-prefix',
  //   <Translation>{t => <>{t('formValidations.prefixError')}</>}</Translation>,
  //   value => {
  //     return /^[A-Z]{2}[0-9]{2}[A-Z]{1}$/.test(value);
  //   }
  // )
  // .length(5)
  fromRange: Yup.string().required(),
  // .test(
  //   'is-digits',
  //   <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
  //   value => !isNaN(value)
  // )
  // .length(6)
  // toRange: Yup.string().required(),
  // .test(
  //   'is-digits',
  //   <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
  //   value => !isNaN(value)
  // )
  // .length(6)
  count: Yup.string().required()
});

class AddByRange extends Component {
  translation = this.props.t;
  state = {
    serialNumbersRange: [],
    count: 0,
    prefix: '',
    fromRange: '',
    toRange: '',
    showError: false,
    registrationDate: undefined
  };
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  /**Call api to add product by range */
  addProductsByRange = () => {
    if (this.state.registrationDate) {
      this.setState({ showError: false });
      this.props.startLoading();
      let observables$ = [];
      //make an array of all observables which will check validity of serial numbers
      this.state.serialNumbersRange.forEach(serialNumber => {
        observables$.push(ProductService.checkSerialNumber(serialNumber));
      });
      // check all serial numbers first. if any fails, don't add.
      forkJoin(...observables$).subscribe({
        next: _response => {
          // all serial numbers are valid
          ProductService.registerProduct(
            this.state.serialNumbersRange,
            this.props.customerId,
            this.state.registrationDate
          ).subscribe({
            next: () => {
              this.props.stopLoading();
              toastr.success(
                `${this.translation('unit', {
                  count: 0
                })} ${this.translation('add')}`,
                this.translation('unitsAreRegisteredWith')
              );
              this.props.goTo(
                '/admin/customers/details/' + this.props.customerId
              );
            },
            error: errorResponse => {
              this.props.stopLoading();
              toastr.error(
                this.translation('error'),
                errorParser(errorResponse)
              );
            }
          });
        },
        error: () => {
          this.props.stopLoading();
          toastr.error(
            this.translation('error'),
            this.translation('someOfProvidedSerialNumbersAreInvalid')
          );
        }
      });
    } else {
      this.setState({ showError: true });
    }
  };
  countSerialNumbers = changeEvent => {
    this.setState(
      { [changeEvent.target.name]: changeEvent.target.value },
      () => {
        const fromRange = this.state.fromRange;
        const count = isNaN(this.state.count) ? 0 : this.state.count;
        const toRange = String(
          this.state.count >= 1
            ? Number(count) + Number(fromRange) - 1
            : Number(count) + Number(fromRange)
        ).padStart(fromRange.length, fromRange);
        this.setState({
          toRange: fromRange !== '' ? toRange : ''
        });
      }
    );
  };
  render() {
    return (
      <>
        <Col xs={12} className="ml-3">
          <Row className="align-items-center">
            <Col xs="auto" className="mb-4">
              <span className="font-size-lg">
                {this.translation('enterRegistrationDate')}:
              </span>
            </Col>
            <Col className="mb-4" xs="auto">
              <DatePicker
                selected={this.state.registrationDate}
                onChange={date => {
                  this.setState({ registrationDate: date, showError: false });
                }}
                className={[
                  this.state.showError ? 'is-invalid' : '',
                  'd-inline-block'
                ].join(' ')}
                name="registrationDate"
                id="registrationDate"
                error={
                  this.state.showError
                    ? this.translation('formValidations.required')
                    : ''
                }
                showError="tooltip"
                placeholder="MMM DD, YYYY"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="ml-3">
          <p className="font-size-lg">
            {this.translation(
              'enterTheSerialNumberSOfTheKitSYouWouldLikeToAdd'
            )}
          </p>
        </Col>
        <Col className="ml-3">
          <Formik
            ref={this.formRef}
            initialValues={{
              fromRange: '',
              toRange: '',
              prefix: ''
            }}
            validate={values => {
              let errors = {};
              // if (
              //   values.toRange.length === 8 &&
              //   values.fromRange.length === 8
              // ) {
              //   if (+values.toRange <= +values.fromRange) {
              //     errors.toRange =
              //       'To range can not be equal to or less than From range.';
              //   }
              //   if (+values.fromRange >= +values.toRange) {
              //     errors.fromRange =
              //       'From range can not be equal to or more than To range.';
              //   }
              //   if (+values.toRange > +values.fromRange + 10000) {
              //     errors.toRange = 'You can add only 10000 products together.';
              //   }
              // }
              if (this.state.count <= 0) {
                errors.count = this.translation(
                  'formValidations.onlyPositiveUnits'
                );
              }
              if (this.state.toRange > +values.fromRange + 10000) {
                errors.count = this.translation(
                  'formValidations.only10000Units'
                );
              }
              if (!this.state.registrationDate) {
                this.setState({ showError: true });
              }
              return errors;
            }}
            validationSchema={AddByRangeFormSchema}
            onSubmit={values => {
              const fromRange = values.fromRange,
                toRange = +this.state.toRange;
              const serialNumberRangeLength = toRange - fromRange + 1;
              const serialNumbersRange = [
                ...Array(serialNumberRangeLength)
              ].map(
                (_, index) =>
                  values.prefix +
                  String(Number(fromRange) + index).padStart(
                    fromRange.length,
                    fromRange
                  )
              );
              this.setState({
                serialNumbersRange
              });
              this.addProductsByRange();
            }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ errors, touched, handleChange, handleSubmit }) => {
              return (
                <Form className="custom-form-control" onSubmit={handleSubmit}>
                  <Form.Row className="align-items-center">
                    <Col sm="12" md={11}>
                      <Row>
                        <Col xs={12} sm={12} md={4}>
                          <Form.Group
                            as={Row}
                            controlId="prefixId"
                            className="align-items-center"
                          >
                            <Form.Label column className="mb-3">
                              {this.translation('prefix')}
                            </Form.Label>
                            <Col xs sm md="7">
                              <FormGroup
                                formControlName="prefix"
                                type="text"
                                label="MF20A"
                                handleChange={changeEvent => {
                                  this.countSerialNumbers(changeEvent);
                                  handleChange(changeEvent);
                                }}
                                touched={touched['prefix']}
                                error={errors['prefix']}
                                alertPositionAbsolute
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <Form.Group
                            as={Row}
                            controlId="fromRangeId"
                            className="align-items-center"
                          >
                            <Form.Label column xs="auto" className="mb-3">
                              {this.translation('startingFrom')}
                            </Form.Label>
                            <Col xs sm md="8">
                              <FormGroup
                                formControlName="fromRange"
                                type="text"
                                label="XXXXXX"
                                handleChange={changeEvent => {
                                  this.countSerialNumbers(changeEvent);
                                  handleChange(changeEvent);
                                }}
                                touched={touched['fromRange']}
                                error={errors['fromRange']}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <Form.Group
                            as={Row}
                            controlId="toRangeId"
                            className="align-items-center"
                          >
                            <Form.Label column xs="auto" className="mb-3">
                              {this.translation('Quantity')}
                            </Form.Label>
                            <Col xs sm md="8">
                              <FormGroup
                                formControlName="count"
                                type="text"
                                label="XXXXXX"
                                handleChange={changeEvent => {
                                  this.countSerialNumbers(changeEvent);
                                  handleChange(changeEvent);
                                }}
                                touched={touched['count']}
                                error={errors['count']}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="5" />
                  </Form.Row>
                  <p className="font-size-lg float-left">
                    {this.translation('LastSerialNumber')}:
                    {' ' + this.state.prefix + this.state.toRange}
                  </p>
                  <Button
                    type="submit"
                    variant="success"
                    size="lg"
                    className="rounded-0 px-5 float-right"
                  >
                    {this.translation('add')}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </>
    );
  }
}

export default withTranslation()(AddByRange);
