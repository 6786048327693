import React, { Component } from 'react';
import {
  Modal,
  Button,
  Form,
  Collapse,
  Alert,
  Row,
  Col
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';

import { FormGroup } from '../../../_shared/components';
import { UserService } from '../../../_shared/services';
import {
  alertActions,
  authenticationActions
} from '../../../_shared/redux/actions';
import { equalTo, errorParser } from '../../../_shared/helpers';
import i18next from 'i18next';
import { Translation } from 'react-i18next';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8)
    .required(),
  newPassword: Yup.string()
    .min(8)
    .required(),
  confirmPassword: Yup.string()
    .min(8)
    .required()
    .equalTo(
      Yup.ref('newPassword'),
      <Translation>
        {t => <>{t('formValidations.passwordsMustMatch')}</>}
      </Translation>
    )
});

class ChangePasswordModal extends Component {
  state = {
    isLoading: false
  };
  render() {
    const { alert } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size={this.props.size}
        aria-labelledby="change-password-modal"
        centered
      >
        <Modal.Body>
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: ''
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={values => {
              this.props.clearAlert();
              this.setState({ isLoading: true });
              UserService.changePassword({
                oldPassword: values.oldPassword.toString(),
                newPassword: values.newPassword.toString()
              }).subscribe({
                next: response => {
                  this.setState({ isLoading: false });
                  this.props.onHide();
                  this.props.goTo('/');
                  this.props.logout();
                  toastr.success(
                    i18next.t('passwordChanged'),
                    i18next.t('passwordChangedSuccess')
                  );
                },
                error: errorResponse => {
                  this.setState({ isLoading: false });
                  this.props.errorAlert(errorParser(errorResponse));
                }
              });
            }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ errors, touched, handleChange, handleSubmit, values }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Collapse in={!!alert.message}>
                    <div>
                      {!!alert.message && (
                        <Alert variant={alert.type}>{alert.message}</Alert>
                      )}
                    </div>
                  </Collapse>
                  <FormGroup
                    formControlName="oldPassword"
                    type="password"
                    label={i18next.t('oldPassword')}
                    handleChange={handleChange}
                    touched={touched['oldPassword']}
                    error={errors['oldPassword']}
                    disabled={this.state.isLoading}
                    required
                  />
                  <FormGroup
                    formControlName="newPassword"
                    type="password"
                    label={i18next.t('newPassword')}
                    handleChange={handleChange}
                    touched={touched['newPassword']}
                    error={errors['newPassword']}
                    disabled={this.state.isLoading}
                    required
                  />
                  <FormGroup
                    formControlName="confirmPassword"
                    type="password"
                    label={i18next.t('reEnterNewPassword')}
                    handleChange={handleChange}
                    touched={touched['confirmPassword']}
                    error={errors['confirmPassword']}
                    disabled={this.state.isLoading}
                    required
                  />
                  <Row className="justify-content-between">
                    <Col xs="auto">
                      <Button
                        type="reset"
                        variant="secondary"
                        size="lg"
                        className="rounded-0 px-5"
                        onClick={this.props.onHide}
                        disabled={this.state.isLoading}
                      >
                        {i18next.t('cancel')}
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        type="submit"
                        variant="success"
                        size="lg"
                        block
                        className="rounded-0 px-5"
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? (
                          <MDSpinner singleColor="#ffffff" />
                        ) : (
                          i18next.t('changePassword')
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStatesToProps = state => {
  const { alert } = state;
  return { alert };
};

const mapDispatchToProps = dispatch => ({
  errorAlert: msg => dispatch(alertActions.error(msg)),
  successAlert: msg => dispatch(alertActions.success(msg)),
  clearAlert: () => dispatch(alertActions.clear()),
  logout: () => dispatch(authenticationActions.logout())
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ChangePasswordModal);
