import React from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import { headerFormatterWithSort } from '../../../_shared/helpers';
import i18next from 'i18next';

const itemImageFormatter = (cell, row) => {
  return (
    <Icon
      iconName={
        row.type === 'Document'
          ? 'document'
          : row.type === 'Video'
          ? 'media-player-play'
          : 'picture'
      }
      size="36"
      fill={ColorConstants.DEFAULT_FONT}
      classes="mr-3 my-3"
    />
  );
};

export const DocumentTableConstants = {
  documentColumns: (startEditDocument, presentDeleteConfirm) => [
    {
      dataField: 'type',
      text: '',
      formatter: itemImageFormatter,
      classes: 'py-0 align-middle',
      style: { width: '60px' },
      headerStyle: { width: '60px' }
    },
    {
      dataField: 'title',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('title')),
      sort: true
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      formatter: (cell, row) => (
        <>
          <Icon
            iconName="edit-inline"
            size="29"
            classes="mr-3 cursor-pointer"
            onClick={event => {
              startEditDocument(row._id);
              event.stopPropagation();
            }}
          />
          <Icon
            iconName="delete-inline"
            size="29"
            classes="cursor-pointer"
            onClick={event => {
              presentDeleteConfirm(row._id, row.title);
              event.stopPropagation();
            }}
          />
        </>
      ),
      classes: 'py-0 text-right',
      headerStyle: { width: '20%' },
      style: { width: '20%' }
    }
  ],
  newDocumentColumns: setDocumentAsBanner => [
    {
      dataField: 'url',
      text: '',
      formatter: itemImageFormatter,
      classes: 'py-0 align-middle',
      headerStyle: { width: '8%' },
      style: { width: '8%' }
    },
    {
      dataField: 'title',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('title')),
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      classes: 'text-right',
      text: '',
      formatter: (cell, row) => {
        return (
          <Button
            variant="success"
            size="sm"
            className="rounded-0 px-3"
            onClick={() => {
              setDocumentAsBanner(row);
            }}
          >
            {i18next.t('add')}
          </Button>
        );
      },
      headerStyle: { width: '10%' },
      style: { width: '10%' }
    }
  ]
};
