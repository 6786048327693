import { authHeader, AxiosSubscriber } from '../helpers';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../constants';
import { stringify } from 'querystring';

export const LicenseService = {
  getLicenseByUserId,
  addLicense
};

/**@function getProductsByUserId
 * Get product for a user
 * @param {string} userId - User ID of which products will be fetched
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort=''] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'|'desc'] - Order of sorting
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getLicenseByUserId(skip = 0, limit = 10, customerId) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit, customerId })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.LICENSE.GET_LICENSE
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function add
 * Add kit
 * @param {Kit} kit - Starting index of the records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */

function addLicense(license) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.LICENSE.POST_LICENSE
      }`,
      axiosOptions = { headers: authHeader() };
    // let's remove _id, if any
    const { _id, ...restValues } = license;
    license = restValues;
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      license
    );
  });
}
