// @flow
import * as React from 'react';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { Icon, ConfirmDialog } from '../../../_shared/components';
import { ReactRouterHistory, ReactRouterMatch } from '../../../models';
import BasicDetails from './BasicDetails/';
import License from './LicenseNew/';
import Kits from './Kits/';
import Users from './Users/';
import ExpiringItems from './ExpiringItems';
import { withTranslation } from 'react-i18next';
import { UserService } from '../../../_shared/services';
import { loaderActions } from '../../../_shared/redux/actions';
import { errorParser } from '../../../_shared/helpers';
import NavButtons from './NavButtons';
import i18next from 'i18next';

type State = {
  customerId: string,
  activeDetail: 'customer' | 'license' | 'kit' | 'items' | 'users',
  addingLicense: boolean,
  showConfirmDialog: boolean,
  isDeleted: boolean | any
};

type Props = {
  startLoading(): void,
  stopLoading(): void,
  match: ReactRouterMatch,
  history: ReactRouterHistory,
  t: i18next.TFunction
};

export class CustomerDetails extends React.Component<Props, State> {
  translation = this.props.t;
  cleanup: any = null;
  /**Components State */
  state = {
    customerId: '',
    activeDetail: 'customer',
    addingLicense: false,
    showConfirmDialog: false,
    isDeleted: undefined
  };
  constructor(props: Props) {
    super(props);
    const customerId: string = props.match.params.customerId;
    this.state.customerId = customerId;
  }
  /**Cleanup resources on un-mount */
  componentWillUnmount() {
    if (this.cleanup) {
      this.cleanup();
      this.cleanup = null;
    }
  }
  /**Add license flag toggler */
  addingLicense = (flag: boolean) => {
    this.setState({ addingLicense: flag });
  };
  addNewKit = () =>
    this.props.history.push(
      '/admin/customers/details/' + this.state.customerId + '/add-kit'
    );

  addNewLicense = () =>
    this.props.history.push(
      '/admin/customers/details/' + this.state.customerId + '/add-license'
    );

  userConfirmed = () => {
    this.setState({ showConfirmDialog: false });
    if (this.state.isDeleted) {
      this.activateCustomer();
    } else {
      this.deactivateCustomer();
    }
  };
  deactivateCustomer = () => {
    this.props.startLoading();
    const deleteUserById$ = UserService.deactivate(
      this.state.customerId
    ).subscribe({
      next: (response: any) => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.success(
            this.translation('user') +
              ' ' +
              this.translation('deactivate', { context: 'past' }),
            response.data.message
          );
          this.setState({ isDeleted: true });
        }
      },
      error: (errorResponse: any) => {
        this.props.stopLoading();
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    });
    this.cleanup = () => {
      deleteUserById$.unsubscribe();
    };
  };
  activateCustomer = () => {
    this.props.startLoading();
    const deleteUserById$ = UserService.activate(
      this.state.customerId
    ).subscribe({
      next: (response: any) => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.success(
            this.translation('user') +
              ' ' +
              this.translation('activate', { context: 'past' }),
            response.data.message
          );
          this.setState({ isDeleted: false });
        }
      },
      error: (errorResponse: any) => {
        this.props.stopLoading();
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    });
    this.cleanup = () => {
      deleteUserById$.unsubscribe();
    };
  };
  goTo = (path: string) => this.props.history.push(path);
  render() {
    return (
      <>
        <ConfirmDialog
          showConfirm={this.state.showConfirmDialog}
          backdrop="static"
          okBtn={this.translation('yes')}
          okClicked={() => this.userConfirmed()}
          cancelBtn={this.translation('no')}
          cancelClicked={() => this.setState({ showConfirmDialog: false })}
          message={this.translation('doYouReallyWantToChangeStatusOfUser', {
            statusVerb: this.state.isDeleted
              ? this.translation('activate').toLocaleLowerCase()
              : this.translation('deactivate').toLocaleLowerCase()
          })}
        />
        <Container fluid>
          <NavButtons
            state={this.state}
            setState={updatedState => this.setState(updatedState)}
          />
          {this.state.activeDetail === 'customer' ? (
            <BasicDetails
              customerId={this.state.customerId}
              goTo={(path: string) => this.props.history.push(path)}
              isDeleted={(isDeleted: boolean) => this.setState({ isDeleted })}
            />
          ) : this.state.activeDetail === 'license' ? (
            <Card>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col className="mb-4">
                    <h3>{this.translation('license', { count: 0 })}</h3>
                  </Col>
                  <Col className="text-right mb-4">
                    <Button variant="" onClick={() => this.addNewLicense()}>
                      {this.translation('add')} {this.translation('license')}{' '}
                      <Icon iconName="add" size="26" />
                    </Button>
                  </Col>
                </Row>
                <License
                  customerId={this.state.customerId}
                  addingLicense={this.addingLicense}
                />
              </Card.Body>
            </Card>
          ) : this.state.activeDetail === 'kit' ? (
            <Card>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col className="mb-4">
                    <h3>{this.translation('unit', { count: 0 })}</h3>
                  </Col>
                  <Col className="text-right mb-4">
                    <Button variant="" onClick={() => this.addNewKit()}>
                      {this.translation('add')} {this.translation('unit')}{' '}
                      <Icon iconName="add" size="26" />
                    </Button>
                  </Col>
                </Row>
                <Kits
                  customerId={this.state.customerId}
                  goTo={(path: string) => this.goTo(path)}
                />
              </Card.Body>
            </Card>
          ) : this.state.activeDetail === 'items' ? (
            <Card>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col className="mb-4">
                    <h3>
                      {this.translation('expire', { context: 'presentCont' })}{' '}
                      {this.translation('item', { count: 0 })}
                    </h3>
                  </Col>
                </Row>
                <ExpiringItems customerId={this.state.customerId} />
              </Card.Body>
            </Card>
          ) : this.state.activeDetail === 'users' ? (
            <Card>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col className="mb-4">
                    <h3>{this.translation('users', { count: 0 })}</h3>
                  </Col>
                </Row>
                <Users
                  customerId={this.state.customerId}
                  goTo={(path: string) => this.goTo(path)}
                />
              </Card.Body>
            </Card>
          ) : (
            ''
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(CustomerDetails)
);
