// @flow
import * as React from 'react';
import i18next from 'i18next';
import * as langmap from 'langmap';
import { LANGUAGE_SERVICE } from '../services';

type Props = {
  showLabel?: boolean,
  className?: string,
  theme?: 'default' | 'white'
};

type State = {
  languages: string[]
};

export class LanguageSelector extends React.Component<Props, State> {
  state = {
    languages: ['en-US', 'fr']
  };
  render() {
    const { showLabel = false, className = '', theme = 'default' } = this.props;
    return this.state.languages && this.state.languages.length ? (
      <div className={className}>
        {showLabel && (
          <label htmlFor="languageSelector">{i18next.t('language')}</label>
        )}
        <select
          id="languageSelector"
          name="language-selector"
          className={
            'custom-select custom-select-sm ml-2 w-auto custom-select-' + theme
          }
          onChange={async ev => {
            await LANGUAGE_SERVICE.changeLanguage(ev.target.value);
          }}
          onBlur={() => {}}
          defaultValue={i18next.language}
        >
          {this.state.languages.map((language, index) => (
            <option value={language} key={index}>
              {langmap[language]['englishName'] +
                ' (' +
                langmap[language]['nativeName'] +
                ')'}
            </option>
          ))}
        </select>
      </div>
    ) : null;
  }
}

export default LanguageSelector;
