import { LoaderConstants } from '../../constants';

export const loaderActions = {
  start,
  stop
};

function start() {
  return { type: LoaderConstants.START_LOADING };
}

function stop() {
  return { type: LoaderConstants.STOP_LOADING };
}
