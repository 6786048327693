import React from 'react';
import { Icon, ImageContainer } from '../../../_shared/components';
import { headerFormatterWithSort } from '../../../_shared/helpers';
import i18next from 'i18next';

const itemImageFormatter = (cell, row) => {
  return (
    <ImageContainer src={cell} alt={row.name} height="40px" className="h-100" />
  );
};

export const kitItemTableConstants = {
  itemColumns: (startEditKitItem, presentDeleteConfirm) => [
    {
      dataField: 'imageSrc',
      text: '',
      formatter: itemImageFormatter,
      classes: 'py-0 align-middle',
      headerStyle: { width: '8%' },
      style: { width: '8%' }
    },
    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemName')),
      sort: true,
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },
    {
      dataField: 'number',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemNumber')),
      sort: true,
      headerStyle: { width: '10%' },
      style: { width: '10%' }
    },
    {
      dataField: 'manufacture',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('manufacture')),
      sort: true,
      headerStyle: { width: '12%' },
      style: { width: '12%' }
    },
    {
      dataField: 'description',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('description')),
      classes: 'text-truncate',
      formatter: cell => <span title={cell}>{cell}</span>,
      headerStyle: { width: '40%' },
      style: { width: '40%' }
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      formatter: (cell, row) => (
        <>
          <Icon
            iconName="edit-inline"
            size="29"
            classes="mr-3 cursor-pointer"
            onClick={() => startEditKitItem(row._id)}
          />
        </>
      ),
      classes: 'py-0 text-right no-text-truncate',
      headerStyle: { width: '5%' },
      style: { width: '5%' }
    }
  ]
};
