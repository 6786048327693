import i18next from 'i18next';

export default class User {
  constructor(
    _id = '',
    email = '',
    name = '',
    token = '',
    type = '',
    language = i18next.language
  ) {
    this._id = _id;
    this.email = email;
    this.name = name;
    this.token = token;
    this.type = type;
    this.language = language;
  }
}
