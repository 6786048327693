import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';

import { Loader, BreadCrumbs, WrapperComponent } from './_shared/components';
import {
  siteAdminRoutes,
  superAdminRoutes,
  superSuperAdminRoutes
} from './routes';
import { authenticationActions } from './_shared/redux/actions';

import {
  Login as SiteAdminLogin,
  Registration as SiteAdminRegistration,
  ForgotPassword as SiteAdminForgotPassword,
  ResetPassword as SiteAdminResetPassword,
  DeleteAccountRequest as SiteAdminDeleteAccountRequest
} from './site-admin/pages';
import {
  NavBar as SiteAdminNavBar,
  Footer as SiteAdminFooter
} from './site-admin/components';
import { Login as SuperAdminLogin, Dashboard } from './super-admin/pages';
import {
  NavBar as SuperAdminNavBar,
  SuperAdminFooter
} from './super-admin/components';
import ReactComment from './_shared/components/ReactComment';
import MDSpinner from 'react-md-spinner';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { ColorConstants, LOCALE_YUP } from './_shared/constants';
import NotAllowed from './super-admin/pages/NotAllowed';
import SuccessMessage from './site-admin/pages/ResetPassword/SuccessMessage';

export class App extends React.Component {
  translation = this.props.t;
  state = {
    currentPath: 'login'
  };
  updateCurrentLocation = currentPath => this.setState({ currentPath });
  render() {
    return (
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center h-100">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        }
      >
        <ReactComment
          text={
            this.translation('appVersion') +
            ': ' +
            process.env.REACT_APP_VERSION
          }
        />
        <Loader show={this.props.isLoading} size="sm" />
        <Container
          fluid={true}
          className={
            'wrapper' +
            (this.props.user && this.props.user.type === 'site'
              ? ' wrapper-site-admin'
              : this.props.user &&
                (this.props.user.type === 'super' ||
                  this.props.user.type === 'super-super')
              ? ' wrapper-super-admin'
              : '') +
            (!this.props.loggedIn
              ? ' wrapper-path-' + this.state.currentPath
              : '')
          }
        >
          {this.props.loggedIn ? (
            this.props.user.type === 'site' ? (
              <Router basename={process.env.PUBLIC_URL}>
                <>
                  <SiteAdminNavBar
                    firstName={this.props.user.name}
                    imageSrc={this.props.user.imageSrc}
                    alert={this.props.user.alert}
                  />
                  <Container fluid className="mt-3">
                    <BreadCrumbs />
                    <Switch>
                      {siteAdminRoutes.map((route, i) => {
                        return (
                          <Route
                            key={i}
                            exact={route.exact === 'true'}
                            path={route.path}
                            render={props => {
                              return (
                                <WrapperComponent
                                  history={props.history}
                                  updateCurrentLocation={lastPathName =>
                                    this.updateCurrentLocation(lastPathName)
                                  }
                                >
                                  <route.component {...props} />
                                </WrapperComponent>
                              );
                            }}
                          />
                        );
                      })}
                      <Route
                        render={props => {
                          props.history.push('/home');
                          return <></>;
                        }}
                      />
                    </Switch>
                  </Container>
                  <SiteAdminFooter />
                </>
              </Router>
            ) : this.props.user.type === 'super' ||
              this.props.user.type === 'super-super' ? (
              <Router basename={process.env.PUBLIC_URL}>
                <>
                  <SuperAdminNavBar
                    firstName={this.props.user.name}
                    imageSrc={this.props.user.imageSrc}
                    alert={this.props.user.alert}
                    userType={this.props.user.type}
                  />
                  <Container fluid className="mt-3">
                    <BreadCrumbs />
                    <Switch>
                      {superAdminRoutes.map((route, i) => (
                        <Route
                          key={i}
                          exact={route.exact === 'true'}
                          path={route.path}
                          render={props => {
                            if (route.guard) {
                              if (this.props.user.type === route.guard) {
                                return (
                                  <WrapperComponent
                                    history={props.history}
                                    updateCurrentLocation={lastPathName =>
                                      this.updateCurrentLocation(lastPathName)
                                    }
                                  >
                                    <route.component {...props} />
                                  </WrapperComponent>
                                );
                              } else {
                                return <NotAllowed {...props} />;
                              }
                            } else {
                              return (
                                <WrapperComponent
                                  history={props.history}
                                  updateCurrentLocation={lastPathName =>
                                    this.updateCurrentLocation(lastPathName)
                                  }
                                >
                                  <route.component {...props} />
                                </WrapperComponent>
                              );
                            }
                          }}
                        />
                      ))}
                      {this.props.user.type === 'super-super' &&
                        superSuperAdminRoutes.map((route, i) => (
                          <Route
                            key={i}
                            exact={route.exact === 'true'}
                            path={route.path}
                            render={props => {
                              return (
                                <WrapperComponent
                                  history={props.history}
                                  updateCurrentLocation={lastPathName =>
                                    this.updateCurrentLocation(lastPathName)
                                  }
                                >
                                  <route.component {...props} />
                                </WrapperComponent>
                              );
                            }}
                          />
                        ))}
                      <Redirect from="/login" to="/admin/dashboard" />
                      />
                      <Route
                        render={props => {
                          return (
                            <WrapperComponent
                              history={props.history}
                              updateCurrentLocation={lastPathName =>
                                this.updateCurrentLocation(lastPathName)
                              }
                            >
                              <Dashboard {...props} />
                            </WrapperComponent>
                          );
                        }}
                      />
                    </Switch>
                    <SuperAdminFooter />
                  </Container>
                </>
              </Router>
            ) : (
              this.translation('invalidUserType')
            )
          ) : (
            <Router basename={process.env.PUBLIC_URL}>
              <Switch>
                <Route
                  exact
                  path={'/'}
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminLogin {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/login"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminLogin {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/password-changed-success"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SuccessMessage {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/registration"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminRegistration {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/forgot-password"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminForgotPassword {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/delete-account-request"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminDeleteAccountRequest {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/reset-password"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminResetPassword {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/admin/login"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SuperAdminLogin {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/admin/forgot-password"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminForgotPassword {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/admin/reset-password"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        {/* <SuperAdminResetPassword {...props} /> */}
                        <SiteAdminResetPassword {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  path="/admin"
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SuperAdminLogin {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
                <Route
                  render={props => {
                    return (
                      <WrapperComponent
                        history={props.history}
                        updateCurrentLocation={lastPathName =>
                          this.updateCurrentLocation(lastPathName)
                        }
                      >
                        <SiteAdminLogin {...props} />
                      </WrapperComponent>
                    );
                  }}
                />
              </Switch>
            </Router>
          )}
        </Container>
      </Suspense>
    );
  }
}

const mapStateToProps = state => {
  const { loading, authentication } = state;
  const { isLoading } = loading;
  const { loggedIn, user } = authentication;
  return {
    isLoading,
    loggedIn,
    user
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authenticationActions.logout())
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
