// @flow
import * as React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Subscription, Observable } from 'rxjs';
import { mergeMap, map, concatMap } from 'rxjs/operators';

import UserAccountDetails from './Details';
import UserAccountPrimaryContact from './PrimaryContact';
import UserAccountSecondaryContact from './SecondaryContact';
import UserAccountAddress from './Address';

import { ColorConstants } from '../../../_shared/constants';
import { UserService, ImageService } from '../../../_shared/services';
import { Icon } from '../../../_shared/components';
import {
  loaderActions,
  authenticationActions
} from '../../../_shared/redux/actions';
import { UserAccountModel } from '../../../models';
import { errorParser } from '../../../_shared/helpers';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

type State = {
  account: UserAccountModel,
  isLoading: boolean,
  editDetails: boolean,
  editPrimaryContact: boolean,
  editSecondaryContact: boolean,
  editAddress: boolean
};

type Props = {
  startLoading(): void,
  stopLoading(): void,
  updateUser(user: any): void,
  history: any,
  t: i18next.TFunction
};

export class UserAccount extends React.Component<Props, State> {
  translation: i18next.TFunction = this.props.t;
  cleanup: any;
  submitDetailForm: any;
  submitPrimaryContactForm: any;
  submitSecondaryContactForm: any;
  submitAddressForm: any;
  state = {
    account: {
      _id: '',
      email: '',
      name: '',
      lastName: '',
      phoneNumber: '',
      userType: 'individual',
      isVerified: false,
      type: 'site',
      isDeleted: false,
      isPasswordReset: false,
      imageSrc: '',
      primaryContact: { name: '', lastName: '', phoneNumber: '', email: '' },
      secondaryContact: { name: '', lastName: '', phoneNumber: '', email: '' },
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        zipCode: ''
      }
    },
    isLoading: true,
    editDetails: false,
    editPrimaryContact: false,
    editSecondaryContact: false,
    editAddress: false
  };
  /**Perform network calls, once component is mounted */
  componentDidMount() {
    this.getAccountDetails();
  }
  /**Free up resources on unmount */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Call api to get current user's account details */
  getAccountDetails() {
    this.setState({ isLoading: true });
    const getCurrentUser$ = UserService.getCurrentUser().subscribe({
      next: (response: {
        data: {
          currentUser: UserAccountModel
        }
      }) => {
        if (this.cleanup) {
          const account = Object.assign(
            this.state.account,
            response.data.currentUser
          );
          this.setState({ account, isLoading: false }, () => {
            this.updateLocalStorageUser();
          });
        }
      },
      error: (errorResponse: { data: { message: string } }) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getCurrentUser$.unsubscribe();
    };
  }

  /**Submit form handler for each form */
  handleSubmitForm = (whichForm: any) => {
    switch (whichForm) {
      case 'detail':
        if (this.submitDetailForm) {
          this.submitDetailForm();
        }
        break;
      case 'primaryContact':
        if (this.submitPrimaryContactForm) {
          this.submitPrimaryContactForm();
        }
        break;
      case 'secondaryContact':
        if (this.submitSecondaryContactForm) {
          this.submitSecondaryContactForm();
        }
        break;
      case 'address':
        if (this.submitAddressForm) {
          this.submitAddressForm();
        }
        break;
      default:
        break;
    }
  };

  /**Submit form binder for each form, so that we can call onSubmit from parent */
  bindSubmitForm = (whichForm: string, submitForm: any) => {
    switch (whichForm) {
      case 'detail':
        this.submitDetailForm = submitForm;
        break;
      case 'primaryContact':
        this.submitPrimaryContactForm = submitForm;
        break;
      case 'secondaryContact':
        this.submitSecondaryContactForm = submitForm;
        break;
      case 'address':
        this.submitAddressForm = submitForm;
        break;
      default:
        break;
    }
  };

  /**Submit form to update details */
  onSubmit = (values: any, imageFile?: File) => {
    this.props.startLoading();
    this.setState({
      editDetails: false,
      editPrimaryContact: false,
      editSecondaryContact: false,
      editAddress: false
    });
    let observable$: Observable;
    let subscription$: Subscription;
    // check if user has removed/changed image
    if (!values.imageSrc && this.state.account.imageSrc) {
      observable$ = ImageService.deleteImage(
        'user',
        this.state.account._id
      ).pipe(mergeMap(() => this.completeOnSubmit(values, imageFile)));
    } else {
      observable$ = this.completeOnSubmit(values, imageFile);
    }
    subscription$ = observable$.subscribe(this.handleResponse());
    this.cleanup = () => {
      if (subscription$) {
        subscription$.unsubscribe();
      }
    };
  };
  completeOnSubmit(account: any, imageFile?: File) {
    let observable$;
    if (imageFile) {
      // let's upload image first
      observable$ = ImageService.startUploadImage(imageFile).pipe(
        map(
          (uploadMageResponse: {
            responseData: {
              imageSrc: string
            }
          }) => uploadMageResponse.responseData.imageSrc
        ),
        concatMap((imageSrc: string) => {
          const updatedAccount = Object.assign(this.state.account, {
            ...account,
            imageSrc
          });
          return UserService.updateCurrentUser(updatedAccount);
        })
      );
    } else {
      observable$ = UserService.updateCurrentUser(account);
    }
    return observable$;
  }

  handleResponse() {
    return {
      next: () => {
        this.props.stopLoading();
        toastr.success(
          this.translation('accountUpdated'),
          this.translation('accountUpdatedSuccess')
        );
        this.getAccountDetails();
      },
      error: (errorResponse: { data: { message: string } }) => {
        this.props.stopLoading();
        toastr.error(this.translation('error'), errorResponse.data.message);
      }
    };
  }
  updateLocalStorageUser(
    imageSrc: string = this.state.account.imageSrc,
    name: string = this.state.account.name,
    lastName: string = this.state.account.lastName,
    email: string = this.state.account.email
  ) {
    this.props.updateUser({
      name,
      lastName,
      email,
      imageSrc
    });
  }

  render() {
    return (
      <Container fluid>
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Row className="justify-content-between">
                <Col>
                  <h3 className="mb-4">{this.translation('details')}</h3>
                </Col>
                <Col className="text-right">
                  {this.state.editDetails ? (
                    <>
                      <Button
                        variant="secondary"
                        className="mr-3 rounded-0"
                        onClick={() => this.setState({ editDetails: false })}
                      >
                        {this.translation('cancel')}
                      </Button>
                      <Button
                        variant="success"
                        className="rounded-0"
                        onClick={() => this.handleSubmitForm('detail')}
                      >
                        {this.translation('saveChanges')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant=""
                      onClick={() => this.setState({ editDetails: true })}
                    >
                      {this.translation('edit')}
                      <Icon iconName="edit" size="26" classes="ml-2" />
                    </Button>
                  )}
                </Col>
              </Row>
              <UserAccountDetails
                details={{
                  name: this.state.account.name,
                  lastName: this.state.account.lastName,
                  email: this.state.account.email,
                  userType: this.state.account.userType,
                  imageSrc: this.state.account.imageSrc,
                  phoneNumber: this.state.account.phoneNumber
                }}
                editDetails={this.state.editDetails}
                bindSubmitForm={this.bindSubmitForm}
                onSubmit={(
                  values: UserAccountModel,
                  imageFile: File,
                  isDiffThanPrevious: boolean
                ) => {
                  if (isDiffThanPrevious) {
                    this.onSubmit(values, imageFile);
                  } else {
                    this.setState({ editDetails: false });
                  }
                }}
                startImageEdit={() => this.setState({ editDetails: true })}
                goTo={path => this.props.history.push(path)}
              />
            </Card.Body>
            <Card.Body>
              <Row className="justify-content-between">
                <Col>
                  <h3 className="mb-4">{this.translation('primaryContact')}</h3>
                </Col>
                <Col className="text-right">
                  {this.state.editPrimaryContact ? (
                    <>
                      <Button
                        variant="secondary"
                        className="mr-3 rounded-0"
                        onClick={() =>
                          this.setState({ editPrimaryContact: false })
                        }
                      >
                        {this.translation('cancel')}
                      </Button>
                      <Button
                        variant="success"
                        className="rounded-0"
                        onClick={() => this.handleSubmitForm('primaryContact')}
                      >
                        {this.translation('saveChanges')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant=""
                      onClick={() =>
                        this.setState({ editPrimaryContact: true })
                      }
                    >
                      {this.translation('edit')}
                      <Icon iconName="edit" size="26" classes="ml-2" />
                    </Button>
                  )}
                </Col>
              </Row>
              <UserAccountPrimaryContact
                details={this.state.account.primaryContact}
                editPrimaryContact={this.state.editPrimaryContact}
                bindSubmitForm={this.bindSubmitForm}
                onSubmit={values => this.onSubmit(values)}
              />
            </Card.Body>
            <Card.Body>
              <Row className="justify-content-between">
                <Col>
                  <h3 className="mb-4">
                    {this.translation('secondaryContact')}
                  </h3>
                </Col>
                <Col className="text-right">
                  {this.state.editSecondaryContact ? (
                    <>
                      <Button
                        variant="secondary"
                        className="mr-3 rounded-0"
                        onClick={() =>
                          this.setState({ editSecondaryContact: false })
                        }
                      >
                        {this.translation('cancel')}
                      </Button>
                      <Button
                        variant="success"
                        className="rounded-0"
                        onClick={() =>
                          this.handleSubmitForm('secondaryContact')
                        }
                      >
                        {this.translation('saveChanges')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant=""
                      onClick={() =>
                        this.setState({ editSecondaryContact: true })
                      }
                    >
                      {this.translation('edit')}
                      <Icon iconName="edit" size="26" classes="ml-2" />
                    </Button>
                  )}
                </Col>
              </Row>
              <UserAccountSecondaryContact
                details={this.state.account.secondaryContact}
                editSecondaryContact={this.state.editSecondaryContact}
                bindSubmitForm={this.bindSubmitForm}
                onSubmit={values => this.onSubmit(values)}
              />
            </Card.Body>
            <Card.Body>
              <Row className="justify-content-between">
                <Col>
                  <h3 className="mb-4">{this.translation('address_label')}</h3>
                </Col>
                <Col className="text-right">
                  {this.state.editAddress ? (
                    <>
                      <Button
                        variant="secondary"
                        className="mr-3 rounded-0"
                        onClick={() => this.setState({ editAddress: false })}
                      >
                        {this.translation('cancel')}
                      </Button>
                      <Button
                        variant="success"
                        className="rounded-0"
                        onClick={() => this.handleSubmitForm('address')}
                      >
                        {this.translation('saveChanges')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant=""
                      onClick={() => this.setState({ editAddress: true })}
                    >
                      {this.translation('edit')}
                      <Icon iconName="edit" size="26" classes="ml-2" />
                    </Button>
                  )}
                </Col>
              </Row>
              <UserAccountAddress
                details={this.state.account.address}
                editAddress={this.state.editAddress}
                bindSubmitForm={this.bindSubmitForm}
                onSubmit={values => this.onSubmit(values)}
              />
            </Card.Body>
          </Card>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop()),
  updateUser: user => dispatch(authenticationActions.update(user))
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(UserAccount)
);
