import React, { Component } from 'react';
import { Row, Col, ListGroup, Collapse, Form, Alert } from 'react-bootstrap';
import { Formik } from 'formik';

import { FormGroup } from '../../../_shared/components';

import { PrimaryContactSchema } from './form.schema.js';

class UserAccountPrimaryContact extends Component {
  constructor(props) {
    super(props);
    this.dbKeys = {
      'First-Name': 'name',
      'Last-Name': 'lastName',
      Email: 'email',
      'Phone-Number': 'phoneNumber'
    };
  }
  render() {
    const { details, editPrimaryContact, bindSubmitForm } = this.props;
    const { name, lastName, email, phoneNumber } = details;
    const formData = {
      'First-Name': name,
      'Last-Name': lastName,
      Email: email,
      'Phone-Number': phoneNumber
    };
    return (
      <>
        <Row className="align-items-center">
          <Col>
            {!editPrimaryContact ? (
              <ListGroup variant="flush" className="pl-md-4">
                {Object.keys(formData).map((key, index) => (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col>{key.replace('-', ' ')}</Col>
                      <Col md={{ offset: 2 }}>{formData[key]}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <Formik
                initialValues={{
                  'First-Name': name,
                  'Last-Name': lastName,
                  Email: email,
                  'Phone-Number': phoneNumber
                }}
                validationSchema={PrimaryContactSchema}
                onSubmit={values => {
                  const newValues = {};
                  Object.keys(values).forEach(key => {
                    newValues[this.dbKeys[key]] = values[key];
                  });
                  const primaryContactValues = { primaryContact: newValues };
                  this.props.onSubmit(primaryContactValues);
                }}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, touched, handleChange, handleSubmit, values }) => {
                  bindSubmitForm('primaryContact', handleSubmit);
                  return (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="form-group-list"
                    >
                      <Collapse in={!!alert.message}>
                        <div>
                          {!!alert.message && (
                            <Alert variant={alert.type}>{alert.message}</Alert>
                          )}
                        </div>
                      </Collapse>
                      <ListGroup variant="flush" className="pl-md-4">
                        {Object.keys(formData).map((key, index) => (
                          <ListGroup.Item key={index}>
                            <Row className="align-items-center">
                              <Col>{key.replace('-', ' ')}</Col>
                              <Col md={{ offset: 2 }}>
                                <FormGroup
                                  formControlName={key}
                                  type="text"
                                  label={key.replace('-', ' ')}
                                  handleChange={handleChange}
                                  touched={touched[key]}
                                  error={errors[key]}
                                  value={values[key]}
                                />
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default UserAccountPrimaryContact;
