export const userConstants = {
  REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

  UPDATE_USER: 'USER_UPDATE_SUCCESS'
};
