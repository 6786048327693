import React from 'react';
import { Row, Col, Form, Button, Alert, Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MDSpinner from 'react-md-spinner';

import {
  authenticationActions,
  alertActions
} from '../../../_shared/redux/actions';
import { FormGroup, LanguageSelector } from '../../../_shared/components';
import { withTranslation } from 'react-i18next';

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required()
    .email(),
  password: Yup.string()
    .required()
    .min(8)
});

export let injected;

export const InnerForm = ({
  handleSubmit,
  loggingIn,
  handleChange,
  touched,
  errors,
  translation,
  history,
  alert
}) => (
  <Form className="login-form" noValidate onSubmit={handleSubmit}>
    <h1 className="text-primary mb-4 d-none d-sm-none d-md-block">
      {translation('welcome')}
    </h1>
    <Collapse in={alert.message}>
      <div>
        <Alert variant={alert.type}>{alert.message}</Alert>
      </div>
    </Collapse>
    <FormGroup
      formControlName="email"
      type="email"
      label={translation('email')}
      icon="profile"
      disabled={loggingIn}
      handleChange={handleChange}
      touched={touched['email']}
      error={errors['email']}
      required
    />
    <FormGroup
      formControlName="password"
      type="password"
      label={translation('password')}
      icon="password"
      disabled={loggingIn}
      handleChange={handleChange}
      touched={touched['password']}
      error={errors['password']}
      required
    />
    <Button
      type="submit"
      variant="primary"
      size="lg"
      block
      className="rounded-0"
      disabled={loggingIn}
      data-testid="test-login-submit"
    >
      {loggingIn ? <MDSpinner singleColor="#ffffff" /> : translation('login')}
    </Button>
    <Button
      variant="link"
      className="float-right mb-md-5"
      onClick={() => history.push('/forgot-password')}
    >
      {translation('forgotPassword?')}
    </Button>
    {/* <div className="clearfix" />
    <p className="text-center">{translation('newToMobilize')}</p>
    <div className="w-100 text-center">
      <Button
        variant="link"
        size="lg"
        className="font-weight-bold"
        onClick={() => history.push('/registration')}
      >
        {translation('registerNow')}
      </Button>
    </div> */}
  </Form>
);

export class LoginForm extends React.Component {
  translation = this.props.t;
  render() {
    const {
      loggingIn,
      username,
      alert,
      loggedIn,
      login,
      clearAlert
    } = this.props;
    if (loggedIn) {
      this.props.history.push('/home');
    } else {
      return (
        <div className="h-100">
          <Row className="align-items-center justify-content-center login-form-row">
            <Col sm={8}>
              <Formik
                initialValues={{
                  email: username,
                  password: ''
                }}
                validationSchema={LoginSchema}
                onSubmit={values => {
                  clearAlert();
                  login(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {formikProps => {
                  injected = formikProps;
                  return (
                    <InnerForm
                      {...formikProps}
                      loggingIn={loggingIn}
                      alert={alert}
                      translation={this.translation}
                      history={this.props.history}
                    />
                  );
                }}
              </Formik>
            </Col>
          </Row>
          {/* <LanguageSelector className="text-center text-sm-center text-md-right" /> */}
        </div>
      );
    }
  }
}

const mapStatesToProps = state => {
  const { authentication, alert } = state;
  const { loggedIn, loggingIn, user } = authentication;
  const { username } = user;
  return { loggedIn, loggingIn, username, alert };
};

const mapDispatchToProps = dispatch => ({
  login: values => dispatch(authenticationActions.login(values)),
  clearAlert: () => dispatch(alertActions.clear())
});

export default withTranslation()(
  connect(
    mapStatesToProps,
    mapDispatchToProps
  )(LoginForm)
);
