// @flow
import * as React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';

type Props = {
  name: string,
  value: any,
  inline?: boolean,
  required?: Boolean,
  label: string,
  isInvalid?: boolean,
  id: string,
  'data-testid': string,
  className?: string,
  error?: string
};

type State = {};

class Checkbox extends React.Component<Props, State> {
  render() {
    const selectedProps = {
      name: this.props.name,
      value: this.props.value,
      inline: this.props.inline,
      required: this.props.required,
      label: this.props.label,
      isInvalid: this.props.isInvalid,
      id: this.props.id,
      'data-testid': this.props['data-testid'],
      className:
        (this.props.className ? this.props.className : '') + ' hide-feedback'
    };
    return (
      <Field name={this.props.name}>
        {({ field, form }) => (
          <Form.Check
            custom
            {...selectedProps}
            checked={field.value.includes(selectedProps.value)}
            onChange={() => {
              if (field.value.includes(selectedProps.value)) {
                const nextValue = field.value.filter(
                  value => value !== selectedProps.value
                );
                form.setFieldValue(selectedProps.name, nextValue);
              } else {
                const nextValue = field.value.concat(selectedProps.value);
                form.setFieldValue(selectedProps.name, nextValue);
              }
            }}
          />
        )}
      </Field>
    );
  }
}

export default Checkbox;
