import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';

import { ColorConstants, tableConstants } from '../../../../_shared/constants';
import { ProductService } from '../../../../_shared/services';
import { kitTableConstants } from './table.constants';
import { errorParser } from '../../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class Kits extends Component {
  translation: i18next.TFunction = this.props.t;
  /**Redirects */
  goTo = path => this.props.goTo(path);
  /**Resource cleaner */
  cleanup = null;
  /**Component state */
  state = {
    isTableLoading: true,
    isFileLoading: false,
    kitsArrived: false,
    kits: [],
    columns: kitTableConstants.kitColumns(this.goTo),
    options: {},
    showAlert: true,
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'serviceExpiryDate',
    sortOrder: 'asc',
    page: 1
  };
  /**Call service apis when component mounted */
  componentDidMount() {
    this.getProducts();
  }
  /**Free up resources when unmounting */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    { sortField, sortOrder, tableData, page, sizePerPage }
  ) => {
    const currentIndex =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getProducts(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Handle observable subscribe response */
  handelResponse = () => ({
    next: response => {
      if (this.cleanup) {
        const {
          currentIndex,
          sizePerPage,
          sortField,
          sortOrder,
          page,
          searchText
        } = this.state;
        const { products, count } = response.data;
        const options = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          kitsArrived: true,
          options,
          kits: products,
          isTableLoading: false,
          currentIndex,
          sizePerPage,
          sortField,
          sortOrder,
          page,
          searchText
        });
      }
    },
    error: errorResponse => {
      if (this.cleanup) {
        this.setState({ kitsArrived: true, isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Call service to get my products */
  getProducts(
    currentIndex = this.state.currentIndex,
    sizePerPage = this.state.sizePerPage,
    sortField = this.state.sortField,
    sortOrder = this.state.sortOrder,
    searchText = this.state.searchText
  ) {
    this.setState({ isTableLoading: true });
    const getProductsByUserId$ = ProductService.getProductsByUserId(
      this.props.customerId,
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      getProductsByUserId$.unsubscribe();
    };
  }
  render() {
    const { t: translation } = this.props;
    return this.state.isTableLoading && !this.state.kitsArrived ? (
      <div className="w-100 text-center">
        <MDSpinner singleColor={ColorConstants.PRIMARY} />
      </div>
    ) : (
      <BootstrapTable
        remote
        classes="with-gap"
        bordered={false}
        bootstrap4
        keyField="_id"
        data={this.state.kits}
        columns={this.state.columns}
        pagination={
          this.state.kits.length ? paginationFactory(this.state.options) : null
        }
        noDataIndication={
          this.state.isTableLoading
            ? translation('pleaseWait')
            : translation('noData')
        }
        rowClasses="colored-background"
        onTableChange={this.onTableChange}
        loading={this.state.isTableLoading}
        overlay={tableConstants.overlay()}
        wrapperClasses="table-responsive-sm"
        defaultSorted={[
          {
            dataField: this.state.sortField,
            order: this.state.sortOrder
          }
        ]}
      />
    );
  }
}

export default withTranslation()(Kits);
