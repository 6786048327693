import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Collapse,
  Alert
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import {
  FormGroup,
  RegistrationSuccessfulModal
} from '../../../_shared/components';
import { equalTo, errorParser } from '../../../_shared/helpers';
import { loaderActions } from '../../../_shared/redux/actions';

import UserPlaceholderSRC from '../../../../assets/images/user.png';
import { ImageService, SuperAdminService } from '../../../_shared/services';
import { ALLOWED_IMAGE, EMAIL_REGEXP } from '../../../_shared/constants';
import { withTranslation, Translation } from 'react-i18next';
import i18next from 'i18next';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const NewSuperAdminFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  lastName: Yup.string(),
  email: Yup.string()
    .email()
    .required()
    .matches(
      EMAIL_REGEXP,
      <Translation>{t => t('formValidations.email')}</Translation>
    ),
  'Confirm-Email': Yup.string()
    .required()
    .email()
    .equalTo(
      Yup.ref('email'),
      <Translation>
        {t => <>{t('formValidations.emailsMustMatch')}</>}
      </Translation>
    )
});

export class NewSuperAdmin extends Component {
  translation = this.props.t;
  state = {
    showSuccessModal: false,
    imageAdded: false,
    placeHolderImage: undefined,
    userImageFile: undefined,
    imageError: ''
  };
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.imagePreviewer = React.createRef();
  }
  componentDidMount() {
    if (this.imagePreviewer.current) {
      const placeHolderImage = this.imagePreviewer.current.children[0];
      this.setState({ placeHolderImage });
    }
  }
  /**Handle user input files and preview image */
  handleFiles = files => {
    const file = files[0];
    if (/\.(jpe?g|png)$/i.test(file.name)) {
      if (file.size > ALLOWED_IMAGE.USER_PROFILE.SIZE) {
        const imageError = this.translation('fileSizeShouldBeLessThan', {
          size: '1 MB'
        });
        this.setState({ imageError });
        toastr.warning(this.translation('warning'), imageError);
      } else {
        const reader = new FileReader();
        reader.onload = readerOnLoadEvent => {
          var image = new Image();
          image.style.maxHeight = ALLOWED_IMAGE.USER_PROFILE.HEIGHT + 'px';
          image.classList.add('img-fluid');
          image.classList.add('rounded-circle');
          image.classList.add('border');
          image.classList.add('border-dark');
          image.classList.add('p-1');
          image.title = file.name;
          image.src = readerOnLoadEvent.target.result;
          image.onload = ImageOnloadEVent => {
            const target = ImageOnloadEVent.target;
            if (
              target.height >= ALLOWED_IMAGE.USER_PROFILE.HEIGHT &&
              target.width >= ALLOWED_IMAGE.USER_PROFILE.WIDTH
            ) {
              const oldImageElement = this.imagePreviewer.current.children[0];
              this.imagePreviewer.current.removeChild(oldImageElement);
              this.imagePreviewer.current.appendChild(image);
              this.setState({ imageAdded: true, userImageFile: file });
            } else {
              const imageError = this.translation('imageDimensionShouldBe', {
                context: 'minimum',
                dimension: '200px X 200px'
              });
              this.setState({ imageError });
              toastr.warning(this.translation('warning'), imageError);
            }
          };
        };
        reader.readAsDataURL(file);
      }
    } else {
      const imageError = this.translation('allowedFileTypes', {
        fileTypes: 'image(.jpg, .jpeg and .png)'
      });
      this.setState({ imageError });
      toastr.warning(this.translation('warning'), imageError);
    }
  };
  /**User removes the image */
  removeImage = () => {
    this.setState({ imageAdded: false, userImageFile: undefined });
    const oldImageElement = this.imagePreviewer.current.children[0];
    this.imagePreviewer.current.removeChild(oldImageElement);
    this.imagePreviewer.current.appendChild(this.state.placeHolderImage);
  };
  render() {
    return (
      <React.Fragment>
        <RegistrationSuccessfulModal
          show={this.state.showSuccessModal}
          goTo={this.props.history.push}
        />
        <Container fluid>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={12} md="auto" className="text-center mb-3">
                  <div ref={this.imagePreviewer}>
                    <img
                      alt="User"
                      className="border rounded-circle p-1 border-dark"
                      src={UserPlaceholderSRC}
                      height="150"
                    />
                  </div>
                  <Button
                    variant="link"
                    className="text-blue"
                    onClick={() => this.fileInput.current.click()}
                  >
                    {i18next.t('changeImage')}
                  </Button>
                  {this.state.imageAdded ? (
                    <Button
                      variant="link"
                      className="text-blue"
                      onClick={() => this.removeImage()}
                    >
                      {i18next.t('remove')}
                    </Button>
                  ) : (
                    ''
                  )}
                  <input
                    type="file"
                    ref={this.fileInput}
                    className="d-none"
                    onChange={inputChangeEvent =>
                      this.handleFiles(inputChangeEvent.target.files)
                    }
                  />
                </Col>
                <Col sm={12} md className="mb-3">
                  <Formik
                    initialValues={{
                      name: '',
                      lastName: '',
                      email: '',
                      'Confirm-Email': ''
                    }}
                    // validate={values => {
                    //   let errors = {};
                    //   if (values['Confirm-Email']) {
                    //     if (values['Confirm-Email'] !== values['email']) {
                    //       errors['Confirm-Email'] = 'Emails must match';
                    //     }
                    //   } else {
                    //     errors['Confirm-Email'] = 'Required';
                    //   }
                    //   return errors;
                    // }}
                    validationSchema={NewSuperAdminFormSchema}
                    onSubmit={values => {
                      this.props.startLoading();
                      if (this.state.imageAdded) {
                        ImageService.startUploadImage(
                          this.state.userImageFile
                        ).subscribe({
                          next: response => {
                            this.addSuperAdmin(values, response);
                          },
                          error: errorResponse => {
                            toastr.error(
                              'Error while uploading image',
                              errorParser(errorResponse)
                            );
                            this.props.stopLoading();
                          }
                        });
                      } else {
                        this.addSuperAdmin(values);
                      }
                    }}
                    validateOnChange={false}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit
                    }) => {
                      return (
                        <Form
                          className="new-super-admin-form"
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          <Collapse in={!!alert.message}>
                            <div>
                              {!!alert.message && (
                                <Alert variant={alert.type}>
                                  {alert.message}
                                </Alert>
                              )}
                            </div>
                          </Collapse>
                          <Form.Row>
                            <Col>
                              <FormGroup
                                formControlName="name"
                                type="text"
                                label={this.translation('firstName')}
                                handleChange={handleChange}
                                touched={touched['name']}
                                error={errors['name']}
                                value={values.name}
                                required
                              />
                            </Col>
                            <Col className="pl-md-2">
                              <FormGroup
                                formControlName="lastName"
                                type="text"
                                label={this.translation('lastName')}
                                handleChange={handleChange}
                                touched={touched['lastName']}
                                error={errors['lastName']}
                                value={values.lastName}
                                required
                              />
                            </Col>
                          </Form.Row>
                          <FormGroup
                            formControlName="email"
                            type="email"
                            label={this.translation('email')}
                            handleChange={handleChange}
                            touched={touched['email']}
                            error={errors['email']}
                            value={values.email}
                            required
                          />
                          <FormGroup
                            formControlName="Confirm-Email"
                            type="email"
                            label={this.translation('confirmEmail')}
                            handleChange={handleChange}
                            touched={touched['Confirm-Email']}
                            error={errors['Confirm-Email']}
                            value={values['Confirm-Email']}
                            required
                          />
                          <Button
                            type="submit"
                            variant="success"
                            size="lg"
                            className="rounded-0 float-right px-5"
                          >
                            {i18next.t('addAdmin')}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </React.Fragment>
    );
  }

  addSuperAdmin(values, response = null) {
    const imageSrc = response ? response.responseData.imageSrc : '';
    SuperAdminService.addSuperAdmin(
      Object.assign(values, {
        imageSrc,
        type: 'super-super',
        userType: 'individual'
      })
    ).subscribe({
      next: () => {
        this.props.stopLoading();
        this.setState({ showSuccessModal: true });
      },
      error: errorResponse => {
        toastr.warning(this.translation('error'), errorParser(errorResponse));
        this.props.stopLoading();
      }
    });
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(NewSuperAdmin)
);
