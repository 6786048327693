import React from 'react';

import { Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../../../_shared/helpers';
import {
  Icon,
  DatePicker,
  ImageContainer
} from '../../../../../_shared/components';
import { ColorConstants } from '../../../../../_shared/constants';
import i18next from 'i18next';

const itemImageFormatter = (cell, row) => {
  return (
    <ImageContainer src={cell} alt={row.name} height="40px" className="h-100" />
  );
};

export const productItemsTableConstants = {
  itemColumns: (
    setDate,
    setLotNumber,
    toggleEdit,
    onSubmit,
    productCreationDate
  ) => [
    {
      dataField: 'imageSrc',
      text: '',
      formatter: itemImageFormatter,
      classes: 'py-0 align-middle',
      headerStyle: { width: '8%' },
      style: { width: '8%' }
    },

    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemName')),
      sort: true,
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },

    {
      dataField: 'number',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemNumber')),
      sort: true
    },

    {
      dataField: 'lotNumbers',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('lotNumber')),
      isDummyField: true,
      formatter: (cell, row) => {
        if (row.editProductItems) {
          if (row.isLotNumberInvalid) {
            return (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-${row._id}`}>
                    {row.isLotNumberInvalid}
                  </Tooltip>
                }
              >
                <Form.Control
                  size="sm"
                  type="text"
                  className="rounded-0 is-invalid"
                  onChange={onChangeEvent =>
                    setLotNumber(onChangeEvent.target.value, row._id)
                  }
                  defaultValue={row.lotNumber}
                  placeholder="XXXXXXXX"
                />
              </OverlayTrigger>
            );
          } else {
            return (
              <Form.Control
                size="sm"
                type="text"
                className="rounded-0"
                onChange={onChangeEvent =>
                  setLotNumber(onChangeEvent.target.value, row._id)
                }
                defaultValue={row.lotNumber}
                placeholder="XXXXXXXX"
              />
            );
          }
        }
        return row.lotNumber;
      }
    },

    {
      dataField: 'quantity',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('quantity')),
      sort: true
    },

    {
      dataField: 'expiryDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('expiryDate')),
      classes: 'no-text-truncate',
      isDummyField: true,
      formatter: (cellContent, row) => {
        if (row.editProductItems) {
          return (
            <DatePicker
              selected={row.expiryDate ? new Date(row.expiryDate) : null}
              className="form-control-sm"
              onChange={date => setDate(date, row._id)}
              readOnly={!row.editProductItems}
              minDate={new Date(productCreationDate)}
            />
          );
        }

        return getFormattedDate(row.expiryDate);
      }
    },
    {
      dataField: 'action',
      text: '',
      isDummyField: true,
      classes: 'text-right',
      formatter: (cell, row) => {
        if (row.editProductItems) {
          return (
            <>
              <Button
                variant=""
                className=""
                onClick={() => toggleEdit(row._id)}
                title={i18next.t('cancel')}
              >
                <Icon
                  iconName="cancel-filled"
                  size="26"
                  fill={ColorConstants.PRIMARY}
                />
              </Button>
              <Button
                variant=""
                className=""
                onClick={() => onSubmit(row._id)}
                title={i18next.t('save')}
              >
                <Icon
                  iconName="check-circle"
                  size="26"
                  fill={ColorConstants.SUCCESS}
                />
              </Button>
            </>
          );
        }
        return (
          <Button
            variant=""
            className=""
            onClick={() => toggleEdit(row._id)}
            title={i18next.t('edit')}
          >
            <Icon iconName="edit-inline" size="26" />
          </Button>
        );
      }
    }
  ]
};
