import React from 'react';
import { Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../_shared/helpers';
import i18next from 'i18next';

export const customersTableConstants = {
  customerColumns: (presentDeleteConfirm, goTo) => [
    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('name')),
      sort: true,
      formatter: (cell, row) => {
        return row.name + ' ' + row.lastName;
      },
      headerStyle: { width: '40%' },
      style: { width: '40%' }
    },
    // {
    //   dataField: 'email',
    //   text: '',
    //   headerFormatter: (...theArgs) =>
    //     headerFormatterWithSort(...theArgs, i18next.t('email')),
    //   sort: true,
    //   headerStyle: { width: '30%' },
    //   style: { width: '30%' }
    // },
    {
      dataField: 'products',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(
          ...theArgs,
          i18next.t('kit', { count: 0 }) +
            ' ' +
            i18next.t('own', { context: 'past' })
        ),
      sort: true,
      formatter: (cell, row) => {
        return row.products.length;
      },
      headerStyle: { width: '30%' },
      style: { width: '30%' }
    },
    // {
    //   dataField: 'license.expirationDate',
    //   text: '',
    //   headerFormatter: (...theArgs) =>
    //     headerFormatterWithSort(...theArgs, i18next.t('servicePlan')),
    //   formatter: cell => getFormattedDate(cell),
    //   sort: true
    // },
    {
      dataField: 'isDeleted',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('status')),
      formatter: cell => (cell ? i18next.t('inactive') : i18next.t('active')),
      sort: true,
      headerStyle: { width: '20%' },
      style: { width: '20%' }
    },
    {
      dataField: 'actions',
      text: '',
      formatter: (cell, row) => (
        <>
          {/* <Icon
            iconName="delete-inline"
            size="29"
            classes="mx-2 cursor-pointer"
            onClick={() => presentDeleteConfirm(row._id, row.name, row.email)}
          /> */}
          <Icon
            iconName="right"
            size="29"
            fill={ColorConstants.DEFAULT_FONT}
            classes="mx-2 cursor-pointer"
            onClick={() => goTo('/admin/customers/details/' + row._id)}
          />
        </>
      ),
      classes: 'py-0 text-right'
    }
  ]
};
