import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Collapse,
  Alert
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import {
  FormGroup,
  MailSentSuccessfulModal
} from '../../../_shared/components';
import CompanyLogoSRC from '../../../../assets/images/header-logo.png';
import ZollLogo from '../../../../assets/images/zoll_logo.png';
import { AuthenticationService } from '../../../_shared/services';
import { loaderActions } from '../../../_shared/redux/actions';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import { PublicFooter } from '../../components';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required()
    .email()
});

export class ForgotPassword extends Component {
  translation = this.props.t;
  state = {
    showSuccessModal: false
  };
  render() {
    const { t: translation } = this.props;
    return (
      <>
        <MailSentSuccessfulModal
          show={this.state.showSuccessModal}
          okClicked={() => this.props.history.push('/login')}
        />
        <Container fluid>
          <Row className="justify-content-center w-100">
            <Col xs="12" md="8">
              <div className="my-md-5">
                <div className="px-5 my-md-5">
                  <Button
                    variant="link"
                    onClick={() => this.props.history.push('/login')}
                  >
                    <img
                      src={CompanyLogoSRC}
                      alt={translation('mobilizeRescueSystem')}
                      className="mb-4 cursor-pointer"
                    />
                    <img
                      src={ZollLogo}
                      height="70"
                      alt={translation('mobilizeRescueSystem')}
                      className="mb-4 cursor-pointer"
                    />
                  </Button>
                  <div className="pl-2">
                    <h2 className="font-weight-bold">
                      {translation('forgotPassword.forgotYourPassword')}
                    </h2>
                    <p className="mb-4">
                      {/* {translation('forgotPassword.dontWorry')} */}
                    </p>
                    <h3 className="mb-4">
                      {translation('forgotPassword.formInputHelper')}
                    </h3>
                    <Formik
                      initialStatus={{
                        email: ''
                      }}
                      validationSchema={ForgotPasswordSchema}
                      onSubmit={values => {
                        this.props.startLoading();
                        AuthenticationService.forgotPassword(
                          values.email
                        ).subscribe({
                          next: () => {
                            // show success modal
                            this.setState({ showSuccessModal: true });
                            this.props.stopLoading();
                          },
                          error: errorResponse => {
                            //some error occurred
                            toastr.error(
                              this.translation('error'),
                              errorParser(errorResponse)
                            );
                            this.props.stopLoading();
                          }
                        });
                      }}
                      validateOnBlur={true}
                      validateOnChange={true}
                    >
                      {({
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        values
                      }) => {
                        return (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Collapse in={!!alert.message}>
                              <div>
                                {!!alert.message && (
                                  <Alert variant={alert.type}>
                                    {alert.message}
                                  </Alert>
                                )}
                              </div>
                            </Collapse>
                            <FormGroup
                              formControlName="email"
                              type="text"
                              label={translation('forgotPassword.inputLabel')}
                              handleChange={handleChange}
                              touched={touched['email']}
                              error={errors['email']}
                              required
                            />
                            <Button
                              type="submit"
                              variant="success"
                              size="lg"
                              className="rounded-0 px-5 float-left mt-4"
                              disabled={!values['email'] || errors['email']}
                            >
                              {translation('submit')}
                            </Button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <PublicFooter />
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(ForgotPassword)
);
