/* eslint-disable no-console */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LoginBanner from './Banner';
import DeleteRequestForm from './Form';

class DeleteAccountRequest extends React.Component {
  render() {
    return (
      <div className="login-page vh-100">
        <Row className="h-md-100 h-lg-100 h-xl-100">
          <Col sm={12} md={5} className="pl-0">
            <LoginBanner />
          </Col>
          <Col sm={12} md={7}>
            <DeleteRequestForm history={this.props.history} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default DeleteAccountRequest;
