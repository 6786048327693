import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { connect } from 'react-redux';

import { ColorConstants, tableConstants } from '../../../_shared/constants';
import { FormGroup, Icon, ConfirmDialog } from '../../../_shared/components';
import {
  errorParser,
  isDevelopmentEnvironment
} from '../../../_shared/helpers';
import { servicePlanService, emailService } from '../../../_shared/services';

import { columns } from './table.constants';
import { withTranslation, Trans } from 'react-i18next';
import { loaderActions } from '../../../_shared/redux/actions';

export class ExpiringServicePlans extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = null;
  /**It will present confirm dialog before sending mail */
  presentEmailConfirm = (serialNumber, name, email, sendMailIndex) => {
    const emailConfirmMessage = (
      // eslint-disable-next-line react-intl/string-is-marked-for-translation
      <Trans i18nKey="sendMailConfirmationServicePlan">
        Do you really want to send email to <strong>{{ name }}</strong> (
        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
        {{ email }}) regarding Expiration of{' '}
        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
        <strong>Service Plan with Serial Number {{ serialNumber }}</strong>?
      </Trans>
    );
    const showEmailConfirm = true;
    this.setState({
      emailConfirmMessage,
      showEmailConfirm,
      sendMailIndex,
      userName: name
    });
  };
  /**Component state */
  state = {
    isTableLoading: true,
    isFileLoading: false,
    itemsArrived: false,
    items: [],
    columns: columns(this.presentEmailConfirm),
    options: {},
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'serviceExpiryDate',
    sortOrder: 'asc',
    _export: false,
    emailSearch: true,
    page: 1,
    searchText: ''
  };
  /**Call service apis when component mounted */
  componentDidMount() {
    this.getItems();
    this.onSearchChange();
  }
  /**Free up resources when unmounting */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$ = fromEvent(
      document.getElementById('expiring-items-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            page: 1,
            currentIndex: 0,
            searchText: inputEvent.target.value,
            isTableLoading: true
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state._export,
            this.state.emailSearch,
            this.state.searchText
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    { sortField, sortOrder, tableData, page, sizePerPage }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getItems(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Email confirm cancel click handler */
  emailCancelClicked = () => {
    const showEmailConfirm = false;
    this.setState({ showEmailConfirm });
  };
  /**Email confirm ok click handler */
  emailOkClicked = () => {
    const showEmailConfirm = false;
    this.setState({ showEmailConfirm });
    this.props.startLoading();
    emailService.sendMail(this.state.sendMailIndex, 'product').subscribe({
      next: response => {
        const sendMailIndex = this.state.sendMailIndex;
        let newItems = this.state.items.slice();
        newItems = newItems.map(item => {
          if (item._id === sendMailIndex) {
            return {
              ...item,
              mailStatus: 'sent'
            };
          }
          return item;
        });
        this.setState(curr => ({ ...curr, items: newItems }));
        toastr.success(
          this.translation('mailSent'),
          this.translation('mailSentToTheUser', {
            userName: this.state.userName
          })
        );
        this.props.stopLoading();
      },
      error: errorResponse => {
        if (this.cleanup) {
          toastr.error(
            this.translation('errorWhileSendingMail'),
            errorParser(errorResponse)
          );
          this.setState({ isExporting: false });
        }
      }
    });
  };
  /**Observable */
  getObservable = (
    currentIndex,
    sizePerPage,
    sortField,
    sortOrder,
    _export,
    emailSearch,
    searchText
  ) => {
    return servicePlanService.get(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      _export,
      emailSearch,
      searchText
    );
  };
  /**Subscriber-response handler */
  handelResponse = () => ({
    next: response => {
      if (this.cleanup) {
        const { sizePerPage, page } = this.state;
        const { products: items, count } = response.data;
        const options = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          items,
          isTableLoading: false,
          itemsArrived: true
        });
      }
    },
    error: errorResponse => {
      if (this.cleanup) {
        this.setState({ isTableLoading: false, itemsArrived: true });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Get expiring items */
  getItems = (
    currentIndex = this.state.currentIndex,
    sizePerPage = this.state.sizePerPage,
    sortField = this.state.sortField,
    sortOrder = this.state.sortOrder,
    _export = this.state._export,
    emailSearch = this.state.emailSearch,
    searchText = this.state.searchText
  ) => {
    this.setState({ isTableLoading: true });
    const getItems$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      _export,
      emailSearch,
      searchText
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      getItems$.unsubscribe();
    };
  };
  exportData = () => {
    this.setState({ isFileLoading: true });
    const servicePlanService$ = servicePlanService
      .get(
        this.state.currentIndex,
        this.state.sizePerPage,
        this.state.sortField,
        this.state.sortOrder,
        true, // export data true
        true, // email search flag
        this.state.searchText
      )
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.setState({
              isFileLoading: false
            });
            fileDownload(
              response.data,
              this.translation('unit', { count: 0 }) +
                '-' +
                moment().format('MM-DD-Y:HH-mm-ss') +
                '.csv'
            );
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            toastr.error(
              this.translation('errorWhileExporting'),
              errorParser(errorResponse)
            );
            this.setState({ isExporting: false });
          }
        }
      });
    this.cleanup = () => {
      servicePlanService$.unsubscribe();
    };
  };
  render() {
    const { t: translation } = this.props;
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showEmailConfirm}
          cancelBtn="No"
          okBtn="Yes"
          cancelClicked={() => this.emailCancelClicked()}
          okClicked={() => this.emailOkClicked()}
          message={this.state.emailConfirmMessage}
        />
        <Container fluid>
          <Row className="justify-content-between align-items-center">
            <Col sm={8} md={4} className="mb-3">
              <FormGroup
                type="text"
                label={
                  this.translation('searchByCustomerSNameEmailOrSerialNumber') +
                  '...'
                }
                title={
                  this.translation('searchByCustomerSNameEmailOrSerialNumber') +
                  '...'
                }
                icon="search"
                iconPosition="right"
                formControlId="expiring-items-search-input"
                classes="mb-2"
              />
            </Col>
            <Col xs="auto">
              <Button
                variant=""
                className={[
                  'mb-3',
                  !isDevelopmentEnvironment() ? 'd-none' : ''
                ].join(' ')}
                disabled={this.state.isTableLoading}
                onClick={() => {
                  this.exportData();
                }}
              >
                {this.state.isFileLoading ? (
                  <>
                    {this.translation('pleaseWait') + '...'}
                    <MDSpinner
                      singleColor={ColorConstants.PRIMARY}
                      className="ml-2"
                    />
                  </>
                ) : (
                  <>
                    {this.translation('exportToExcel')}
                    <Icon iconName="share" size="26" classes="ml-2" />
                  </>
                )}
              </Button>
            </Col>
          </Row>
          {this.state.isTableLoading && !this.state.itemsArrived ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <Row>
              <Col xs={12}>
                <BootstrapTable
                  remote
                  classes="with-gap"
                  bordered={false}
                  bootstrap4
                  keyField="_id"
                  data={this.state.items}
                  columns={this.state.columns}
                  pagination={
                    this.state.items.length
                      ? paginationFactory(this.state.options)
                      : null
                  }
                  wrapperClasses="table-responsive-sm"
                  noDataIndication={
                    this.state.isTableLoading
                      ? translation('pleaseWait')
                      : translation('noData')
                  }
                  rowClasses="colored-background"
                  loading={this.state.isTableLoading}
                  overlay={tableConstants.overlay()}
                  onTableChange={this.onTableChange}
                  defaultSorted={[
                    {
                      dataField: this.state.sortField,
                      order: this.state.sortOrder
                    }
                  ]}
                />
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(ExpiringServicePlans)
);
