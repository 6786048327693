// @flow
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import i18next from 'i18next';

type Props = {
  setState(state: any): void,
  state: any,
  t: i18next.TFunction
};

type State = {};

class NavButtons extends React.Component<Props, State> {
  translation = this.props.t;
  render() {
    return (
      <div className="mb-3">
        <Button
          variant={this.props.state.activeDetail === 'user' ? 'link' : ''}
          size="lg"
          onClick={() =>
            this.props.setState({
              activeDetail: 'user',
              addingLicense: false
            })
          }
        >
          {this.translation('user') + ' ' + this.translation('detail')}
        </Button>
        <span className="font-size-xl font-weight-lighter">|</span>
        <Button
          variant={this.props.state.activeDetail === 'license' ? 'link' : ''}
          size="lg"
          onClick={() => this.props.setState({ activeDetail: 'license' })}
        >
          {this.translation('licenseCode') +
            ' and ' +
            this.translation('serialNumber') +
            (this.props.state.addingLicense
              ? ' > ' + this.translation('add')
              : '')}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(NavButtons);
