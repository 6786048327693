import React from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions';

class WrapperComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props.history.listen((location, action) => {
      const pathNameArray = location.pathname.split('/');
      const pathNameArrayLength = pathNameArray.length;
      const lastPathName = pathNameArray[pathNameArrayLength - 1];
      this.props.updateCurrentLocation(lastPathName);
      // clear alert on location change
      this.props.clearAlerts();
    });
  }
  render() {
    return <>{this.props.children}</>;
  }
}

const mapDispatchToProps = dispatch => ({
  clearAlerts: () => dispatch(alertActions.clear())
});

export default connect(
  null,
  mapDispatchToProps
)(WrapperComponent);
