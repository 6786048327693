import React from 'react';
import * as Yup from 'yup';
import { Translation } from 'react-i18next';
import { PHONE_NUMBER_REGEXP } from '../../../../_shared/constants';

export const DetailSchema = Yup.object().shape({
  'First-Name': Yup.string().required(),
  'Last-Name': Yup.string(),
  Email: Yup.string().email(),
  'Phone-Number': Yup.string().matches(
    PHONE_NUMBER_REGEXP,
    <Translation>{t => <>{t('formValidations.phone')}</>}</Translation>
  )
});
export const PrimaryContactSchema = Yup.object().shape({
  'First-Name': Yup.string(),
  'Last-Name': Yup.string(),
  'Phone-Number': Yup.string().matches(
    PHONE_NUMBER_REGEXP,
    <Translation>{t => <>{t('formValidations.phone')}</>}</Translation>
  ),
  Email: Yup.string().email()
});
export const SecondaryContactSchema = Yup.object().shape({
  'First-Name': Yup.string(),
  'Last-Name': Yup.string(),
  'Phone-Number': Yup.string().matches(
    PHONE_NUMBER_REGEXP,
    <Translation>{t => <>{t('formValidations.phone')}</>}</Translation>
  ),
  Email: Yup.string().email()
});
export const AddressSchema = Yup.object().shape({
  'Street-Address-1': Yup.string(),
  'Street-Address-2': Yup.string(),
  City: Yup.string(),
  'State/Province': Yup.string(),
  Country: Yup.string(),
  'Zip-Code': Yup.string()
});
