import React from 'react';
import { Icon, ImageContainer } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../_shared/helpers';
import i18next from 'i18next';

const kitImageFormatter = (cell, row) => {
  return (
    <ImageContainer
      src={cell}
      alt={row.kit.name}
      height="50px"
      className="h-100"
    />
  );
};
const kitRegisteredFormatter = (cell, row) => {
  // return cell ? 'Yes' : 'No';
  return i18next.t('yes');
};

export const kitTableConstants = {
  kitColumns: [
    {
      dataField: 'kit.imageSrc',
      text: '',
      formatter: kitImageFormatter,
      classes: 'py-0',
      headerStyle: { width: '8%' },
      style: { width: '8%' }
    },
    {
      dataField: 'kit.name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('kitName')),
      sort: true,
      headerStyle: { width: '15%' },
      style: { width: '15%' }
    },
    {
      dataField: 'serialNumber',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('kitSerialNumber')),
      sort: true
    },
    {
      dataField: 'serviceExpiryDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('servicePlanRenewDate')),
      formatter: cell => getFormattedDate(cell),
      sort: true
    },
    {
      dataField: 'productCreatedDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('productCreatedDate')),
      formatter: cell => getFormattedDate(cell),
      sort: true
    },
    {
      dataField: 'registered',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(
          ...theArgs,
          i18next.t('register', { context: 'past' })
        ),
      formatter: kitRegisteredFormatter,
      sort: true
    },
    {
      dataField: '',
      text: '',
      formatter: () => (
        <Icon iconName="right" size="29" fill={ColorConstants.DEFAULT_FONT} />
      ),
      classes: 'py-0 text-right',
      headerStyle: { width: '5%' },
      style: { width: '5%' }
    }
  ]
};
