// @flow
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import i18next from 'i18next';

type Props = {
  setState(state: any): void,
  state: any,
  t: i18next.TFunction
};

type State = {};

class NavButtons extends React.Component<Props, State> {
  translation = this.props.t;
  render() {
    return (
      <div className="mb-3">
        <Button
          variant={this.props.state.activeDetail === 'customer' ? 'link' : ''}
          size="lg"
          onClick={() =>
            this.props.setState({
              activeDetail: 'customer',
              addingLicense: false
            })
          }
        >
          {this.translation('customer') + ' ' + this.translation('detail')}
        </Button>
        <span className="font-size-xl font-weight-lighter">|</span>
        <Button
          variant={this.props.state.activeDetail === 'license' ? 'link' : ''}
          size="lg"
          onClick={() => this.props.setState({ activeDetail: 'license' })}
        >
          {this.translation('license')}
        </Button>
        <span className="font-size-xl font-weight-lighter">|</span>
        <Button
          variant={this.props.state.activeDetail === 'kit' ? 'link' : ''}
          size="lg"
          onClick={() => this.props.setState({ activeDetail: 'kit' })}
        >
          {this.translation('unit_single', { count: 0 })}
        </Button>
        <span className="font-size-xl font-weight-lighter">|</span>
        <Button
          variant={this.props.state.activeDetail === 'users' ? 'link' : ''}
          size="lg"
          onClick={() => this.props.setState({ activeDetail: 'users' })}
        >
          {this.translation('users', { count: 0 })}
        </Button>
        {/* <Button
          variant={this.props.state.activeDetail === 'items' ? 'link' : ''}
          size="lg"
          onClick={() => this.props.setState({ activeDetail: 'items' })}
        >
          {this.translation('expire', { context: 'presentCont' })}{' '}
          {this.translation('item', { count: 0 })}
        </Button> */}
        <Button
          variant={this.props.state.isDeleted ? 'success' : 'primary'}
          size="lg"
          onClick={() => this.props.setState({ showConfirmDialog: true })}
          hidden={this.props.state.isDeleted === undefined}
          className="float-right rounded-0 mr-3"
        >
          {this.props.state.isDeleted
            ? this.translation('activate')
            : this.translation('deactivate')}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(NavButtons);
