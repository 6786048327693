import React from 'react';
import { Row, Col } from 'react-bootstrap';

import CompanyLogo from '../../../assets/images/company_logo.png';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../../_shared/components/LanguageSelector';

export class Footer extends React.Component {
  render() {
    const { t: translation } = this.props;
    return (
      <Row
        className="bg-primary p-4 position-absolute"
        style={{ left: 0, right: '15px', bottom: 0 }}
      >
        <Col
          sm={12}
          md={6}
          className="mb-3 text-center text-sm-center text-md-left"
        >
          <Row className="align-items-center">
            <Col
              sm
              md="auto"
              className="text-white text-left text-sm-left mb-3"
            >
              <img
                src={CompanyLogo}
                alt={translation('mobilizeCompanyLogo')}
                height="125"
                className="pl-4"
              />
            </Col>
            <Col
              sm
              md="auto"
              className="text-white text-right text-sm-right text-md-left mb-3"
            >
              <address className="mb-0">
                {translation('address.line1')}
                <br />
                {translation('address.line2')}
                <br />
                {translation('address.line3')}
                <br />
                {translation('address.line4')}
                <br />
                {translation('address.zipCode')}
              </address>
            </Col>
          </Row>
        </Col>
        <Col
          sm={12}
          md={6}
          className="text-center text-sm-center text-md-right pr-4 mb-3"
        >
          <Row className="h-100">
            <Col xs={12} className="mb-3">
              <a href="/#" className="text-white">
                {translation('disclaimers')}
              </a>
              <span className="px-2 text-white">|</span>
              <a href="/#" className="text-white">
                {translation('privacyPolicy')}
              </a>
              <span className="px-2 text-white">|</span>
              <a href="/#" className="text-white">
                {translation('termsOfUse')}
              </a>
              <span className="px-2 text-white">|</span>
              <a href="/#" className="text-white">
                {translation('eula')}
              </a>
            </Col>
            <Col xs={12}>
              <p className="text-white">
                <span title={translation('copyright')}>©</span>{' '}
                {new Date().getFullYear()} {translation('mobilizeRrsLlc')}.{' '}
                {translation('allRightsReserved')}
              </p>
            </Col>
            <Col xs={12}>
              <LanguageSelector theme="white" />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(Footer);
