import { stringify } from 'querystring';
import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../constants/api-endpoints.constants';
import { authHeader, AxiosSubscriber } from '../helpers';
import moment from 'moment';

export const DashboardService = {
  getChartsData,
  getInstallationsData
};

/**@function getChartsData
 * Get charts data from server for super admin
 * @param {Date} [start=<This year's first date>] - Start date
 * @param {Date} [end=<This year's last date>] - End date
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getChartsData(
  start = moment()
    .startOf('year')
    .format(),
  end = moment()
    .endOf('year')
    .format()
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ start, end })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DASHBOARD.GET_CHARTS_DATA
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

function getInstallationsData(
  start = moment()
    .subtract(1, 'months')
    .format(),
  end = moment().format(),
  startMonth = moment()
    .subtract(12, 'month')
    .startOf('month')
    .format(),
  endMonth = moment().format()
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ start, end, startMonth, endMonth })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DASHBOARD.GET_APPLICATION_STATISTICS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
