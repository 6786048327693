// @flow
import * as React from 'react';
import { Container, Row, Col, Card, Collapse } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import rafSchedule from 'raf-schd';
import DocumentPreviewModal from './DocumentPreviewModal';

import { FormGroup, Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import { DocumentService } from '../../../_shared/services';
import { IDocument } from '../../../models';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { errorParser } from '../../../_shared/helpers';

type Props = {
  t: i18next.TFunction
};

type State = {
  isLoading: boolean,
  isLoadingMore: boolean,
  documents: IDocument[],
  currentIndex: number,
  sizePerPage: number,
  sortOrder: 'asc' | 'desc',
  page: number,
  searchText: '',
  showPreviewModal: boolean,
  previewDocument: IDocument,
  pageY: number,
  totalRecords: number
};

export class DocumentsComponent extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Component's state */
  state = {
    isLoading: true,
    isLoadingMore: true,
    documents: [],
    currentIndex: 0,
    sizePerPage: 12,
    sortOrder: 'asc',
    page: 1,
    searchText: '',
    showPreviewModal: false,
    previewDocument: {
      _id: '',
      title: '',
      type: 'Image',
      url: ''
    },
    pageY: 0,
    totalRecords: 1
  };
  /**Call service apis when component mounted */
  componentDidMount() {
    window.onscroll = () => {
      this.handleScroll();
    };
    this.getDocuments();
    this.onSearchChange();
  }
  /**Free up resources when unmounting */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
    // Cancel any pending updates since we're unmounting.
    this.scheduleUpdate.cancel();
  }
  // Create a new function to schedule updates.
  scheduleUpdate = rafSchedule<any>((point: { x: number, y: number }) => {
    const screenHeight = window.screen.availHeight;
    const scrollHeight = document.getElementsByTagName('body')[0].scrollHeight;
    if (
      scrollHeight - (point.y + screenHeight) <= 250 &&
      point.y - this.state.pageY > 0
    ) {
      this.setState(
        state => {
          return {
            pageY: point.y,
            currentIndex: state.currentIndex + state.sizePerPage
          };
        },
        () => {
          if (this.state.currentIndex + 1 < this.state.totalRecords) {
            this.getDocuments();
          }
        }
      );
    }
  });
  /**Handle Search Input */
  onSearchChange = () => {
    const concatDocuments = false;
    const searchInput$ = fromEvent(
      document.getElementById('documents-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            currentIndex: 0,
            searchText: inputEvent.target.value,
            isLoading: true,
            pageY: 0,
            totalRecords: 1,
            page: 1
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.searchText
          );
        })
      )
      .subscribe(this.handelResponse(concatDocuments));
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    searchText: string
  ) => {
    return DocumentService.getDocuments(currentIndex, sizePerPage, searchText);
  };
  /**Subscriber-response handler */
  handelResponse = (concatDocuments: boolean = true) => ({
    next: (response: { data: { documents: IDocument[], count: number } }) => {
      if (this.cleanup) {
        let { documents, count } = response.data;
        if (concatDocuments) {
          documents = this.state.documents.concat(response.data.documents);
        }
        this.setState({
          documents,
          isLoading: false,
          isLoadingMore: false,
          totalRecords: count
        });
      }
    },
    error: (errorResponse: any) => {
      if (this.cleanup) {
        this.setState({ isLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  getDocuments = (
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    searchText: string = this.state.searchText
  ) => {
    this.setState({ isLoadingMore: true });
    const getDocuments$ = this.getObservable(
      currentIndex,
      sizePerPage,
      searchText
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      getDocuments$.unsubscribe();
    };
  };
  /**Show document preview */
  showPreview = (document: IDocument) => {
    this.setState(
      { previewDocument: document },
      this.setState({ showPreviewModal: true })
    );
  };
  handleScroll = () => {
    if (!this.state.isLoadingMore) {
      const doc: any = document.documentElement;
      // When we receive a scroll event, schedule an update.
      // If we receive many updates within a frame, we'll only publish the latest value.
      this.scheduleUpdate({
        x:
          (window.pageXOffset || doc ? doc.scrollLeft : 0) -
          (doc ? doc.clientLeft : false || 0),
        y:
          (window.pageYOffset || doc ? doc.scrollTop : 0) -
          (doc ? doc.clientTop : false || 0)
      });
    }
  };
  render() {
    return (
      <>
        <DocumentPreviewModal
          show={this.state.showPreviewModal}
          onHide={() => this.setState({ showPreviewModal: false })}
          onHidden={() => this.setState({ previewDocument: null })}
          document={this.state.previewDocument}
        />
        <Container fluid>
          <Row className="justify-content-between align-items-center">
            <Col sm={8} md={4} className="mb-4">
              <FormGroup
                formControlId="documents-search-input"
                type="text"
                label={this.translation('searchDocuments')}
                title={this.translation('searchDocuments')}
                icon="search"
                iconPosition="right"
                classes="mb-2"
              />
            </Col>
          </Row>
          {this.state.isLoading ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <>
              <Row className="documents-row">
                {this.state.documents.length ? (
                  this.state.documents.map((document, index) => (
                    <Col
                      xs={6}
                      sm={4}
                      md={3}
                      className={[
                        'mb-5',
                        (index + 1) % 1 === 0
                          ? 'pr-4'
                          : (index + 1) % 4 === 0
                          ? 'pl-4'
                          : 'px-4'
                      ].join(' ')}
                      key={index}
                    >
                      <Card
                        className="h-100 cursor-pointer hoverable fadeIn"
                        onClick={() => this.showPreview(document)}
                      >
                        <Card.Img
                          variant="top"
                          src={document.type === 'Image' ? document.url : ''}
                          className="object-fit-cover"
                          height="200"
                        />
                        <div className="document-thumb-overlay bg-dark-rgba-7 d-flex justify-content-center align-items-center">
                          <Icon
                            iconName={
                              document.type === 'Document'
                                ? 'document'
                                : document.type === 'Video'
                                ? 'media-player-play'
                                : 'picture'
                            }
                            size="75"
                            fill="#FFFFFF"
                          />
                        </div>
                        <Card.Body>
                          <Card.Title className="text-center mb-0">
                            {document.title}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p className="text-center w-100">
                    {this.translation('noDocumentsFound')}
                  </p>
                )}
              </Row>
              <Collapse in={this.state.isLoadingMore}>
                <div className="w-100 text-center">
                  <MDSpinner singleColor={ColorConstants.PRIMARY} />
                </div>
              </Collapse>
            </>
          )}
        </Container>
      </>
    );
  }
}

export default withTranslation()(DocumentsComponent);
