import React, { Component } from 'react';
import { Row, Col, Form, FormControl } from 'react-bootstrap';
import Icon from './Icon';
import { tick_then } from '../helpers';
import MDSpinner from 'react-md-spinner';
import { ColorConstants } from '../constants';

class ChipInput extends Component {
  constructor(props) {
    super(props);
    this.formControlRef = React.createRef();
  }
  state = {
    focused: false,
    exitingIndex: null
  };
  removeChip = index => {
    this.setState({ exitingIndex: index });
    tick_then(() => {
      this.props.onRemove(index);
      this.setState({ exitingIndex: null });
    }, 250);
  };
  editChip = index => {
    const chips = this.props.chips.slice();
    const editChipValue = chips[index];
    chips.splice(index, 1);
    this.setState({ chips });
    this.props.onRemove(index);
    tick_then(() => {
      this.formControlRef.current.value = editChipValue;
      this.formControlRef.current.focus();
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.chips.length !== this.props.chips.length) {
      this.formControlRef.current.value = '';
    }
  }
  render() {
    const {
      label = 'Enter Here...',
      disabled = false,
      loading = false,
      helpText
    } = this.props;
    return (
      <div className="mb-3">
        <div
          className={[
            this.props.classes,
            'chip-input',
            this.state.focused ? 'chip-input-focus' : ''
          ].join(' ')}
        >
          <Row className="align-items-center position-relative" noGutters>
            {loading ? (
              <div
                className="w-100 position-absolute bg-dark-rgba-1"
                style={{ zIndex: 1, top: 0, bottom: 0 }}
              >
                <div className="d-flex align-items-center justify-content-center h-100">
                  <MDSpinner singleColor={ColorConstants.PRIMARY} />
                </div>
              </div>
            ) : (
              ''
            )}
            {this.props.chips.map((chip, index) => (
              <Col xs="auto" className="p-2" key={index}>
                <div
                  className={[
                    'chip',
                    'show',
                    this.state.exitingIndex === index ? 'hide' : ''
                  ].join(' ')}
                  onDoubleClick={() => this.editChip(index)}
                >
                  {chip}
                  <Icon
                    iconName="cancel"
                    size="12"
                    classes="ml-3"
                    onClick={() => this.removeChip(index)}
                  />
                </div>
              </Col>
            ))}
            <Col xs>
              <Form
                className="custom-form-control"
                onSubmit={onSubmitEvent => {
                  onSubmitEvent.preventDefault();
                  this.props.onSubmit(this.formControlRef.current.value);
                }}
                noValidate
              >
                <FormControl
                  ref={this.formControlRef}
                  name="chipInput"
                  placeholder={label}
                  aria-label="Chip Input"
                  className="m-0 border-0 no-focus mb-0"
                  onFocus={() => this.setState({ focused: true })}
                  onBlur={() => this.setState({ focused: false })}
                  disabled={disabled}
                />
              </Form>
            </Col>
          </Row>
        </div>
        <Form.Text className="text-muted mt-2">{helpText}</Form.Text>
      </div>
    );
  }
}

export default ChipInput;
