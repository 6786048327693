import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import { FormGroup } from '../../../_shared/components';
import ZollLogo from '../../../../assets/images/zoll_logo.png';
import CompanyLogoSRC from '../../../../assets/images/header-logo.png';
import { equalTo, errorParser } from '../../../_shared/helpers';
import { UserService } from '../../../_shared/services/user.service';
import { loaderActions } from '../../../_shared/redux/actions';
import { withTranslation, Translation } from 'react-i18next';
import { PublicFooter } from '../../components';

export class ResetPasswordSuccessMessage extends Component {
  translation = this.props.t;
  render() {
    return (
      <Container fluid className="pt-5">
        <Row className="justify-content-center w-100">
          <Col xs="12" md="8">
            <div className="my-md-5">
              <div className="px-sm-0 px-md-5 px-0 my-md-5">
                <Button
                  variant="link"
                  onClick={() => this.props.history.push('/login')}
                >
                  <img
                    src={CompanyLogoSRC}
                    alt="Mobilize Rescue System"
                    className="mb-4 cursor-pointer"
                  />
                  <img
                    src={ZollLogo}
                    height="70"
                    alt="Mobilize Rescue System"
                    className="mb-4 cursor-pointer"
                  />
                </Button>
                <div className="pl-2">
                  <h3 className="mb-4">
                    {this.translation('PasswordSuccessfullyResetMessage')}
                  </h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <PublicFooter />
      </Container>
    );
  }
}

export default withTranslation()(ResetPasswordSuccessMessage);
