// @flow
import * as React from 'react';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Icon from './Icon';
import { ColorConstants, iconName } from '../constants';

type Props = {
  handleChange?: () => void,
  touched?: any,
  error?: any,
  formControlName: string,
  type?:
    | 'email'
    | 'text'
    | 'search'
    | 'number'
    | 'password'
    | 'checkbox'
    | 'date'
    | 'file'
    | 'radio',
  label: string,
  icon?: iconName,
  iconPosition?: 'left' | 'right',
  disabled?: boolean,
  required?: boolean,
  helpText?: string | any,
  value?: string,
  defaultValue?: string,
  classes?: string,
  alertPositionAbsolute?: boolean,
  formControlId?: string,
  min?: string | number,
  title?: string,
  as?: 'textarea',
  rows?: string
};

type State = {
  oldError: any,
  showHelp: boolean
};

class FormGroup extends React.Component<Props, State> {
  state = {
    oldError: '',
    showHelp: false
  };
  componentDidUpdate(prevProps: Props) {
    // Typical usage (don't forget to compare props):
    if (this.props.error !== prevProps.error) {
      this.storeError(this.props.error);
    }
  }
  storeError = (oldErr: any) => {
    if (oldErr) {
      this.setState({ oldError: oldErr });
    }
  };
  handleFocus = () => {
    this.setState({ showHelp: true });
  };
  handleBlur = () => {
    this.setState({ showHelp: false });
  };
  render() {
    const {
      handleChange,
      error,
      formControlName = 'form-name-not-given',
      type,
      label,
      icon,
      iconPosition = 'left',
      disabled,
      required,
      helpText,
      value,
      defaultValue,
      classes,
      formControlId = 'form-id-not-given',
      min = undefined,
      title = undefined,
      as,
      rows
    } = this.props;
    let inputGroupTextClasses = 'rounded-0 bg-white p-0 ';
    if (error) {
      inputGroupTextClasses += 'border-danger';
    }
    return (
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip
            id={`tooltip-${formControlId}`}
            className={error ? '' : 'd-none'}
          >
            {error}
          </Tooltip>
        }
      >
        {this.getMainFormComponent(
          classes,
          label,
          icon,
          iconPosition,
          inputGroupTextClasses,
          type,
          disabled,
          formControlId,
          formControlName,
          handleChange,
          error,
          required,
          value,
          defaultValue,
          min,
          title,
          helpText,
          as,
          rows
        )}
      </OverlayTrigger>
    );
  }

  getMainFormComponent(
    classes?: string,
    label: string,
    icon?: iconName,
    iconPosition?: 'left' | 'right',
    inputGroupTextClasses: string,
    type?:
      | 'email'
      | 'text'
      | 'search'
      | 'number'
      | 'password'
      | 'checkbox'
      | 'date'
      | 'file'
      | 'radio',
    disabled?: boolean,
    formControlId: string,
    formControlName: string,
    handleChange?: () => void,
    error?: string,
    required?: boolean,
    value?: string,
    defaultValue?: string,
    min?: string | number,
    title?: string,
    helpText?: string | any,
    as?: 'textarea',
    rows?: string
  ) {
    return (
      <Form.Group className={['custom-form-group', classes].join(' ')}>
        <Form.Label className="sr-only">{label}</Form.Label>
        <InputGroup>
          {icon && iconPosition === 'left' && (
            <InputGroup.Prepend>
              <InputGroup.Text className={inputGroupTextClasses}>
                <div className="svg-icon-container mw-100 w-100 border-right h-75 d-flex justify-content-center align-items-center">
                  <Icon iconName={icon} fill={error && ColorConstants.DANGER} />
                </div>
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Form.Control
            as={as}
            rows={rows}
            type={type}
            className={[
              'form-control',
              'rounded-0',
              !icon
                ? ''
                : iconPosition === 'left'
                ? 'border-left-0'
                : 'border-right-0'
            ].join(' ')}
            disabled={disabled}
            placeholder={label}
            id={formControlId ? formControlId : formControlName}
            name={formControlName}
            onChange={handleChange}
            isInvalid={!!error}
            required={required}
            value={value}
            defaultValue={defaultValue}
            onFocus={() => this.handleFocus()}
            onBlur={() => this.handleBlur()}
            min={min}
            onKeyDown={onKeyDownEvent => {
              // let's not allow spaces in passwords
              if (type === 'password') {
                if (onKeyDownEvent.which === 32) {
                  onKeyDownEvent.preventDefault();
                }
              }
            }}
            title={title}
            data-testid={'test-' + formControlName}
          />
          {icon && iconPosition === 'right' && (
            <InputGroup.Append>
              <InputGroup.Text className={inputGroupTextClasses}>
                <div className="svg-icon-container mw-100 w-100 border-left h-75 d-flex justify-content-center align-items-center">
                  <Icon iconName={icon} fill={error && ColorConstants.DANGER} />
                </div>
              </InputGroup.Text>
            </InputGroup.Append>
          )}
        </InputGroup>
      </Form.Group>
    );
  }
}

export default FormGroup;
