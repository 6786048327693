// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';

import store from './app/_shared/redux/store';

import './styles/main.scss';

import App from './app/App';

import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './error-boundary';
// import i18n (needs to be bundled ;))
import './i18n';
import MDSpinner from 'react-md-spinner';
import { ColorConstants } from './app/_shared/constants';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Suspense
    fallback={
      <div className="d-flex justify-content-center align-items-center h-100">
        <MDSpinner singleColor={ColorConstants.PRIMARY} />
      </div>
    }
  >
    <ErrorBoundary>
      <Provider store={store}>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <App />
      </Provider>
    </ErrorBoundary>
  </Suspense>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
