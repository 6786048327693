import React, { Component } from 'react';
import { Container, Row, Col, Button, Collapse, Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

import { ColorConstants, tableConstants } from '../../../_shared/constants';
import { FormGroup, Icon } from '../../../_shared/components';
import { ProductService } from '../../../_shared/services';

import { kitTableConstants } from './table.constants';
import { withTranslation } from 'react-i18next';
import { errorParser } from '../../../_shared/helpers';

export class MyKits extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = null;
  /**Component state */
  state = {
    searchText: '',
    isTableLoading: true,
    isFileLoading: false,
    kitsArrived: false,
    kits: [],
    columns: kitTableConstants.kitColumns,
    options: {},
    showAlert: true,
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'kit.name',
    sortOrder: 'asc',
    page: 1
  };
  /**Call service apis when component mounted */
  componentDidMount() {
    this.getProducts();
    this.onSearchChange();
  }
  /**Free up resources when unmounting */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$ = fromEvent(
      document.getElementById('my-kits-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            searchText: inputEvent.target.value,
            isTableLoading: true,
            page: 1,
            currentIndex: 0
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    { sortField, sortOrder, tableData, page, sizePerPage }
  ) => {
    const currentIndex =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getProducts(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Observable */
  getObservable = (
    currentIndex,
    sizePerPage,
    sortField,
    sortOrder,
    searchText
  ) => {
    return ProductService.getMyProducts(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    );
  };
  /**Handle observable subscribe response */
  handelResponse = () => ({
    next: response => {
      if (this.cleanup) {
        const {
          currentIndex,
          sizePerPage,
          sortField,
          sortOrder,
          page,
          searchText
        } = this.state;
        const { products, count } = response.data;
        const options = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          kitsArrived: true,
          options,
          kits: products,
          isTableLoading: false,
          currentIndex,
          sizePerPage,
          sortField,
          sortOrder,
          page,
          searchText
        });
      }
    },
    error: errorResponse => {
      if (this.cleanup) {
        this.setState({ kitsArrived: true, isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Call service to get my products */
  getProducts(
    currentIndex = this.state.currentIndex,
    sizePerPage = this.state.sizePerPage,
    sortField = this.state.sortField,
    sortOrder = this.state.sortOrder,
    searchText = this.state.searchText
  ) {
    this.setState({ isTableLoading: true });
    const getMyProducts$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      getMyProducts$.unsubscribe();
    };
  }
  render() {
    const { alert } = this.props;
    return (
      <Container fluid>
        <Collapse in={!!alert.message && this.state.showAlert}>
          <div>
            {!!alert.message && (
              <Alert variant={alert.type}>
                {alert.message}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => this.setState({ showAlert: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Alert>
            )}
          </div>
        </Collapse>
        <Row className="justify-content-between align-items-center">
          <Col sm={8} md={4} className="mb-3">
            <FormGroup
              type="text"
              label={this.translation('searchMyKits')}
              title={this.translation('searchMyKits')}
              icon="search"
              iconPosition="right"
              formControlId="my-kits-search-input"
              classes="mb-2"
            />
          </Col>
          <Col sm={4} md="auto" className="mb-3">
            <Button
              variant=""
              onClick={() => {
                this.props.history.push('/my-kits/add-new');
              }}
              className="mb-2"
            >
              {this.translation('addNewKit')}
              <Icon iconName="add" size="26" classes="ml-2" />
            </Button>
          </Col>
        </Row>
        {this.state.isTableLoading && !this.state.kitsArrived ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <Row>
            <Col xs={12}>
              <BootstrapTable
                remote
                classes="with-gap"
                bordered={false}
                bootstrap4
                keyField="_id"
                data={this.state.kits}
                columns={this.state.columns}
                pagination={
                  this.state.kits.length
                    ? paginationFactory(this.state.options)
                    : null
                }
                rowEvents={{
                  onClick: (onClickEvent, row, rowIndex) => {
                    this.props.history.push(`/my-kits/details/${row._id}`);
                  }
                }}
                noDataIndication={
                  this.state.isTableLoading
                    ? this.translation('pleaseWait')
                    : this.translation('noData')
                }
                rowClasses="colored-background cursor-pointer"
                onTableChange={this.onTableChange}
                loading={this.state.isTableLoading}
                overlay={tableConstants.overlay()}
                wrapperClasses="table-responsive-sm"
                defaultSorted={[
                  {
                    dataField: this.state.sortField,
                    order: this.state.sortOrder
                  }
                ]}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStatesToProps = state => {
  const { alert } = state;
  return { alert };
};

export default withTranslation()(connect(mapStatesToProps)(MyKits));
