// @flow
import * as React from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ReactRouterHistory, ReactRouterMatch } from '../../../models';
import BasicDetails from './BasicDetails';
import LicenseDetails from './licenseAndSerialNumber';
import { withTranslation } from 'react-i18next';
import { loaderActions } from '../../../_shared/redux/actions';
import NavButtons from './NavButtons';
import i18next from 'i18next';

type State = {
  userId: string,
  activeDetail: 'user' | 'license' | 'serialNumber',
  isDeleted: boolean | any
};

type Props = {
  startLoading(): void,
  stopLoading(): void,
  match: ReactRouterMatch,
  history: ReactRouterHistory,
  t: i18next.TFunction
};

export class UserDetails extends React.Component<Props, State> {
  translation = this.props.t;
  cleanup: any = null;
  /**Components State */
  state = {
    userId: '',
    activeDetail: 'user',
    isDeleted: undefined
  };
  constructor(props: Props) {
    super(props);
    const userId: string = props.match.params.userId;
    this.state.userId = userId;
  }
  /**Cleanup resources on un-mount */
  componentWillUnmount() {
    if (this.cleanup) {
      this.cleanup();
      this.cleanup = null;
    }
  }

  goTo = (path: string) => this.props.history.push(path);
  render() {
    return (
      <>
        <Container fluid>
          <NavButtons
            state={this.state}
            setState={updatedState => this.setState(updatedState)}
          />
          {this.state.activeDetail === 'user' ? (
            <BasicDetails
              userId={this.state.userId}
              goTo={(path: string) => this.props.history.push(path)}
              isDeleted={(isDeleted: boolean) => this.setState({ isDeleted })}
            />
          ) : this.state.activeDetail === 'license' ? (
            <LicenseDetails userId={this.state.userId} />
          ) : (
            ''
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(UserDetails)
);
