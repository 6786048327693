import React from 'react';

import { Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../_shared/helpers';
import { getFormattedTime } from '../../../_shared/helpers/methods';
import i18next from 'i18next';

export const TreatmentSummariesTableConstants = {
  columns: (activeTreatmentId = null) => [
    {
      dataField: 'timeStamp',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('date')),
      sort: true,
      classes: (cell, row) => {
        if (row._id === activeTreatmentId) {
          return 'border-primary border-top border-bottom border-left';
        }
      },
      formatter: cell => {
        return getFormattedDate(cell);
      }
    },
    {
      dataField: 'time',
      isDummyField: true,
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('time')),
      sort: false,
      classes: (cell, row) => {
        if (row._id === activeTreatmentId) {
          return 'border-primary border-top border-bottom';
        }
      },
      formatter: (cell, row) => {
        return getFormattedTime(row.timeStamp);
      }
    },
    {
      dataField: '',
      text: '',
      formatter: () => (
        <Icon
          iconName="right"
          size="29"
          fill={ColorConstants.DEFAULT_FONT}
          classes="float-right"
        />
      ),
      classes: (cell, row) => {
        return (
          (row._id === activeTreatmentId
            ? 'border-primary border-top border-bottom border-right '
            : '') + 'py-0'
        );
      }
    }
  ]
};
