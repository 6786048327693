import { API_ENDPOINTS } from '../constants/api-endpoints.constants';
import { AxiosSubscriber, authHeader } from '../helpers';
import { Observable } from 'rxjs';
import { stringify } from 'query-string';

export const servicePlanService = {
  get
};

function get(
  skip = 0,
  limit = 10,
  sort = 'serviceExpiryDate',
  order = 'asc',
  _export = false,
  emailSearch = false,
  search = '',
  servicePlanOnly = 1,
  expired = 0,
  user = undefined
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        sort,
        order,
        search,
        expired,
        export: _export,
        offset: new Date().getTimezoneOffset(),
        emailSearch,
        servicePlanOnly,
        ...(user && user)
      })}`;
    let urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
