import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import ExpiringItems from './ExpiringItems';
import ExpiringServicePlans from './ExpiringServicePlans';
import { ChartsSlides } from '../../components';
import ApplicationStatistics from './ApplicationStatistics';
import { withTranslation } from 'react-i18next';
export class Dashboard extends Component {
  translation = this.props.t;
  state = {};

  render() {
    let mobilizeLocalStorage =
      JSON.parse(localStorage.getItem('mobilize')) || {};
    let userLocalStorage = mobilizeLocalStorage.user || {};
    return (
      <Container fluid>
        {/* {userLocalStorage.type === 'super-super' && (
          <Row>
            <ApplicationStatistics />
          </Row>
        )} */}
        <Row>
          <Col className="mb-5">
            <Card>
              <Card.Body>
                <Card.Title>{this.translation('unitStatistics')}</Card.Title>
                <ChartsSlides />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* {userLocalStorage.type === 'super-super' && (
          <Row>
            <Col className="mb-5">
              <ExpiringItems history={this.props.history} />
            </Col>
          </Row>
        )}
        {userLocalStorage.type === 'super-super' && (
          <Row>
            <Col className="mb-5">
              <ExpiringServicePlans history={this.props.history} />
            </Col>
          </Row>
        )} */}
      </Container>
    );
  }
}

export default withTranslation()(Dashboard);
