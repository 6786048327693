import React, { Component } from 'react';
import { Col, Button, Collapse, Alert, Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { ChipInput, DatePicker } from '../../../_shared/components';
import { ProductService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';

class AddByNumbers extends Component {
  translation = this.props.t;
  state = {
    serialNumbers: [],
    checkingValidity: false,
    isValueValid: false,
    valueValidationMessage: '',
    showError: false,
    registrationDate: undefined
  };
  /**Handler to add chip */
  addChip = value => {
    const serialNumbers = this.state.serialNumbers.slice();
    serialNumbers.push(value);
    this.setState({ serialNumbers });
  };
  /**Handler to remove chip */
  removeChip = index => {
    const serialNumbers = this.state.serialNumbers.slice();
    serialNumbers.splice(index, 1);
    this.setState({ serialNumbers, valueValidationMessage: '' });
  };
  /**Validate value entered by user, it should have exactly 8 digits and it should be valid */
  validateValue = value => {
    let isMatch = false;
    this.setState({ checkingValidity: true });
    const isExist = this.state.serialNumbers.indexOf(value);
    if (this.state.serialNumbers.length > 0) {
      this.state.serialNumbers.map(serial => {
        if (
          (serial.localeCompare(value, undefined, { sensitivity: 'base' }) ===
            0) ===
          true
        ) {
          isMatch = true;
        }
      });
    }
    if (
      isExist > -1 ||
      (isMatch === true && this.state.serialNumbers.length != 0)
    ) {
      this.setState({
        isValueValid: false,
        checkingValidity: false,
        valueValidationMessage: this.translation('serialNumberAlreadyAdded')
      });
    } else {
      let isValueValid = value.trim().length >= 1;
      if (isValueValid) {
        this.validateSerialNumber(value);
      } else {
        this.setState({
          isValueValid,
          checkingValidity: false,
          valueValidationMessage: value
            ? this.translation('formValidations.invalidSerialNumber')
            : this.translation('addAtLeastOneSerialNumber')
        });
      }
    }
  };
  /**Call api to check serial number */
  validateSerialNumber(value) {
    if (value) {
      ProductService.checkSerialNumber(value).subscribe({
        next: response => {
          const { status, message } = response.data;
          this.setState({ checkingValidity: false });
          if (status) {
            this.addChip(value);
            this.setState({ isValueValid: status, valueValidationMessage: '' });
          } else {
            this.setState({
              isValueValid: status,
              valueValidationMessage: message
            });
          }
        },
        error: errorResponse => {
          const { status, message } = errorResponse.data;
          this.setState({
            checkingValidity: false,
            isValueValid: status,
            valueValidationMessage: message
          });
        }
      });
    } else {
      this.setState({
        checkingValidity: false,
        isValueValid: false,
        valueValidationMessage: this.translation(
          'formValidations.invalidSerialNumber'
        )
      });
    }
  }
  /**Call api to add product */
  addProducts = () => {
    if (this.state.registrationDate) {
      this.setState({ showError: false });
      this.props.startLoading();
      ProductService.registerProduct(
        this.state.serialNumbers,
        this.props.customerId,
        this.state.registrationDate
      ).subscribe({
        next: response => {
          this.props.stopLoading();
          toastr.success(
            `${this.translation('unit', { count: 0 })} ${this.translation(
              'add',
              { context: 'past' }
            )}`,
            response.data.message
          );
          this.props.goTo('/admin/customers/details/' + this.props.customerId);
        },
        error: errorResponse => {
          this.props.stopLoading();
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      });
    } else {
      this.setState({ showError: true });
    }
  };
  render() {
    return (
      <>
        <Col xs={12} className="ml-3">
          <Row className="align-items-center">
            <Col xs="auto" className="mb-4">
              <span className="font-size-lg">
                {this.translation('enterRegistrationDate')}:
              </span>
            </Col>
            <Col className="mb-4" xs="auto">
              <DatePicker
                selected={this.state.registrationDate}
                onChange={date => {
                  this.setState({ registrationDate: date, showError: false });
                }}
                className={[
                  this.state.showError ? 'is-invalid' : '',
                  'd-inline-block'
                ].join(' ')}
                name="registrationDate"
                id="registrationDate"
                placeholder="MMM DD, YYYY"
                error={this.state.showError ? 'Required' : ''}
                showError="tooltip"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="ml-3">
          <p className="font-size-lg">
            {this.translation(
              'enterTheSerialNumberSOfTheKitSYouWouldLikeToAdd'
            )}
            .
          </p>
        </Col>
        <Col xs={12} className="mb-3">
          <ChipInput
            classes="ml-3"
            chips={this.state.serialNumbers}
            onSubmit={value => this.validateValue(value)}
            onRemove={index => this.removeChip(index)}
            label={
              this.state.checkingValidity
                ? this.translation('pleaseWait') + '...'
                : this.translation('enterSerialNumber')
            }
            disabled={this.state.checkingValidity}
            loading={this.state.checkingValidity}
            helpText={
              <span className="pl-3">
                {this.translation('chipInputHelpTexts.text1')}
                <kbd className="py-0 mx-2 font-size-lg">↩</kbd>
                {this.translation('chipInputHelpTexts.text2')}.
              </span>
            }
          />
          <Collapse
            in={
              !this.state.checkingValidity &&
              !this.state.isValueValid &&
              !!this.state.valueValidationMessage
            }
          >
            <div>
              {!!this.state.valueValidationMessage && (
                <Alert variant="danger" className="ml-3 w-auto">
                  {this.state.valueValidationMessage}
                </Alert>
              )}
            </div>
          </Collapse>
          <p className="font-size-lg ml-3">
            {this.translation('quantity')}:
            {' ' + this.state.serialNumbers.length}
          </p>
          <Button
            variant="success"
            size="lg"
            className="rounded-0 px-5 float-right"
            disabled={!this.state.serialNumbers.length}
            onClick={() => this.addProducts()}
          >
            {this.translation('add')}
          </Button>
        </Col>
      </>
    );
  }
}

export default withTranslation()(AddByNumbers);
