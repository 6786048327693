import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { connect } from 'react-redux';

import { siteAdminRoutes, superAdminRoutes } from '../../routes';
import { withTranslation } from 'react-i18next';

class BreadCrumbs extends Component {
  handleClick = (pathname, url) => {
    if (pathname !== url) {
      this.props.history.push(
        // (this.props.user.type === 'admin'
        //   ? '/admin'
        //   : '') +
        url
      );
    }
  };
  render() {
    let { breadcrumbs } = this.props;
    const { t: translation } = this.props;
    breadcrumbs =
      breadcrumbs.length > 1
        ? // ? process.env.PUBLIC_URL.length
          // ? breadcrumbs.slice(2)
          breadcrumbs.slice(1)
        : breadcrumbs;
    if (
      this.props.user.type === 'super' ||
      (this.props.user.type === 'super-super' && breadcrumbs.length > 1)
    ) {
      breadcrumbs = breadcrumbs.splice(1);
    }
    return (
      <Breadcrumb>
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span className="mx-1">></span>}
            <Breadcrumb.Item
              onClick={() =>
                this.handleClick(
                  breadcrumb.props.location.pathname,
                  breadcrumb.props.match.url
                )
              }
              key={index}
              className={[
                'mx-1',
                breadcrumb.props.location.pathname ===
                  breadcrumb.props.match.url && 'active'
              ].join(' ')}
            >
              {breadcrumb.key !== '/'
                ? breadcrumb.key !== '/admin'
                  ? breadcrumb
                  : translation('dashboard')
                : this.props.user.type === 'site'
                ? translation('home')
                : translation('dashboard')}
            </Breadcrumb.Item>
          </React.Fragment>
        ))}
      </Breadcrumb>
    );
  }
}

const mapStatesToProps = state => {
  const { loggedIn, user } = state.authentication;
  return { loggedIn, user };
};

export default withTranslation()(
  withBreadcrumbs(siteAdminRoutes.concat(superAdminRoutes))(
    connect(mapStatesToProps)(BreadCrumbs)
  )
);
