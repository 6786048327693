// @flow
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Container, Row, Collapse, Col } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { toastr } from 'react-redux-toastr';

import { IDocument } from '../../../models';
import { loaderActions } from '../../../_shared/redux/actions';
import DocumentPreviewModal from '../Documents/DocumentPreviewModal';
import { ColorConstants } from '../../../_shared/constants';
import { ConfigService } from '../../../_shared/services';
import DraggableComponent from './DragabbleComponent';
import { errorParser } from '../../../_shared/helpers';

type Props = {
  t: i18next.TFunction,
  documents: IDocument[],
  isLoading: Boolean,
  startLoading(): void,
  stopLoading(): void,
  rearrangeDocuments(document: IDocument): void,
  editDocument(): void,
  deleteDocument(): void
};

type State = {
  documents: IDocument[],
  showPreviewModal: boolean,
  previewDocument: IDocument,
  draggingIndex: number
};

class BannersContainer extends React.Component<Props, State> {
  translation = this.props.t;
  cleanup: any = null;
  state = {
    documents: this.props.documents,
    isLoading: true,
    showPreviewModal: false,
    previewDocument: {
      _id: '',
      title: '',
      type: 'Image',
      url: ''
    },
    draggingIndex: -1
  };

  /**Show document preview */
  showPreview = (document: IDocument) => {
    this.setState(
      { previewDocument: document },
      this.setState({ showPreviewModal: true })
    );
  };

  /** document card component */
  renderDocument = (document, index) => (
    <Col
      xs={6}
      sm={4}
      md={3}
      // style={{ zIndex: 2, opacity: props.draggingIndex === index ? 0 : 1 }}
      className={[
        'mb-5',
        (index + 1) % 1 === 0 ? 'pr-4' : (index + 1) % 4 === 0 ? 'pl-4' : 'px-4'
      ].join(' ')}
      key={index}
    >
      <DraggableComponent
        showPreview={this.showPreview}
        document={document}
        key={index}
        index={index}
        moveCard={this.moveCard}
        setDraggingIndex={this.setDraggingIndex.bind(this)}
        draggingIndex={this.state.draggingIndex}
        deleteDocument={this.props.deleteDocument}
        editDocument={this.props.editDocument}
      />
    </Col>
  );

  /**move card when another card hover on it. */
  moveCard = (dragIndex, hoverIndex) => {
    let { documents } = this.props;
    this.setState({
      draggingIndex: hoverIndex
    });
    if (dragIndex !== undefined && hoverIndex !== undefined) {
      let document = documents[dragIndex];
      documents.splice(dragIndex, 1);
      documents.splice(hoverIndex, 0, document);
      this.props.rearrangeDocuments(documents);
    }
  };

  /**set dragging index for hide a box in banner container. */
  setDraggingIndex(index) {
    this.setState({
      draggingIndex: index
    });
    this.updateConfig();
  }

  /**Update Config */
  updateConfig() {
    let { documents } = this.props;
    this.props.startLoading();
    documents = documents.map(document => document._id);
    const updateConfig$ = ConfigService.updateConfig(
      'banners',
      documents
    ).subscribe({
      next: (response: { data: { message: string } }) => {
        this.props.stopLoading();
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      updateConfig$.unsubscribe();
    };
  }

  componentWillUnmount() {
    if (this.cleanup) {
      this.cleanup();
    }
  }

  translation = this.props.t;
  render() {
    let { documents } = this.props;
    return (
      <>
        <DocumentPreviewModal
          show={this.state.showPreviewModal}
          onHide={() => this.setState({ showPreviewModal: false })}
          onHidden={() => this.setState({ previewDocument: null })}
          document={this.state.previewDocument}
        />
        <Container fluid>
          {this.props.isLoading ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <>
              <Row className="documents-row">
                {documents.length ? (
                  <DndProvider backend={HTML5Backend}>
                    {documents.map(this.renderDocument)}
                  </DndProvider>
                ) : (
                  <p className="text-center w-100">
                    {this.translation('noDocumentsFound')}
                  </p>
                )}
              </Row>
              <Collapse>
                <div className="w-100 text-center">
                  <MDSpinner singleColor={ColorConstants.PRIMARY} />
                </div>
              </Collapse>
            </>
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(BannersContainer)
);
