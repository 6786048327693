import { userConstants, adminConstants } from '../../constants';
import { User } from '../../../models';

let mobilizeLocalStorage = JSON.parse(localStorage.getItem('mobilize')) || {};
let userLocalStorage = mobilizeLocalStorage.user || {};
const initialState = userLocalStorage.token
  ? { loggedIn: true, user: userLocalStorage }
  : {
      loggedIn: false,
      loggingIn: false,
      loggingOut: false,
      user: new User()
    };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.UPDATE_USER:
      return { loggedIn: true, loggingIn: false, user: action.user };
    case adminConstants.LOGIN_REQUEST:
    case userConstants.LOGIN_REQUEST:
      return {
        loggedIn: false,
        loggingIn: true,
        user: action.user
      };
    case adminConstants.LOGIN_SUCCESS:
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.user
      };
    case adminConstants.LOGIN_FAILURE:
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loggingIn: false,
        user: action.user
      };
    case adminConstants.LOGOUT_SUCCESS:
    case userConstants.LOGOUT_SUCCESS:
      return {
        loggedIn: false,
        loggingIn: false,
        loggingOut: false,
        user: new User()
      };
    case adminConstants.LOGOUT_FAILURE:
    case userConstants.LOGOUT_FAILURE:
      return {
        loggedIn: false,
        loggingIn: false,
        loggingOut: false,
        user: action.user
      };
    default:
      return state;
  }
}
