/* eslint-disable id-blacklist */
import React, { Component } from 'react';
import { Carousel, Row, Col, Container } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import 'chartjs-plugin-doughnutlabel';
import 'chartjs-plugin-datalabels';
import { detect } from 'detect-browser';

import {
  ChartColorConstants,
  ColorConstants,
  ChartLightColorConstants
} from '../../_shared/constants';
import { Icon } from '../../_shared/components';
import { DashboardService, kitService } from '../../_shared/services';
import { errorParser, logger, randomString } from '../../_shared/helpers';
import MDSpinner from 'react-md-spinner';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

export class ChartsSlides extends Component {
  translation = this.props.t;
  state = {
    isLoading: true,
    monthWiseData: [],
    kitTypes: [],
    isKitTypeLoading: true,
    browser: '',
    showSecondSlide: false,
    showThirdSlide: false
  };
  /**Resource cleaner */
  cleanup = null;
  /**Call apis once component is mounted */
  componentDidMount() {
    const browser = detect();
    if (browser) {
      this.setState({ browser: browser.name });
      this.getData();
    }
  }
  getChartData() {
    const dashboardService$ = DashboardService.getChartsData().subscribe({
      next: response => {
        if (this.cleanup) {
          const monthWiseData = [];
          let responseData = response.data.data;
          // sort by month
          responseData = responseData.sort(
            (firstData, secondData) =>
              firstData._id.month - secondData._id.month
          );
          // convert data so that it's compatible with chart
          responseData.forEach(monthData => {
            const monthNumber = monthData._id.month;
            const shortName = moment.monthsShort(monthNumber - 1);
            logger.log(this.state.kitTypes);
            const data = this.state.kitTypes.map(kitType => {
              const isKitTypeFound = monthData.data.filter(
                monthKitTypeWithCount => {
                  return monthKitTypeWithCount.type === kitType.label;
                }
              )[0];
              const count = isKitTypeFound ? isKitTypeFound.count : 0;
              return count;
            });
            monthWiseData.push({ month: shortName, data });
          });
          this.setState({
            monthWiseData,
            isLoading: false
          });
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          toastr.error(this.translation('error'), errorParser(errorResponse));
          this.setState({ isLoading: false });
        }
      }
    });
    this.cleanup = () => dashboardService$.unsubscribe();
  }

  /**Free up resources once component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  getData = () => {
    // first, we will fetch kit types
    // then, we will fetch chart data
    this.setState({ isKitTypeLoading: true });
    const kitService$ = kitService.getKitTypes().subscribe({
      next: response => {
        if (this.cleanup) {
          const kitTypes = response.data.kits.map(kit => {
            return {
              label: kit.name,
              value: kit._id
            };
          });
          this.setState({ isKitTypeLoading: false, kitTypes }, () => {
            this.getChartData();
          });
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          this.setState({ isKitTypeLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      kitService$.unsubscribe();
    };
  };
  /**Convert api data to chart compatible data */
  configureChartData = data => {
    return {
      labels: this.state.kitTypes.map(kitType => kitType.label),
      datasets: [
        {
          data,
          backgroundColor: ChartColorConstants,
          hoverBorderColor: ChartLightColorConstants,
          hoverBorderWidth: '12'
        }
      ]
    };
  };
  getChartOptions(item) {
    const total = item.data.reduce((i, j) => i + j);
    return {
      legend: { display: false },
      layout: { padding: { bottom: 30 } },
      plugins: {
        doughnutlabel: {
          labels: [
            {
              text: item.month,
              font: {
                size: '24'
              }
            }
          ]
        },
        datalabels: {
          formatter: value =>
            Math.round(((value * 100) / total) * 100) / 100 + '%',
          color: '#ffffff',
          font: {
            size: '14'
          },
          display: context => {
            let index = context.dataIndex;
            let value = context.dataset.data[index];
            return (value * 100) / total >= 10;
          }
        }
      },
      tooltips: {
        enabled: false,
        custom: function(tooltip) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }
          function getBody(bodyItem) {
            return bodyItem.lines;
          }
          // Set Text
          if (tooltip.body) {
            const bodyLines = tooltip.body.map(getBody);
            const title = bodyLines[0].toString().split(':')[0];
            const value = bodyLines[0]
              .toString()
              .split(':')[1]
              .trim();
            const percentage = Math.round(((value * 100) / total) * 100) / 100;
            const colors = tooltip.labelColors[0];
            const innerHTML = `<div class="rounded-0 bg-white p-2 shadow border">
                            <span class="font-size-md font-weight-bold mb-1 d-inline-block"><span class="mr-1" style="color: ${
                              colors.backgroundColor
                            }">&#9679;</span>${title}</span><br/>
                            <span class="pl-3">${value}</span>
                            <span>(${percentage}%)</span>
                          </div>`;
            let tableRoot = tooltipEl.querySelector('div');
            tableRoot.innerHTML = innerHTML;
          }
          var positionY = this._chart.canvas.offsetTop;
          var positionX = this._chart.canvas.offsetLeft;
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = positionX + tooltip.caretX + 'px';
          tooltipEl.style.top = positionY + tooltip.caretY + 'px';
          tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
          tooltipEl.style.fontSize = tooltip.bodyFontSize;
          tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
          tooltipEl.style.padding =
            tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
        }
      }
    };
  }
  render() {
    return (
      <Container fluid className="chart-slider-container">
        {this.state.monthWiseData.length ? (
          <Carousel
            className="chart-slider"
            nextIcon={
              this.state.monthWiseData.length > 4 && (
                <Icon
                  iconName="chart-slider-left"
                  fill={ColorConstants.CHART_BLUE}
                  size="36"
                />
              )
            }
            prevIcon={
              this.state.monthWiseData.length > 4 && (
                <Icon
                  iconName="chart-slider-right"
                  fill={ColorConstants.CHART_BLUE}
                  size="36"
                />
              )
            }
            indicators={false}
            slide={this.state.browser !== 'chrome'}
          >
            <Carousel.Item
              className={[
                'px-5',
                this.state.monthWiseData.length < 4 ? 'text-center' : ''
              ].join(' ')}
            >
              {this.state.monthWiseData.slice(0, 4).map((item, index) => (
                <div
                  className="w-25 d-inline-block px-vxl-5"
                  key={randomString(index + 9)}
                >
                  <Doughnut
                    data={this.configureChartData(item.data)}
                    options={this.getChartOptions(item)}
                    height={310}
                  />
                </div>
              ))}
            </Carousel.Item>
            {this.getSecondSlide()}
            {this.getThirdSlide()}
          </Carousel>
        ) : (
          <div className="w-100 px-vxl-5">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: '310px' }}
            >
              {this.state.isLoading ? (
                <MDSpinner singleColor={ColorConstants.PRIMARY} />
              ) : (
                i18next.t('noData')
              )}
            </div>
          </div>
        )}

        <Row className="px-5">
          <Col>
            <Row className="justify-content-around px-5">
              {this.state.kitTypes.map((kitType, index) => (
                <Col className="text-center mb-3" xs="auto" key={index}>
                  <span
                    className={[
                      'rounded-circle',
                      'position-absolute',
                      'chart-legend'
                    ].join(' ')}
                    style={{ backgroundColor: ChartColorConstants[index] }}
                  />
                  {kitType.label}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <div id="chartjs-tooltip">
          <div />
        </div>
      </Container>
    );
  }

  getThirdSlide() {
    return (
      this.state.monthWiseData.length > 8 && (
        <Carousel.Item className="px-5">
          {this.state.monthWiseData.slice(8, 12).map((item, index) => (
            <div
              className="w-25 d-inline-block px-vxl-5"
              key={randomString(index + 5)}
            >
              <Doughnut
                data={this.configureChartData(item.data)}
                options={this.getChartOptions(item)}
                height={310}
              />
            </div>
          ))}
        </Carousel.Item>
      )
    );
  }

  getSecondSlide() {
    return (
      this.state.monthWiseData.length > 4 && (
        <Carousel.Item
          className={[
            'px-5'
            // this.state.browser === 'chrome' ? 'carousel-item-next' : ''
          ].join(' ')}
        >
          {this.state.monthWiseData.slice(4, 8).map((item, index) => (
            <div
              className="w-25 d-inline-block px-vxl-5"
              key={randomString(index + 3)}
            >
              <Doughnut
                data={this.configureChartData(item.data)}
                options={this.getChartOptions(item)}
                height={310}
              />
            </div>
          ))}
        </Carousel.Item>
      )
    );
  }
}

export default withTranslation()(ChartsSlides);
