import React, { Component } from 'react';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toastr } from 'react-redux-toastr';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import moment from 'moment';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

import { ColorConstants, tableConstants } from '../../../_shared/constants';
import { FormGroup, Icon, ConfirmDialog } from '../../../_shared/components';
import { UserService } from '../../../_shared/services';

import { customersTableConstants } from './table.constants';
import { loaderActions } from '../../../_shared/redux/actions';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';

export class MobileUsers extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = null;
  /**Present confirm delete */
  presentDeleteConfirm = (id, name, email) => {
    const deleteConfirmMessage = (
      <p>
        {this.translation('deleteCustomer')},
        <br />{' '}
        <b>
          {this.translation('name')}: {name}
        </b>
        ,{' '}
        <b>
          {this.translation('email')}: {email}
        </b>{' '}
        ?
      </p>
    );
    this.setState({
      showConfirmDelete: true,
      deleteConfirmMessage,
      userIdToBeDeleted: id
    });
  };
  /**Redirects from children */
  goTo = url => this.props.history.push(url);
  /**Component's state */
  state = {
    isTableLoading: true,
    usersArrived: false,
    showConfirmDelete: false,
    deleteConfirmMessage: '',
    userIdToBeDeleted: '',
    users: [],
    searchText: '',
    userType: '',
    isExporting: false,
    maxUserCount: 0,
    columns: customersTableConstants.customerColumns(
      this.presentDeleteConfirm,
      this.goTo
    ),
    options: {},
    showAlert: true,
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'name',
    sortOrder: 'asc',
    page: 1
  };
  /**Call apis when component mounted */
  componentDidMount() {
    this.getCustomers();
    this.onSearchChange();
  }
  /**Free up resources when component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$ = fromEvent(
      document.getElementById('customers-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            page: 1,
            currentIndex: 0,
            searchText: inputEvent.target.value,
            isTableLoading: true
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText,
            this.state.userType
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Confirm dialog cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };
  /**Confirm dialog ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    this.deleteUser();
  };
  /**Set user type */
  setUserType = userType => {
    this.setState({ userType, page: 1, currentIndex: 0 }, () => {
      this.getCustomers();
    });
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    { sortField, sortOrder, tableData, page, sizePerPage }
  ) => {
    const currentIndex =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getCustomers(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };

  /**Export data */
  exportData = () => {
    this.setState({ isExporting: true });
    const exportIndex = 0;
    const export$ = UserService.export(
      exportIndex,
      this.state.maxUserCount,
      this.state.sortField,
      this.state.sortOrder,
      this.state.searchText,
      'individual',
      this.state.filterEnabled && this.state.filter
    ).subscribe({
      next: (response: any) => {
        if (this.cleanup) {
          this.setState({ isExporting: false });
          fileDownload(
            response.data,
            this.translation('users', { count: 0 }) +
              '-' +
              moment().format('MM-DD-Y:HH-mm-ss') +
              '.csv',
            'text/csv'
          );
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          toastr.error(
            this.translation('error') +
              ' ' +
              this.translation('while') +
              ' ' +
              this.translation('export', { context: 'presentCont' }),
            errorParser(errorResponse)
          );
          this.setState({ isExporting: false });
        }
      }
    });
    this.cleanup = () => {
      export$.unsubscribe();
    };
  };

  /**Observable */
  getObservable = (
    currentIndex,
    sizePerPage,
    sortField,
    sortOrder,
    searchText,
    userType
  ) => {
    return UserService.get(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      (userType = 'individual')
    );
  };
  /**Subscriber-response handler */
  handelResponse = () => ({
    next: response => {
      if (this.cleanup) {
        const { sizePerPage, page } = this.state;
        const { users, count } = response.data;
        this.setState({ usersArrived: true, maxUserCount: count });
        const options = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          users,
          isTableLoading: false
        });
      }
    },
    error: errorResponse => {
      if (this.cleanup) {
        this.setState({ usersArrived: true, isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Once confirmed, delete user */
  deleteUser() {
    const deleteUser$ = UserService.delete(
      this.state.userIdToBeDeleted
    ).subscribe({
      next: response => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.success(this.translation('success'), response.data.message);
          this.getCustomers();
        }
      },
      error: errorResponse => {
        this.props.stopLoading();
        if (this.cleanup) {
          this.setState({ isTableLoading: false, itemsArrived: true });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      deleteUser$.unsubscribe();
    };
  }
  /**Get customers from server */
  getCustomers(
    currentIndex = this.state.currentIndex,
    sizePerPage = this.state.sizePerPage,
    sortField = this.state.sortField,
    sortOrder = this.state.sortOrder,
    searchText = this.state.searchText,
    userType = this.state.userType
  ) {
    this.setState({ isTableLoading: true });
    const userService$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      userType
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      userService$.unsubscribe();
    };
  }

  render() {
    const translation = this.props.t;
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn={this.translation('no')}
          okBtn={this.translation('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.state.deleteConfirmMessage}
        />
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={8} md={4} className="mb-3">
              <FormGroup
                type="text"
                label={translation('searchByUsersSNameEmail')}
                title={translation('searchByUsersSNameEmail')}
                icon="search"
                iconPosition="right"
                classes="mb-2"
                formControlId="customers-search-input"
              />
            </Col>
            {this.state.users.length > 0 && (
              <Col className="text-right mb-4">
                <Button
                  variant=""
                  className="mb-2"
                  onClick={() => this.exportData()}
                  disabled={this.state.isExporting}
                >
                  {this.state.isExporting ? (
                    <>
                      {this.translation('pleaseWait') + '...'}
                      <MDSpinner singleColor={ColorConstants.PRIMARY} />
                    </>
                  ) : (
                    <>
                      {this.translation('export') +
                        ' ' +
                        this.translation('users')}
                      <Icon iconName="share" size="26" classes="ml-2" />
                    </>
                  )}
                </Button>
              </Col>
            )}
            {/* <Col className="text-right mb-3">
              <Button
                variant=""
                onClick={() => {
                  this.props.history.push('/admin/customers/new-customer');
                }}
                className="mb-2"
              >
                {this.translation('new')} {this.translation('customer')}
                <Icon iconName="add" size="26" classes="ml-2" />
              </Button>
            </Col> */}
          </Row>
          {this.state.isTableLoading && !this.state.usersArrived ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <Row>
              <Col xs={12}>
                <BootstrapTable
                  classes="with-gap"
                  wrapperClasses="table-responsive-sm"
                  remote
                  bordered={false}
                  bootstrap4
                  keyField="_id"
                  data={this.state.users}
                  columns={this.state.columns}
                  pagination={
                    this.state.users.length
                      ? paginationFactory(this.state.options)
                      : null
                  }
                  noDataIndication={
                    this.state.isTableLoading
                      ? translation('pleaseWait')
                      : translation('noData')
                  }
                  rowClasses="colored-background"
                  onTableChange={this.onTableChange}
                  loading={this.state.isTableLoading}
                  overlay={tableConstants.overlay()}
                  defaultSorted={[
                    {
                      dataField: this.state.sortField, // if dataField is not match to any column you defined, it will be ignored.
                      order: this.state.sortOrder // desc or asc
                    }
                  ]}
                />
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(MobileUsers)
);
