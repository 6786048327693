// @flow
import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Collapse,
  Form,
  Dropdown
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

import { FormGroup, Icon, DatePicker } from '../../../_shared/components';
import { ProductService, kitService } from '../../../_shared/services';
import { tableConstants, ColorConstants } from '../../../_shared/constants';
import { Product, ReactRouterHistory, Kit } from '../../../models';
import ImportCSVModal from './ImportCSVModal';
import { errorParser } from '../../../_shared/helpers';
import { productsTableConstants } from './table.constants';
import { fromEvent } from 'rxjs';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import MDSpinner from 'react-md-spinner';
import fileDownload from 'js-file-download';
import { withTranslation, Translation } from 'react-i18next';
import i18next from 'i18next';

const SerialNumberFormSchema = Yup.object().shape({
  prefix: Yup.string(),
  serialNumberStart: Yup.string()
    .required()
    .test(
      'is-number',
      <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
      value => !isNaN(value)
    )
    .test(
      'is-positive',
      <Translation>
        {t => <>{t('formValidations.negativeNotAllowed')}</>}
      </Translation>,
      value => +value > -1
    ),
  serialNumberEnd: Yup.string()
    .required()
    .test(
      'is-number',
      <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
      value => !isNaN(value)
    )
    .test(
      'is-positive',
      <Translation>
        {t => <>{t('formValidations.negativeNotAllowed')}</>}
      </Translation>,
      value => +value > -1
    )
});

type Filter = {
  searchText: string,
  selected: 'serialNumber' | 'createDate' | 'productType',
  prefix: '',
  serialNumberStart: string,
  serialNumberEnd: string,
  productType: {
    types: { value: string, label: string, checked: boolean }[]
  },
  productCreatedDateStart: ?Date,
  productCreatedDateEnd: ?Date
};

type Props = {
  history: ReactRouterHistory,
  t: i18next.TFunction
};

type State = {
  isTableLoading: boolean,
  isExporting: boolean,
  productsArrived: boolean,
  products: Product[],
  showAdvanceSearch: boolean,
  filter: Filter,
  columns: any,
  options: any,
  showAlert: boolean,
  showImportModal: boolean,
  currentIndex: number,
  MaxUnitCount: number,
  sizePerPage: number,
  sortField: string,
  sortOrder: 'asc' | 'desc',
  searchText: string,
  page: number,
  isKitTypeLoading: boolean,
  filterEnabled: boolean
};

export class Products extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Redirects from child component */
  goTo = (url: string) => this.props.history.push(url);
  today: Date = new Date(moment());
  prevMonthDay: Date = new Date(moment().subtract(1, 'M'));
  /**Component's state */
  state = {
    isTableLoading: true,
    isExporting: false,
    productsArrived: false,
    products: [],
    showAdvanceSearch: false,
    filter: {
      searchText: '',
      selected: 'serialNumber',
      prefix: '',
      serialNumberStart: '',
      serialNumberEnd: '',
      productType: {
        types: []
      },
      productCreatedDateStart: undefined,
      productCreatedDateEnd: undefined
    },
    columns: productsTableConstants.productColumns(this.goTo),
    options: {},
    showAlert: true,
    showImportModal: false,
    currentIndex: 0,
    MaxUnitCount: 0,
    sizePerPage: 10,
    sortField: 'serialNumber',
    sortOrder: 'asc',
    searchText: '',
    page: 1,
    isKitTypeLoading: true,
    filterEnabled: false
  };
  /**Call apis once components mounts */
  componentDidMount() {
    this.getProducts();
    this.onSearchChange();
    this.getKitType();
  }
  /**Free up resources once component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Get observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    sortField: string,
    sortOrder: 'asc' | 'desc',
    searchText: string,
    filter: Filter
  ) => {
    return ProductService.get(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      this.state.filterEnabled && filter
    );
  };
  /**Subscriber-response handler */
  handelResponse = () => ({
    next: (response: { data: { products: Product[], count: number } }) => {
      if (this.cleanup) {
        const { sizePerPage, page } = this.state;
        const { products, count } = response.data;
        this.setState({ productsArrived: true, MaxUnitCount: count });
        const options = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          products,
          isTableLoading: false
        });
      }
    },
    error: (errorResponse: any) => {
      if (this.cleanup) {
        this.setState({ productsArrived: true, isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Get customers from server */
  getProducts(
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    sortField: string = this.state.sortField,
    sortOrder: 'asc' | 'desc' = this.state.sortOrder,
    searchText: string = this.state.searchText,
    filter: Filter = this.state.filter
  ) {
    this.setState({ isTableLoading: true });
    const productService$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      filter
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      productService$.unsubscribe();
    };
  }
  /**Handle pagination and sorting */
  onTableChange = (
    type: any,
    {
      sortField,
      sortOrder,
      tableData,
      page,
      sizePerPage
    }: {
      sortField: string,
      sortOrder: 'asc' | 'desc',
      tableData: any,
      page: number,
      sizePerPage: number
    }
  ) => {
    const currentIndex: number =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getProducts(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Advanced filter type change handler */
  handleFilterByChange = (changeEvent: any) => {
    let filter = this.state.filter;
    filter.selected = changeEvent.target.value;
    this.setState({ filter });
  };
  /**Advanced filter product type change handler */
  handleProductTypeChange = (index: number) => {
    let filter = this.state.filter;
    let types = filter.productType.types;
    types[index].checked = !types[index].checked;
    filter.productType.types = types;
    this.setState({ filter });
  };
  /**Open/close advance search collapse */
  toggleAdvanceSearch = () => {
    const reloadRequired = this.state.filterEnabled;
    const filterEnabled =
      this.state.filterEnabled && this.state.showAdvanceSearch
        ? false
        : this.state.filterEnabled;
    this.setState(
      {
        showAdvanceSearch: !this.state.showAdvanceSearch,
        filterEnabled: filterEnabled
      },
      () => {
        if (reloadRequired) {
          this.goToFirstPage().then(() => {
            this.getProducts();
          });
        }
      }
    );
  };
  /**Change date handler */
  handleChangeDate = (whichDate: any, date: Date) => {
    const filter = this.state.filter;
    filter[whichDate] = date;
    this.setState({
      filter
    });
  };
  /**Called when modal is going to hide */
  onImportCSVModalHide = () => {
    this.setState({ showImportModal: false });
  };
  /**Handler for Search Input */
  onSearchChange = () => {
    const searchInput$ = fromEvent(
      document.getElementById('products-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            page: 1,
            currentIndex: 0,
            searchText: inputEvent.target.value,
            isTableLoading: true
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText,
            this.state.filter
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Export data */
  exportData = () => {
    this.setState({ isExporting: true });
    const exportIndex = 0;
    const export$ = ProductService.export(
      exportIndex,
      this.state.MaxUnitCount,
      this.state.sortField,
      this.state.sortOrder,
      this.state.searchText,
      this.state.filterEnabled && this.state.filter
    ).subscribe({
      next: (response: any) => {
        if (this.cleanup) {
          this.setState({ isExporting: false });
          fileDownload(
            response.data,
            this.translation('unit', { count: 0 }) +
              '-' +
              moment().format('MM-DD-Y:HH-mm-ss') +
              '.csv',
            'text/csv'
          );
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          toastr.error(
            this.translation('error') +
              ' ' +
              this.translation('while') +
              ' ' +
              this.translation('export', { context: 'presentCont' }),
            errorParser(errorResponse)
          );
          this.setState({ isExporting: false });
        }
      }
    });
    this.cleanup = () => {
      export$.unsubscribe();
    };
  };
  /**Gte Kit Types */
  getKitType = () => {
    this.setState({ isKitTypeLoading: true });
    const kitService$ = kitService.getKitTypes().subscribe({
      next: (response: { data: { kits: Kit[], count: number } }) => {
        if (this.cleanup) {
          const types: {
            value: string,
            label: string,
            checked: boolean
          }[] = response.data.kits.map((kit: Kit) => {
            return {
              label: kit.name,
              value: kit._id,
              checked: false
            };
          });
          const filter = Object.assign({}, this.state.filter);
          const productType = Object.assign({}, this.state.filter.productType);
          productType.types = types;
          filter.productType = productType;
          this.setState({ isKitTypeLoading: false, filter });
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.setState({ isKitTypeLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      kitService$.unsubscribe();
    };
  };
  // Go to first page of table
  goToFirstPage = () => {
    return new Promise<any>((resolve, reject) => {
      this.setState({ page: 1, currentIndex: 0 }, () => {
        resolve();
      });
    });
  };
  render() {
    return (
      <>
        <ImportCSVModal
          show={this.state.showImportModal}
          onSuccess={() => {
            this.getProducts();
          }}
          onHide={() => this.onImportCSVModalHide()}
        />
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={8} md={4} className="mb-4">
              <FormGroup
                type="text"
                label={this.translation('searchBySerialNumberOrCustomerName')}
                title={this.translation('searchBySerialNumberOrCustomerName')}
                icon="search"
                iconPosition="right"
                formControlId="products-search-input"
                classes="mb-2"
              />
            </Col>
            <Col sm="4" md="3" className="mb-4">
              <Button
                acti
                size="lg"
                variant="info"
                className="rounded-0 mb-2 mt-0 input-height"
                onClick={() => {
                  this.toggleAdvanceSearch();
                }}
              >
                {this.translation('advance', { context: 'adjective' }) +
                  ' ' +
                  this.translation('search')}
              </Button>
            </Col>
            <Col className="text-right mb-4">
              {this.state.products.length > 0 && (
                <Button
                  variant=""
                  className="mb-2"
                  onClick={() => this.exportData()}
                  disabled={this.state.isExporting}
                >
                  {this.state.isExporting ? (
                    <>
                      {this.translation('pleaseWait') + '...'}
                      <MDSpinner singleColor={ColorConstants.PRIMARY} />
                    </>
                  ) : (
                    <>
                      {this.translation('export') +
                        ' ' +
                        this.translation('data')}
                      <Icon iconName="share" size="26" classes="ml-2" />
                    </>
                  )}
                </Button>
              )}
              <Dropdown className="d-inline-block">
                <Dropdown.Toggle variant="" className="mb-2 no-arrow-icon">
                  {this.translation('new') + ' ' + this.translation('unit')}
                  <Icon iconName="add" size="26" classes="ml-2" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right rounded-0 shadow-sm">
                  <Dropdown.Item
                    className="py-3"
                    onClick={() =>
                      this.props.history.push('/admin/units/new-unit')
                    }
                  >
                    {this.translation('add') +
                      ' ' +
                      this.translation('new') +
                      ' ' +
                      this.translation('unit', { count: 0 })}
                  </Dropdown.Item>
                  <Dropdown.Divider className="my-0" />
                  <Dropdown.Item
                    className="py-3"
                    onClick={() => this.setState({ showImportModal: true })}
                  >
                    {this.translation('import') +
                      ' ' +
                      this.translation('from') +
                      ' ' +
                      this.translation('excel')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Collapse in={this.state.showAdvanceSearch}>
            <Row>
              <Col className="border py-3 mx-3 mb-3">
                <Row>
                  <Col>
                    <h5 className="mb-4">
                      {this.translation('filter') +
                        ' ' +
                        this.translation('by')}
                    </h5>
                    <Form.Group className="mb-5">
                      <Form.Check
                        inline
                        type="radio"
                        label={this.translation('serialNumber')}
                        name="filterByRadio"
                        id="filterByRadio1"
                        className="form-check-success mr-3"
                        value="serialNumber"
                        defaultChecked
                        onChange={this.handleFilterByChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label={
                          this.translation('unit') +
                          ' ' +
                          this.translation('type')
                        }
                        name="filterByRadio"
                        id="filterByRadio2"
                        className="form-check-success mx-3"
                        value="productType"
                        onChange={this.handleFilterByChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label={
                          this.translation('create') +
                          ' ' +
                          this.translation('date')
                        }
                        name="filterByRadio"
                        id="filterByRadio3"
                        className="form-check-success ml-3"
                        value="createDate"
                        onChange={this.handleFilterByChange}
                      />
                    </Form.Group>
                    {this.state.filter.selected === 'serialNumber' ? (
                      <Formik
                        initialValues={{
                          prefix: '',
                          serialNumberStart: '',
                          serialNumberEnd: ''
                        }}
                        validate={values => {
                          let errors = {};
                          if (
                            +values.serialNumberEnd >
                            +values.serialNumberStart + 1000
                          ) {
                            errors.serialNumberEnd = this.translation(
                              'formValidations.onlySerialNumbersSearched',
                              { count: 1000 }
                            );
                          }
                          if (
                            values.serialNumberEnd < values.serialNumberStart
                          ) {
                            errors.serialNumberEnd = this.translation(
                              'formValidations.toFieldValueShouldBeGreaterThanFromField'
                            );
                          }
                          return errors;
                        }}
                        validationSchema={SerialNumberFormSchema}
                        onSubmit={values => {
                          const filter = this.state.filter;
                          filter.prefix = values.prefix;
                          filter.serialNumberStart = values.serialNumberStart;
                          filter.serialNumberEnd = values.serialNumberEnd;
                          this.setState({ filter, filterEnabled: true }, () => {
                            this.goToFirstPage().then(() => {
                              this.getProducts();
                            });
                          });
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                      >
                        {({
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          values
                        }) => {
                          return (
                            <Form noValidate onSubmit={handleSubmit}>
                              <Row className="align-items-center">
                                <Col md="auto">
                                  <Row className="align-items-center">
                                    <Col md="auto">
                                      <p>
                                        {this.translation('prefix', {
                                          context: 'presentCont'
                                        })}
                                      </p>
                                    </Col>
                                    <Col>
                                      <FormGroup
                                        formControlName="prefix"
                                        type="text"
                                        label={this.translation('MF20A')}
                                        handleChange={handleChange}
                                        touched={touched['prefix']}
                                        error={errors['prefix']}
                                        value={values['prefix']}
                                        alertPositionAbsolute
                                      />
                                    </Col>
                                    <Col md="auto">
                                      <p>
                                        {this.translation('start', {
                                          context: 'presentCont'
                                        }) +
                                          ' ' +
                                          this.translation('from')}
                                      </p>
                                    </Col>
                                    <Col>
                                      <FormGroup
                                        formControlName="serialNumberStart"
                                        type="text"
                                        label={this.translation('numberLabel')}
                                        handleChange={handleChange}
                                        touched={touched['serialNumberStart']}
                                        error={errors['serialNumberStart']}
                                        value={values['serialNumberStart']}
                                        alertPositionAbsolute
                                        required
                                      />
                                    </Col>
                                    <Col md="auto">
                                      <p>{this.translation('to')}</p>
                                    </Col>
                                    <Col>
                                      <FormGroup
                                        formControlName="serialNumberEnd"
                                        type="text"
                                        label={this.translation('numberLabel')}
                                        handleChange={handleChange}
                                        touched={touched['serialNumberEnd']}
                                        error={errors['serialNumberEnd']}
                                        value={values['serialNumberEnd']}
                                        alertPositionAbsolute
                                        required
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Button
                                type="submit"
                                variant="success"
                                size="lg"
                                className="rounded-0 px-5 float-right"
                              >
                                {this.translation('apply')}
                              </Button>
                            </Form>
                          );
                        }}
                      </Formik>
                    ) : this.state.filter.selected === 'productType' ? (
                      this.state.isKitTypeLoading ? (
                        <div className="w-100 text-center">
                          <MDSpinner singleColor={ColorConstants.PRIMARY} />
                        </div>
                      ) : (
                        <Form.Group>
                          {this.state.filter.productType.types.map(
                            (item, index) => {
                              return (
                                <Form.Check
                                  key={index}
                                  inline
                                  type="checkbox"
                                  label={item.label}
                                  name="productTypeCheckbox"
                                  id={item.value + 'Checkbox'}
                                  className="mr-3"
                                  checked={item.checked}
                                  onChange={() =>
                                    this.handleProductTypeChange(index)
                                  }
                                />
                              );
                            }
                          )}
                          <Button
                            variant="success"
                            size="lg"
                            className="rounded-0 px-5 float-right"
                            onClick={() => {
                              this.setState({ filterEnabled: true }, () => {
                                this.goToFirstPage().then(() => {
                                  this.getProducts();
                                });
                              });
                            }}
                          >
                            {this.translation('apply')}
                          </Button>
                        </Form.Group>
                      )
                    ) : this.state.filter.selected === 'createDate' ? (
                      <Formik
                        initialValues={{
                          productCreatedDateStart: this.prevMonthDay,
                          productCreatedDateEnd: this.today
                        }}
                        validate={() => {
                          let errors = {};
                          if (
                            this.state.filter.productCreatedDateStart &&
                            this.state.filter.productCreatedDateEnd
                          ) {
                            if (
                              moment(
                                this.state.filter.productCreatedDateEnd
                              ).isBefore(
                                this.state.filter.productCreatedDateStart,
                                'D'
                              )
                            ) {
                              errors = {
                                productCreatedDateEnd: this.translation(
                                  'formValidations.endDateCanNotBeLessThanStartDate'
                                )
                              };
                            } else if (
                              moment(
                                this.state.filter.productCreatedDateStart
                              ).isAfter(
                                this.state.filter.productCreatedDateEnd,
                                'D'
                              )
                            ) {
                              errors = Object.assign(errors, {
                                productCreatedDateStart: this.translation(
                                  'formValidations.startDateCanNotBeMoreThanEndDate'
                                )
                              });
                            }
                          } else {
                            if (!this.state.filter.productCreatedDateEnd) {
                              errors = Object.assign(errors, {
                                productCreatedDateEnd: this.translation(
                                  'required'
                                )
                              });
                            }
                            if (!this.state.filter.productCreatedDateStart) {
                              errors = Object.assign(errors, {
                                productCreatedDateStart: this.translation(
                                  'required'
                                )
                              });
                            }
                          }

                          return errors;
                        }}
                        onSubmit={() => {
                          this.setState({ filterEnabled: true }, () => {
                            this.goToFirstPage().then(() => {
                              this.getProducts();
                            });
                          });
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                      >
                        {({ errors, handleSubmit }) => {
                          return (
                            <Form noValidate onSubmit={handleSubmit}>
                              <Row className="align-items-center">
                                <Col md="auto">
                                  <Row>
                                    <Col md="auto" className="pt-3">
                                      <p>
                                        {this.translation('start', {
                                          context: 'presentCont'
                                        }) +
                                          ' ' +
                                          this.translation('from')}
                                      </p>
                                    </Col>
                                    <Col>
                                      <DatePicker
                                        maxDate={
                                          this.state.filter
                                            .productCreatedDateEnd
                                        }
                                        selected={
                                          this.state.filter
                                            .productCreatedDateStart
                                        }
                                        id="productCreatedDateStart"
                                        onChange={(date, changeEvent) => {
                                          this.handleChangeDate(
                                            'productCreatedDateStart',
                                            date
                                          );
                                        }}
                                        className={
                                          errors['productCreatedDateStart']
                                            ? 'is-invalid'
                                            : ''
                                        }
                                      />
                                      <p className="invalid-feedback-font-size text-danger">
                                        {errors['productCreatedDateStart']}
                                      </p>
                                    </Col>
                                    <Col md="auto" className="pt-3">
                                      <p>{this.translation('to')}</p>
                                    </Col>
                                    <Col>
                                      <DatePicker
                                        minDate={
                                          this.state.filter
                                            .productCreatedDateStart
                                        }
                                        selected={
                                          this.state.filter
                                            .productCreatedDateEnd
                                        }
                                        id="productCreatedDateEnd"
                                        onChange={(date, changeEvent) => {
                                          this.handleChangeDate(
                                            'productCreatedDateEnd',
                                            date
                                          );
                                        }}
                                        className={
                                          errors['productCreatedDateEnd']
                                            ? 'is-invalid'
                                            : ''
                                        }
                                      />
                                      <p className="invalid-feedback-font-size text-danger">
                                        {errors['productCreatedDateEnd']}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Button
                                type="submit"
                                variant="success"
                                size="lg"
                                className="rounded-0 px-5 float-right"
                              >
                                {this.translation('apply')}
                              </Button>
                            </Form>
                          );
                        }}
                      </Formik>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Collapse>
          <Row>
            <Col xs={12}>
              <div className="table-responsive-sm">
                <BootstrapTable
                  remote
                  classes="with-gap"
                  bordered={false}
                  bootstrap4
                  keyField="_id"
                  data={this.state.products}
                  columns={this.state.columns}
                  pagination={
                    this.state.products.length
                      ? paginationFactory(this.state.options)
                      : null
                  }
                  noDataIndication={
                    this.state.isTableLoading
                      ? this.translation('pleaseWait')
                      : this.translation('noData')
                  }
                  rowClasses="colored-background"
                  onTableChange={this.onTableChange}
                  loading={this.state.isTableLoading}
                  overlay={tableConstants.overlay()}
                  defaultSorted={[
                    {
                      dataField: 'serialNumber', // if dataField is not match to any column you defined, it will be ignored.
                      order: 'asc' // desc or asc
                    }
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation()(Products);
