import React from 'react';
import { Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../_shared/helpers';
import i18next from 'i18next';

export const productsTableConstants = {
  productColumns: goTo => [
    {
      dataField: 'serialNumber',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('serialNumber')),
      sort: true,
      headerStyle: { width: '20%' },
      style: { width: '20%' }
    },
    {
      dataField: 'user.fullName',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('customer')),
      formatter: cell =>
        cell ? cell : <span className="text-primary">{i18next.t('n/a')}</span>,
      sort: true,
      headerStyle: { width: '15%' },
      style: { width: '15%' }
    },
    {
      dataField: 'kit.name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('unitType')),
      sort: true,
      headerStyle: { width: '15%' },
      style: { width: '15%' }
    },

    {
      dataField: 'productCreatedDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('createdDate')),
      formatter: cell => getFormattedDate(cell),
      sort: true,
      headerStyle: { width: '15%' },
      style: { width: '15%' }
    },
    {
      dataField: 'registrationDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('register')),
      formatter: cell => getFormattedDate(cell),
      sort: true
    },
    {
      dataField: 'activationDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('ActivationDate')),
      formatter: cell => getFormattedDate(cell),
      sort: true
    },
    // {
    //   dataField: 'serviceExpiryDate',
    //   text: '',
    //   headerFormatter: (...theArgs) =>
    //     headerFormatterWithSort(...theArgs, i18next.t('servicePlan')),
    //   formatter: cell => getFormattedDate(cell),
    //   sort: true
    // },
    {
      dataField: 'actions',
      text: '',
      formatter: (cell, row) => (
        <>
          <Icon
            iconName="right"
            size="29"
            fill={ColorConstants.DEFAULT_FONT}
            classes="mx-2 cursor-pointer"
            onClick={() => goTo('/admin/units/details/' + row._id)}
          />
        </>
      ),
      classes: 'py-0 text-right',
      headerStyle: { width: '8%' },
      style: { width: '8%' }
    }
  ]
};
