import * as Yup from 'yup';
import i18next from 'i18next';
export const LOCALE_YUP = Yup.setLocale({
  mixed: {
    required: () => i18next.t('formValidations.required')
  },
  string: {
    min: ({ min }) => i18next.t('formValidations.minChars', { count: min }),
    email: () => i18next.t('formValidations.email'),
    length: ({ length }) => i18next.t('formValidations.length', { length })
  },
  array: {
    min: ({ min }) => i18next.t('formValidations.minElements', { count: min })
  }
});
