import React from 'react';
import { ImageContainer } from '../../../_shared/components';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../_shared/helpers';
import i18next from 'i18next';

const imageFormatter = (cell, row) => {
  return (
    <ImageContainer
      src={cell}
      alt={row.item.name}
      height="40px"
      className="h-100"
    />
  );
};

const itemNameFormatter = (cell, row) => {
  return `${cell} (${row.quantity})`;
};

export const columns = [
  {
    dataField: 'item.imageSrc',
    text: '',
    formatter: imageFormatter,
    classes: 'py-0 align-middle',
    headerStyle: { width: '8%' },
    style: { width: '8%' }
  },
  {
    dataField: 'item.name',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('itemNameQty')),
    sort: true,
    formatter: itemNameFormatter,
    headerStyle: { width: '25%' },
    style: { width: '25%' }
  },
  {
    dataField: 'item.number',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('itemNumber')),
    sort: true
  },
  {
    dataField: 'kit.name',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('kitType')),
    sort: true
  },
  {
    dataField: 'productSerialNumber',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('kitSerialNumber')),
    sort: true
  },
  {
    dataField: 'expiryDate',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('expiryDate')),
    formatter: cell => getFormattedDate(cell, 60, 'YYYY/MM'),
    sort: true
  }
];
