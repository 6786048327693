import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Icon from './Icon';
import i18next from 'i18next';

class MailSentSuccessfulModal extends Component {
  state = {};
  render() {
    return (
      <Modal show={this.props.show} backdrop="static" centered>
        <Modal.Body>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center p-5">
            <Icon iconName="mail-send" size="80" classes="mb-4 floating" />
            <p>{i18next.t('confirmationMail')}</p>
            <Button
              variant="success"
              size="lg"
              className="rounded-0 px-5"
              onClick={this.props.okClicked}
            >
              {i18next.t('ok')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default MailSentSuccessfulModal;
