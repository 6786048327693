import React, { Component } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { loaderActions } from '../../../_shared/redux/actions';
import AddByNumbers from './AddByNumbers';
import AddByRange from './AddByRange';
import { withTranslation } from 'react-i18next';

export class NewKit extends Component {
  translation = this.props.t;
  state = {
    addByRange: false
  };
  toggleAddByRange = addByRange => {
    this.setState({ addByRange });
  };

  render() {
    return (
      <Container fluid>
        <Card>
          <Card.Body>
            <Card.Title>
              <Button
                variant={!this.state.addByRange ? 'link' : ''}
                size="lg"
                onClick={() => this.toggleAddByRange(false)}
              >
                {this.translation('addUnitsBrackets')}
              </Button>
              |
              <Button
                variant={this.state.addByRange ? 'link' : ''}
                size="lg"
                onClick={() => this.toggleAddByRange(true)}
              >
                {this.translation('addUnitsByRange')}
              </Button>
            </Card.Title>
            <Row>
              <Col xs={12} className="ml-3">
                <p className="font-size-lg">
                  {this.translation(
                    'enterTheSerialNumberSOfTheKitSYouWouldLikeToAdd'
                  )}
                </p>
              </Col>
              {!this.state.addByRange ? (
                <AddByNumbers
                  goTo={path => this.props.history.push(path)}
                  startLoading={() => this.props.startLoading()}
                  stopLoading={() => this.props.stopLoading()}
                />
              ) : (
                <AddByRange
                  goTo={path => this.props.history.push(path)}
                  startLoading={() => this.props.startLoading()}
                  stopLoading={() => this.props.stopLoading()}
                />
              )}
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(NewKit)
);
