import { LoaderConstants } from '../../constants';

const initialState = {
  isLoading: false
};
export function loading(state = initialState, action) {
  switch (action.type) {
    case LoaderConstants.START_LOADING:
      return {
        isLoading: true
      };
    case LoaderConstants.END_LOADING:
      return {
        isLoading: false
      };
    default:
      return initialState;
  }
}
