import React, { Component } from 'react';
import { Container, Card, Button, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { loaderActions } from '../../../_shared/redux/actions';
import AddByNumbers from './AddByNumbers';
import AddByRange from './AddByRange';
import { withTranslation } from 'react-i18next';

export class NewKit extends Component {
  state = {
    addByRange: false,
    customerId: '',
    registrationDate: Date
  };
  constructor(props) {
    super(props);
    const customerId = props.match.params.customerId;
    this.state.customerId = customerId;
  }
  toggleAddByRange = addByRange => {
    this.setState({ addByRange });
  };

  render() {
    const translation = this.props.t;
    return (
      <Container fluid>
        <Card>
          <Card.Body>
            <Card.Title>
              <Button
                variant={!this.state.addByRange ? 'link' : ''}
                size="lg"
                onClick={() => this.toggleAddByRange(false)}
              >
                {translation('addUnitsBrackets')}
              </Button>{' '}
              |
              <Button
                variant={this.state.addByRange ? 'link' : ''}
                size="lg"
                onClick={() => this.toggleAddByRange(true)}
              >
                {translation('addUnitsByRange')}
              </Button>
            </Card.Title>
            <Row>
              {!this.state.addByRange ? (
                <AddByNumbers
                  customerId={this.state.customerId}
                  goTo={path => this.props.history.push(path)}
                  startLoading={() => this.props.startLoading()}
                  stopLoading={() => this.props.stopLoading()}
                />
              ) : (
                <AddByRange
                  customerId={this.state.customerId}
                  goTo={path => this.props.history.push(path)}
                  startLoading={() => this.props.startLoading()}
                  stopLoading={() => this.props.stopLoading()}
                />
              )}
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(NewKit)
);
