import { Observable } from 'rxjs';

import { API_ENDPOINTS } from '../constants/api-endpoints.constants';
import { authHeader, AxiosSubscriber } from '../helpers';
const configToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoidGhpcyBpcyBhIGNvbmZpZyBmaWxlIGNvbnRyb2xsZXIgc3BlY2lhbCB0b2tlbiBpZiB5b3UgaGF2ZSB0aGlzIHRva2VuIHRoZW4gZ2V0IGNvbmZpZyBiZXR0ZXIgcmVzcG9uc2UifQ.4n4A4bUtleubJxHTJOeO077b7s_gnSletSEikFZjtsg';
export const ConfigService = {
  getConfig,
  updateConfig
};

/**@function getConfig
 * Get config value
 * @param {key} - config key
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getConfig(key) {
  return new Observable(observer => {
    const requestType = 'get',
      url = `${API_ENDPOINTS.URL}${API_ENDPOINTS.CONFIG.GET_CONFIG}/${key}`,
      axiosOptions = { headers: { token: configToken } };
    new AxiosSubscriber(observer, url, requestType, axiosOptions);
  });
}

/**@function updateConfig
 * Update value
 * @param {key} - config key
 * @param {value} - config value
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function updateConfig(key, value) {
  return new Observable(observer => {
    const requestType = 'patch',
      url = `${API_ENDPOINTS.URL}${API_ENDPOINTS.CONFIG.GET_CONFIG}/${key}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(observer, url, requestType, axiosOptions, {
      value
    });
  });
}
