// @flow
import * as React from 'react';
import { Container, Button } from 'react-bootstrap';

import { ReactRouterHistory } from '../../../models';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  history: ReactRouterHistory,
  t: i18next.TFunction
};

type State = {};

export class NotAllowed extends React.Component<Props, State> {
  translation = this.props.t;
  render() {
    return (
      <Container fluid>
        <div className="w-100 text-center">
          <h1 className="display-1">
            {this.translation('youReNotAllowedToAccessThisPage')}
          </h1>
          <Button
            variant="link"
            onClick={() => this.props.history.push('/admin/dashboard')}
          >
            {this.translation('goToDashboard')}
          </Button>
        </div>
      </Container>
    );
  }
}

export default withTranslation()(NotAllowed);
