import React from 'react';
import * as Yup from 'yup';
import { Translation } from 'react-i18next';

export const LicenseSchema = Yup.object().shape({
  code: Yup.string().required(),
  maxActivation: Yup.string()
    .required()
    .test(
      'is-digits',
      <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
      value => !isNaN(value)
    )
    .test(
      'is-less-than-one',
      <Translation>
        {t => (
          <>
            {t('formValidations.valueLessThan', {
              name: 'Max Activations',
              value: '1'
            })}
          </>
        )}
      </Translation>,
      value => +value > 0
    )
    .when('totalActivations', {
      is: val => !isNaN(val),
      then: Yup.number().min(
        Yup.ref('totalActivations'),
        <Translation>
          {t => (
            <>
              {t('formValidations.valueLessThan', {
                name: 'Max Activations',
                value: '1'
              })}
            </>
          )}
        </Translation>
      )
    })
});
