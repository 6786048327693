// @flow
import * as React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {
  Icon,
  ConfirmDialog,
  ImageContainer
} from '../../../_shared/components';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';

import { ColorConstants } from '../../../_shared/constants';
import { kitService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import { loaderActions } from '../../../_shared/redux/actions';
import { Kit } from '../../../models';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ToggleButton from 'react-toggle-button';

type Props = {
  history: any,
  startLoading(): void,
  stopLoading(): void,
  t: i18next.TFunction
};

type State = {
  isLoading: boolean,
  showConfirmDialog: boolean,
  ConfirmationMessage: React.Element<any>,
  kitId: string,
  kits: Kit[]
};

export class Kits extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**State of component */
  state = {
    isLoading: true,
    showConfirmDialog: false,
    ConfirmationMessage: <></>,
    kitId: '',
    kits: []
  };
  /**Call apis once component mounted */
  componentDidMount() {
    this.getKits();
  }
  /**Free up resources once component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Get kits from server */
  getKits() {
    this.setState({ isLoading: true });
    const getKitTypes$ = kitService.getKitTypes().subscribe({
      next: (response: { data: { kits: Kit[] } }) => {
        if (this.cleanup) {
          const kits = response.data.kits;
          this.setState({ kits, isLoading: false });
        }
      },
      error: (errorResponse: { data: { message: string } }) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getKitTypes$.unsubscribe();
    };
  }
  /**Present confirm delete */
  presentConfirmDialog = (id: string, name: string, active: boolean) => {
    if (active != true) {
      this.setState({
        showConfirmDialog: false,
        kitId: id
      });
      this.props.startLoading();
      this.activatedKit(id, active);
    } else {
      const ConfirmationMessage = (
        <p>
          {this.translation('confirmDeactivateThisKit')}
          <br /> <br />{' '}
          <b>
            {this.translation('kitName')}: {name}
          </b>
        </p>
      );
      this.setState({
        showConfirmDialog: true,
        ConfirmationMessage,
        kitId: id
      });
    }
  };
  /**Confirm dialog cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDialog: false });
  };
  /**Confirm dialog ok click handler */
  deactivateKit = () => {
    this.setState({ showConfirmDialog: false });
    this.props.startLoading();
    this.deactivatedKit();
  };

  activatedKit(id: string, activate: boolean) {
    const deleteKit$: Subscription = kitService
      .activatedKit(id, activate)
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.success(this.translation('success'), response.data.message);
            this.getKits();
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
    this.cleanup = () => {
      deleteKit$.unsubscribe();
    };
  }

  /**Once confirmed, delete user */
  deactivatedKit() {
    const deactivateKit$: Subscription = kitService
      .deactivatedKit(this.state.kitId)
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.success(this.translation('success'), response.data.message);
            this.getKits();
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
    this.cleanup = () => {
      deactivateKit$.unsubscribe();
    };
  }
  render() {
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDialog}
          cancelBtn={this.translation('no')}
          okBtn={this.translation('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deactivateKit()}
          message={this.state.ConfirmationMessage}
        />
        <Container fluid>
          <Row className="justify-content-end">
            <Col xs="auto" className="mb-3">
              <Button
                variant=""
                className="rounded-0"
                onClick={() =>
                  this.props.history.push('/admin/kits/add-kit-type')
                }
              >
                {this.translation('addNewKitType')}
                <Icon iconName="add" size="26" classes="ml-2 mr-1" />
              </Button>
            </Col>
          </Row>
          {this.state.isLoading ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <>
              {this.state.kits.length ? (
                this.state.kits.map((kitType, index) => (
                  <Row className="justify-content-between" key={index}>
                    <Col className="mb-3 bg-light">
                      <div className="h-100 d-flex align-items-center h5">
                        {kitType.name}
                      </div>
                    </Col>
                    <Col className="mb-3 bg-light align-self-stretch py-4 mr-0 mr-sm-0 mr-md-2">
                      <div className="ToggleButton-color h-100 d-flex align-items-center justify-content-end">
                        {/* <Icon
                      classes="mx-2"
                      iconName="edit-inline"
                      size="29"
                      fill={ColorConstants.DEFAULT_FONT}
                    /> */}
                        {/* <Icon
                          classes="mx-2 cursor-pointer"
                          iconName="delete-inline"
                          size="29"
                          fill={ColorConstants.DEFAULT_FONT}
                          onClick={() => {
                            this.presentDeleteConfirm(
                              kitType._id,
                              kitType.name
                            );
                          }}
                        /> */}

                        {/* <Button
                          variant={
                            kitType.active === false
                              ? 'outline-success'
                              : 'outline-primary'
                          }
                          size="sm"
                          className={
                            kitType.active === false
                              ? 'padding-button rounded-0 mr-3'
                              : 'rounded-0 mr-3'
                          }
                          onClick={() => {
                            this.presentConfirmDialog(
                              kitType._id,
                              kitType.name,
                              kitType.active
                            );
                          }}
                        >
                          {kitType.active === false
                            ? i18next.t('active')
                            : i18next.t('disable')}
                        </Button> */}
                        <div className="align-items-center">
                          {kitType.active ? 'Active' : 'Inactive'}
                        </div>
                        <ToggleButton
                          value={kitType.active}
                          inactiveLabel={''}
                          activeLabel={''}
                          colors={{
                            activeThumb: {
                              base: 'rgb(250,250,250)'
                            },
                            inactiveThumb: {
                              base: 'rgb(250,250,250)'
                            },
                            active: {
                              base: 'rgb(22, 184, 108)',
                              hover: 'rgb(177, 191, 215)'
                            },
                            inactive: {
                              base: 'rgb(177	30	41)',
                              hover: 'rgb(95,96,98)'
                            }
                          }}
                          onToggle={value =>
                            this.presentConfirmDialog(
                              kitType._id,
                              kitType.name,
                              kitType.active
                            )
                          }
                        />
                        <Icon
                          classes="mx-2 cursor-pointer"
                          iconName="right"
                          size="29"
                          fill={ColorConstants.DEFAULT_FONT}
                          onClick={() =>
                            this.props.history.push(
                              '/admin/kits/details/' + kitType._id
                            )
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                ))
              ) : (
                <div className="w-100 text-center">
                  {this.translation('noKitTypeFound')}
                  <br />
                  <Button
                    variant="link"
                    className="text-blue"
                    onClick={() =>
                      this.props.history.push('/admin/kits/add-kit-type')
                    }
                  >
                    {this.translation('addKitType')}
                  </Button>
                </div>
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(Kits)
);
