import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { toastr } from 'react-redux-toastr';

import { Slides } from '../../components';
import { columns } from './table.constants';
import { ProductService } from '../../../_shared/services';
import { tableConstants } from '../../../_shared/constants';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';

export class Home extends Component {
  translation = this.props.t;
  cleanup = null;
  state = {
    isTableLoading: true,
    itemsArrived: false,
    items: [],
    sortField: 'expiryDate',
    sortOrder: 'asc'
  };
  /**Call service apis when component mounted */
  componentDidMount() {
    this.getItems();
  }
  /**Free up resources when unmounting */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Get expiring items */
  getItems = (sort = this.state.sortField, order = this.state.sortOrder) => {
    this.setState({ isTableLoading: true });
    const skip = 0,
      limitTo = 10;
    const getExpiryProductItems$ = ProductService.getExpiryProductItems(
      skip,
      limitTo,
      sort,
      order
    ).subscribe({
      next: response => {
        if (this.cleanup) {
          const items = response.data.productItems;
          this.setState({ items, isTableLoading: false });
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          this.setState({ isTableLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getExpiryProductItems$.unsubscribe();
    };
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    { sortField, sortOrder, tableData, page, sizePerPage }
  ) => {
    this.getItems(sortField, sortOrder);
  };
  render() {
    const { t: translation } = this.props;
    return (
      <Container fluid>
        {/* <Row>
          <Col xs={12} className="text-center mb-3">
            <div className="border border-thick border-success text-success">
              <h4 className="my-2 py-1 font-weight-bold">
                Your Service Plan Renews Feb 23, 2020
              </h4>
            </div>
          </Col>
        </Row> */}
        <Slides />
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Card.Title>{translation('expiringItems')}</Card.Title>
                <BootstrapTable
                  remote
                  classes="border-bottom"
                  bordered={false}
                  bootstrap4
                  keyField="_id"
                  data={this.state.items}
                  columns={columns}
                  wrapperClasses="table-responsive-sm"
                  noDataIndication={
                    this.state.isTableLoading
                      ? translation('pleaseWait')
                      : translation('noData')
                  }
                  loading={this.state.isTableLoading}
                  overlay={tableConstants.overlay()}
                  onTableChange={this.onTableChange}
                  defaultSorted={[
                    {
                      dataField: this.state.sortField,
                      order: this.state.sortOrder
                    }
                  ]}
                />
                <button
                  className="btn btn-link text-blue"
                  onClick={() =>
                    this.props.history.push('/home/expiring-items')
                  }
                >
                  {translation('showAllList')}
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(Home);
