// @flow
import * as React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toastr } from 'react-redux-toastr';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';

import { FormGroup, Icon } from '../../../_shared/components';
import { tableConstants } from '../../../_shared/constants';
import { DocumentService } from '../../../_shared/services';
import { IDocument } from '../../../models';

import { DocumentTableConstants } from '../Documents/table.constants';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  show: boolean,
  onHide(renderBannerPage: boolean): void,
  existingDocuments: IDocument[],
  t: i18next.TFunction
};

type State = {
  isTableLoading: boolean,
  documents: IDocument[],
  columns: any,
  options: any,
  currentIndex: number,
  sizePerPage: number,
  sortField: string,
  sortOrder: 'asc' | 'desc',
  page: number,
  searchText: string,
  documentType: string,
  renderBannerPage: boolean,
  documentsArrived: boolean
};

class AddExistingDocumentModal extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;

  /**Set document as banner image */
  setDocumentAsBanner = (document: IDocument) => {
    this.setState({
      isTableLoading: true,
      renderBannerPage: true
    });
    DocumentService.update(document._id, { banner: true, add: true }).subscribe(
      {
        next: () => {
          this.getDocuments();
        },
        error: (errorResponse: any) => {
          if (this.cleanup) {
            this.setState({ documentsArrived: true, isTableLoading: false });
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      }
    );
  };
  /**Component state */
  state = {
    isTableLoading: true,
    documents: [],
    itemsToBeAdded: [],
    documentsArrived: false,
    columns: DocumentTableConstants.newDocumentColumns(
      this.setDocumentAsBanner
    ),
    options: {},
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'name',
    sortOrder: 'asc',
    page: 1,
    searchText: '',
    documentType: 'Video,Image',
    renderBannerPage: false
  };
  /**Free up resources when exiting */
  onExited() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
    this.setState({
      currentIndex: 0,
      sizePerPage: 10,
      sortField: 'name',
      sortOrder: 'asc',
      page: 1,
      searchText: '',
      isTableLoading: true
    });
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$: Observable<Event> = fromEvent(
      document.getElementById('document-items-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            searchText: inputEvent.target.value,
            isTableLoading: true,
            page: 1,
            currentIndex: 0
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText,
            this.state.documentType
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Handle pagination and sorting */
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    {
      sortField,
      sortOrder,
      tableData,
      page,
      sizePerPage
    }: {
      sortField: string,
      sortOrder: 'asc' | 'desc',
      tableData: any,
      page: number,
      sizePerPage: number
    }
  ) => {
    const currentIndex: number =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getDocuments(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    sortField: string,
    sortOrder: 'asc' | 'desc',
    searchText: string,
    documentType: string
  ) => {
    return DocumentService.getDocuments(
      currentIndex,
      sizePerPage,
      searchText,
      documentType,
      sortField,
      sortOrder
    );
  };

  /**Subscriber-response handler */
  handelResponse = () => ({
    next: (response: { data: { documents: IDocument[], count: number } }) => {
      if (this.cleanup) {
        const {
          sizePerPage,
          page
        }: { sizePerPage: number, page: number } = this.state;
        const {
          documents,
          count
        }: { documents: IDocument[], count: number } = response.data;
        this.setState({ documentsArrived: true });
        const options: any = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          documents,
          isTableLoading: false
        });
      }
    },
    error: (errorResponse: any) => {
      if (this.cleanup) {
        this.setState({ documentsArrived: true, isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Get expiring items */
  getDocuments(
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    sortField: string = this.state.sortField,
    sortOrder: 'asc' | 'desc' = this.state.sortOrder,
    searchText: string = this.state.searchText,
    documentType: string = this.state.documentType
  ) {
    this.setState({ isTableLoading: true });
    const documentService$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      documentType
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      documentService$.unsubscribe();
    };
  }

  render() {
    const translation = this.props.t;
    return (
      <Modal
        onEntered={() => {
          this.onSearchChange();
        }}
        show={this.props.show}
        onHide={() => this.props.onHide(this.state.renderBannerPage)}
        onExited={() => this.onExited()}
        size="lg"
        aria-labelledby="add-item-in-kit-type-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-truncate w-100">
            <span className="float-left text-truncate mw-97">
              {this.translation('selectDocument')}
            </span>
            <Icon
              iconName="cancel"
              classes="float-right cursor-pointer mt-2"
              onClick={() => this.props.onHide(this.state.renderBannerPage)}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-between align-items-center">
            <Col sm={8} md={6} className="mb-3">
              <FormGroup
                type="text"
                label={this.translation('searchByDocumentTitleDescription')}
                title={this.translation('searchByDocumentTitleDescription')}
                icon="search"
                iconPosition="right"
                formControlId="document-items-search-input"
                classes="mb-2"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BootstrapTable
                remote
                classes="with-gap"
                bordered={false}
                bootstrap4
                keyField="_id"
                data={this.state.documents}
                columns={this.state.columns}
                pagination={
                  this.state.documents.length
                    ? paginationFactory(this.state.options)
                    : null
                }
                wrapperClasses="table-responsive-sm"
                noDataIndication={
                  this.state.isTableLoading
                    ? translation('pleaseWait')
                    : translation('noData')
                }
                rowClasses="colored-background"
                loading={this.state.isTableLoading}
                overlay={tableConstants.overlay()}
                onTableChange={this.onTableChange}
                defaultSorted={[
                  {
                    dataField: 'title', // if dataField is not match to any column you defined, it will be ignored.
                    order: 'asc' // desc or asc
                  }
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(AddExistingDocumentModal);
