// @flow
import * as React from 'react';
import {
  Row,
  Col,
  ListGroup,
  Button,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import MDSpinner from 'react-md-spinner';

import { Icon, ConfirmDialog } from '../../../../_shared/components';
import { Product } from '../../../../models';

import { ColorConstants } from '../../../../_shared/constants';
import { getFormattedDate, errorParser } from '../../../../_shared/helpers';
import { ProductService } from '../../../../_shared/services';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  details: Product,
  goTo(path: string): void,
  startLoading(): void,
  stopLoading(): void,
  getProductDetails(): void,
  t: i18next.TFunction
};

type State = {
  showConfirmDelete: boolean,
  maxActivationUpdate: boolean,
  maxActivation: number,
  isMaxActivationUpdating: boolean,
  showMaxActivationError: boolean | string
};

class Details extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /** State of component */
  state = {
    showConfirmDelete: false,
    maxActivationUpdate: false,
    maxActivation: 0,
    isMaxActivationUpdating: false,
    showMaxActivationError: false
  };
  /**Free up resources once component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Redirects */
  goTo = (path: string) => this.props.goTo(path);
  /**Remove user click handler */
  removeUser = () => {
    this.props.startLoading();
    const unRegisterProduct$ = ProductService.unRegisterProduct(
      this.props.details._id,
      { userId: this.props.details.userId }
    ).subscribe({
      next: (response: { data: { message: string } }) => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.success(
            this.translation('user') +
              ' ' +
              this.translation('remove', { context: 'past' }),
            response.data.message
          );
          this.props.getProductDetails();
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      unRegisterProduct$.unsubscribe();
    };
  };
  updateMaxActivation = () => {
    this.setState({
      isMaxActivationUpdating: true
    });
    const updateMaxActivation$ = ProductService.updateMaxActivation(
      this.state.maxActivation || this.props.details.maxActivation,
      this.props.details._id
    ).subscribe({
      next: (response: { data: { message: string } }) => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.success(
            this.translation('Unit') +
              ' ' +
              this.translation('update', { context: 'past' }),
            response.data.message
          );
          this.props.getProductDetails();
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.props.stopLoading();
          this.setState({
            isMaxActivationUpdating: false,
            maxActivationUpdate: false,
            maxActivation: this.props.details.maxActivation
          });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      updateMaxActivation$.unsubscribe();
    };
  };
  /**Modal Cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };
  /**Modal Ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.removeUser();
  };
  handleMaxActivationChange = (event: any) => {
    const maxActivation = event.target.value;
    this.setState({ maxActivation });
    if (!maxActivation) {
      this.setState({
        showMaxActivationError: this.translation('formValidations.required')
      });
    } else if (maxActivation < this.props.details.maxActivation) {
      this.setState({
        showMaxActivationError: this.translation(
          'youCanTReduceMaxActivationCount'
        )
      });
    } else {
      this.setState({ showMaxActivationError: false });
    }
  };
  render() {
    const {
      details
    }: {
      details: Product
    } = this.props;
    const {
      kit,
      serialNumber,
      productCreatedDate,
      registrationDate,
      // distributor,
      user,
      maxActivation,
      totalActivation,
      activationDate
    } = details;
    const { name } = kit;
    const formData = {
      Model: name,
      serialNumber: serialNumber,
      createdDate: productCreatedDate,
      registrationDate: registrationDate,
      activationDate: activationDate,
      // Distributor: distributor,
      customer: user,
      registered: registrationDate ? 'Yes' : 'No',
      maxActivation: maxActivation,
      totalActivation: totalActivation
    };
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn="No"
          okBtn="Yes"
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.translation('doYouReallyWantToRemoveUser')}
        />
        <Row className="align-items-center">
          <Col>
            <ListGroup variant="flush" className="pl-md-4">
              {Object.keys(formData).map((key, index) => (
                <ListGroup.Item key={index}>
                  <Row className="align-items-center">
                    <Col>{this.translation(key)}</Col>
                    <Col>
                      {key === 'customer' ? (
                        <>
                          {user && (user.fullName || user.name) ? (
                            <>
                              <Button
                                variant="link"
                                className="text-blue m-0 p-0"
                                onClick={() =>
                                  this.goTo(
                                    '/admin/customers/details/' + user._id
                                  )
                                }
                              >
                                {user.fullName ||
                                  user.name +
                                    (user.lastName ? ' ' + user.lastName : '')}
                              </Button>
                              <Button
                                variant=""
                                className="m-0 p-0"
                                onClick={() =>
                                  this.setState({ showConfirmDelete: true })
                                }
                                title={
                                  this.translation('remove') +
                                  ' ' +
                                  this.translation('user')
                                }
                              >
                                <Icon
                                  iconName="cancel-filled"
                                  size="26"
                                  classes="ml-2"
                                  fill={ColorConstants.PRIMARY}
                                />
                              </Button>
                            </>
                          ) : (
                            <span className="text-primary">
                              {this.translation('n/a')}
                            </span>
                          )}
                        </>
                      ) : key === 'createdDate' ||
                        key === 'activationDate' ||
                        key === 'registrationDate' ? (
                        getFormattedDate(formData[key])
                      ) : key === 'maxActivation' ? (
                        this.state.maxActivationUpdate ? (
                          <>
                            <InputGroup className="w-50">
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip
                                    id="tooltip-max-activation"
                                    className={
                                      !this.state.showMaxActivationError
                                        ? 'd-none'
                                        : ''
                                    }
                                  >
                                    {this.state.showMaxActivationError}
                                  </Tooltip>
                                }
                              >
                                <FormControl
                                  size="sm"
                                  type="number"
                                  placeholder="XX"
                                  defaultValue={
                                    this.state.maxActivation || maxActivation
                                  }
                                  className={[
                                    'rounded-0',
                                    this.state.showMaxActivationError
                                      ? 'is-invalid'
                                      : ''
                                  ].join(' ')}
                                  onChange={this.handleMaxActivationChange}
                                  disabled={this.state.isMaxActivationUpdating}
                                  min={
                                    details.maxActivation
                                      ? details.maxActivation
                                      : 0
                                  }
                                />
                              </OverlayTrigger>
                              <InputGroup.Append>
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  onClick={() =>
                                    this.setState((state, props) => ({
                                      maxActivation:
                                        props.details.maxActivation,
                                      maxActivationUpdate: false,
                                      showMaxActivationError: false
                                    }))
                                  }
                                  disabled={this.state.isMaxActivationUpdating}
                                >
                                  {this.translation('cancel')}
                                </Button>
                                <Button
                                  size="sm"
                                  variant="success"
                                  className="rounded-0"
                                  onClick={() => {
                                    if (!this.state.showMaxActivationError) {
                                      this.updateMaxActivation();
                                    }
                                  }}
                                  style={{ width: '70px' }}
                                  disabled={this.state.isMaxActivationUpdating}
                                >
                                  {this.state.isMaxActivationUpdating ? (
                                    <MDSpinner
                                      size="20"
                                      singleColor="#FFFFFF"
                                    />
                                  ) : (
                                    this.translation('save')
                                  )}
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </>
                        ) : (
                          <>
                            {formData[key]}
                            <Button
                              variant=""
                              className="m-0 p-0"
                              onClick={() => {
                                this.setState({ maxActivationUpdate: true });
                              }}
                              title={this.translation(
                                'changeMaxActivationCount'
                              )}
                            >
                              <Icon
                                iconName="edit"
                                size="24"
                                classes="ml-2"
                                fill={ColorConstants.PRIMARY}
                              />
                            </Button>
                          </>
                        )
                      ) : (
                        <>
                          {formData[key] ? (
                            formData[key]
                          ) : (
                            <span className="text-primary">
                              {this.translation('n/a')}
                            </span>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation()(Details);
