import React from 'react';
import { logger } from './app/_shared/helpers';
import { withTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
export class ErrorBoundary extends React.Component {
  translation = this.props.t;
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    logger.error(error);
    logger.info(info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container fluid>
          <div className="my-5 text-center">
            <h1 className="display-1">
              {this.translation('somethingWentWrong')}
            </h1>
            <p>{this.translation('weApologize')}</p>
          </div>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
