import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { UserService } from '../../../_shared/services';
import { toastr } from 'react-redux-toastr';
import MDSpinner from 'react-md-spinner';
import { ColorConstants } from '../../../_shared/constants';
import { errorParser, getFormattedDate } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';

export class LicenseCode extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = null;
  /**State of component */
  state = {
    isLoading: true,
    license: {
      code: 'N/A',
      maxActivation: 0,
      shippedTo: 'N/A',
      expirationDate: ''
    },
    totalActivations: 0
  };
  /**Call apis once component is mounted */
  componentDidMount() {
    this.getLicenseDetails();
  }
  /**Free up resources once component un mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Get license details */
  getLicenseDetails = () => {
    const getLicenseDetails$ = UserService.getCurrentUser().subscribe({
      next: response => {
        if (this.cleanup) {
          if (response.data.currentUser.license) {
            const license = response.data.currentUser.license;
            const totalActivations = response.data.currentUser.devices.length;
            this.setState({ license, totalActivations });
          }
          this.setState({ isLoading: false });
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getLicenseDetails$.unsubscribe();
    };
  };
  render() {
    return (
      <Container fluid>
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <Row className="justify-content-center">
            <Col xs="12" className="mt-md-3">
              <Card>
                <Card.Body className="px-5 pb-4">
                  <h4>
                    {this.translation('licenseCode')}:{' '}
                    <span className="text-blue">{this.state.license.code}</span>
                  </h4>
                  <Row className="border-bottom">
                    <Col className="my-3">
                      {this.translation('maxActivations')}
                    </Col>
                    <Col className="my-3">
                      {this.state.license.maxActivation}
                    </Col>
                  </Row>
                  <Row className="border-bottom">
                    <Col className="my-3">
                      {this.translation('totalActivations')}
                    </Col>
                    <Col className="my-3">{this.state.totalActivations}</Col>
                  </Row>
                  <Row>
                    <Col className="my-3">
                      {this.translation('expirationDate')}
                    </Col>
                    <Col className="my-3">
                      {getFormattedDate(this.state.license.expirationDate)}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default withTranslation()(LicenseCode);
