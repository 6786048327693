// @flow

import * as React from 'react';
import { Modal } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';

import { IDocument } from '../../../models';
import { ColorConstants } from '../../../_shared/constants';
import { Icon, ImageContainer } from '../../../_shared/components';
import { Trans } from 'react-i18next';

type Props = {
  onHide(): void,
  onHidden(): void,
  document: IDocument,
  show: boolean
};

type State = {};

class RegistrationSuccessfulModal extends React.Component<Props, State> {
  state = {};
  markup = (val: any) => {
    return { __html: val };
  };
  render() {
    const { document } = this.props;
    return (
      <Modal
        show={this.props.show}
        backdrop="static"
        centered
        size="lg"
        onHide={this.props.onHide}
        onExited={this.props.onHidden}
      >
        {document ? (
          <>
            <Modal.Header>
              <Modal.Title className="text-truncate w-100">
                <span className="float-left text-truncate mw-97">
                  {document.title}
                </span>
                <Icon
                  iconName="cancel"
                  classes="float-right cursor-pointer mt-2"
                  onClick={() => this.props.onHide()}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              {document.type === 'Image' ? (
                <div>
                  <ImageContainer
                    className="document-image-preview img-fluid h-100 mb-3"
                    src={document.url}
                    alt={document.name}
                  />
                  <hr />
                  <p>
                    {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
                    <Trans i18nKey="documentPreviewModal.userWarning">
                      If you are not able to see the resource, you click on this
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={document.url}
                      >
                        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
                        link
                        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
                      </a>
                      , to open resource directly.
                    </Trans>
                  </p>
                </div>
              ) : document.type === 'Document' ? (
                <>
                  <div className="document-pdf-preview mb-3">
                    <iframe
                      title={document.title}
                      src={document.url}
                      className="document-pdf-preview-content"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    />
                  </div>
                  <hr />
                  <p>
                    {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
                    <Trans i18nKey="documentPreviewModal.userWarning">
                      If you are not able to see the resource, you click on this
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={document.url}
                      >
                        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
                        link
                        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
                      </a>
                      , to open resource directly.
                    </Trans>
                  </p>
                </>
              ) : (
                // TODO: @Dharmen: Find out better solution
                <div dangerouslySetInnerHTML={this.markup(document.url)} />
              )}
            </Modal.Body>
          </>
        ) : (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        )}
      </Modal>
    );
  }
}

export default RegistrationSuccessfulModal;
