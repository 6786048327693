// @flow
import * as React from 'react';
import { Row, Col, ListGroup, Form, Dropdown, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import { toastr } from 'react-redux-toastr';
import {
  toCapitalize,
  getFormattedDate,
  errorParser,
  logger
} from '../../../../_shared/helpers/index.js';
import { ProductService } from '../../../../_shared/services/index.js';
import { DatePicker } from '../../../../_shared/components/index.js';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  details: {
    productId: string,
    servicePlanStatus: 'active' | 'inactive',
    serviceExpiryDate: Date | any,
    registrationDate: Date
  },
  editDetails: boolean,
  bindSubmitForm(key: string, formValue: any): void,
  startLoading(): void,
  stopLoading(): void,
  getProductDetails(): void,
  t: i18next.TFunction
};

type State = {
  productId: string,
  servicePlanStatus: 'active' | 'inactive',
  serviceExpiryDate: Date | any,
  showMaxActivationError: boolean
};

class ServicePlan extends React.Component<Props, State> {
  translation: i18next.TFunction = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  componentDidMount() {
    this.createState();
  }
  createState() {
    const {
      productId,
      servicePlanStatus,
      serviceExpiryDate
    } = this.props.details;
    logger.info('details', this.props.details);
    this.setState(
      {
        productId,
        servicePlanStatus: servicePlanStatus ? servicePlanStatus : 'inactive',
        serviceExpiryDate: serviceExpiryDate ? new Date(serviceExpiryDate) : ''
      },
      () => {
        logger.info('state.serviceExpiryDate', this.state.serviceExpiryDate);
      }
    );
  }

  /**Free up resources once component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**If editing canceled, reset state */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.editDetails && !this.props.editDetails) {
      this.createState();
    }
  }
  handleExpiringDateChange = (serviceExpiryDate: Date) => {
    this.setState({ serviceExpiryDate });
  };
  render() {
    const { editDetails, bindSubmitForm } = this.props;
    return (
      <Row className="align-items-center">
        {!this.props.details.registrationDate && (
          <Col xs={12}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row>
                  <Col>
                    <Alert variant="danger">
                      {this.translation('registerToCustomer')}
                    </Alert>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        )}
        <Col>
          {!editDetails ? (
            <ListGroup variant="flush" className="pl-md-4">
              <ListGroup.Item>
                <Row>
                  <Col>{this.translation('servicePlanEnroll')}</Col>
                  <Col>
                    {toCapitalize(this.props.details.servicePlanStatus)}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{this.translation('expiringDate')}</Col>
                  <Col>
                    <span
                      className={
                        this.props.details.servicePlanStatus === 'inactive'
                          ? 'text-muted'
                          : ''
                      }
                      title={
                        this.props.details.servicePlanStatus === 'inactive'
                          ? this.translation('statusInactiveNoEffectOfDate')
                          : ''
                      }
                    >
                      {getFormattedDate(this.props.details.serviceExpiryDate)}
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          ) : (
            <Formik
              initialValues={{
                'Service-Plan-Enroll': this.state.servicePlanStatus,
                'Expiring-Date': this.state.serviceExpiryDate
              }}
              // validationSchema={ServicePlanSchema}
              validate={values => {
                let errors = {};
                if (this.state.servicePlanStatus === 'active') {
                  if (!this.state.serviceExpiryDate) {
                    errors['Expiring-Date'] = 'Required';
                  }
                }
                return errors;
              }}
              onSubmit={() => {
                this.props.startLoading();
                const updateServicePlan$ = ProductService.updateServicePlan(
                  this.state.productId,
                  {
                    servicePlanStatus: this.state.servicePlanStatus,
                    serviceExpiryDate: this.state.serviceExpiryDate
                  }
                ).subscribe({
                  next: (response: { data: { message: string } }) => {
                    if (this.cleanup) {
                      toastr.success(
                        this.translation('servicePlan') +
                          ' ' +
                          toCapitalize(
                            this.translation('update', { context: 'past' })
                          ),
                        response.data.message
                      );
                      this.props.getProductDetails();
                      this.props.stopLoading();
                    }
                  },
                  error: (errorResponse: any) => {
                    if (this.cleanup) {
                      toastr.error(
                        this.translation('error'),
                        errorParser(errorResponse)
                      );
                      this.props.stopLoading();
                    }
                  }
                });
                this.cleanup = () => {
                  updateServicePlan$.unsubscribe();
                };
              }}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ handleSubmit, handleChange, errors }) => {
                bindSubmitForm('servicePlan', handleSubmit);
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <ListGroup variant="flush" className="pl-md-4">
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col>{this.translation('servicePlanEnroll')}</Col>
                          <Col>
                            <Dropdown>
                              <Dropdown.Toggle
                                className="border rounded-0 input-height mt-0 mb-0"
                                variant=""
                              >
                                {toCapitalize(this.state.servicePlanStatus)}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="dropdown-menu-right px-3 rounded-0 shadow-sm">
                                <Dropdown.Item
                                  className="py-3"
                                  onClick={() => {
                                    this.setState({
                                      servicePlanStatus: 'active'
                                    });
                                  }}
                                >
                                  {this.translation('active')}
                                </Dropdown.Item>
                                <Dropdown.Divider className="my-0" />
                                <Dropdown.Item
                                  className="py-3"
                                  onClick={() => {
                                    this.setState({
                                      servicePlanStatus: 'inactive'
                                    });
                                  }}
                                >
                                  {this.translation('inactive')}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col>{this.translation('expiringDate')}</Col>
                          <Col>
                            <DatePicker
                              disabled={
                                this.state.servicePlanStatus === 'inactive'
                              }
                              minDate={
                                new Date(
                                  moment(
                                    this.props.details.registrationDate
                                  ).add(1, 'days')
                                )
                              }
                              selected={this.state.serviceExpiryDate}
                              onChange={(date, event) => {
                                this.handleExpiringDateChange(date);
                                handleChange(event);
                              }}
                              error={errors['Expiring-Date']}
                              showError={
                                errors['Expiring-Date'] ? 'tooltip' : ''
                              }
                              className={
                                errors['Expiring-Date'] ? 'is-invalid' : ''
                              }
                              title={
                                this.state.servicePlanStatus === 'inactive'
                                  ? this.translation(
                                      'statusInactiveNoEffectOfDate'
                                    )
                                  : ''
                              }
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(ServicePlan);
