// @flow
import React from 'react';
import { Carousel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import MDSpinner from 'react-md-spinner';

import VideoPlay from '../../../assets/images/video_play.png';
import { IDocument } from '../../models';
import { DocumentService, ConfigService } from '../../_shared/services';
import { errorParser } from '../../_shared/helpers';
import DocumentPreviewModal from '../pages/Documents/DocumentPreviewModal';
import { ColorConstants } from '../../_shared/constants';
import i18next from 'i18next';

// const SlideItems = [
//   {
//     type: 'vid',
//     src: VideoSRC,
//     imageSrc: VideoImage,
//     alt: 'First Slide',
//     caption: {
//       label: 'COMPREHENSIVE rescue system',
//       text: 'The perfect complement to your AED | Be ready for the unexpected.'
//     }
//   }
// ];

type State = {
  showVideo: boolean,
  slideItems: IDocument[],
  currentIndex: number,
  sizePerPage: number,
  sort: string,
  order: string,
  showPreviewModal: boolean,
  previewDocument: IDocument,
  isLoading: boolean
};

type Props = {
  t: i18next.TFunction
};

export class Slides extends React.Component<Props, State> {
  translation: i18next.TFunction = this.props.t;
  cleanup: any = null;
  state = {
    showVideo: false,
    slideItems: [],
    currentIndex: 0,
    sizePerPage: 10,
    sort: 'order',
    order: 'ASC',
    showPreviewModal: false,
    previewDocument: {},
    isLoading: true
  };
  componentDidMount() {
    this.getDocuments();
  }

  showPreview = (slideItem: IDocument) => {
    this.setState(
      { previewDocument: slideItem },
      this.setState({ showPreviewModal: true })
    );
  };

  getDocuments = (
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    sort: string = this.state.sort,
    order: string = this.state.order
  ) => {
    const getDocuments$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sort,
      order
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      getDocuments$.unsubscribe();
    };
  };

  /**Observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    sort: string,
    order: string
  ) => {
    const fetchOnlyBanners = true,
      searchText = '',
      typeOfDoc = '';
    return DocumentService.getDocuments(
      currentIndex,
      sizePerPage,
      searchText,
      typeOfDoc,
      sort,
      order,
      fetchOnlyBanners
    );
  };

  /**Subscriber-response handler */
  handelResponse = () => ({
    next: (response: { data: { documents: IDocument[], count: number } }) => {
      if (this.cleanup) {
        let { documents } = response.data;
        this.sortDocuments(documents);
      }
    },
    error: (errorResponse: any) => {
      if (this.cleanup) {
        this.setState({ isLoading: false });
        toastr.error(errorParser(errorResponse));
      }
    }
  });

  /**Sort documents by config and show on page */
  sortDocuments(documents: IDocument[]) {
    let sortedDocuments = [];
    ConfigService.getConfig('banners').subscribe({
      next: (response: { data: { data: { value: IDocument[] } } }) => {
        const { value } = response.data.data;
        value.forEach(id => {
          let currentDocument = documents.find(document => document._id === id);
          if (currentDocument) {
            sortedDocuments.push(currentDocument);
          }
        });
        this.setState({
          slideItems: sortedDocuments,
          isLoading: false
        });
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
  }

  componentWillUnmount() {
    this.cleanup();
  }

  render() {
    return (
      <>
        <DocumentPreviewModal
          show={this.state.showPreviewModal}
          onHide={() => this.setState({ showPreviewModal: false })}
          onHidden={() => this.setState({ previewDocument: null })}
          document={this.state.previewDocument}
        />
        <div className="slides mb-5">
          <Carousel controls={true} indicators={true}>
            {this.state.isLoading && (
              <div className="w-100 text-center">
                <MDSpinner
                  singleColor={ColorConstants.PRIMARY}
                  style={{ top: '45%' }}
                />
              </div>
            )}
            {this.state.slideItems.map((slideItem, i) => (
              <Carousel.Item key={i}>
                <div>
                  <img
                    className="d-block w-100 object-fit-cover h-100"
                    src={
                      slideItem.type === 'Video'
                        ? slideItem.thumbnail
                        : slideItem.url
                    }
                    alt={slideItem.title}
                  />
                  <div
                    className={
                      (slideItem.type === 'Video' ? 'bg-black-rgba-7 ' : '') +
                      'position-absolute top-left-0 h-100 w-100 cursor-pointer'
                    }
                    onClick={() => this.showPreview(slideItem)}
                    role="presentation"
                  >
                    <div>
                      {slideItem.type === 'Video' && (
                        <img
                          src={VideoPlay}
                          alt={this.translation('play')}
                          height="100"
                          className="position-absolute"
                          style={{
                            marginLeft: '-50px',
                            marginTop: '-50px',
                            left: '50%',
                            top: '40%'
                          }}
                        />
                      )}
                      <div
                        className="slide-caption text-center text-white position-absolute w-100"
                        style={{ top: '75%' }}
                      >
                        <h4>{slideItem.title}</h4>
                        <h5>{slideItem.description}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </>
    );
  }
}

export default withTranslation()(Slides);
