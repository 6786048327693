import { authHeader, AxiosSubscriber } from '../helpers';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../constants';
import { stringify } from 'querystring';

export const kitService = {
  add,
  getKitTypes,
  getKitTypeById,
  delete: _delete,
  update,
  activatedKit,
  deactivatedKit
};

/**@function add
 * Add kit
 * @param {Kit} kit - Starting index of the records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function add(kit) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KITS.POST_KITS
      }`,
      axiosOptions = { headers: authHeader() };
    // let's remove _id, if any
    const { _id, ...restValues } = kit;
    kit = restValues;
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      kit
    );
  });
}

/**@function getKitTypes
 * Get kits from server for super admin
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=50] - Size per page
 * @param {string} [sort='name'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getKitTypes(skip = 0, limit = 50, sort = 'name', order = 'asc') {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit, sort, order })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KITS.GET_KITS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getKitById
 * Get kit from server for super admin
 * @param {string}  kitId- Kit ID
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getKitTypeById(kitId) {
  return new Observable(observer => {
    const requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KITS.GET_KITS
      }/${kitId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function _delete
 * Delete kit from server for super admin
 * @param {string} kitId - Kit ID
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _delete(kitId) {
  return new Observable(observer => {
    const requestType = 'delete',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KITS.DELETE_KIT
      }/${kitId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function update
 * update kit
 * @param {string} kitId - Kit ID
 * @param {Kit} kit - Updated kit
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function update(kitId, kit) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KITS.PATCH_KIT
      }/${kitId}`,
      axiosOptions = { headers: authHeader() };
    // let's remove _id, if any
    const { _id, ...restValues } = kit;
    kit = restValues;
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      kit
    );
  });
}

/**@function update
 * update kit
 * @param {string} kitId - Kit ID
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function activatedKit(kitId) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KITS.PATCH_KIT
      }/${kitId}/activate`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
/**@function update
 * update kit
 * @param {string} kitId - Kit ID
 * @param {active} active - Updated kit
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function deactivatedKit(kitId) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KITS.PATCH_KIT
      }/${kitId}/deactivate`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
