import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import moment from 'moment';

import { ColorConstants } from '../../../_shared/constants';
import { DashboardService } from '../../../_shared/services';
import LineCharts from '../../components/LineCharts';

export class ApplicationStatistics extends Component {
  translation = this.props.t;
  state = {
    lineChartData: [[1, 2, 1, 6], [2, 3, 10, 1]],
    charts: [
      {
        title: 'Total iOS Installations',
        count: '0',
        id: 'totaliOS',
        color: ColorConstants.CHART_1
      },
      {
        title: 'Total Android Installations',
        count: '0',
        id: 'totalAndroid',
        color: ColorConstants.CHART_2
      },
      {
        title: 'Total iOS Installations (--)',
        count: '0',
        id: 'monthiOS',
        color: ColorConstants.CHART_3
      },
      {
        title: 'Total Android Installations (--)',
        count: '0',
        id: 'monthAndroid',
        color: ColorConstants.CHART_5
      }
    ],
    isLoading: true
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const getInstallationCountService$ = DashboardService.getInstallationsData().subscribe(
      {
        next: response => {
          if (this.cleanup) {
            const monthCount = response.data.monthCount;
            const totalCount = response.data.totalCount;
            const dayCount = response.data.dayCount;
            // Filter by device type and month
            let iosDataMonth = monthCount.filter(
              date => date._id.type === 'iOS'
            );
            let androidDataMonth = monthCount.filter(
              date => date._id.type === 'Android'
            );
            let iosDataDay = dayCount.filter(date => date._id.type === 'iOS');
            let androidDataDay = dayCount.filter(
              date => date._id.type === 'Android'
            );
            let androidGraphMonth = [],
              iOSGraphMonth = [],
              androidGraphDay = [],
              iOSGraphDay = [];
            // month loop
            for (
              let i = moment()
                .subtract(12, 'month')
                .startOf('month');
              i.isSameOrBefore(moment());
              i = i.add(1, 'month')
            ) {
              let findIOSKey = iosDataMonth.find(iosData => {
                return moment(iosData.createdAt).isSame(i, 'month');
              });
              let findAndroidKey = androidDataMonth.find(androidData => {
                return moment(androidData.createdAt).isSame(i, 'month');
              });
              iOSGraphMonth.push([
                i.format('MMMM - YYYY'),
                findIOSKey ? findIOSKey.count : 0
              ]);
              androidGraphMonth.push([
                i.format('MMMM - YYYY'),
                findAndroidKey ? findAndroidKey.count : 0
              ]);
            }
            // For day by day loop
            for (
              let i = moment().subtract(1, 'months');
              i.isSameOrBefore(moment());
              i = i.add(1, 'day')
            ) {
              let findIOSKey = iosDataDay.find(iosData => {
                return moment(iosData.createdAt).isSame(i, 'day');
              });
              let findAndroidKey = androidDataDay.find(androidData => {
                return moment(androidData.createdAt).isSame(i, 'day');
              });
              iOSGraphDay.push([
                i.format('DD - MMM'),
                findIOSKey ? findIOSKey.count : 0
              ]);
              androidGraphDay.push([
                i.format('DD - MMM'),
                findAndroidKey ? findAndroidKey.count : 0
              ]);
            }
            let { charts, lineChartData } = this.state;
            // For total counts use static values
            lineChartData = [
              iOSGraphMonth,
              androidGraphMonth,
              iOSGraphDay,
              androidGraphDay
            ];
            if (totalCount && totalCount[0]) {
              charts[0].count = totalCount.find(deviceType => {
                return deviceType._id.type === 'iOS';
              }).count;
            }
            if (totalCount && totalCount[1]) {
              charts[1].count = totalCount.find(deviceType => {
                return deviceType._id.type === 'Android';
              }).count;
            }
            const iosDataKey = monthCount.find(monthData => {
              return (
                monthData._id.type === 'iOS' &&
                moment(monthData.createdAt).isSame(moment(), 'month')
              );
            });
            charts[2].count = (iosDataKey && iosDataKey.count) || 0;
            charts[2].title = `Total iOS Installations (${moment(
              iosDataKey && iosDataKey.createdAt
            ).format('MMM')})`;
            const androidDataKey = monthCount.find(monthData => {
              return (
                monthData._id.type === 'Android' &&
                moment(monthData.createdAt).isSame(moment(), 'month')
              );
            });
            charts[3].count = (androidDataKey && androidDataKey.count) || 0;
            charts[3].title = `Total Android Installations (${moment(
              androidDataKey && androidDataKey.createdAt
            ).format('MMM')})`;
            this.setState({
              charts,
              lineChartData,
              isLoading: false
            });
            // convert data so that it's compatible with chart
            // responseData.forEach(monthData => {
            //   const monthNumber = monthData._id.month;
            //   const shortName = moment.monthsShort(monthNumber - 1);
            //   logger.log(this.state.kitTypes);
            //   const data = this.state.kitTypes.map(kitType => {
            //     const isKitTypeFound = monthData.data.filter(
            //       monthKitTypeWithCount => {
            //         return monthKitTypeWithCount.type === kitType.label;
            //       }
            //     )[0];
            //     const count = isKitTypeFound ? isKitTypeFound.count : 0;
            //     return count;
            //   });
            //   monthWiseData.push({ month: shortName, data });
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.cleanup();
            // toastr.error(this.translation('error'), errorParser(errorResponse));
            // this.setState({ isLoading: false });
          }
        }
      }
    );
    this.cleanup = () => getInstallationCountService$.unsubscribe();
  };

  render() {
    return (
      <>
        {this.state.charts.map((item, index) => (
          <Col sm="12" md="3" className="mb-5" key={index}>
            {this.state.isLoading ? (
              <>
                <p className="font-weight-bold">{item.title}</p>
                <Card className="hoverable">
                  <Card.Body>
                    <Row className="d-flex align-items-center justify-content-center">
                      <MDSpinner singleColor={ColorConstants.PRIMARY} />
                    </Row>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <>
                <p className="font-weight-bold">{item.title}</p>
                <Card className="hoverable">
                  <Card.Body>
                    <Row className="justify-content-between align-items-center">
                      <Col xs="12" sm="5" md="4">
                        <h4 className="mb-0">{item.count}</h4>
                      </Col>
                      <Col xs="12" sm="7" md="8">
                        <LineCharts
                          data={this.state.lineChartData[index] || []}
                          color={item.color}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>
            )}
          </Col>
        ))}
      </>
    );
  }
}

export default withTranslation()(ApplicationStatistics);
