export const ColorConstants = {
  DEFAULT_FONT: '#4D4F5C',
  TEXT_MUTED: '#6c757d',
  PRIMARY: '#B11E29',
  INFO: '#3B86FF',
  SUCCESS: '#28A745',
  DANGER: '#DC3545',
  CHART_1: '#71C285',
  CHART_1_LIGHT: 'rgba(113, 194, 133, 0.5)',
  CHART_2: '#36A2EB',
  CHART_2_LIGHT: 'rgba(54, 162, 235, 0.5)',
  CHART_3: '#F49753',
  CHART_3_LIGHT: 'rgba(244, 151, 83, 0.5)',
  CHART_4: '#FFCE56',
  CHART_4_LIGHT: 'rgba(255, 206, 86, 0.5)',
  CHART_5: '#FF6384',
  CHART_5_LIGHT: 'rgba(255, 99, 132, 0.5)',
  CHART_6: '#EF5454',
  CHART_6_LIGHT: 'rgba(239, 84, 84, 0.5)',
  CHART_7: '#6FD3DB',
  CHART_7_LIGHT: 'rgba(111, 211, 219, 0.5)',
  CHART_8: '#BF6BD3',
  CHART_8_LIGHT: 'rgba(191, 107, 211, 0.5)',
  CHART_9: '#9B7951',
  CHART_9_LIGHT: 'rgba(155, 121, 81, 0.5)',
  CHART_10: '#3F376C',
  CHART_10_LIGHT: 'rgba(63,55,108,0.5)',
  CHART_11: '#e884b1',
  CHART_11_LIGHT: 'rgba(232, 132, 177,0.5)',
  CHART_12: '#8cf283',
  CHART_12_LIGHT: 'rgba(140, 242, 131,0.5)',
  CHART_13: '#dbaf63',
  CHART_13_LIGHT: 'rgba(219, 175, 99,0.5)',
  CHART_14: '#5d5661',
  CHART_14_LIGHT: 'rgba(93, 86, 97,0.5)',
  CHART_15: '#7f66ed',
  CHART_15_LIGHT: 'rgba(127, 102, 237,0.5)'
};

export const ChartColorConstants = [
  ColorConstants.CHART_2,
  ColorConstants.CHART_1,
  ColorConstants.CHART_4,
  ColorConstants.CHART_3,
  ColorConstants.CHART_5,
  ColorConstants.CHART_7,
  ColorConstants.CHART_6,
  ColorConstants.CHART_9,
  ColorConstants.CHART_8,
  ColorConstants.CHART_10,
  ColorConstants.CHART_11,
  ColorConstants.CHART_12,
  ColorConstants.CHART_13,
  ColorConstants.CHART_14,
  ColorConstants.CHART_15
];

export const ChartLightColorConstants = [
  ColorConstants.CHART_2_LIGHT,
  ColorConstants.CHART_1_LIGHT,
  ColorConstants.CHART_4_LIGHT,
  ColorConstants.CHART_3_LIGHT,
  ColorConstants.CHART_5_LIGHT,
  ColorConstants.CHART_7_LIGHT,
  ColorConstants.CHART_6_LIGHT,
  ColorConstants.CHART_9_LIGHT,
  ColorConstants.CHART_8_LIGHT,
  ColorConstants.CHART_10_LIGHT,
  ColorConstants.CHART_11_LIGHT,
  ColorConstants.CHART_12_LIGHT,
  ColorConstants.CHART_13_LIGHT,
  ColorConstants.CHART_14_LIGHT,
  ColorConstants.CHART_15_LIGHT
];
