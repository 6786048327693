import React from 'react';
import { getFormattedDate } from '../../../_shared/helpers/methods';
import { headerFormatterWithSort } from '../../../_shared/helpers';
import i18next from 'i18next';
import { Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';

export const columns = presentConfirm => [
  {
    dataField: 'user.name',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('customerName')),
    formatter: (cell, row) => {
      return row.user && row.user._id ? (
        cell + ' ' + (row.user.lastName || '')
      ) : (
        <span className="text-primary">{i18next.t('n/a')}</span>
      );
    },
    sort: true,
    headerStyle: { width: '25%' },
    style: { width: '25%' }
  },
  {
    dataField: 'user.email',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('customerEmail')),
    formatter: cell => {
      if (cell) {
        return cell;
      } else {
        return <span className="text-primary">{i18next.t('n/a')}</span>;
      }
    },
    sort: true,
    headerStyle: { width: '30%' },
    style: { width: '30%' }
  },
  {
    dataField: 'serialNumber',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('serialNumber')),
    sort: true,
    headerStyle: { width: '20%' },
    style: { width: '20%' }
  },
  {
    dataField: 'serviceExpiryDate',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('servicePlan')),
    formatter: cell => getFormattedDate(cell, 60, 'YYYY/MM'),
    sort: true,
    headerStyle: { width: '15%' },
    style: { width: '15%' }
  },
  {
    dataField: 'customerEmail',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('email')),
    isDummyField: true,
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return row ? (
        formatExtraData[row.mailStatus]
      ) : (
        <span className="text-primary">{i18next.t('n/a')}</span>
      );
    },
    formatExtraData: {
      undefined: (
        <Icon
          iconName="send-mail"
          size="20"
          fill={ColorConstants.DEFAULT_FONT}
          classes="cursor-pointer"
        />
      ),
      sent: (
        <div className="scaleUp">
          {i18next.t('mailSent')}
          <Icon
            iconName="check-circle"
            classes="ml-2"
            fill={ColorConstants.PRIMARY}
          />
        </div>
      )
    },
    events: {
      onClick: (onClickEvent, column, columnIndex, row, rowIndex) => {
        if (!row.mailStatus) {
          presentConfirm(
            row.serialNumber,
            row.user.fullName,
            row.user.email,
            row._id
          );
        }
      }
    }
  }
];
