import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LanguageSelector from '../../_shared/components/LanguageSelector';

class SuperAdminFooter extends React.Component {
  render() {
    return (
      <Row
        className="p-4 position-absolute"
        style={{ left: 0, right: '15px', bottom: 0 }}
      >
        <Col sm={12} md={6} />
        {/* <Col sm={12} md={6} className="text-right pr-4">
          <Row className="h-100">
            <Col xs={12}>
              <LanguageSelector />
            </Col>
          </Row>
        </Col> */}
      </Row>
    );
  }
}

export default SuperAdminFooter;
