import { Observable } from 'rxjs';
import { AxiosSubscriber, authHeader, authHeaderWithJSON } from '../helpers';
import { API_ENDPOINTS } from '../constants';
import { stringify } from 'query-string';

export const ProductService = {
  checkSerialNumber,
  createProduct,
  export: _export,
  get,
  getExpiryProductItems,
  getMyProducts,
  getProductById,
  getProductsByUserId,
  registerProduct,
  serialNumberAvailable,
  serialNumberAvailableSingle,
  unRegisterProduct,
  updateLocation,
  updateServicePlan,
  importData,
  updateMaxActivation,
  delete: _delete
};

/**@function get
 * Get products for super-admin
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='serialNumber'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @param {string} [search=''] - Maximum Numbers of records to be fetched
 * @param {object} [filter=null] - Maximum Numbers of records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function get(
  skip = 0,
  limit = 10,
  sort = 'serialNumber',
  order = 'asc',
  search = '',
  filter = null
) {
  return new Observable(observer => {
    filter = filter
      ? filter.selected === 'serialNumber'
        ? {
            prefix: filter.prefix,
            serialNumberStart: filter.serialNumberStart,
            serialNumberEnd: filter.serialNumberEnd
          }
        : filter.selected === 'productType'
        ? {
            kit:
              '[' +
              filter.productType.types
                .filter(item => item.checked)
                .map(item => '"' + item.value + '"') +
              ']'
          }
        : filter.selected === 'createDate'
        ? {
            productCreatedDateStart: filter.productCreatedDateStart,
            productCreatedDateEnd: new Date(
              filter.productCreatedDateEnd.getFullYear(),
              filter.productCreatedDateEnd.getMonth(),
              filter.productCreatedDateEnd.getDate(),
              23,
              59,
              59
            )
          }
        : {}
      : {};
    filter = Object.assign({ skip, limit, search, sort, order }, filter);
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify(filter)}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**
 * @function updateMaxActivation
 * Update max activations here
 * @param {number} [maxActivation] - send max activation count
 * @param {string} productId - product id for update
 * @returns {Observable} observable - Observable to which we can subscribe to
 */

function updateMaxActivation(maxActivation = 1, productId) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }/${productId}/maxActivation`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { maxActivation }
    );
  });
}

/**@function export
 * export products for super-admin
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='serialNumber'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @param {string} [search=''] - Maximum Numbers of records to be fetched
 * @param {object} [filter=null] - Maximum Numbers of records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _export(
  skip = 0,
  limit = 10,
  sort = 'serialNumber',
  order = 'asc',
  search = '',
  filter = null
) {
  return new Observable(observer => {
    filter = filter
      ? filter.selected === 'serialNumber'
        ? {
            serialNumberStart: filter.serialNumberStart,
            serialNumberEnd: filter.serialNumberEnd
          }
        : filter.selected === 'productType'
        ? {
            kit:
              '[' +
              filter.productType.types
                .filter(item => item.checked)
                .map(item => '"' + item.value + '"') +
              ']'
          }
        : filter.selected === 'createDate'
        ? {
            productCreatedDateStart: filter.productCreatedDateStart,
            productCreatedDateEnd: new Date(
              filter.productCreatedDateEnd.getFullYear(),
              filter.productCreatedDateEnd.getMonth(),
              filter.productCreatedDateEnd.getDate(),
              23,
              59,
              59
            )
          }
        : {}
      : {};
    filter = Object.assign(
      {
        skip,
        limit,
        search,
        sort,
        order,
        export: true,
        offset: new Date().getTimezoneOffset()
      },
      filter
    );
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify(filter)}`,
      urlWithQueryParams = `${API_ENDPOINTS.ExportURL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

function getMyProducts(
  skip = 0,
  limit = 10,
  sort = 'name',
  order = 'asc',
  search = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit, search, sort, order })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getExpiryProductItems
/* Get expiring product items from server
* @param {number} [skip=0] - Starting index of the records to be fetched
* @param {number} [limit=10] - Maximum Numbers of records to be fetched
* @param {string} [sort='expiryDate'] - The field name on which sorting should be done
* @param {'asc'|'desc'} [order='desc'] - Order of sorting
* @param {string} [search=''] - The field name on which sorting should be done
* @returns {Observable} Observable to which we can subscribe to get expiring product items
*/
function getExpiryProductItems(
  skip = 0,
  limit = 10,
  sort = 'expiryDate',
  order = 'desc',
  search = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        search,
        sort,
        order,
        expired: 0
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCT_ITEMS.GET_PRODUCT_ITEMS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function checkSerialNumber
 * Check validity of serial number
 * @param {string} serialNumber - Serial number which should be checked
 * @returns {Observable} Observable to which we can subscribe to
 */
function checkSerialNumber(serialNumber) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ serialNumber })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_CHECK_SERIAL_NUMBER
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function registerProduct
 * Register products to site admin
 * @param {string[]} serialNumber - Serial numbers of which products will be added
 * @param {string} [userId=undefined] - User ID, to which kit will be linked, if not provided, user-id will be fetched from header token.
 * @param {Date} [registrationDate=(new Date()).toUTCString()] - Date when kit is/was/wll be assigned to user
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function registerProduct(
  serialNumber,
  userId = undefined,
  registrationDate = new Date().toUTCString()
) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.PATCH_REGISTER_PRODUCT
      }`,
      axiosOptions = { headers: authHeaderWithJSON() },
      requestBody = {
        serialNumber,
        registrationDate,
        ...(userId && { userId })
      };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      requestBody
    );
  });
}

/**@function getProductById
 * Get product by id from server
 * @param {string} productId - ID of product
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getProductById(productId) {
  return new Observable(observer => {
    const requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }/${productId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function unRegisterProduct
 * Un-register product from user
 * @param {string} productId - Starting index of the records to be fetched
 * @param {{userId:string}} requestBody - User ID, this will be needed when super-admin calls this api
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function unRegisterProduct(productId, requestBody = undefined) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.PATCH_UNREGISTER_PRODUCT
      }/${productId}`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      requestBody && requestBody
    );
  });
}

/**@function serialNumberAvailable
 * Check whether serial number is available or not
 * @param {string} serialNumber - Serial number which should be checked
 * @returns {Observable} Observable to which we can subscribe to
 */
function serialNumberAvailable(prefix, fromRange, count) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        prefix: prefix,
        fromRange: fromRange,
        count: count
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_SERIAL_NUMBER_AVAILABLE
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function serialNumberAvailableSingle
 * Check whether serial number is available or not
 * @param {string} serialNumber - Serial number which should be checked
 * @returns {Observable} Observable to which we can subscribe to
 */
function serialNumberAvailableSingle(serialNumber) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ serialNumber })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_SERIAL_NUMBER_AVAILABLE
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function updateLocation
 * Update location of product
 * @param {string} productId - Product ID
 * @param {string} location - Location to be updated
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function updateLocation(productId, location) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }/${productId}/location`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { location: location }
    );
  });
}

/**@function getProductsByUserId
 * Get product for a user
 * @param {string} userId - User ID of which products will be fetched
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort=''] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'|'desc'] - Order of sorting
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getProductsByUserId(
  user,
  skip = 0,
  limit = 10,
  sort = '',
  order = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit, sort, order, user })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.GET_PRODUCTS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function createProduct
 * Create new product for super admin
 * @param {Product} product - Product to be added
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function createProduct(product) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.POST_NEW_PRODUCT
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      product
    );
  });
}

/**@function updateServicePlan
 * Update service plan of product
 * @param {string} productId - Product ID
 * @param {{servicePlanStatus:'active'|'inactive', serviceExpiryDate:Date}} requestBody - Details to be updated
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function updateServicePlan(productId, requestBody) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.PATCH_SERVICE_PLAN
      }/${productId}`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      requestBody
    );
  });
}

/**@function importData
 * Import data from excel sheet
 * @param {string} serialNumbers - Serial numbers array in excel sheet
 * @param {string} kitName kit name in csv
 * @param {string} kitItemNumbers kit item number array in csv
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function importData(serialNumbers, kitName, kitItemNumbers, customerNameBox) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.IMPORT
      }`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { serialNumbers, kitName, kitItemNumbers, customerNameBox }
    );
  });
}

/**@function _delete
 * Hard delete product from server
 * @param {string} [productId] - Product's ID, which will be deleted
 * @returns {Observable} observable - Observable to which we can subscribe.
 */
function _delete(productId) {
  return new Observable(observer => {
    const requestType = 'delete',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCTS.DELETE_PRODUCT
      }/${productId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
