import { AxiosSubscriber, authHeader } from '../helpers';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../constants/api-endpoints.constants';
import { stringify } from 'querystring';

export const itemService = {
  add,
  getKitItems,
  getProductItems,
  getById,
  update,
  export: _export,
  updateProductItem,
  getByUser
};

/**@function add
 * Add Kit Item
 * @param {KitItem} kitItem - Kit Item
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function add(kitItem) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KIT_ITEMS.POST_KIT_ITEMS
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      kitItem
    );
  });
}

/**@function getKitItems
 * Get kit items
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='name'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @param {string} [search=''] - Maximum Numbers of records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getKitItems(
  skip = 0,
  limit = 10,
  sort = 'name',
  order = 'asc',
  search = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit, search, sort, order })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KIT_ITEMS.GET_KIT_ITEMS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

function getProductItems(
  skip = 0,
  limit = 10,
  sort = 'createdAt',
  order = 'asc',
  userOnly = undefined,
  search = '',
  expired = 0,
  user = '',
  product = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        search,
        sort,
        order,
        expired,
        userOnly,
        ...(user && user),
        ...(product && product)
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCT_ITEMS.GET_PRODUCT_ITEMS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getById
 * Get kit item by id
 * @param {string} kitItemId - Kit item id
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getById(kitItemId) {
  return new Observable(observer => {
    const requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KIT_ITEMS.GET_KIT_ITEMS
      }/${kitItemId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function update
 * Update Kit Item
 * @param {string} kitItemId - Kit Item ID
 * @param {KitItem} kitItem - Kit Item
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function update(kitItemId, kitItem) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.KIT_ITEMS.PATCH_KIT_ITEMS
      }/${kitItemId}`,
      axiosOptions = { headers: authHeader() };
    const { _id, ...restValues } = kitItem;
    kitItem = restValues;
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      kitItem
    );
  });
}

/**@function export
 * export products for super-admin
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='serialNumber'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @param {string} [search=''] - Maximum Numbers of records to be fetched
 * @param {object} [filter=null] - Maximum Numbers of records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _export(
  skip = 0,
  limit = 10,
  sort = 'createdAt',
  order = 'asc',
  search = '',
  userOnly = undefined,
  expired = 0,
  user = '',
  product = '',
  _export = true
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        sort,
        order,
        search,
        expired,
        userOnly,
        export: _export,
        offset: new Date().getTimezoneOffset(),
        ...(user && user),
        ...(product && product)
      })}`;
    let urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCT_ITEMS.GET_PRODUCT_ITEMS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function updateProductItem
 * Update product item
 * @param {string} itemId - Item ID of item which will be updated
 * @param {{lotNumber: string, expiryDate?: string}} requestBody - details to be updated
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function updateProductItem(itemId, requestBody) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCT_ITEMS.PATCH_ITEMS
      }/${itemId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      requestBody
    );
  });
}

/**@function getByUser
 * Get expiring items by user
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='expiryDate'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='desc'] - Order of sorting
 * @param {string} [user=''] - User ID
 * @returns {Observable} observable - Observable to which we can subscribe.
 */
function getByUser(
  skip = 0,
  limit = 10,
  sort = 'expiryDate',
  order = 'desc',
  user
) {
  if (!user) {
    return;
  }
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit, user, sort, order })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.PRODUCT_ITEMS.GET_PRODUCT_ITEMS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
