// @flow
import * as React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import Details from './Details';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import { ColorConstants } from '../../../../_shared/constants';
import { ProductService } from '../../../../_shared/services';
import { errorParser } from '../../../../_shared/helpers';
import { Icon, ConfirmDialog } from '../../../../_shared/components';
import ServicePlan from './ServicePlan';
import ProductItems from './ProductItems';
import { Product } from '../../../../models';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { loaderActions } from '../../../../_shared/redux/actions';

type Props = {
  productId: string,
  startLoading(): void,
  stopLoading(): void,
  goTo(path: string): void,
  t: i18next.TFunction
};

type State = {
  product: Product,
  isLoading: boolean,
  editServicePlan: boolean,
  showConfirmDelete: boolean
};

class BasicDetails extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Forms */
  submitDetailForm: any = null;
  submitServicePlanForm: any = null;
  /**State of the component */
  state = {
    product: {
      _id: '',
      items: [],
      kit: {
        name: ''
      },
      kit_items: [],
      serialNumber: '',
      productCreatedDate: '',
      registrationDate: '',
      serviceExpiryDate: '',
      servicePlanStatus: ''
    },
    isLoading: true,
    editServicePlan: false,
    showConfirmDelete: false
  };
  /**Call apis once component mounted */
  componentDidMount() {
    this.getProductDetails();
  }
  /**Free up resource once component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Get product details */
  getProductDetails() {
    this.setState({
      isLoading: true,
      editServicePlan: false
    });
    const getProductById$ = ProductService.getProductById(
      this.props.productId
    ).subscribe({
      next: (response: { data: { product: Product } }) => {
        if (this.cleanup) {
          this.setState({
            product: Object.assign({}, response.data.product, {
              maxActivation:
                response.data.product.maxActivation ||
                response.data.product.kit.maxActivation ||
                1
            }),
            isLoading: false
          });
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getProductById$.unsubscribe();
    };
  }
  /**Submit form caller */
  handleSubmitForm = (whichForm: string, submitEVent: any) => {
    switch (whichForm) {
      case 'servicePlan':
        if (this.submitServicePlanForm) {
          this.submitServicePlanForm(submitEVent);
        }
        break;
      default:
        break;
    }
  };
  /**Submit form binder */
  bindSubmitForm = (whichForm: string, submitForm: any) => {
    switch (whichForm) {
      case 'servicePlan':
        this.submitServicePlanForm = submitForm;
        break;
      default:
        break;
    }
  };
  /**Redirect from child and this component */
  goTo = (path: string) => this.props.goTo(path);
  /**Confirm whether user really want to delete the product */
  confirmDeleteProduct = () => {
    this.setState({ showConfirmDelete: true });
  };
  /**Cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };
  /**Ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    const deleteProduct$ = ProductService.delete(
      this.state.product._id
    ).subscribe({
      next: response => {
        if (this.cleanup) {
          this.goTo('/admin/units');
          this.props.stopLoading();
          toastr.success(this.translation('success'), response.data.message);
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      deleteProduct$.unsubscribe();
    };
  };
  render() {
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn={this.translation('no')}
          okBtn={this.translation('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.translation('confirmDeleteThisUnit')}
        />
        <Container fluid>
          {this.state.isLoading ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <>
              <Row>
                <Col className="ml-auto text-right">
                  <Button
                    variant=""
                    disabled={this.state.isLoading}
                    onClick={() => this.confirmDeleteProduct()}
                  >
                    {this.translation('deleteThisUnit')}
                    <Icon iconName="delete" size="26" classes="ml-2" />
                  </Button>
                </Col>
              </Row>
              <Card className="mb-5">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <h4 className="mb-4">
                        {this.translation('unit') +
                          ': ' +
                          this.state.product.kit.name.toLocaleUpperCase()}
                      </h4>
                    </Col>
                  </Row>
                  <Details
                    details={this.state.product}
                    goTo={this.goTo}
                    startLoading={() => this.props.startLoading()}
                    stopLoading={() => this.props.stopLoading()}
                    getProductDetails={() => this.getProductDetails()}
                  />
                </Card.Body>
                {/* <Card.Body>
                  <Row className="justify-content-between">
                    <Col>
                      <h4 className="mb-4">
                        {this.translation('servicePlan')}
                      </h4>
                    </Col>
                    <Col className="text-right">
                      {this.state.editServicePlan ? (
                        <>
                          <Button
                            variant="secondary"
                            className="mr-3 rounded-0"
                            onClick={() =>
                              this.setState({ editServicePlan: false })
                            }
                          >
                            {this.translation('cancel')}
                          </Button>
                          <Button
                            variant="success"
                            onClick={clickEvent =>
                              this.handleSubmitForm('servicePlan', clickEvent)
                            }
                            className="rounded-0"
                          >
                            {this.translation('save') +
                              ' ' +
                              this.translation('change', { count: 0 })}
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant=""
                          onClick={() =>
                            this.setState({ editServicePlan: true })
                          }
                          className="rounded-0"
                          disabled={!this.state.product.registrationDate}
                          title={
                            !this.state.product.registrationDate
                              ? this.translation('registerToCustomer')
                              : ''
                          }
                        >
                          {this.translation('edit')}
                          <Icon iconName="edit" size="26" classes="ml-2" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <ServicePlan
                    details={{
                      productId: this.state.product._id,
                      servicePlanStatus: this.state.product.servicePlanStatus,
                      serviceExpiryDate: this.state.product.serviceExpiryDate,
                      registrationDate: this.state.product.registrationDate
                    }}
                    editDetails={this.state.editServicePlan}
                    bindSubmitForm={this.bindSubmitForm}
                    startLoading={() => this.props.startLoading()}
                    stopLoading={() => this.props.stopLoading()}
                    getProductDetails={() => this.getProductDetails()}
                  />
                </Card.Body> */}
              </Card>
              {/* <Card>
                <Card.Body>
                  <h4 className="mb-4">
                    {this.translation('unit') +
                      ' ' +
                      this.translation('item', { count: 0 })}
                  </h4>
                  <ProductItems
                    items={this.state.product.items}
                    kitItems={this.state.product.kit_items}
                    bindSubmit={this.bindSubmitForm}
                    startLoading={() => this.props.startLoading()}
                    stopLoading={() => this.props.stopLoading()}
                    getProductDetails={() => this.getProductDetails()}
                    productCreationDate={this.state.product.productCreatedDate}
                  />
                </Card.Body>
              </Card> */}
            </>
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(BasicDetails)
);
