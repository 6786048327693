// @flow
import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  showConfirm: boolean,
  backdrop: Boolean | 'static',
  message: String,
  cancelClicked(): void,
  okClicked(): void,
  t: i18next.TFunction,
  title?: String,
  cancelBtn?: string,
  okBtn?: string
};

type State = {};

class ConfirmDialog extends React.Component<Props, State> {
  translation = this.props.t;
  render() {
    const {
      showConfirm,
      backdrop,
      message,
      cancelBtn = this.translation('cancel'),
      cancelClicked,
      okBtn = this.translation('ok'),
      okClicked,
      title = this.translation('please') + ' ' + this.translation('confirm')
    } = this.props;
    return (
      <Modal show={showConfirm} backdrop={backdrop} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{message}</Modal.Body>

        <Modal.Footer>
          <Button
            className="rounded-0 px-3"
            variant="secondary"
            onClick={() => cancelClicked()}
          >
            {cancelBtn}
          </Button>
          <Button
            className="rounded-0 px-3"
            variant="primary"
            onClick={() => okClicked()}
          >
            {okBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(ConfirmDialog);
