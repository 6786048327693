// @flow
import * as React from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';
import { ImageContainer } from '../../../../../_shared/components';
import { getFormattedTime } from '../../../../../_shared/helpers/methods';
import { API_ENDPOINTS } from '../../../../../_shared/constants';
import i18next from 'i18next';

type State = {};

type Props = {
  steps: any[]
};

export default class StepsListProvider extends React.Component<Props, State> {
  getMediaSrc = (media: { extension: string, name: string }) => {
    let src = '';
    if (media && media.name && media.extension) {
      src =
        API_ENDPOINTS.SUMMARY.ABSOLUTE_MEDIA +
        '/' +
        encodeURI(media.name + media.extension);
    }
    return src;
  };
  render() {
    return (
      <>
        <ListGroup
          variant="flush"
          className="d-none overflow-auto mb-0 treatment-steps-viewer-list print-list"
          id="print-summary"
        >
          {this.props.steps.length
            ? this.props.steps.map((step, index) => (
                <ListGroup.Item
                  key={index}
                  className="border-bottom-0 border-top-0"
                >
                  <Row className="align-items-center border-bottom">
                    <Col xs="auto" className="text-center mb-3">
                      <div
                        className="d-inline-block border border-dark text-center rounded-circle text-dark"
                        style={{
                          height: '36px',
                          width: '36px',
                          paddingTop: '4px'
                        }}
                      >
                        {index + 1}
                      </div>
                    </Col>
                    <Col xs="4" md="2" className="text-center mb-3">
                      <ImageContainer
                        src={this.getMediaSrc(step.media)}
                        alt={step.question.question}
                        height="50px"
                        className="img-fluid h-100"
                      />
                    </Col>
                    <Col className="mb-3">
                      <p className="mb-0">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: step.question.question
                          }}
                        />
                        {/* {step.question.question} */}
                        <small className="text-muted ml-2">
                          {getFormattedTime(step.timeStamp)}
                        </small>
                      </p>
                      <span className="border border-primary rounded-left rounded-right px-2 py-1 d-inline-block">
                        {step.answer.answer}
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))
            : i18next.t('noData')}
        </ListGroup>
        <ListGroup
          variant="flush"
          className="d-print-none overflow-auto mb-0 treatment-steps-viewer-list"
        >
          {this.props.steps.length
            ? this.props.steps.map((step, index) => (
                <ListGroup.Item
                  key={index}
                  className="border-bottom-0 border-top-0"
                >
                  <Row className="align-items-center">
                    <Col xs="4" md="2" className="text-center">
                      <ImageContainer
                        src={this.getMediaSrc(step.media)}
                        alt={step.question.question}
                        height="50px"
                        className="img-fluid h-100"
                      />
                    </Col>
                    <Col>
                      <p className="mb-0">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: step.question.question
                          }}
                        />
                        {/* {step.question.question} */}
                        <small className="text-muted ml-2">
                          {getFormattedTime(step.timeStamp)}
                        </small>
                      </p>
                      <span className="border border-primary rounded-left rounded-right px-2 py-1 d-inline-block">
                        {step.answer.answer}
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))
            : i18next.t('noData')}
        </ListGroup>
      </>
    );
  }
}
