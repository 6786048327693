import React from 'react';
import MDSpinner from 'react-md-spinner';
import { ColorConstants } from './color.constants';
import { Dropdown } from 'react-bootstrap';
import i18next from 'i18next';
const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => (
  <Dropdown>
    <Dropdown.Toggle variant="secondary" size="sm">
      {currSizePerPage}
    </Dropdown.Toggle>{' '}
    <span className="font-size-sm">{i18next.t('itemsPerPage')}</span>
    <Dropdown.Menu>
      {options.map(option => (
        <Dropdown.Item
          key={option.text}
          onClick={() => onSizePerPageChange(option.page)}
        >
          {option.text}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
export const tableConstants = {
  paginationOptions: (
    totalLength,
    showSizePerPageDropDown = true,
    page = 1,
    sizePerPage = 10
  ) => {
    return {
      lastPageTitle: i18next.t('lastPage'),
      firstPageTitle: i18next.t('firstPage'),
      page: page,
      sizePerPage: sizePerPage,
      alwaysShowAllBtns: true,
      prePageText: i18next.t('previous'),
      nextPageText: i18next.t('next'),
      totalSize: totalLength,
      sizePerPageList: !showSizePerPageDropDown
        ? []
        : [
            {
              text: '5',
              value: 5
            },
            {
              text: '10',
              value: 10
            },
            {
              text: '20',
              value: 20
            },
            {
              text: '30',
              value: 30
            },
            {
              text: '50',
              value: 50
            },
            {
              text: '100',
              value: 100
            }
          ],
      sizePerPageRenderer
    };
  },
  noDataIndication: () => {
    return i18next.t('noData');
  },
  overlay: options => loading => {
    return class CustomOverlay extends React.Component {
      render() {
        return (
          <div style={{ position: 'relative' }}>
            {loading && (
              <div className="w-100 h-100 bg-dark-rgba-3 position-absolute d-flex align-items-center justify-content-center fadeIn">
                <MDSpinner singleColor={ColorConstants.PRIMARY} />
              </div>
            )}
            {this.props.children}
          </div>
        );
      }
    };
  }
};
