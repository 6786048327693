// @flow
import * as React from 'react';
import { Row, Col, Button, Form, Container, Dropdown } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import MDSpinner from 'react-md-spinner';
import { withTranslation, Translation } from 'react-i18next';
import i18next from 'i18next';

import { FormGroup } from '../../../_shared/components';
import { UserService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import Customer from '../../../models/Customer';
import { PHONE_NUMBER_REGEXP } from '../../../_shared/constants';

const NewCustomerSchema: any = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email(),
  phoneNumber: Yup.string().matches(
    PHONE_NUMBER_REGEXP,
    <Translation>{t => <>{t('formValidations.phone')}</>}</Translation>
  )
});

type Props = {
  history: any,
  t: i18next.TFunction
};

type State = {
  submitting: boolean,
  customerType: 'Organization'
};

export class NewCustomer extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Component's state */
  state = {
    submitting: false,
    customerType: 'Organization'
  };
  /**Free up resources, when component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  render() {
    return (
      <Container fluid>
        <Row className="align-items-center h-100 justify-content-center">
          <Col>
            <Formik
              initialValues={{
                name: '',
                lastName: '',
                email: '',
                phoneNumber: ''
              }}
              validationSchema={NewCustomerSchema}
              onSubmit={(values: FormikValues) => {
                this.setState({ submitting: true });
                const addUser$ = UserService.addUser({
                  userType: this.state.customerType.toLowerCase(),
                  ...values
                }).subscribe({
                  next: (response: {
                    data: { message: string, user: Customer }
                  }) => {
                    toastr.success(
                      this.translation('customerAdded'),
                      response.data.message
                    );
                    this.props.history.push(
                      '/admin/customers/details/' + response.data.user._id
                    );
                    if (this.cleanup) {
                      this.setState({ submitting: false });
                    }
                  },
                  error: (errorResponse: { data: { message: string } }) => {
                    toastr.error(
                      this.translation('error'),
                      errorParser(errorResponse)
                    );
                    if (this.cleanup) {
                      this.setState({ submitting: false });
                    }
                  }
                });
                this.cleanup = () => {
                  addUser$.unsubscribe();
                };
              }}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ errors, touched, handleChange, handleSubmit }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="align-items-center">
                      <Col sm="6" md="3" lg="2" className="mb-3">
                        {this.translation('name')}
                      </Col>
                      <Col sm="6" md="9" lg="10" className="mb-3">
                        <FormGroup
                          formControlName="name"
                          type="text"
                          label={this.translation('name')}
                          disabled={this.state.submitting}
                          handleChange={handleChange}
                          touched={touched['name']}
                          error={errors['name']}
                          required
                          classes="mb-0"
                        />
                      </Col>
                    </Row>
                    {/* <Row className="align-items-center">
                      <Col sm="6" md="3" lg="2" className="mb-3">
                        {this.translation('emailAddress')}
                      </Col>
                      <Col sm="6" md="9" lg="10" className="mb-3">
                        <FormGroup
                          formControlName="email"
                          type="text"
                          label="Enter Email Address"
                          disabled={this.state.submitting}
                          handleChange={handleChange}
                          touched={touched['email']}
                          error={errors['email']}
                          classes="mb-0"
                        />
                      </Col>
                    </Row> */}
                    <Row>
                      <Col
                        className="align-items-center"
                        sm="6"
                        md="3"
                        lg="2"
                        className="mb-3"
                      >
                        {this.translation('phoneNumber')}
                      </Col>
                      <Col sm="6" md="9" lg="10" className="mb-3">
                        <FormGroup
                          formControlName="phoneNumber"
                          type="phone"
                          label={this.translation('enterPhoneNumberOptional')}
                          disabled={this.state.submitting}
                          handleChange={handleChange}
                          touched={touched['phoneNumber']}
                          error={errors['phoneNumber']}
                          classes="mb-0"
                        />
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      variant="success"
                      size="lg"
                      className="rounded-0 px-5 float-right mt-3"
                      disabled={this.state.submitting}
                    >
                      {this.state.submitting ? (
                        <MDSpinner singleColor="#ffffff" />
                      ) : (
                        this.translation('submit')
                      )}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(NewCustomer);
