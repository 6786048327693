import React, { Component } from 'react';
import { Row, Col, ListGroup, Collapse, Form, Alert } from 'react-bootstrap';
import { Formik } from 'formik';

import { FormGroup } from '../../../../_shared/components';

import { AddressSchema } from './form.schema.js';
import i18next from 'i18next';

class UserAccountAddress extends Component {
  constructor(props) {
    super(props);
    this.dbKeys = {
      'Street-Address-1': 'street1',
      'Street-Address-2': 'street2',
      City: 'city',
      'State/Province': 'state',
      Country: 'country',
      'Zip-Code': 'zipCode'
    };
  }
  render() {
    const { details, editAddress, bindSubmitForm } = this.props;
    const { street1, street2, city, state, country, zipCode } = details;
    const formData = {
      'Street-Address-1': {
        text: i18next.t('streetAddress1'),
        value: street1 ? street1 : i18next.t('n/a')
      },
      'Street-Address-2': {
        text: i18next.t('streetAddress2'),
        value: street2 ? street2 : i18next.t('n/a')
      },
      City: {
        text: i18next.t('city'),
        value: city ? city : i18next.t('n/a')
      },
      'State/Province': {
        text: i18next.t('stateOrProvince'),
        value: state ? state : i18next.t('n/a')
      },
      Country: {
        text: i18next.t('country'),
        value: country ? country : i18next.t('n/a')
      },
      'Zip-Code': {
        text: i18next.t('zipCode'),
        value: zipCode ? zipCode : i18next.t('n/a')
      }
    };
    return (
      <>
        <Row className="align-items-center">
          <Col>
            {!editAddress ? (
              <ListGroup variant="flush" className="pl-md-4">
                {Object.keys(formData).map((key, index) => (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col>{formData[key].text}</Col>
                      <Col>{formData[key].value}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <Formik
                initialValues={{
                  'Street-Address-1': street1,
                  'Street-Address-2': street2,
                  City: city,
                  'State/Province': state,
                  Country: country,
                  'Zip-Code': zipCode
                }}
                validationSchema={AddressSchema}
                onSubmit={values => {
                  const newValues = {};
                  Object.keys(values).forEach(key => {
                    newValues[this.dbKeys[key]] = values[key];
                  });
                  const addressValues = { address: newValues };
                  this.props.onSubmit(addressValues);
                }}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, touched, handleChange, handleSubmit, values }) => {
                  bindSubmitForm('address', handleSubmit);
                  return (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="form-group-list"
                    >
                      <Collapse in={!!alert.message}>
                        <div>
                          {!!alert.message && (
                            <Alert variant={alert.type}>{alert.message}</Alert>
                          )}
                        </div>
                      </Collapse>
                      <ListGroup variant="flush" className="pl-md-4">
                        {Object.keys(formData).map((key, index) => (
                          <ListGroup.Item key={index}>
                            <Row className="align-items-center">
                              <Col>{formData[key].text}</Col>
                              <Col>
                                <FormGroup
                                  formControlName={key}
                                  type="text"
                                  label={formData[key].text}
                                  handleChange={handleChange}
                                  touched={touched[key]}
                                  error={errors[key]}
                                  value={values[key]}
                                />
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default UserAccountAddress;
