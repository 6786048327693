import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Icon } from '../../../_shared/components';

import CompanyLogo from '../../../../assets/images/company_logo.png';
import StartQuote from '../../../../assets/images/start-quote.png';
import EndQuote from '../../../../assets/images/end-quote.png';
import { withTranslation } from 'react-i18next';

export class LoginBanner extends React.Component {
  translation = this.props.t;
  render() {
    return (
      <React.Fragment>
        <div className="login-background d-none d-sm-none d-md-block">
          <div className="layer">
            <Row className="align-items-center align-content-center h-100">
              <Col xs={12} className="text-center mb-md-3">
                <img
                  src={CompanyLogo}
                  className="company-logo"
                  alt={this.translation('mobilizeCompanyLogo')}
                />
              </Col>
              {/* <Col xs={12} className="d-none d-sm-none d-md-block">
                <div className="text-white company-text px-3">
                  <blockquote className="blockquote">
                    <p className="text-center">
                      <img
                        src={StartQuote}
                        alt={this.translation('startQuote')}
                        className="align-top mr-1"
                      />
                      {this.translation('companyMission')}
                      <img
                        src={EndQuote}
                        alt={this.translation('endQuote')}
                        className="align-top ml-1"
                      />
                    </p>
                  </blockquote>
                  <div className="d-flex justify-content-center mt-4">
                    <div className="text-left">
                      <a
                        href={'mailto:' + this.translation('address.infoEmail')}
                        className="d-block text-white text-decoration-none mb-3 h5"
                      >
                        <Icon
                          iconName="closed-envelope"
                          size="29"
                          classes="mr-2 align-middle"
                        />
                        {this.translation('address.infoEmail')}
                      </a>
                      <a
                        href={'tel:' + this.translation('address.phoneNumber')}
                        className="d-block text-white text-decoration-none h5"
                      >
                        <Icon
                          iconName="phone-receiver"
                          size="29"
                          classes="mr-2 align-top"
                        />
                        {this.translation('address.phoneNumber')}
                      </a>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
        <div className="d-block d-sm-block d-md-none">
          <Row className="justify-content-center align-items-center">
            <Col xs="auto">
              <div className="bg-danger rounded p-2 m-3 company-logo-container">
                <img
                  src={CompanyLogo}
                  alt={this.translation('mobilizeCompanyLogo')}
                  className="h-100"
                />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(LoginBanner);
