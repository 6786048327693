import React, { Component } from 'react';
import { Row, Col, ListGroup, Collapse, Form, Alert } from 'react-bootstrap';
import { Formik } from 'formik';

import { FormGroup } from '../../../../_shared/components';
import { toCapitalize } from '../../../../_shared/helpers';

import { DetailSchema } from './form.schema.js';
import { withTranslation } from 'react-i18next';

class UserAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.dbKeys = {
      'First-Name': 'name',
      'Last-Name': 'lastName',
      Email: 'email',
      Type: 'userType',
      'Phone-Number': 'phoneNumber'
    };
    this.cleanup = null;
  }
  render() {
    const { details, editDetails, bindSubmitForm } = this.props;
    const { name, lastName, email, userType, phoneNumber } = details;
    const translation = this.props.t;
    return (
      <>
        <Row className="align-items-center">
          <Col>
            {!editDetails ? (
              <ListGroup variant="flush" className="pl-md-4">
                <ListGroup.Item>
                  <Row>
                    <Col>
                      {userType === 'organization' ? 'Customer' : 'First'}{' '}
                      {translation('name')}
                    </Col>
                    <Col>{name}</Col>
                  </Row>
                </ListGroup.Item>
                {userType !== 'organization' && (
                  <ListGroup.Item>
                    <Row>
                      <Col>{translation('lastName')}</Col>
                      <Col>{lastName}</Col>
                    </Row>
                  </ListGroup.Item>
                )}
                {email ? (
                  <ListGroup.Item>
                    <Row>
                      <Col>{translation('email')}</Col>
                      <Col>{email}</Col>
                    </Row>
                  </ListGroup.Item>
                ) : (
                  ''
                )}
                <ListGroup.Item>
                  <Row>
                    <Col>{translation('phoneNumber')}</Col>
                    <Col>{phoneNumber ? phoneNumber : translation('n/a')}</Col>
                  </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <Row>
                    <Col>{translation('type')}</Col>
                    <Col>
                      {userType === 'organization'
                        ? toCapitalize('customer')
                        : ''}
                    </Col>
                  </Row>
                </ListGroup.Item> */}
              </ListGroup>
            ) : (
              <Formik
                initialValues={{
                  'First-Name': name,
                  'Last-Name': lastName,
                  Email: email,
                  Type: userType,
                  'Phone-Number': phoneNumber
                }}
                validationSchema={DetailSchema}
                onSubmit={values => {
                  const newValues = {};
                  Object.keys(values).forEach(key => {
                    newValues[this.dbKeys[key]] = values[key];
                  });
                  this.props.onSubmit(newValues);
                }}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, touched, handleChange, handleSubmit, values }) => {
                  bindSubmitForm('detail', handleSubmit);
                  return (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="form-group-list"
                    >
                      <Collapse in={!!alert.message}>
                        <div>
                          {!!alert.message && (
                            <Alert variant={alert.type}>{alert.message}</Alert>
                          )}
                        </div>
                      </Collapse>
                      <ListGroup variant="flush" className="pl-md-4">
                        <ListGroup.Item>
                          <Row className="align-items-center">
                            <Col>
                              {userType === 'organization'
                                ? translation('Customer')
                                : translation('first')}{' '}
                              {translation('name')}
                            </Col>
                            <Col>
                              <FormGroup
                                formControlName="First-Name"
                                type="text"
                                label={
                                  (userType === 'organization'
                                    ? translation('Customer')
                                    : translation('first')) +
                                  translation('name')
                                }
                                handleChange={handleChange}
                                touched={touched['First-Name']}
                                error={errors['First-Name']}
                                value={values['First-Name']}
                                required
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        {userType !== 'organization' && (
                          <ListGroup.Item>
                            <Row className="align-items-center">
                              <Col>{translation('lastName')}</Col>
                              <Col>
                                <FormGroup
                                  formControlName="Last-Name"
                                  type="text"
                                  label={translation('lastName')}
                                  handleChange={handleChange}
                                  touched={touched['Last-Name']}
                                  error={errors['Last-Name']}
                                  value={values['Last-Name']}
                                />
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {values['Email'] ? (
                          <ListGroup.Item>
                            <Row className="align-items-center">
                              <Col>{translation('email')}</Col>
                              <Col>
                                <FormGroup
                                  formControlName="Email"
                                  type="text"
                                  label={translation('email')}
                                  handleChange={handleChange}
                                  touched={touched['Email']}
                                  error={errors['Email']}
                                  value={values['Email']}
                                />
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ) : (
                          ''
                        )}
                        <ListGroup.Item>
                          <Row className="align-items-center">
                            <Col>{translation('phoneNumber')}</Col>
                            <Col>
                              <FormGroup
                                formControlName="Phone-Number"
                                type="phone"
                                label={translation('phoneNumber')}
                                handleChange={handleChange}
                                touched={touched['Phone-Number']}
                                error={errors['Phone-Number']}
                                value={values['Phone-Number']}
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        {/* <ListGroup.Item>
                          <Row className="align-items-center">
                            <Col>{translation('type')}</Col>
                            <Col>
                              <FormGroup
                                formControlName="Type"
                                type="text"
                                label={translation('type')}
                                handleChange={handleChange}
                                touched={touched['Type']}
                                error={errors['Type']}
                                value={
                                  values['Type'] === 'organization'
                                    ? 'Customer'
                                    : ''
                                }
                                disabled
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item> */}
                      </ListGroup>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation()(UserAccountDetails);
