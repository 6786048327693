import React from 'react';
import { Icon } from '../../../../_shared/components';
import { ColorConstants } from '../../../../_shared/constants';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../../_shared/helpers';
import i18next from 'i18next';

const kitRegisteredFormatter = (cell, row) => {
  return i18next.t('yes');
};

export const kitTableConstants = {
  kitColumns: goTo => [
    {
      dataField: 'code',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('licenseCode')),
      sort: true
    },
    {
      dataField: 'totalActivation',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('totalActivations')),
      sort: true
    },
    {
      dataField: 'maxActivation',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('maxActivations')),
      sort: true
    },
    {
      dataField: 'activationDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('ActivationDate')),
      formatter: cell => getFormattedDate(cell),
      sort: true
    }
    // {
    //   dataField: 'registered',
    //   text: '',
    //   headerFormatter: (...theArgs) =>
    //     headerFormatterWithSort(
    //       ...theArgs,
    //       i18next.t('register', { context: 'past' })
    //     ),
    //   formatter: kitRegisteredFormatter,
    //   sort: true
    // },
    // {
    //   dataField: 'unitCreatedDate',
    //   text: '',
    //   headerFormatter: (...theArgs) =>
    //     headerFormatterWithSort(...theArgs, i18next.t('createdDate')),
    //   formatter: cell => {
    //     return getFormattedDate(cell);
    //   },
    //   sort: true
    // },
    // {
    //   dataField: 'serviceExpiryDate',
    //   text: '',
    //   headerFormatter: (...theArgs) =>
    //     headerFormatterWithSort(...theArgs, i18next.t('servicePlan')),
    //   formatter: cell => getFormattedDate(cell, 60),
    //   sort: true
    // },
    // {
    //   dataField: '',
    //   text: '',
    //   isDummyField: true,
    //   formatter: (cell, row) => (
    //     <Icon
    //       iconName="right"
    //       size="29"
    //       fill={ColorConstants.DEFAULT_FONT}
    //       classes="cursor-pointer"
    //       onClick={() => goTo('/admin/units/details/' + row._id)}
    //     />
    //   ),
    //   classes: 'py-0 text-right'
    // }
  ]
};
