import React from 'react';
import Icon from '../../../_shared/components/Icon';
import { ColorConstants } from '../../../_shared/constants';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../_shared/helpers';
import i18next from 'i18next';

const itemNameFormatter = (cell, row) => {
  return `${cell} (${row.quantity})`;
};

export const expiringTableConstants = {
  itemColumns: presentConfirm => {
    return [
      {
        dataField: 'item.name',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('itemNameQty')),
        formatter: itemNameFormatter,
        sort: true,
        headerStyle: { width: '25%' },
        style: { width: '25%' }
      },
      {
        dataField: 'productSerialNumber',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('kitSerialNumber')),
        sort: true,
        headerStyle: { width: '25%' },
        style: { width: '25%' }
      },
      {
        dataField: 'user.name',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('customer')),
        formatter: (cell, row) => {
          return row.user._id ? (
            cell + ' ' + (row.user.lastName || '')
          ) : (
            <span className="text-primary">{i18next.t('n/a')}</span>
          );
        },
        sort: true,
        headerStyle: { width: '20%' },
        style: { width: '20%' }
      },
      {
        dataField: 'expiryDate',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('expiryDate')),
        formatter: cell => getFormattedDate(cell, 60, 'YYYY/MM'),
        sort: true,
        headerStyle: { width: '20%' },
        style: { width: '20%' }
      }
      // {
      //   dataField: 'customerEmail',
      //   text: '',
      //   headerFormatter: (...theArgs) =>
      //     headerFormatterWithSort(...theArgs, i18next.t('email')),
      //   isDummyField: true,
      //   formatter: (cell, row, rowIndex, formatExtraData) => {
      //     return row ? (
      //       formatExtraData[row.mailStatus]
      //     ) : (
      //       <span className="text-primary">{i18next.t('n/a')}</span>
      //     );
      //   },
      //   formatExtraData: {
      //     undefined: (
      //       <Icon
      //         iconName="send-mail"
      //         size="20"
      //         fill={ColorConstants.DEFAULT_FONT}
      //         classes="cursor-pointer"
      //       />
      //     ),
      //     sent: (
      //       <div className="scaleUp">
      //         {i18next.t('mailSent')}
      //         <Icon
      //           iconName="check-circle"
      //           classes="ml-2"
      //           fill={ColorConstants.PRIMARY}
      //         />
      //       </div>
      //     )
      //   },
      //   events: {
      //     onClick: (onClickEvent, column, columnIndex, row, rowIndex) => {
      //       if (!row.mailStatus) {
      //         presentConfirm(
      //           row.item.name,
      //           row.productSerialNumber,
      //           row.user.fullName,
      //           row.user.email,
      //           row._id
      //         );
      //       }
      //     }
      //   }
      //   // headerStyle: { width: '5%' },
      //   // style: { width: '5%' }
      // }
    ];
  },
  serviceColumns: presentConfirm => {
    return [
      {
        dataField: 'serialNumber',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('serialNumber')),
        sort: true,
        headerStyle: { width: '30%' },
        style: { width: '30%' }
      },
      {
        dataField: 'kit.name',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('unitType')),
        sort: true,
        headerStyle: { width: '30%' },
        style: { width: '30%' }
      },
      {
        dataField: 'user.name',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('customer')),
        formatter: (cell, row) => {
          return row.user && row.user._id ? (
            cell + ' ' + (row.user.lastName || '')
          ) : (
            <span className="text-primary">{i18next.t('n/a')}</span>
          );
        },
        sort: true,
        headerStyle: { width: '25%' },
        style: { width: '25%' }
      },
      {
        dataField: 'serviceExpiryDate',
        text: '',
        headerFormatter: (...theArgs) =>
          headerFormatterWithSort(...theArgs, i18next.t('expiresAt')),
        formatter: cell => getFormattedDate(cell, 60, 'YYYY/MM'),
        sort: true,
        headerStyle: { width: '25%' },
        style: { width: '25%' }
      }
      // {
      //   dataField: 'customerEmail',
      //   text: '',
      //   headerFormatter: (...theArgs) =>
      //     headerFormatterWithSort(...theArgs, i18next.t('email')),
      //   isDummyField: true,
      //   formatter: (cell, row, rowIndex, formatExtraData) =>
      //     formatExtraData[row.mailStatus],
      //   formatExtraData: {
      //     undefined: (
      //       <Icon
      //         iconName="send-mail"
      //         size="20"
      //         fill={ColorConstants.DEFAULT_FONT}
      //         classes="cursor-pointer"
      //       />
      //     ),
      //     sent: (
      //       <div className="scaleUp">
      //         {i18next.t('mailSent')}
      //         <Icon iconName="check-circle" fill={ColorConstants.PRIMARY} />
      //       </div>
      //     )
      //   },
      //   events: {
      //     onClick: (onClickEvent, column, columnIndex, row, rowIndex) => {
      //       if (!row.mailStatus) {
      //         presentConfirm(
      //           row.serialNumber,
      //           row.user.fullName,
      //           row.user.email,
      //           row._id
      //         );
      //       }
      //     }
      //   },
      //   headerStyle: { width: '15%' },
      //   style: { width: '15%' }
      // }
    ];
  }
};
