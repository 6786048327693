import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { alert } from './alert.reducer';
import { loading } from './loader.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  alert,
  loading,
  toastr
});

export default rootReducer;
