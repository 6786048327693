import React, { Component } from 'react';
import { Container, Row, Col, Collapse } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import { NotificationService } from '../../../_shared/services';
import MDSpinner from 'react-md-spinner';
import { errorParser, getFormattedDate } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import rafSchedule from 'raf-schd';

export class Notifications extends Component {
  translation = this.props.t;
  cleanup = null;
  state = {
    isLoading: true,
    notifications: [],
    isLoadingMore: true,
    currentIndex: 0,
    sizePerPage: 10,
    page: 1,
    pageY: 0,
    totalRecords: 1
  };
  componentDidMount() {
    this.getNotifications();
    window.onscroll = () => {
      this.handleScroll();
    };
  }
  getNotifications(
    currentIndex = this.state.currentIndex,
    sizePerPage = this.state.sizePerPage
  ) {
    this.setState({ isLoadingMore: true });
    const getNotifications$ = NotificationService.get(
      currentIndex,
      sizePerPage
    ).subscribe({
      next: response => {
        if (this.cleanup) {
          const { alerts, count } = response.data;
          const notifications = this.state.notifications.concat(alerts);
          this.setState({
            notifications,
            isLoading: false,
            isLoadingMore: false,
            totalRecords: count
          });
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getNotifications$.unsubscribe();
    };
  }

  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
    // Cancel any pending updates since we're unmounting.
    this.scheduleUpdate.cancel();
  }
  // Create a new function to schedule updates.
  scheduleUpdate = rafSchedule(point => {
    const screenHeight = window.screen.availHeight;
    const scrollHeight = document.getElementsByTagName('body')[0].scrollHeight;
    if (
      scrollHeight - (point.y + screenHeight) <= 250 &&
      point.y - this.state.pageY > 0
    ) {
      this.setState(
        state => {
          return {
            pageY: point.y,
            currentIndex: state.currentIndex + state.sizePerPage
          };
        },
        () => {
          if (this.state.currentIndex + 1 < this.state.totalRecords) {
            this.getNotifications();
          }
        }
      );
    }
  });
  handleScroll = () => {
    if (!this.state.isLoadingMore) {
      const doc = document.documentElement;
      // When we receive a scroll event, schedule an update.
      // If we receive many updates within a frame, we'll only publish the latest value.
      this.scheduleUpdate({
        x:
          (window.pageXOffset || doc ? doc.scrollLeft : 0) -
          (doc ? doc.clientLeft : false || 0),
        y:
          (window.pageYOffset || doc ? doc.scrollTop : 0) -
          (doc ? doc.clientTop : false || 0)
      });
    }
  };
  render() {
    return (
      <Container fluid>
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <>
            <Row>
              {this.state.notifications.length ? (
                <>
                  {this.state.notifications.map((notification, index) => (
                    <Col xs={12} className="mb-3" key={index}>
                      <Row className="notification-row hoverable grey align-items-center bg-dark-rgba-1">
                        <Col md="auto" className="py-3">
                          <Icon
                            iconName="empty-bell"
                            size="25"
                            fill={ColorConstants.DEFAULT_FONT}
                          />
                        </Col>
                        <Col md={8} className="py-3">
                          <h5 className="mb-1">{notification.title}</h5>
                          <p className="mb-0 text-muted">
                            {notification.description}
                          </p>
                        </Col>
                        <Col md className="py-3 text-right">
                          <h5 className="mb-0 d-inline-block broadcast-date mr-3">
                            {getFormattedDate(notification.createdAt)}
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                  <Col xs="12" className="text-right">
                    {this.translation('alertsReceivedLast_90Days')}
                  </Col>
                </>
              ) : (
                <div className="w-100 text-center">
                  {this.translation('noData')}
                </div>
              )}
            </Row>
            <Collapse in={this.state.isLoadingMore}>
              <div className="w-100 text-center">
                <MDSpinner singleColor={ColorConstants.PRIMARY} />
              </div>
            </Collapse>
          </>
        )}
      </Container>
    );
  }
}

export default withTranslation()(Notifications);
