// @flow
import * as React from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import i18next from 'i18next';
import {
  switchMap,
  tap,
  debounceTime,
  map,
  concatMap,
  mergeMap
} from 'rxjs/operators';
import { connect } from 'react-redux';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { loaderActions } from '../../../_shared/redux/actions';
import { Icon, ConfirmDialog } from '../../../_shared/components';
import BannersContainer from './BannersContainer';
import AddDocumentModal from '../Documents/AddDocumentModal';
import { ReactRouterHistory, IDocument } from '../../../models';
import {
  ImageService,
  DocumentService,
  ConfigService
} from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import AddExistingDocumentModal from './AddExistingDocumentModal';

type State = {
  documents: IDocument[],
  showAddDocumentModal: boolean,
  showAddExistingDocumentModal: boolean,
  documentsArrived: boolean,
  addDetails: boolean,
  editDetails: boolean,
  currentIndex: number,
  sizePerPage: number,
  sortField: string,
  sortOrder: 'asc' | 'desc',
  searchText: string,
  documentType: string,
  banner: boolean,
  isLoading: boolean,
  documentIdToBeUpdated: string,
  deleteConfirmMessage: Object,
  showConfirmDelete: boolean
};

type Props = {
  t: i18next.TFunction,
  history: ReactRouterHistory,
  startLoading(): void,
  stopLoading(): void
};

class ManageBanner extends React.Component<Props, State> {
  translation = this.props.t;
  cleanup = null;
  state = {
    showAddDocumentModal: false,
    showAddExistingDocumentModal: false,
    addDetails: false,
    editDetails: false,
    currentIndex: 0,
    sizePerPage: 15,
    sortField: 'title',
    sortOrder: 'asc',
    searchText: '',
    documentType: 'Video,Image',
    banner: true,
    documentsArrived: false,
    documents: [],
    isLoading: true,
    deleteConfirmMessage: <></>,
    documentIdToBeUpdated: '',
    showConfirmDelete: false
  };

  /**Call apis when component mounted */
  componentDidMount() {
    this.getDocuments();
    // this.onSearchChange();
  }

  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$: Observable<Event> = fromEvent(
      document.getElementById('documents-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            searchText: inputEvent.target.value,
            currentIndex: 0,
            isLoading: true
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText,
            this.state.documentType,
            this.state.banner
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };

  /**Observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    sortField: string,
    sortOrder: 'asc' | 'desc',
    searchText: string,
    documentType: string,
    banner: boolean
  ) => {
    return DocumentService.getDocuments(
      currentIndex,
      sizePerPage,
      searchText,
      documentType,
      sortField,
      sortOrder,
      banner
    );
  };

  /**Sort documents by config and show on page */
  sortDocuments(documents) {
    let sortedDocuments = [];
    ConfigService.getConfig('banners').subscribe({
      next: (response: { data: { data: { value: IDocument[] } } }) => {
        const { value } = response.data.data;
        value.forEach(id => {
          let currentDocument = documents.find(document => document._id === id);
          if (currentDocument) {
            sortedDocuments.push(currentDocument);
          }
        });
        this.setState({
          documents: sortedDocuments,
          addDetails: false,
          editDetails: false,
          documentIdToBeUpdated: '',
          isLoading: false
        });
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
  }

  /**Subscriber-response handler */
  handelResponse = () => ({
    next: async (response: {
      data: { documents: IDocument[], count: number }
    }) => {
      if (this.cleanup) {
        const { documents }: { documents: IDocument[] } = response.data;
        this.sortDocuments(documents);
        // this.setState({ documents });
      }
    },
    error: (errorResponse: any) => {
      if (this.cleanup) {
        this.setState({ documentsArrived: true, isLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });

  /**Get customers from server */
  getDocuments(
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    sortField: string = this.state.sortField,
    sortOrder: 'asc' | 'desc' = this.state.sortOrder,
    searchText: string = this.state.searchText,
    documentType: string = this.state.documentType,
    banner: boolean = this.state.banner
  ) {
    this.setState({ isLoading: true });
    const documentService$: Subscription = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      documentType,
      banner
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      documentService$.unsubscribe();
    };
  }

  onSubmit = (values, uploadedFile = undefined) => {
    this.setState({ showAddDocumentModal: false });
    this.props.startLoading();
    const stateDoc = this.state.documents.filter(
      (doc: IDocument) => doc._id === this.state.documentIdToBeUpdated
    )[0];
    let observable$, subscription$;
    if (uploadedFile && stateDoc && stateDoc.url) {
      observable$ = ImageService.deleteImage(
        'document',
        this.state.documentIdToBeUpdated
      ).pipe(mergeMap(() => this.completeOnSubmit(uploadedFile, values)));
    } else {
      observable$ = this.completeOnSubmit(uploadedFile, values);
    }
    subscription$ = observable$.subscribe(this.handleResponseOnSubmit());
    this.cleanup = () => {
      if (subscription$) {
        subscription$.unsubscribe();
      }
    };
  };

  /**Confirm dialog cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };

  /**Confirm dialog ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    this.deleteDocument();
  };

  /**Called when user clicks on edit icon */
  startEditDocument = ({ _id }) => {
    this.setState((curr: State) => ({
      ...curr,
      documentIdToBeUpdated: _id,
      addDetails: false,
      editDetails: true
    }));
    // let's open modal to edit the document
    this.setState({ showAddDocumentModal: true });
  };

  /**Present confirm delete */
  presentDeleteConfirm = ({ _id, title }) => {
    const deleteConfirmMessage = (
      <>
        <p>
          {this.translation('deleteDocument')}:
          <br />
          <b>
            {this.translation('name')}: {title}
          </b>
          ?
        </p>
      </>
    );
    this.setState({
      showConfirmDelete: true,
      deleteConfirmMessage,
      documentIdToBeUpdated: _id
    });
  };

  /**Once confirmed, delete user */
  deleteDocument() {
    const deleteDocument$: Observable<any> = DocumentService.delete(
      this.state.documentIdToBeUpdated
    ).subscribe({
      next: (response: { data: { message: string } }) => {
        if (this.cleanup) {
          //let's make the id null, to avoid leaks
          this.setState({
            documentIdToBeUpdated: '',
            addDetails: false,
            editDetails: false
          });
          this.props.stopLoading();
          toastr.success(this.translation('success'), response.data.message);
          this.setState({ currentIndex: 0 }, () => {
            this.getDocuments();
          });
        }
      },
      error: (errorResponse: any) => {
        this.props.stopLoading();
        if (this.cleanup) {
          this.setState({ isLoading: false, documentsArrived: true });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      deleteDocument$.unsubscribe();
    };
  }

  /**Handle response on submit */
  handleResponseOnSubmit = () => {
    return {
      next: response => {
        if (this.cleanup) {
          toastr.success(
            `${this.translation('document')} ` +
              (!this.state.addDetails
                ? this.translation('update', { context: 'past' })
                : this.translation('add', { context: 'past' })),
            response.data.message
          );
          this.setState({
            addDetails: false,
            editDetails: false,
            documentIdToBeUpdated: ''
          });
          this.props.stopLoading();
          // once updated, let's get the user back to fist page
          this.setState({ currentIndex: 0 }, () => {
            this.getDocuments();
          });
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          toastr.error(this.translation('error'), errorParser(errorResponse));
          this.props.stopLoading();
        }
      }
    };
  };

  completeOnSubmit(uploadedFile, values) {
    let observable$;
    if (uploadedFile) {
      // let's upload image first
      observable$ = ImageService.startUploadImage(uploadedFile).pipe(
        map(uploadMageResponse => uploadMageResponse.responseData.imageSrc),
        concatMap(url => {
          const updatedDocument = Object.assign(values, {
            url,
            banner: true
          });
          if (this.state.addDetails) {
            return DocumentService.add(updatedDocument);
          } else if (this.state.editDetails) {
            return DocumentService.update(
              this.state.documentIdToBeUpdated,
              updatedDocument
            );
          }
        })
      );
      // .subscribe(this.handleResponseOnSubmit());
    } else if (this.state.addDetails) {
      observable$ = DocumentService.add({ ...values, banner: true });
      // .subscribe(this.handleResponseOnSubmit());
    } else {
      observable$ = DocumentService.update(
        this.state.documentIdToBeUpdated,
        values
      );
      // .subscribe(this.handleResponseOnSubmit());
    }
    return observable$;
  }

  hideExistingDocumentModel(renderPage) {
    this.setState({ showAddExistingDocumentModal: false });
    if (renderPage) {
      this.getDocuments();
    }
  }

  render() {
    return (
      <>
        <AddDocumentModal
          show={this.state.showAddDocumentModal}
          documentId={this.state.documentIdToBeUpdated}
          hideDocumentFieldOption={true}
          onClose={() =>
            this.setState({
              showAddDocumentModal: false,
              documentIdToBeUpdated: '',
              addDetails: false,
              editDetails: false
            })
          }
          onSubmit={(document, uploadedFile) => {
            this.onSubmit(document, uploadedFile);
          }}
        />
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn={this.translation('no')}
          okBtn={this.translation('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.state.deleteConfirmMessage}
        />
        <AddExistingDocumentModal
          show={this.state.showAddExistingDocumentModal}
          onHide={this.hideExistingDocumentModel.bind(this)}
        />
        <Container fluid className="manage-banner-container">
          <Row>
            {/* <Col md="4" className="mb-3">
              <FormGroup
                title={this.translation('searchBy', {
                  what:
                    this.translation('document') +
                    ' ' +
                    this.translation('title') +
                    ' ' +
                    this.translation('or').toLocaleLowerCase() +
                    ' ' +
                    this.translation('description') +
                    '...'
                })}
                type="text"
                label={this.translation('searchBy', {
                  what:
                    this.translation('document') +
                    ' ' +
                    this.translation('title') +
                    ' ' +
                    this.translation('or').toLocaleLowerCase() +
                    ' ' +
                    this.translation('description') +
                    '...'
                })}
                icon="search"
                iconPosition="right"
                classes="mb-2"
                formControlId="banners-search-input"
              />
            </Col> */}
            <Col className="text-right mb-3">
              <Dropdown className="d-inline-block">
                <Dropdown.Toggle variant="" className="mb-2 no-arrow-icon">
                  {this.translation('add') + ' ' + this.translation('banner')}
                  <Icon iconName="add" size="26" classes="ml-2" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right rounded-0 shadow-sm">
                  <Dropdown.Item
                    className="py-3"
                    onClick={() => {
                      this.setState({
                        addDetails: true,
                        editDetails: false,
                        showAddDocumentModal: true
                      });
                    }}
                  >
                    {this.translation('add') + ' ' + this.translation('new')}
                  </Dropdown.Item>
                  <Dropdown.Divider className="my-0" />
                  <Dropdown.Item
                    className="py-3"
                    onClick={() => {
                      this.setState({
                        showAddExistingDocumentModal: true
                      });
                    }}
                  >
                    {this.translation('add') +
                      ' ' +
                      this.translation('existing')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <BannersContainer
            documents={this.state.documents}
            isLoading={this.state.isLoading}
            deleteDocument={this.presentDeleteConfirm}
            editDocument={this.startEditDocument}
            rearrangeDocuments={documents => {
              this.setState({ documents });
            }}
          />
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(ManageBanner)
);
