import React, { Component } from 'react';
import { Container, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { loaderActions } from '../../../_shared/redux/actions';
import { NotificationService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import { InnerForm } from './InnerForm';

const SendMessageSchema = Yup.object().shape({
  sendTo: Yup.array()
    .required()
    .min(1),
  title: Yup.string()
    .required()
    .min(3),
  description: Yup.string()
    .required()
    .min(3)
});

export let injected;

export class SendMessage extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = null;
  state = {};
  /**Free up resources once component unmounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  render() {
    return (
      <Container fluid>
        <Card>
          <Card.Body>
            <Formik
              initialValues={{
                sendTo: ['site'],
                title: '',
                description: ''
              }}
              validationSchema={SendMessageSchema}
              onSubmit={values => {
                this.props.startLoading();
                const notificationService$ = NotificationService.create(
                  values
                ).subscribe({
                  next: response => {
                    if (this.cleanup) {
                      toastr.success(
                        this.translation('alert') +
                          ' ' +
                          this.translation('create', { context: 'past' }),
                        response.data.message
                      );
                      this.props.history.push('/admin/notifications');
                    }
                  },
                  error: errorResponse => {
                    if (this.cleanup) {
                      toastr.error(
                        this.translation('error'),
                        errorParser(errorResponse)
                      );
                    }
                  }
                });
                this.cleanup = () => {
                  notificationService$.unsubscribe();
                };
              }}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {formikProps => {
                injected = formikProps;
                return (
                  <InnerForm {...formikProps} translation={this.translation} />
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(SendMessage)
);
