import React, { Component } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';

import { FormGroup, Icon, ImageContainer } from '../../../_shared/components';
import MDSpinner from 'react-md-spinner';
import { ColorConstants, ALLOWED_IMAGE } from '../../../_shared/constants';
import { DocumentService } from '../../../_shared/services';
import { errorParser, tick_then } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';

const DocumentFormSchema = Yup.object().shape({
  title: Yup.string().required(),
  url: Yup.string(),
  description: Yup.string().required()
});

class AddDocumentModal extends Component {
  translation = this.props.t;
  documentAnchor = React.createRef();
  /**Resource cleaner */
  cleanup = null;
  /**Placeholder image ref */
  placeHolderImageRef = React.createRef();
  fileInput = React.createRef();
  imagePreviewer = React.createRef();
  /**Component's state */
  state = {
    isLoading: true,
    document: {
      title: '',
      url: '',
      type: 'Image',
      description: ''
    },
    fileAdded: false,
    fileNeeded: false,
    userUploadedFile: undefined,
    placeHolderImage: null,
    oldImage: null,
    showPreview: false,
    fileName: ''
  };
  onEntering = () => {
    if (this.props.documentId) {
      this.setState({ isLoading: true });
    }
  };
  /**Set placeholder image value */
  onEntered = () => {
    if (this.props.documentId) {
      DocumentService.getById(this.props.documentId).subscribe({
        next: response => {
          const document = response.data.document;
          this.setState(
            {
              document,
              isLoading: false,
              fileName:
                document.type !== 'Video'
                  ? document.url.split('/')[document.url.split('/').length - 1]
                  : ''
            },
            () => this.setImage()
          );
        },
        error: errorResponse => {
          toastr.error(this.translation('error'), errorParser(errorResponse));
          this.setState(() => this.setImage());
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          document: {
            title: '',
            url: '',
            type: 'Image',
            description: ''
          }
        },
        () => {
          this.setImage();
        }
      );
    }
  };

  /**Handle user input files and preview image */
  handleFiles = files => {
    const file = files[0];
    switch (this.state.document.type) {
      case 'Image':
        if (/\.(jpe?g|png)$/i.test(file.name)) {
          if (file.size > ALLOWED_IMAGE.DOCUMENT.IMAGE.SIZE) {
            const imageError = this.translation('fileSizeShouldBeLessThan', {
              size: '5 MB'
            });
            toastr.warning(this.translation('warning'), imageError);
          } else {
            const reader = new FileReader();
            reader.onload = readerOnloadEvent => {
              var image = new Image();
              image.style.minHeight =
                ALLOWED_IMAGE.DOCUMENT.IMAGE.HEIGHT + 'px';
              image.style.minWidth = ALLOWED_IMAGE.DOCUMENT.IMAGE.WIDTH + 'px';
              image.classList.add('img-fluid');
              image.title = file.name;
              image.src = readerOnloadEvent.target.result;
              image.onload = imageOnloadEvent => {
                const target = imageOnloadEvent.target;
                if (
                  target.height >= ALLOWED_IMAGE.KIT_ITEM.HEIGHT &&
                  target.width >= ALLOWED_IMAGE.KIT_ITEM.WIDTH
                ) {
                  this.setState({
                    fileAdded: true,
                    userUploadedFile: file,
                    fileName: file.name
                  });
                } else {
                  const imageError = this.translation(
                    'imageDimensionShouldBe',
                    {
                      context: 'minimum',
                      dimension:
                        ALLOWED_IMAGE.KIT_ITEM.WIDTH +
                        'px X ' +
                        ALLOWED_IMAGE.KIT_ITEM.HEIGHT +
                        'px'
                    }
                  );
                  toastr.warning(this.translation('warning'), imageError);
                }
              };
            };
            reader.readAsDataURL(file);
          }
        } else {
          const imageError = this.translation('onlyImageFilesAllowed');
          toastr.warning(this.translation('warning'), imageError);
        }
        break;
      case 'Document':
        if (/\.(pdf)$/i.test(file.name)) {
          if (file.size > ALLOWED_IMAGE.DOCUMENT.PDF.SIZE) {
            const documentError = this.translation('fileSizeShouldBeLessThan', {
              size: '10 MB'
            });
            toastr.warning(this.translation('warning'), documentError);
          } else {
            this.setState({
              fileAdded: true,
              userUploadedFile: file,
              fileName: file.name
            });
          }
        } else {
          const documentError = this.translation('allowedFileTypes', {
            fileTypes: 'PDF (.pdf)'
          });
          toastr.warning(this.translation('warning'), documentError);
        }
        break;
      default:
        break;
    }
  };
  /**User removes the file */
  removeFile = () => {
    this.setState({ fileAdded: false, userUploadedFile: undefined });
    const imageHolder = this.imagePreviewer.current.querySelector(
      '#imageHolder'
    );
    const oldImageElement = imageHolder.children[0];
    imageHolder.removeChild(oldImageElement);
    if (this.state.oldImage) {
      imageHolder.appendChild(this.state.oldImage);
    } else {
      imageHolder.appendChild(this.state.placeHolderImage);
    }
  };
  setImage() {
    const imageHolder = this.imagePreviewer.current.querySelector(
      '#imageHolder'
    );
    if (this.state.document.url) {
      this.setState({ fileAdded: true });
    } else {
      const oldImage = imageHolder.children[0];
      this.setState({ oldImage });
    }
    const placeHolderImage = this.placeHolderImageRef.current;
    this.setState({ placeHolderImage });
  }
  /**Document type handler dropdown */
  setDocumentType = type => {
    this.removeFile();
    const document = Object.assign(this.state.document, { type, url: '' });
    // we have to make sure that file is removed, that's why tick_then
    tick_then(this.setState({ document }));
  };
  markup = val => {
    return { __html: val };
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        onExited={() => {
          this.onExited();
        }}
        onEntered={() => this.onEntered()}
        onEntering={() => this.onEntering()}
        backdrop="static"
        size="lg"
        aria-labelledby="add-update-document-modal"
        centered
      >
        {this.state.showPreview ? (
          <>
            <Modal.Header>
              <Modal.Title className="text-truncate w-100">
                <Icon
                  iconName="right"
                  classes="rotate-180 mr-2 align-middle cursor-pointer"
                  size="25"
                  onClick={() => {
                    this.setState({ showPreview: false });
                  }}
                />
                {this.translation('documentPreview')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <div
                dangerouslySetInnerHTML={this.markup(this.state.document.url)}
              />
            </Modal.Body>
          </>
        ) : (
          <Modal.Body>
            {this.state.isLoading ? (
              <div className="w-100 text-center">
                <MDSpinner singleColor={ColorConstants.PRIMARY} />
              </div>
            ) : (
              <>
                <h4 className="mb-3">
                  {this.props.documentId
                    ? this.translation('edit')
                    : `${this.translation('add')} ${this.translation('new')}`}
                  <Icon
                    iconName="cancel"
                    classes="float-right cursor-pointer"
                    onClick={() => this.props.onClose()}
                  />
                </h4>
                <Row>
                  <Col
                    md="3"
                    className={[
                      'pr-0',
                      this.state.document.type === 'Video' ? 'd-none' : ''
                    ].join(' ')}
                  >
                    <div className="d-none">
                      <div ref={this.placeHolderImageRef}>
                        <Icon
                          iconName="upload"
                          classes={[
                            this.state.dragEnter ? 'floating' : ''
                          ].join(' ')}
                          size="50"
                          fill={
                            this.state.dragEnter
                              ? ColorConstants.PRIMARY
                              : ColorConstants.TEXT_MUTED
                          }
                        />
                        <p
                          className={[
                            this.state.dragEnter
                              ? 'text-primary font-weight-bold'
                              : 'text-muted',
                            'mb-1'
                          ].join(' ')}
                        >
                          {this.translation('dropFileToUpload')}
                        </p>
                      </div>
                    </div>
                    <div
                      ref={this.imagePreviewer}
                      className={[
                        'document-upload-box',
                        'd-flex',
                        'align-items-center',
                        'flex-column',
                        'px-2',
                        'text-center',
                        'position-relative',
                        this.state.dragEnter
                          ? 'border-dashed border-thick bg-primary-rgba-1'
                          : 'border',
                        this.state.fileNeeded ? 'border-primary' : '',
                        this.state.fileAdded
                          ? 'border-0'
                          : 'justify-content-center'
                      ].join(' ')}
                      onDragEnter={dragEnterEvent => {
                        dragEnterEvent.stopPropagation();
                        dragEnterEvent.preventDefault();
                        this.setState({ dragEnter: true, dragLeave: false });
                      }}
                      onDragOver={dragOverEvent => {
                        dragOverEvent.stopPropagation();
                        dragOverEvent.preventDefault();
                        this.setState({ dragEnter: true, dragLeave: false });
                      }}
                      onDragLeave={dragLeaveEvent => {
                        dragLeaveEvent.stopPropagation();
                        dragLeaveEvent.preventDefault();
                        this.setState({ dragEnter: false, dragLeave: true });
                      }}
                      onDrop={dropEVent => {
                        dropEVent.stopPropagation();
                        dropEVent.preventDefault();
                        const dt = dropEVent.dataTransfer;
                        const files = dt.files;
                        this.handleFiles(files);
                        this.setState({ dragEnter: false, dragLeave: true });
                      }}
                    >
                      <div id="imageHolder" className="w-100">
                        {this.state.userUploadedFile ? (
                          this.state.document.type === 'Image' ? (
                            <div
                              className="rounded hoverable border"
                              title={this.state.fileName}
                            >
                              <ImageContainer
                                key="user-uploaded-image"
                                alt={
                                  this.state.fileName ||
                                  this.state.document.title
                                }
                                file={this.state.userUploadedFile}
                                height={167}
                                width="100%"
                                className="w-100 object-fit-contain h-100"
                              />
                              <div className="border-top text-truncate pl-2 py-2">
                                <Icon iconName="picture" />{' '}
                                {this.state.fileName}
                              </div>
                            </div>
                          ) : this.state.document.type === 'Document' ? (
                            <div
                              className="pdf-preview mb-2 hoverable"
                              title={this.state.fileName}
                            >
                              <Icon iconName="pdf-box" size="166" />
                              {/* TODO:@Khushil create an api to get preview of pdf file : https://github.com/mozilla/pdf.js/blob/master/examples/node/pdf2png/pdf2png.js */}
                              <div className="border-top text-truncate pl-2 py-2">
                                <Icon iconName="document" />{' '}
                                {this.state.fileName}
                              </div>
                            </div>
                          ) : (
                            ''
                          )
                        ) : this.state.document.url ? (
                          this.state.document.type === 'Image' ? (
                            <>
                              <div
                                className="rounded hoverable border cursor-pointer"
                                onClick={() => {
                                  this.documentAnchor.current.click();
                                }}
                                role="presentation"
                              >
                                <ImageContainer
                                  key="src-image"
                                  alt={this.state.document.title}
                                  src={this.state.document.url}
                                  height={167}
                                  width="100%"
                                  className="w-100 object-fit-contain h-100"
                                  title={this.translation('clickToSeeFile', {
                                    file: this.state.fileName
                                  })}
                                />
                                <div className="border-top text-truncate pl-2 py-2">
                                  <Icon iconName="picture" />{' '}
                                  {this.state.fileName}
                                </div>
                              </div>
                              <a
                                className="text-truncate text-blue d-none"
                                target="_blank"
                                title={this.translation('clickToSeeDocument')}
                                rel="noopener noreferrer"
                                href={this.state.document.url}
                                ref={this.documentAnchor}
                              >
                                {this.state.fileName}
                              </a>
                            </>
                          ) : this.state.document.type === 'Document' ? (
                            <>
                              <div
                                className="pdf-preview cursor-pointer mb-2 hoverable"
                                onClick={() => {
                                  this.documentAnchor.current.click();
                                }}
                                role="presentation"
                                title={this.translation('clickToSeeFile', {
                                  file: this.state.fileName
                                })}
                              >
                                <Icon iconName="pdf-box" size="166" />
                                {/* TODO:@Khushil create an api to get preview of pdf file : https://github.com/mozilla/pdf.js/blob/master/examples/node/pdf2png/pdf2png.js */}
                                <div className="border-top text-truncate pl-2 py-2">
                                  <Icon iconName="document" />{' '}
                                  {this.state.fileName}
                                </div>
                              </div>
                              <a
                                className="text-truncate text-blue d-none"
                                target="_blank"
                                title={this.translation('clickToSeeDocument')}
                                rel="noopener noreferrer"
                                href={this.state.document.url}
                                ref={this.documentAnchor}
                              >
                                {this.state.fileName}
                              </a>
                            </>
                          ) : (
                            <div className="rounded hoverable border">
                              <ImageContainer
                                key="src-image"
                                alt={this.state.document.title}
                                src={this.state.document.thumbnail}
                                height={167}
                                width="100%"
                                className="w-100 object-fit-contain h-100"
                              />
                            </div>
                          )
                        ) : (
                          <div>
                            <Icon
                              iconName="upload"
                              classes={[
                                this.state.dragEnter ? 'floating' : ''
                              ].join(' ')}
                              size="50"
                              fill={
                                this.state.dragEnter || this.state.fileNeeded
                                  ? ColorConstants.PRIMARY
                                  : ColorConstants.TEXT_MUTED
                              }
                            />
                            <p
                              className={[
                                this.state.dragEnter
                                  ? 'text-primary font-weight-bold'
                                  : 'text-muted',
                                this.state.fileNeeded ? 'text-primary' : '',
                                'mb-1'
                              ].join(' ')}
                            >
                              {this.translation('dropFileToUpload')}
                            </p>
                          </div>
                        )}
                      </div>
                      {this.state.document.type !== 'Video' ? (
                        this.state.fileAdded ? (
                          <div>
                            <Button
                              variant="link"
                              className={[
                                this.state.dragEnter ? 'd-none' : '',
                                'text-blue',
                                'my-0',
                                'pl-0'
                              ].join(' ')}
                              onClick={() => this.fileInput.current.click()}
                            >
                              {this.translation('change')}
                            </Button>
                            {/* <Button
                              variant="link"
                              className={[
                                this.state.dragEnter ? 'd-none' : '',
                                'text-blue',
                                'my-0',
                                'pr-0'
                              ].join(' ')}
                              onClick={() => this.removeFile()}
                            >
                              Remove
                            </Button> */}
                          </div>
                        ) : (
                          <Button
                            variant="link"
                            className={[
                              this.state.dragEnter ? 'd-none' : '',
                              'text-blue',
                              this.state.fileNeeded ? 'text-primary' : '',
                              'my-0'
                            ].join(' ')}
                            onClick={() => this.fileInput.current.click()}
                          >
                            {this.translation('browse')}
                          </Button>
                        )
                      ) : (
                        ''
                        // TODO:@Dharmen show video thumbnail based on video type
                        // https://stackoverflow.com/a/1361192/3339907
                        // https://stackoverflow.com/a/2068371/3339907
                        // https://www.npmjs.com/package/xml2js already installed
                      )}

                      <input
                        type="file"
                        ref={this.fileInput}
                        className="d-none"
                        onChange={changeEVent =>
                          this.handleFiles(changeEVent.target.files)
                        }
                        accept={
                          this.state.document.type === 'Document'
                            ? '.pdf'
                            : this.state.document.type === 'Image'
                            ? '.jpg,.jpeg,.png'
                            : ''
                        }
                      />
                    </div>
                    {this.state.fileNeeded && (
                      <p className="text-danger invalid-feedback-font-size">
                        {this.translation('pleaseUploadFile')}
                      </p>
                    )}
                  </Col>
                  <Col md>
                    <Formik
                      initialValues={{
                        title: this.state.document.title,
                        url: this.state.document.url,
                        description: this.state.document.description
                      }}
                      validate={values => {
                        this.setState({ fileNeeded: false });
                        let errors = {};
                        if (
                          this.state.document.type === 'Video' &&
                          !values.url
                        ) {
                          errors.url = this.translation(
                            'iframeSnippetRequiredForVideo'
                          );
                        }
                        if (this.state.document.type !== 'Video') {
                          if (!this.state.fileAdded) {
                            this.setState({ fileNeeded: true });
                          } else {
                            this.setState({ fileNeeded: false });
                          }
                        } else {
                          this.setState({ fileNeeded: false });
                        }
                        return errors;
                      }}
                      validationSchema={DocumentFormSchema}
                      onSubmit={values => {
                        if (
                          this.state.document.type !== 'Video' &&
                          !this.state.fileAdded
                        ) {
                          values = Object.assign(values, { url: '' });
                        }
                        const subType =
                          values.url.indexOf('vimeo.com') > -1
                            ? 'Vimeo'
                            : values.url.indexOf('youtube.com') > -1
                            ? 'Youtube'
                            : '';
                        if (subType) {
                          values = Object.assign(values, { subType });
                        }
                        values = Object.assign(values, {
                          type: this.state.document.type
                        });
                        if (!this.state.fileNeeded) {
                          this.props.onSubmit(
                            values,
                            this.state.userUploadedFile
                          );
                        }
                      }}
                      validateOnBlur={false}
                      validateOnChange={false}
                      enableReinitialize={true}
                    >
                      {({
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        values
                      }) => {
                        return (
                          <Form noValidate onSubmit={handleSubmit}>
                            <FormGroup
                              formControlName="title"
                              type="text"
                              label={`${this.translation(
                                'enter'
                              )} ${this.translation('name')}`}
                              handleChange={handleChange}
                              touched={touched['title']}
                              error={errors['title']}
                              value={values['title']}
                              required
                            />
                            <Row>
                              <Col
                                md="6"
                                className={[
                                  this.state.document.type !== 'Video'
                                    ? 'd-none'
                                    : ''
                                ].join(' ')}
                              >
                                <FormGroup
                                  formControlName="url"
                                  type="text"
                                  label={this.translation('enterIframeSnippet')}
                                  handleChange={handleChange}
                                  touched={touched['url']}
                                  error={errors['url']}
                                  value={values['url']}
                                  required
                                />
                              </Col>
                              <Col className="mb-2">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="border rounded-0 input-height mt-0 mb-2 w-100 text-left"
                                    variant=""
                                    disabled={this.props.documentId}
                                  >
                                    {this.translation('type')}:{' '}
                                    {this.state.document.type}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="dropdown-menu-right px-3 rounded-0 shadow-sm">
                                    {!this.props.hideDocumentFieldOption && (
                                      <>
                                        {' '}
                                        <Dropdown.Item
                                          className="py-3"
                                          onClick={() =>
                                            this.setDocumentType('Document')
                                          }
                                        >
                                          {this.translation('document')}
                                        </Dropdown.Item>
                                        <Dropdown.Divider className="my-0" />
                                      </>
                                    )}
                                    <Dropdown.Item
                                      className="py-3"
                                      onClick={() =>
                                        this.setDocumentType('Video')
                                      }
                                    >
                                      {this.translation('video')}
                                    </Dropdown.Item>
                                    <Dropdown.Divider className="my-0" />
                                    <Dropdown.Item
                                      className="py-3"
                                      onClick={() =>
                                        this.setDocumentType('Image')
                                      }
                                    >
                                      {this.translation('image')}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip
                                  id="tooltip-description"
                                  className={
                                    !errors['description'] ? 'd-none' : ''
                                  }
                                >
                                  {errors['description']}
                                </Tooltip>
                              }
                            >
                              <Form.Group>
                                <Form.Control
                                  as="textarea"
                                  rows="3"
                                  name="description"
                                  placeholder={this.translation('description')}
                                  className={[
                                    errors['description'] ? 'is-invalid' : '',
                                    'rounded-0'
                                  ].join(' ')}
                                  onChange={handleChange}
                                  value={values['description']}
                                  required
                                />
                              </Form.Group>
                            </OverlayTrigger>
                            <Row className="justify-content-end">
                              <Col xs="auto">
                                {this.state.document.type === 'Video' && (
                                  <Button
                                    variant="info"
                                    size="lg"
                                    className="rounded-0 px-5 mr-3"
                                    disabled={!values['url'] || errors['url']}
                                    onClick={() => {
                                      const document = Object.assign(
                                        this.state.document,
                                        values
                                      );
                                      this.setState({
                                        showPreview: true,
                                        document
                                      });
                                    }}
                                  >
                                    {this.translation('preview')}
                                  </Button>
                                )}
                                <Button
                                  type="submit"
                                  variant="success"
                                  size="lg"
                                  className="rounded-0 px-5"
                                >
                                  {this.props.documentId
                                    ? this.translation('update')
                                    : this.translation('upload')}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
        )}
      </Modal>
    );
  }

  onExited() {
    this.setState({
      isLoading: true,
      document: {
        title: '',
        url: '',
        type: 'Document',
        description: ''
      },
      fileAdded: false,
      fileNeeded: false,
      userUploadedFile: undefined,
      placeHolderImage: null,
      oldImage: null
    });
  }
}

export default withTranslation()(AddDocumentModal);
