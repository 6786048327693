import React, { Component } from 'react';
import {
  Row,
  Col,
  ListGroup,
  Button,
  Form,
  Alert,
  Collapse
} from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { FormGroup } from '../../../_shared/components';
import i18next from 'i18next';
import { Translation } from 'react-i18next';

const DetailSchema = Yup.object().shape({
  name: Yup.string().required(),
  maxActivation: Yup.string()
    .required()
    .test(
      'is-number',
      <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
      value => !isNaN(value)
    )
});

class Details extends Component {
  /**Placeholder image ref */
  placeHolderImageRef = React.createRef();
  /**Component's state */
  state = {
    imageAdded: false,
    userImageFile: undefined,
    imageError: '',
    placeHolderImage: null,
    oldImage: null,
    name: '',
    maxActivation: 1,
    imageSrc: '',
    imageRemoved: false
  };
  fileInput = React.createRef();
  imagePreviewer = React.createRef();
  /**Set placeholder image value */
  componentDidMount() {
    this.initDetails();
  }

  initDetails() {
    const placeHolderImage = this.placeHolderImageRef.current;
    const { name, imageSrc, maxActivation } = this.props.details;
    this.setState({
      placeHolderImage,
      name,
      imageSrc,
      maxActivation
    });
  }

  render() {
    const { editDetails, bindSubmitForm } = this.props;
    return (
      <Row className="align-items-center">
        <Col className="mr-md-6">
          {!editDetails ? (
            <ListGroup variant="flush" className="pl-md-6">
              <ListGroup.Item>
                <Row>
                  <Col md="2">{i18next.t('name')}</Col>
                  <Col md="4">{this.state.name}</Col>
                  <Col md="2">{i18next.t('maxActivation')}</Col>
                  <Col md="4">{this.state.maxActivation}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          ) : (
            <Formik
              initialValues={{
                name: this.state.name,
                maxActivation: this.state.maxActivation
              }}
              validationSchema={DetailSchema}
              validate={values => {
                let errors = {};
                if (+values.maxActivation <= 0) {
                  errors.maxActivation = i18next.t('maxActivationOne');
                } else if (+values.maxActivation < +this.state.maxActivation) {
                  errors.maxActivation = i18next.t(
                    'maxActivationReduceWarning'
                  );
                }
                return errors;
              }}
              onSubmit={values => {
                this.completeSubmit(
                  Object.assign(values, { imageSrc: this.state.imageSrc })
                );
              }}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ errors, touched, handleChange, handleSubmit, values }) => {
                bindSubmitForm(handleSubmit);
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Collapse in={!!alert.message}>
                      <div>
                        {!!alert.message && (
                          <Alert variant={alert.type}>{alert.message}</Alert>
                        )}
                      </div>
                    </Collapse>
                    <ListGroup variant="flush" className="pl-md-6">
                      <ListGroup.Item>
                        <Row className="align-items-center">
                          <Col md="2">
                            <p className="mb-0">{i18next.t('name')}</p>
                          </Col>
                          <Col md="4">
                            <FormGroup
                              formControlName="name"
                              type="text"
                              label={i18next.t('name')}
                              handleChange={handleChange}
                              touched={touched['name']}
                              error={errors['name']}
                              value={values['name']}
                              classes="mb-0"
                            />
                          </Col>
                          <Col md="2">
                            <p className="mb-0">{i18next.t('maxActivation')}</p>
                          </Col>
                          <Col md="4">
                            <FormGroup
                              formControlName="maxActivation"
                              type="text"
                              label={i18next.t('twoDigitsLabel')}
                              handleChange={handleChange}
                              touched={touched['maxActivation']}
                              error={errors['maxActivation']}
                              value={values['maxActivation']}
                              classes="mb-0"
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Col>
      </Row>
    );
  }

  completeSubmit(values) {
    if (!this.state.imageAdded) {
      values = Object.assign(values, { imageSrc: '' });
    }
    const isDiffThanPrevious =
      (this.state.imageAdded && this.state.userImageFile) ||
      ['name', 'maxActivation', 'imageSrc']
        .map(val => values[val] === this.state[val])
        .indexOf(false) > -1;
    this.props.onSubmit(values, this.state.userImageFile, isDiffThanPrevious);
    return values;
  }
}

export default Details;
