import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Collapse,
  Alert
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import {
  FormGroup,
  RegistrationSuccessfulModal
} from '../../../_shared/components';
import { equalTo, errorParser } from '../../../_shared/helpers';
import { loaderActions } from '../../../_shared/redux/actions';
import { NavBar, Footer } from '../../components';

import UserPlaceholderSRC from '../../../../assets/images/user.png';
import { UserService, ImageService } from '../../../_shared/services';
import { ALLOWED_IMAGE, PHONE_NUMBER_REGEXP } from '../../../_shared/constants';
import { withTranslation, Translation } from 'react-i18next';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const RegistrationFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  lastName: Yup.string(),
  phoneNumber: Yup.string()
    .required()
    .matches(
      PHONE_NUMBER_REGEXP,
      <Translation>
        {t => t('formValidations.phoneNumberIsNotValid')}
      </Translation>
    ),
  email: Yup.string()
    .required()
    .email(),
  confirmEmail: Yup.string()
    .required()
    .email()
    .equalTo(
      Yup.ref('email'),
      <Translation>
        {t => <>{t('formValidations.emailsMustMatch')}</>}
      </Translation>
    ),
  password: Yup.string()
    .required()
    .min(8),
  confirmPassword: Yup.string()
    .required()
    .min(8)
    .equalTo(
      Yup.ref('password'),
      <Translation>
        {t => <>{t('formValidations.passwordsMustMatch')}</>}
      </Translation>
    )
});

export class Registration extends Component {
  translation = this.props.t;
  state = {
    registerAs: 'individual',
    showSuccessModal: false,
    imageAdded: false,
    placeHolderImage: undefined,
    userImageFile: undefined,
    imageError: ''
  };
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.imagePreviewer = React.createRef();
  }
  componentDidMount() {
    if (this.imagePreviewer.current) {
      const placeHolderImage = this.imagePreviewer.current.children[0];
      this.setState({ placeHolderImage });
    }
  }
  /**Handle user input files and preview image */
  handleFiles = files => {
    const file = files[0];
    if (/\.(jpe?g|png)$/i.test(file.name)) {
      if (file.size > ALLOWED_IMAGE.USER_PROFILE.SIZE) {
        const imageError = this.translation('fileSizeShouldBeLessThan', {
          size: '1 MB'
        });
        this.setState({ imageError });
        toastr.warning(this.translation('warning'), imageError);
      } else {
        const reader = new FileReader();
        reader.onload = readerOnLoadEvent => {
          var image = new Image();
          image.style.maxHeight = ALLOWED_IMAGE.USER_PROFILE.HEIGHT + 'px';
          image.classList.add('img-fluid');
          image.classList.add('rounded-circle');
          image.classList.add('border');
          image.classList.add('border-dark');
          image.classList.add('p-1');
          image.title = file.name;
          image.src = readerOnLoadEvent.target.result;
          image.onload = ImageOnloadEVent => {
            const target = ImageOnloadEVent.target;
            if (
              target.height >= ALLOWED_IMAGE.USER_PROFILE.HEIGHT &&
              target.width >= ALLOWED_IMAGE.USER_PROFILE.WIDTH
            ) {
              const oldImageElement = this.imagePreviewer.current.children[0];
              this.imagePreviewer.current.removeChild(oldImageElement);
              this.imagePreviewer.current.appendChild(image);
              this.setState({ imageAdded: true, userImageFile: file });
            } else {
              const imageError = this.translation('imageDimensionShouldBe', {
                context: this.translation('minimum'),
                dimension: '200px X 200px'
              });
              this.setState({ imageError });
              toastr.warning(this.translation('warning'), imageError);
            }
          };
        };
        reader.readAsDataURL(file);
      }
    } else {
      const imageError = this.translation('allowedFileTypes', {
        fileTypes: 'image(.jpg, .jpeg and .png)'
      });
      this.setState({ imageError });
      toastr.warning(this.translation('warning'), imageError);
    }
  };
  /**User removes the image */
  removeImage = () => {
    this.setState({ imageAdded: false, userImageFile: undefined });
    const oldImageElement = this.imagePreviewer.current.children[0];
    this.imagePreviewer.current.removeChild(oldImageElement);
    this.imagePreviewer.current.appendChild(this.state.placeHolderImage);
  };
  render() {
    return (
      <React.Fragment>
        <RegistrationSuccessfulModal
          show={this.state.showSuccessModal}
          goTo={this.props.history.push}
        />
        <NavBar onlyLogo history={this.props.history} />
        <Container fluid className="mt-5">
          <Card>
            <Card.Body className="p-5">
              <Card.Title className="mb-5">
                {this.translation('mobilizeRescueRegistration')}
              </Card.Title>
              <Row>
                <Col sm={12} md="auto" className="text-center mb-3">
                  <div ref={this.imagePreviewer}>
                    <img
                      alt="User"
                      className="border rounded-circle p-1 border-dark"
                      src={UserPlaceholderSRC}
                      height="200"
                    />
                  </div>
                  <Button
                    variant="link"
                    className="text-blue"
                    onClick={() => this.fileInput.current.click()}
                  >
                    {this.translation('changeImage')}
                  </Button>
                  {this.state.imageAdded ? (
                    <Button
                      variant="link"
                      className="text-blue"
                      onClick={() => this.removeImage()}
                    >
                      {this.translation('remove')}
                    </Button>
                  ) : (
                    ''
                  )}
                  <input
                    type="file"
                    ref={this.fileInput}
                    className="d-none"
                    onChange={inputChangeEvent =>
                      this.handleFiles(inputChangeEvent.target.files)
                    }
                  />
                </Col>
                <Col sm={12} md className="mb-3">
                  <Formik
                    initialValues={{
                      name: '',
                      lastName: '',
                      email: '',
                      confirmEmail: '',
                      password: '',
                      confirmPassword: '',
                      phoneNumber: ''
                    }}
                    validate={values => {
                      let errors = {};
                      if (values.confirmEmail) {
                        if (values.confirmEmail !== values['email']) {
                          errors.confirmEmail = this.translation(
                            'formValidations.mustMatch',
                            { what: this.translation('email', { count: 0 }) }
                          );
                        }
                      } else {
                        errors.confirmEmail = this.translation(
                          'formValidations.required'
                        );
                      }
                      if (values.confirmPassword) {
                        if (values.confirmPassword.length >= 8) {
                          if (values.confirmPassword !== values['password']) {
                            errors.confirmPassword = this.translation(
                              'formValidations.mustMatch',
                              {
                                what: this.translation('password', { count: 0 })
                              }
                            );
                          }
                        } else {
                          errors.confirmPassword = this.translation(
                            'formValidations.min8Chars'
                          );
                        }
                      } else {
                        errors['Confirm-Password'] = this.translation(
                          'formValidations.required'
                        );
                      }
                      return errors;
                    }}
                    validationSchema={RegistrationFormSchema}
                    onSubmit={values => {
                      this.props.startLoading();
                      UserService.register(
                        {
                          ...values,
                          userType: this.state.registerAs
                        },
                        this.state.userImageFile
                      ).subscribe({
                        next: response => {
                          //registration successful
                          // now upload user image
                          if (this.state.imageAdded) {
                            ImageService.uploadImage(
                              this.state.userImageFile,
                              response.data.url
                            ).subscribe({
                              next: () => {
                                // show success modal
                                this.setState({ showSuccessModal: true });
                                this.props.stopLoading();
                              },
                              error: errorResponse => {
                                //some error occurred
                                toastr.warning(
                                  this.translation('errorWhileUploadingImage'),
                                  errorResponse.data.message
                                );
                                this.props.stopLoading();
                                // show success modal
                                this.setState({ showSuccessModal: true });
                              }
                            });
                          } else {
                            // show success modal
                            this.setState({ showSuccessModal: true });
                            this.props.stopLoading();
                          }
                        },
                        error: errorResponse => {
                          //some error occurred
                          toastr.error(
                            this.translation('error'),
                            errorParser(errorResponse)
                          );
                          this.props.stopLoading();
                        }
                      });
                    }}
                    validateOnChange={false}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit
                    }) => {
                      return (
                        <Form
                          className="registration-form"
                          noValidate
                          onSubmit={handleSubmit}
                        >
                          <Collapse in={!!alert.message}>
                            <div>
                              {!!alert.message && (
                                <Alert variant={alert.type}>
                                  {alert.message}
                                </Alert>
                              )}
                            </div>
                          </Collapse>
                          <Form.Row>
                            <Col>
                              <FormGroup
                                formControlName="name"
                                type="text"
                                label={
                                  (this.state.registerAs === 'organization'
                                    ? this.translation('organization')
                                    : this.translation('first')) +
                                  ' ' +
                                  this.translation('name')
                                }
                                handleChange={handleChange}
                                touched={touched['name']}
                                error={errors['name']}
                                value={values.name}
                                required
                              />
                            </Col>
                            {this.state.registerAs === 'individual' && (
                              <Col className="pl-md-2">
                                <FormGroup
                                  formControlName="lastName"
                                  type="text"
                                  label={this.translation('lastName')}
                                  handleChange={handleChange}
                                  touched={touched['lastName']}
                                  error={errors['lastName']}
                                  value={values.lastName}
                                  required
                                />
                              </Col>
                            )}
                          </Form.Row>
                          <FormGroup
                            formControlName="phoneNumber"
                            type="phone"
                            label={this.translation('phoneNumber')}
                            handleChange={handleChange}
                            touched={touched['phoneNumber']}
                            error={errors['phoneNumber']}
                            required
                          />
                          <FormGroup
                            formControlName="email"
                            type="email"
                            label={this.translation('email')}
                            handleChange={handleChange}
                            touched={touched['email']}
                            error={errors['email']}
                            required
                          />
                          <FormGroup
                            formControlName="confirmEmail"
                            type="email"
                            label={this.translation('confirmEmail')}
                            handleChange={handleChange}
                            touched={touched['confirmEmail']}
                            error={errors['confirmEmail']}
                            required
                          />
                          <FormGroup
                            formControlName="password"
                            type="password"
                            label={this.translation('password')}
                            handleChange={handleChange}
                            touched={touched['password']}
                            error={errors['password']}
                            required
                          />
                          <FormGroup
                            formControlName="confirmPassword"
                            type="password"
                            label={this.translation('confirmPassword')}
                            handleChange={handleChange}
                            touched={touched['confirmPassword']}
                            error={errors['confirmPassword']}
                            required
                          />
                          <Button
                            type="submit"
                            variant="success"
                            size="lg"
                            className="rounded-0 float-right px-5"
                          >
                            {this.translation('submit')}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(Registration)
);
