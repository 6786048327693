import { Subscriber } from 'rxjs';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import { randomString } from './methods';
import { AuthenticationService } from '../services';
import i18next from 'i18next';
// adds cancel prototype method
axiosCancel(axios);

export default class AxiosSubscriber extends Subscriber {
  constructor(
    observer,
    urlWithQueryParams,
    requestType,
    axiosRequestConfig,
    requestBody
  ) {
    super(observer);

    // create sample request id
    this.requestId = randomString() + Math.random() + '-xhr-id';

    // XHR complete pointer
    this.completed = false;

    // make axios request on subscription
    let axiosPromise = null;
    switch (requestType) {
      case 'delete':
        axiosPromise = axios.delete(urlWithQueryParams, {
          requestId: this.requestId,
          ...axiosRequestConfig
        });
        break;
      case 'get':
        axiosPromise = axios.get(urlWithQueryParams, {
          requestId: this.requestId,
          ...axiosRequestConfig
        });
        break;
      case 'patch':
        axiosPromise = axios.patch(urlWithQueryParams, requestBody, {
          requestId: this.requestId,
          ...axiosRequestConfig
        });
        break;
      case 'post':
        axiosPromise = axios.post(urlWithQueryParams, requestBody, {
          requestId: this.requestId,
          ...axiosRequestConfig
        });
        break;
      case 'put':
        axiosPromise = axios.put(urlWithQueryParams, requestBody, {
          requestId: this.requestId,
          ...axiosRequestConfig
        });
        break;
      default:
        break;
    }
    if (axiosPromise) {
      axiosPromise
        .then(response => {
          observer.next(this._handleResponse(response));
          this.completed = true;
          observer.complete();
        })
        .catch(error => {
          this.completed = true;
          const status = error.response
            ? error.response.status
              ? error.response.status
              : undefined
            : undefined;
          observer.error(error.response || error);
          if (status === 401) {
            // auto logout if 401 response returned from api
            AuthenticationService.logout().then(() => {
              // eslint-disable-next-line no-restricted-globals
              window.location.reload();
            });
          }
        });
    } else {
      observer.error(i18next.t('invalidRequest'));
    }
  }

  unsubscribe() {
    super.unsubscribe();

    // cancel XHR
    if (this.completed === false) {
      axios.cancel(this.requestId);
      this.completed = true;
    }
  }

  _handleResponse(response) {
    return response;
  }

  _getErrorMessage(status, defaultMessage) {
    switch (status) {
      case 400:
        return i18next.t('400Error');
      case 404:
        return i18next.t('404Error');
      default:
        return defaultMessage;
    }
  }
}
