import React from 'react';

import { headerFormatterWithSort } from '../../../../_shared/helpers';
import i18next from 'i18next';
export const usersTableConstants = {
  usersColumns: goTo => [
    {
      dataField: 'user.fullName',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('Username')),
      sort: true
    },
    {
      dataField: 'user.email',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('email')),
      sort: true
    },
    {
      dataField: 'licenseCode',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('licenseCode')),
      sort: true
    },
    {
      dataField: 'serialNumber',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('serialNumber')),
      sort: true
    },
    {
      dataField: 'customer.name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('customer')),
      sort: true
    }
  ]
};
