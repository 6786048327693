import React from 'react';
import { Icon } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import { headerFormatterWithSort } from '../../../_shared/helpers';
import i18next from 'i18next';

export const customersTableConstants = {
  customerColumns: (presentDeleteConfirm, goTo) => [
    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('firstName')),
      sort: true,
      formatter: (cell, row) => {
        return row.name;
      },
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },
    {
      dataField: 'lastName',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('lastName')),
      sort: true,
      formatter: (cell, row) => {
        return row.lastName ? row.lastName : 'N/A';
      },
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },
    {
      dataField: 'email',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('email')),
      sort: true,
      headerStyle: { width: '30%' },
      style: { width: '30%' }
    },
    {
      dataField: 'actions',
      text: '',
      formatter: (cell, row) => (
        <>
          <Icon
            iconName="right"
            size="29"
            fill={ColorConstants.DEFAULT_FONT}
            classes="mx-2 cursor-pointer"
            onClick={() => goTo('/admin/users/details/' + row._id)}
          />
        </>
      ),
      classes: 'py-0 text-right'
    }
  ]
};
