// @flow
import * as React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toastr } from 'react-redux-toastr';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

import { FormGroup, Icon } from '../../../_shared/components';
import { tableConstants } from '../../../_shared/constants';
import { itemService } from '../../../_shared/services';
import { KitItem } from '../../../models';

import { expiringTableConstants } from './table.constants';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  show: boolean,
  onHide(): void,
  addKitItems(kitItems: KitItem[]): void,
  existingItems: KitItem[],
  t: i18next.TFunction
};

type State = {
  isTableLoading: boolean,
  itemsArrived: boolean,
  items: KitItem[],
  itemsToBeAdded: KitItem[],
  columns: any,
  options: any,
  currentIndex: number,
  sizePerPage: number,
  sortField: string,
  sortOrder: 'asc' | 'desc',
  page: number,
  searchText: string
};

class AddItemModal extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Set item when clicked on add */
  setItem = (itemToBeSet: KitItem) => {
    let newItems = [...this.props.existingItems];
    const itemAlreadyExists = newItems.findIndex(
      (item: KitItem) => item._id === itemToBeSet._id
    );
    if (itemAlreadyExists > -1) {
      newItems = newItems.map((item: KitItem, index: number) => {
        if (index === itemAlreadyExists) {
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        return item;
      });
    } else {
      newItems.push({ ...itemToBeSet, item: itemToBeSet._id, quantity: 1 });
    }
    this.setState({ itemsToBeAdded: newItems }, () => {
      this.addKitItems();
    });
  };
  /**Component state */
  state = {
    isTableLoading: true,
    itemsArrived: false,
    items: [],
    itemsToBeAdded: [],
    columns: expiringTableConstants.newItemColumns(this.setItem),
    options: {},
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'name',
    sortOrder: 'asc',
    page: 1,
    searchText: ''
  };
  /**Free up resources when exiting */
  onExited() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
    this.setState({
      currentIndex: 0,
      sizePerPage: 10,
      sortField: 'name',
      sortOrder: 'asc',
      page: 1,
      searchText: '',
      isTableLoading: true,
      itemsArrived: false,
      items: [],
      itemsToBeAdded: []
    });
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$ = fromEvent(
      document.getElementById('kit-items-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            searchText: inputEvent.target.value,
            isTableLoading: true
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type: any,
    {
      sortField,
      sortOrder,
      tableData,
      page,
      sizePerPage
    }: {
      sortField: string,
      sortOrder: 'asc' | 'desc',
      tableData: any,
      page: number,
      sizePerPage: number
    }
  ) => {
    const currentIndex: number = (page - 1) * sizePerPage;
    this.setState({ page, sizePerPage, sortField, sortOrder }, () => {
      this.getItems(currentIndex, sizePerPage, sortField, sortOrder);
    });
  };
  /**Observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    sortField: string,
    sortOrder: string,
    searchText: string
  ) => {
    return itemService.getKitItems(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    );
  };
  /**Subscriber-response handler */
  handelResponse = () => ({
    next: (response: { data: { kitItems: KitItem[], count: number } }) => {
      if (this.cleanup) {
        const { sizePerPage, page } = this.state;
        let { kitItems: items, count } = response.data;
        const options = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          items,
          isTableLoading: false,
          itemsArrived: true
        });
      }
    },
    error: (errorResponse: { data: { message: string } }) => {
      if (this.cleanup) {
        this.setState({ isTableLoading: false, itemsArrived: true });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Get expiring items */
  getItems = (
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    sortField: string = this.state.sortField,
    sortOrder: 'asc' | 'desc' = this.state.sortOrder,
    searchText: string = this.state.searchText
  ) => {
    this.setState({ isTableLoading: true });
    const getItems$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      getItems$.unsubscribe();
    };
  };
  /**Add Kit Items */
  addKitItems = () => {
    this.props.addKitItems(this.state.itemsToBeAdded);
    this.props.onHide();
  };
  render() {
    const translation = this.props.t;
    return (
      <Modal
        onEntered={() => {
          this.onSearchChange();
        }}
        show={this.props.show}
        onHide={this.props.onHide}
        onExited={() => this.onExited()}
        size="lg"
        aria-labelledby="add-item-in-kit-type-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-truncate w-100">
            <span className="float-left text-truncate mw-97">
              {this.translation('selectKitItems')}
            </span>
            <Icon
              iconName="cancel"
              classes="float-right cursor-pointer mt-2"
              onClick={this.props.onHide}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-between align-items-center">
            <Col sm={8} md={6} className="mb-3">
              <FormGroup
                type="text"
                label={this.translation('searchByItemNameOrDescription')}
                title={this.translation('searchByItemNameOrDescription')}
                icon="search"
                iconPosition="right"
                formControlId="kit-items-search-input"
                classes="mb-2"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BootstrapTable
                remote
                classes="with-gap"
                bordered={false}
                bootstrap4
                keyField="_id"
                data={this.state.items}
                columns={this.state.columns}
                pagination={
                  this.state.items.length
                    ? paginationFactory(this.state.options)
                    : null
                }
                wrapperClasses="table-responsive-sm"
                noDataIndication={
                  this.state.isTableLoading
                    ? translation('pleaseWait')
                    : translation('noData')
                }
                rowClasses="colored-background"
                loading={this.state.isTableLoading}
                overlay={tableConstants.overlay()}
                onTableChange={this.onTableChange}
                defaultSorted={[
                  {
                    dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
                    order: 'asc' // desc or asc
                  }
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(AddItemModal);
