import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import { FormGroup } from '../../../_shared/components';
import ZollLogo from '../../../../assets/images/zoll_logo.png';
import CompanyLogoSRC from '../../../../assets/images/header-logo.png';
import { equalTo, errorParser } from '../../../_shared/helpers';
import { UserService } from '../../../_shared/services/user.service';
import { loaderActions } from '../../../_shared/redux/actions';
import { withTranslation, Translation } from 'react-i18next';
import { PublicFooter } from '../../components';

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8)
    .required(),
  confirmPassword: Yup.string()
    .min(8)
    .required()
    .equalTo(
      Yup.ref('newPassword'),
      <Translation>
        {t => <>{t('formValidations.passwordsMustMatch')}</>}
      </Translation>
    )
});

export class ResetPassword extends Component {
  translation = this.props.t;
  state = {
    setNewPassword: false
  };
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.token = params.get('token');
    let setNewPassword = Boolean(params.get('setnewpassword'));
    this.setState({
      setNewPassword
    });
  }
  render() {
    return (
      <Container fluid className="pt-5">
        <Row className="justify-content-center w-100">
          <Col xs="12" md="8">
            <div className="my-md-5">
              <div className="px-sm-0 px-md-5 px-0 my-md-5">
                <Button
                  variant="link"
                  onClick={() => this.props.history.push('/login')}
                >
                  <img
                    src={CompanyLogoSRC}
                    alt="Mobilize Rescue System"
                    className="mb-4 cursor-pointer"
                  />
                  <img
                    src={ZollLogo}
                    height="70"
                    alt="Mobilize Rescue System"
                    className="mb-4 cursor-pointer"
                  />
                </Button>
                <div className="pl-2">
                  <h2 className="font-weight-bold">
                    {this.state.setNewPassword
                      ? this.translation('setNewPassword')
                      : this.translation('resetYourPassword')}
                  </h2>
                  <h3 className="mb-4">
                    {this.translation('pleaseUseNewPasswordForSignIn')}
                  </h3>
                  <Formik
                    initialValues={{
                      newPassword: '',
                      confirmPassword: ''
                    }}
                    validationSchema={ResetPasswordSchema}
                    onSubmit={values => {
                      this.props.startLoading();
                      UserService.resetPassword({
                        token: this.token,
                        password: values.newPassword
                      }).subscribe({
                        next: response => {
                          // show success modal
                          this.setState({ showSuccessModal: true });
                          toastr.success(
                            this.translation('success'),
                            this.state.setNewPassword
                              ? this.translation('passwordCreated')
                              : this.translation('passwordChanged')
                          );
                          this.props.stopLoading();
                          this.props.history.push('/password-changed-success');
                        },
                        error: errorResponse => {
                          this.props.stopLoading();
                          toastr.error(
                            this.translation('error'),
                            errorParser(errorResponse)
                          );
                        }
                      });
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      values
                    }) => {
                      return (
                        <Form noValidate onSubmit={handleSubmit}>
                          <FormGroup
                            formControlName="newPassword"
                            type="password"
                            label={
                              this.translation('new') +
                              ' ' +
                              this.translation('password')
                            }
                            handleChange={handleChange}
                            touched={touched['newPassword']}
                            error={errors['newPassword']}
                            value={values.newPassword}
                            required
                          />
                          <FormGroup
                            formControlName="confirmPassword"
                            type="password"
                            label={
                              this.translation('reEnter') +
                              ' ' +
                              this.translation('new') +
                              ' ' +
                              this.translation('password')
                            }
                            handleChange={handleChange}
                            touched={touched['confirmPassword']}
                            error={errors['confirmPassword']}
                            value={values.confirmPassword}
                            required
                          />
                          <Button
                            type="submit"
                            variant="success"
                            size="lg"
                            className="rounded-0 px-5 float-left"
                          >
                            {this.state.setNewPassword
                              ? this.translation('create') +
                                ' ' +
                                this.translation('password')
                              : this.translation('change') +
                                ' ' +
                                this.translation('password')}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <PublicFooter />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(ResetPassword)
);
