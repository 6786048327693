import React from 'react';
import * as Yup from 'yup';
import { TIME_OUT } from '../constants';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

export function toCapitalize(str) {
  let finalStr = '';
  if (str) {
    str.split(' ').forEach((str, index) => {
      finalStr +=
        (index > 0 ? ' ' : '') +
        str.charAt(0).toLocaleUpperCase() +
        str.slice(1).toLocaleLowerCase();
    });
  }
  return finalStr;
}
export function tick_then(fn, millis = 0) {
  setTimeout(fn, millis);
}

/**Convert any string to camelCase
 * @link https://stackoverflow.com/a/2970667/3339907
 */
export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function timeout(promise, ms = TIME_OUT) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error(i18next.t('requestTimedOutError')));
    }, ms);
    promise.then(resolve, reject);
  });
}

/**Random string for xhr-ids
 * @link https://stackoverflow.com/a/1349426/3339907
 */
export function randomString(length = 5) {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

/**Formik's function to check whether two fields has same value */
export function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref);
    }
  });
}

/**Get desired formatted date */
export function getFormattedDate(date, indicatorDays = 0, format = undefined) {
  const validDate = date ? (
    format === 'YYYY/MM' ? (
      new Date(date).toLocaleString(getBrowserLanguage(), {
        year: 'numeric'
      }) +
      '/' +
      new Date(date).toLocaleString(getBrowserLanguage(), {
        month: '2-digit'
      })
    ) : (
      new Date(date).toLocaleString(getBrowserLanguage(), {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    )
  ) : (
    <span className="text-primary">
      <Translation>{t => <>{t('n/a')}</>}</Translation>
    </span>
  );
  if (date && indicatorDays) {
    let date1 = new Date();
    let date2 = new Date(date);

    // To calculate the time difference of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days <= indicatorDays ? (
      <span
        className="indicate-date"
        title={i18next.t('expiringIn', { days: indicatorDays })}
      >
        {validDate}
      </span>
    ) : (
      validDate
    );
  }
  return validDate;
}

/**Get desired formatted time */
export function getFormattedTime(date) {
  return date ? (
    new Date(date).toLocaleString(getBrowserLanguage(), {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })
  ) : (
    <span className="text-primary">
      <Translation>{t => <>{t('n/a')}</>}</Translation>
    </span>
  );
}

/**Console logs */
export const logger = {
  info: (...args) => {
    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'local'
    ) {
      // eslint-disable-next-line no-console
      console.info(...args);
    }
  },
  error: (...args) => {
    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'local'
    ) {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  },
  log: (...args) => {
    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'local'
    ) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'local'
    ) {
      // eslint-disable-next-line no-console
      console.warn(...args);
    }
  },
  debug: (...args) => {
    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'local'
    ) {
      // eslint-disable-next-line no-console
      console.debug(...args);
    }
  }
};

/**Parse error response */
export function errorParser(errorResponse) {
  return errorResponse.data ? (
    errorResponse.data.message ? (
      errorResponse.data.message
    ) : (
      <Translation>{t => <>{t('pleaseTryAfterSometime')}</>}</Translation>
    )
  ) : (
    errorResponse.toString() || (
      <Translation>{t => <>{t('pleaseTryAfterSometime')}</>}</Translation>
    )
  );
}

/**This function will let us know whether this is development environment or not */
export function isDevelopmentEnvironment() {
  return (
    process.env.REACT_APP_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'local'
  );
}

/**Get browser's language */
export function getBrowserLanguage() {
  return navigator.language || navigator.userLanguage;
}
