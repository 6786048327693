import {
  authHeader,
  AxiosSubscriber,
  authHeaderWithJSON,
  headerJSON
} from '../helpers';
import { API_ENDPOINTS } from '../constants';
import { Observable } from 'rxjs';
import { stringify } from 'query-string';

export const UserService = {
  addUser,
  changePassword,
  register,
  get,
  getById,
  getCurrentUser,
  delete: _delete,
  updateCurrentUser,
  updateUserById,
  updateLicenseById,
  resetPassword,
  activate,
  lastClickOnAlert,
  deactivate,
  export: _export,
  getDeleteRequestData
};

/**@function addUser
 * Add user from super admin
 * @param {object} user - User to be added
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function addUser(user) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.POST_USERS
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      user
    );
  });
}

/**@function changePassword
 * Change password of current user
 * @param {{oldPassword : string, newPassword:string}} passwords - Old and new passwords
 * @param {string} newPassword - New password
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function changePassword(passwords) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.CURRENT_USER.PATCH_CHANGE_PASSWORD
      }`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      passwords
    );
  });
}

/**@function resetPassword
 * Reset user password
 * @param {{token : string, password:string}} passwordObj - new passwords with token
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function resetPassword(passwordObj) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.AUTH.PATCH_RESET_PASSWORD
      }`,
      axiosOptions = { headers: headerJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      passwordObj
    );
  });
}

function get(
  skip = 0,
  limit = 10,
  sort = 'name',
  order = 'asc',
  search = '',
  userType = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        sort,
        order,
        search,
        userType
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.GET_USERS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

function getDeleteRequestData(
  skip = 0,
  limit = 10,
  sort = 'name',
  order = 'asc',
  search = '',
  userType = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        sort,
        order,
        search,
        userType
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.GET_DELETE_REQUEST_USERS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getById
 * 'Get data from server'
 * @param {string} userId - User ID of which details to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getById(userId) {
  return new Observable(observer => {
    const requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.GET_USERS
      }/${userId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getCurrentUser
 * Get current user account details
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getCurrentUser() {
  return new Observable(observer => {
    const requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.CURRENT_USER.GET_CURRENT_USER
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function register
/*  Post user data for registration
* @param {Object} user - User object containing details
* @param {File} file - Image file to be uploaded
* @returns {Observable} Observable to which we can subscribe to Post user data for registration.
* When subscribed, it will return link to which user image can be uploaded.
*/
function register(user, file) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.SITE_ADMIN.POST_REGISTER
      }`,
      axiosOptions = { headers: { 'Content-Type': 'application/json' } };
    const requestBody = file
      ? { ...user, imageName: file.name, imageType: file.type }
      : user;
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      requestBody
    );
  });
}

/**@function updateCurrentUser
 * Update current user
 * @param {UserAccountModel} user - User account details
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function updateCurrentUser(user) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.CURRENT_USER.PATCH_CURRENT_USER
      }`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      user
    );
  });
}

/**@function lastClickOnAlert
 * Update a last click of user on notification.
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function lastClickOnAlert() {
  return new Observable(observer => {
    const requestType = 'patch',
      url = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.CURRENT_USER.PATCH_LAST_CLICK
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(observer, url, requestType, axiosOptions);
  });
}

/**@function updateCurrentUser
 * Update current user
 * @param {string} userId - User ID
 * @param {UserAccountModel} user - User account details
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function updateUserById(userId, user) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.PATCH_USERS
      }/${userId}`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      user
    );
  });
}

/**@function updateCurrentUser
 * Update current user
 * @param {string} userId - User ID
 * @param {UserLicenseDetailsModel} license - License details
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function updateLicenseById(userId, license) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.PATCH_USERS
      }/${userId}/license`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      license
    );
  });
}

// prefixed function name with underscore because delete is a reserved word in javascript
/**@function _delete
 * Delete user
 * @param {string} userId - User's ID, which will be deleted
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _delete(userId, email) {
  const QUERY_PARAMS = `?${stringify({
    adminEmail: email
  })}`;
  return new Observable(observer => {
    const requestType = 'delete',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.DELETE_USERS
      }/${userId}${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function activate
 * Activate customer
 * @param {string} userId - User's ID, which will be activated
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function activate(userId) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.PATCH_USERS
      }/${userId}${API_ENDPOINTS.USERS.PATCH_ACTIVATE}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function deactivate
 * Activate customer
 * @param {string} userId - User's ID, which will be deactivated
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function deactivate(userId) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.PATCH_USERS
      }/${userId}${API_ENDPOINTS.USERS.PATCH_DEACTIVATE}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
/**@function export
 * export products for super-admin
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='serialNumber'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @param {string} [search=''] - Maximum Numbers of records to be fetched
 * @param {object} [filter=null] - Maximum Numbers of records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _export(
  skip = 0,
  limit = 10,
  sort = 'name',
  order = 'asc',
  search = '',
  userType = 'individual',
  filter = null
) {
  return new Observable(observer => {
    filter = Object.assign(
      {
        skip,
        limit,
        sort,
        order,
        search,
        userType,
        export: true,
        offset: new Date().getTimezoneOffset()
      },
      filter
    );
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify(filter)}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.GET_USERS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
