import React from 'react';

export const headerFormatterWithSort = (
  column,
  colIndex,
  components,
  mainComponent
) => (
  <>
    {mainComponent}
    {components.sortElement}
  </>
);
