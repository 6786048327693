export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('mobilize')).user;

  if (user && user.token) {
    return { token: user.token };
  } else {
    return {};
  }
}

export function authHeaderWithJSON() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('mobilize')).user;

  if (user && user.token) {
    return { token: user.token, 'Content-Type': 'application/json' };
  } else {
    return { 'Content-Type': 'application/json' };
  }
}

export function headerJSON() {
  return { 'Content-Type': 'application/json' };
}
