// @flow
import * as React from 'react';
import { Container, Card, ListGroup, Row, Col, Form } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { ColorConstants } from '../../../../_shared/constants';
import { UserService } from '../../../../_shared/services';
import {
  loaderActions,
  authenticationActions
} from '../../../../_shared/redux/actions';
import { UserAccountModel } from '../../../../models';
import { errorParser } from '../../../../_shared/helpers';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

type State = {
  account: UserAccountModel,
  isLoading: boolean
};

type Props = {
  userId: string,
  startLoading(): void,
  stopLoading(): void,
  goTo(path: string): void,
  isDeleted(isDeleted: boolean): void,
  t: i18next.TFunction
};

class BasicDetails extends React.Component<Props, State> {
  translation: i18next.TFunction = this.props.t;
  /**Resource cleaner */
  cleanup: any;
  /**Form submit handlers */
  /**Component's state */
  state = {
    account: {
      _id: '',
      email: '',
      name: '',
      lastName: '',
      userType: 'individual',
      isVerified: false,
      type: 'site',
      isDeleted: false,
      isPasswordReset: false,
      imageSrc: '',
      phoneNumber: '',
      primaryContact: { name: '', lastName: '', phoneNumber: '', email: '' },
      secondaryContact: { name: '', lastName: '', phoneNumber: '', email: '' },
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        zipCode: ''
      }
    },
    isLoading: true
  };
  /**Resource cleaner */
  cleanup = [];
  /**Perform network calls, once component is mounted */
  componentDidMount() {
    this.getAccountDetails();
  }
  /**Free up resources on unmount */
  componentWillUnmount() {
    if (this.cleanup.length) this.cleanup.forEach(obs => obs.unsubscribe());
    this.cleanup = [];
  }
  /**Redirects */
  goTo = (path: string) => this.props.goTo(path);
  /**Call api to get current user's account details */
  getAccountDetails() {
    this.setState({ isLoading: true });
    const getAccountDetails$ = UserService.getById(this.props.userId).subscribe(
      {
        next: (response: {
          data: {
            user: UserAccountModel
          }
        }) => {
          if (this.cleanup) {
            const user = response.data.user;
            const account = Object.assign(this.state.account, user);
            this.setState({ account, isLoading: false });
            this.props.isDeleted(user.isDeleted);
          }
        },
        error: (errorResponse: { data: { message: string } }) => {
          if (this.cleanup) {
            this.setState({ isLoading: false });
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      }
    );
    this.cleanup = () => {
      getAccountDetails$.unsubscribe();
    };
  }

  render() {
    const { name, lastName, email, userType, phoneNumber } = this.state.account;
    const translation = this.props.t;
    return (
      <Container fluid>
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <>
            <Card className="mb-4">
              <Card.Body>
                <h3 className="mb-4">
                  {this.translation('user')}:{' '}
                  {this.state.account.name + ' ' + this.state.account.lastName}
                </h3>
                <Row className="justify-content-between">
                  <Col>
                    <h4 className="mb-4">{this.translation('details')}</h4>
                  </Col>
                </Row>
                <ListGroup variant="flush" className="pl-md-4">
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        {userType === 'organization' ? 'Customer' : 'First'}{' '}
                        {translation('name')}
                      </Col>
                      <Col>{name}</Col>
                    </Row>
                  </ListGroup.Item>
                  {userType !== 'organization' && (
                    <ListGroup.Item>
                      <Row>
                        <Col>{translation('lastName')}</Col>
                        <Col>{lastName}</Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  {email ? (
                    <ListGroup.Item>
                      <Row>
                        <Col>{translation('email')}</Col>
                        <Col>{email}</Col>
                      </Row>
                    </ListGroup.Item>
                  ) : (
                    ''
                  )}
                  <ListGroup.Item>
                    <Row>
                      <Col>{translation('phoneNumber')}</Col>
                      <Col>
                        {phoneNumber ? phoneNumber : translation('n/a')}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop()),
  updateUser: user => dispatch(authenticationActions.update(user))
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(BasicDetails)
);
