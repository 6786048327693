import { API_ENDPOINTS } from '../constants';
import { Observable } from 'rxjs';
import { authHeaderWithJSON, AxiosSubscriber, authHeader } from '../helpers';
import { stringify } from 'query-string';

export const NoteService = {
  create,
  delete: _delete,
  getByUserId,
  update
};

/**@function create
 * Create note
 * @param {string} note - Note text to be added
 * @param {string} user - User ID
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function create(note, user) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.NOTES.POST_NOTE
      }`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { note, user }
    );
  });
}

/**@function _delete
 * Delete note
 * @param {string} noteId - Note ID, which will be deleted
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _delete(noteId) {
  return new Observable(observer => {
    const requestType = 'delete',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.NOTES.DELETE_NOTE
      }/${noteId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getByUserId
 * Gte note by user-id from server
 * @param {string} user - User ID, which note will be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getByUserId(user) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ user })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.NOTES.GET.BY_USER_ID
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function update
 * Update note to server
 * @param {string} noteId - Note Id
 * @param {string} note - Note text
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function update(noteId, note) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.NOTES.POST_NOTE
      }/${noteId}`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { note }
    );
  });
}
