import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { ColorConstants } from '../constants';
import i18next from 'i18next';

class Loader extends Component {
  render() {
    return (
      <Modal {...this.props} centered>
        <Modal.Body>
          <div className="text-center">
            <p>{i18next.t('pleaseWait')}...</p>
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Loader;
