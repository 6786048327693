import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  ListGroup,
  Collapse,
  Form,
  Alert
} from 'react-bootstrap';
import { Formik } from 'formik';
import { toastr } from 'react-redux-toastr';

import {
  FormGroup,
  ImageContainer,
  PasswordChangedSuccessfulModal
} from '../../../_shared/components';
import { ALLOWED_IMAGE } from '../../../_shared/constants';

import ChangePasswordModal from './ChangePasswordModal';
import { DetailSchema } from './form.schema.js';

import UserPlaceholderImage from '../../../../assets/images/user.png';
import { withTranslation } from 'react-i18next';

class UserAccountDetails extends Component {
  translation = this.props.t;
  state = {
    changePassword: false,
    imageAdded: false,
    placeHolderImage: undefined,
    userImageFile: undefined,
    imageError: '',
    imageUploading: false,
    oldImage: null,
    imageRemoved: false,
    imageSrc: '',
    showSuccessModal: false
  };
  constructor(props) {
    super(props);
    this.dbKeys = {
      'First-Name': 'name',
      'Last-Name': 'lastName',
      Email: 'email',
      Type: 'userType'
    };
    this.fileInput = React.createRef();
    this.imagePreviewer = React.createRef();
    this.placeHolderImageRef = React.createRef();
    this.cleanup = null;
  }
  /**Set placeholder image value */
  componentDidMount() {
    this.initDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      this.state.imageRemoved &&
      prevProps.editDetails !== this.props.editDetails &&
      !this.props.editDetails
    ) {
      this.undoRemoveImage();
    }
  }
  /**Handle user input files and preview image */
  handleFiles = files => {
    const file = files[0];
    if (/\.(jpe?g|png)$/i.test(file.name)) {
      if (file.size > ALLOWED_IMAGE.USER_PROFILE.SIZE) {
        const imageError = this.translation('fileSizeShouldBeLessThan', {
          size: '1 MB'
        });
        this.setState({ imageError });
        toastr.warning(this.translation('warning'), imageError);
      } else {
        const reader = new FileReader();
        reader.onload = readerOnloadEvent => {
          var image = new Image();
          image.style.height = '154px';
          image.style.width = '154px';
          image.classList.add('img-fluid');
          image.classList.add('rounded-circle');
          image.classList.add('p-1');
          image.classList.add('border');
          image.classList.add('border-black');
          image.classList.add('object-fit-cover');
          image.title = file.name;
          image.src = readerOnloadEvent.target.result;
          image.onload = imageOnloadEVent => {
            const target = imageOnloadEVent.target;
            if (
              target.height >= ALLOWED_IMAGE.USER_PROFILE.HEIGHT &&
              target.width >= ALLOWED_IMAGE.USER_PROFILE.WIDTH
            ) {
              const imageHolder = this.imagePreviewer.current.querySelector(
                '#imageHolder'
              );
              const oldImageElement = imageHolder.children[0];
              imageHolder.removeChild(oldImageElement);
              imageHolder.appendChild(image);
              this.setState({
                imageAdded: true,
                userImageFile: file,
                imageRemoved: false
              });
            } else {
              const imageError = this.translation('imageDimensionShouldBe', {
                context: 'minimum',
                dimension: '128px X 128px'
              });
              this.setState({ imageError });
              toastr.warning(this.translation('warning'), imageError);
            }
          };
        };
        reader.readAsDataURL(file);
      }
    } else {
      const imageError = this.translation('allowedFileTypes', {
        fileTypes: 'image(.jpg, .jpeg, .png)'
      });
      this.setState({ imageError });
      toastr.warning(this.translation('warning'), imageError);
    }
  };
  /**User removes the image */
  removeImage = () => {
    this.setState({
      imageAdded: false,
      userImageFile: undefined,
      imageRemoved: true
    });
    const imageHolder = this.imagePreviewer.current.querySelector(
      '#imageHolder'
    );
    const oldImageElement = imageHolder.children[0];

    // below will be useful when user clicks on cancel
    this.setState({ oldImageSrcElement: oldImageElement });

    imageHolder.removeChild(oldImageElement);
    if (this.state.oldImage) {
      imageHolder.appendChild(this.state.oldImage);
    } else {
      imageHolder.appendChild(this.state.placeHolderImage);
    }
  };
  undoRemoveImage = () => {
    this.setState({
      imageAdded: true,
      imageRemoved: false
    });
    const imageHolder = this.imagePreviewer.current.querySelector(
      '#imageHolder'
    );
    const oldImageElement = imageHolder.children[0];
    imageHolder.removeChild(oldImageElement);
    imageHolder.appendChild(this.state.oldImageSrcElement);
  };
  initDetails() {
    const imageHolder = this.imagePreviewer.current.querySelector(
      '#imageHolder'
    );
    if (!this.props.details.imageSrc) {
      const oldImage = imageHolder.children[0];
      this.setState({ oldImage });
    } else {
      this.setState({ imageAdded: true });
    }
    const placeHolderImage = this.placeHolderImageRef.current;
    const { imageSrc } = this.props.details;
    this.setState({
      placeHolderImage,
      imageSrc
    });
  }
  /**Modal close handler */
  modalClose = showSuccessModal =>
    this.setState({ changePassword: false, showSuccessModal });
  render() {
    const { details, editDetails, bindSubmitForm } = this.props;
    const { name, lastName, email } = details;
    const formData = {
      'First-Name': { text: this.translation('firstName'), value: name },
      'Last-Name': { text: this.translation('lastName'), value: lastName },
      Email: { text: this.translation('email'), value: email }
    };
    return (
      <div className="user-account-details">
        <ChangePasswordModal
          show={this.state.changePassword}
          onHide={this.modalClose}
          goTo={path => this.props.goTo(path)}
          size="lg"
        />
        <PasswordChangedSuccessfulModal
          show={this.state.showSuccessModal}
          logout={this.props.logout}
        />
        <Row className="align-items-center">
          <Col sm="12" md="3" className="text-center ml-md-3 h-100 px-0">
            <div className="d-none">
              <div ref={this.placeHolderImageRef}>
                <img
                  alt={this.state.name}
                  src={UserPlaceholderImage}
                  height="154"
                  className="rounded-circle p-1 border border-black"
                />
              </div>
            </div>
            <div
              ref={this.imagePreviewer}
              className={[
                'upload-box d-flex align-items-center justify-content-center flex-column p-2 image-previewer',
                this.state.dragEnter
                  ? 'border-dashed border-thick bg-primary-rgba-1'
                  : ''
              ].join(' ')}
              onDragEnter={drageEnterEvent => {
                drageEnterEvent.stopPropagation();
                drageEnterEvent.preventDefault();
                this.setState({ dragEnter: true, dragLeave: false });
              }}
              onDragOver={dragOverEvent => {
                dragOverEvent.stopPropagation();
                dragOverEvent.preventDefault();
                this.setState({ dragEnter: true, dragLeave: false });
              }}
              onDragLeave={dragLeaveEvent => {
                dragLeaveEvent.stopPropagation();
                dragLeaveEvent.preventDefault();
                this.setState({ dragEnter: false, dragLeave: true });
              }}
              onDrop={dropEvent => {
                dropEvent.stopPropagation();
                dropEvent.preventDefault();
                const dt = dropEvent.dataTransfer;
                const files = dt.files;
                this.handleFiles(files);
                this.setState({ dragEnter: false, dragLeave: true });
              }}
            >
              <div id="imageHolder">
                {this.state.imageSrc ? (
                  <ImageContainer
                    alt={this.state.name}
                    src={this.state.imageSrc}
                    height="154px"
                    className="img-fluid rounded-circle p-1 border border-black h-100"
                  />
                ) : (
                  <div>
                    <img
                      alt={this.state.name}
                      src={UserPlaceholderImage}
                      height="154"
                      className="rounded-circle p-1 border border-black"
                    />
                  </div>
                )}
              </div>
              {this.state.imageAdded ? (
                <div>
                  <Button
                    variant="link"
                    className={[
                      this.state.dragEnter ? 'd-none' : '',
                      'text-blue',
                      'my-0'
                    ].join(' ')}
                    onClick={() => {
                      this.props.startImageEdit();
                      this.fileInput.current.click();
                    }}
                  >
                    {this.translation('change')}
                  </Button>
                  <Button
                    variant="link"
                    className={[
                      this.state.dragEnter ? 'd-none' : '',
                      'text-blue',
                      'my-0'
                    ].join(' ')}
                    onClick={() => {
                      this.props.startImageEdit();
                      this.removeImage();
                    }}
                  >
                    {this.translation('remove')}
                  </Button>
                </div>
              ) : (
                <Button
                  variant="link"
                  className={[
                    this.state.dragEnter ? 'd-none' : '',
                    'text-blue',
                    'my-0'
                  ].join(' ')}
                  onClick={() => {
                    this.props.startImageEdit();
                    this.fileInput.current.click();
                  }}
                >
                  {this.translation('browse')}
                </Button>
              )}

              <input
                type="file"
                ref={this.fileInput}
                className="d-none"
                onChange={changeEvent =>
                  this.handleFiles(changeEvent.target.files)
                }
                accept=".jpg,.jpeg,.png"
              />
            </div>
          </Col>
          <Col>
            {!editDetails ? (
              <ListGroup variant="flush" className="pl-md-4">
                {Object.keys(formData).map((key, index) => (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col>{formData[key].text}</Col>
                      <Col>{formData[key].value}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <Row>
                    <Col />
                    <Col>
                      <Button
                        variant="link"
                        className="text-blue m-0 p-0"
                        onClick={() => this.setState({ changePassword: true })}
                      >
                        {this.translation('change') +
                          ' ' +
                          this.translation('password')}
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            ) : (
              <Formik
                initialValues={{
                  'First-Name': name,
                  'Last-Name': lastName,
                  Email: email
                }}
                validationSchema={DetailSchema}
                onSubmit={values => {
                  const newValues = {};
                  Object.keys(values).forEach(key => {
                    newValues[this.dbKeys[key]] = values[key];
                  });
                  this.completeSubmit(
                    Object.assign(this.props.details, newValues)
                  );
                }}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, touched, handleChange, handleSubmit, values }) => {
                  bindSubmitForm('detail', handleSubmit);
                  return (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="form-group-list"
                    >
                      <Collapse in={!!alert.message}>
                        <div>
                          {!!alert.message && (
                            <Alert variant={alert.type}>{alert.message}</Alert>
                          )}
                        </div>
                      </Collapse>
                      <ListGroup variant="flush" className="pl-md-4">
                        <ListGroup.Item>
                          <Row>
                            <Col className="pt-2 mt-1">
                              <p>
                                {this.translation('first') +
                                  ' ' +
                                  this.translation('name')}
                              </p>
                            </Col>
                            <Col>
                              <FormGroup
                                formControlName="First-Name"
                                type="text"
                                label={
                                  this.translation('first') +
                                  ' ' +
                                  this.translation('name')
                                }
                                handleChange={handleChange}
                                touched={touched['First-Name']}
                                error={errors['First-Name']}
                                value={values['First-Name']}
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col className="pt-2 mt-1">
                              <p>
                                {this.translation('last') +
                                  ' ' +
                                  this.translation('name')}
                              </p>
                            </Col>
                            <Col>
                              <FormGroup
                                formControlName="Last-Name"
                                type="text"
                                label={
                                  this.translation('last') +
                                  ' ' +
                                  this.translation('name')
                                }
                                handleChange={handleChange}
                                touched={touched['Last-Name']}
                                error={errors['Last-Name']}
                                value={values['Last-Name']}
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col className="pt-2 mt-1">
                              <p>{this.translation('email')}</p>
                            </Col>
                            <Col>
                              <FormGroup
                                formControlName="Email"
                                type="text"
                                label={this.translation('email')}
                                handleChange={handleChange}
                                touched={touched['Email']}
                                error={errors['Email']}
                                value={values['Email']}
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Col>
        </Row>
      </div>
    );
  }
  completeSubmit(values) {
    if (!this.state.imageAdded) {
      values = Object.assign(values, { imageSrc: '' });
    }
    const isDiffThanPrevious =
      ['email', 'lastName', 'name', 'imageSrc']
        .map(val => values[val] === this.state[val])
        .indexOf(false) > -1;
    this.props.onSubmit(values, this.state.userImageFile, isDiffThanPrevious);
    return values;
  }
}

export default withTranslation()(UserAccountDetails);
