import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Alert,
  Collapse,
  Container
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MDSpinner from 'react-md-spinner';
import { userConstants, ColorConstants } from '../../../_shared/constants';

import {
  authenticationActions,
  alertActions
} from '../../../_shared/redux/actions';
import { ConfirmDialog, FormGroup } from '../../../_shared/components';
import { withTranslation } from 'react-i18next';
import { AuthenticationService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import { toastr } from 'react-redux-toastr';

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required()
    .email(),
  password: Yup.string()
    .required()
    .min(8)
});

export let injected;

export const InnerForm = ({
  handleSubmit,
  loggingIn,
  handleChange,
  touched,
  errors,
  translation,
  history,
  alert
}) => (
  <Form className="login-form" noValidate onSubmit={handleSubmit}>
    <h1 className="text-primary mb-4 d-none d-sm-none d-md-block">
      {translation('deleteAccount')}
    </h1>
    <Collapse in={alert.message}>
      <div>
        <Alert variant={alert.type}>{alert.message}</Alert>
      </div>
    </Collapse>
    <FormGroup
      formControlName="email"
      type="email"
      label={translation('email')}
      icon="profile"
      disabled={loggingIn}
      handleChange={handleChange}
      touched={touched['email']}
      error={errors['email']}
      required
    />
    <FormGroup
      formControlName="password"
      type="password"
      label={translation('password')}
      icon="password"
      disabled={loggingIn}
      handleChange={handleChange}
      touched={touched['password']}
      error={errors['password']}
      required
    />
    <Button
      type="submit"
      variant="primary"
      size="lg"
      block
      className="rounded-0"
      disabled={loggingIn}
      data-testid="test-login-submit"
    >
      {loggingIn ? (
        <MDSpinner singleColor="#ffffff" />
      ) : (
        translation('deleteMyAccount')
      )}
    </Button>
    <Button
      variant="link"
      className="float-right mb-md-5"
      onClick={() => history.push('/forgot-password')}
    >
      {translation('forgotPassword?')}
    </Button>
    {/* <div className="clearfix" />
    <p className="text-center">{translation('newToMobilize')}</p>
    <div className="w-100 text-center">
      <Button
        variant="link"
        size="lg"
        className="font-weight-bold"
        onClick={() => history.push('/registration')}
      >
        {translation('registerNow')}
      </Button>
    </div> */}
  </Form>
);

export class DeleteRequestForm extends React.Component {
  translation = this.props.t;
  state = {
    showConfirmDelete: false,
    isTableLoading: false,
    showConfirmDelete2: false,
    userId: ''
  };
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.setState({ showConfirmDelete2: false });
  };
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.setState({ showConfirmDelete2: true });
  };
  finalDeleteRequest = () => {
    this.setState({ isTableLoading: true });
    AuthenticationService.deleteRequest(this.state.userId).subscribe({
      next: () => {
        toastr.success(
          `${this.translation('delete')} `,
          this.translation('successDeleteRequest')
        );
        this.setState({ showConfirmDelete2: false, isTableLoading: false });
      },
      error: errorResponse => {
        toastr.error(this.translation('error'), errorParser(errorResponse));
        this.setState({ showConfirmDelete2: false, isTableLoading: false });
      }
    });
  };
  render() {
    const {
      loggingIn,
      username,
      alert,
      loggedIn,
      login,
      userVerify,
      clearAlert
    } = this.props;
    const submitRequest = values => {
      AuthenticationService.userVerify(values.email, values.password).subscribe(
        {
          next: response => {
            this.setState({
              showConfirmDelete: true,
              userId: response.data.user._id
            });
          },
          error: errorResponse => {
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      );
      // };
      function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
      }
      function failure(user) {
        return { type: userConstants.LOGIN_FAILURE, user };
      }
    };

    if (loggedIn) {
      this.props.history.push('/');
    } else {
      return (
        <>
          <>
            <ConfirmDialog
              backdrop="static"
              showConfirm={this.state.showConfirmDelete}
              cancelBtn={this.translation('Cancel')}
              okBtn={this.translation('confirm')}
              cancelClicked={() => this.deleteCancelClicked()}
              okClicked={() => this.deleteOkClicked()}
              message={
                <>
                  <div>{this.translation('deleteApprovalMessage')}</div>
                  <br /> <div>{this.translation('deleteApprovalMessage1')}</div>
                </>
              }
              title={this.translation('information')}
            />
          </>
          <>
            <ConfirmDialog
              backdrop="static"
              showConfirm={this.state.showConfirmDelete2}
              cancelBtn={this.translation('no')}
              okBtn={this.translation('yes')}
              cancelClicked={() => this.deleteCancelClicked()}
              okClicked={() => this.finalDeleteRequest()}
              message={
                this.state.isTableLoading ? (
                  <div className="w-100 text-center">
                    <MDSpinner singleColor={ColorConstants.PRIMARY} />
                  </div>
                ) : (
                  <div>
                    <div>{this.translation('deletionRequestMessage')}</div>
                    <br /> <div>{this.translation('confirmationMessage')}</div>
                  </div>
                )
              }
              title={this.translation('deleteAccount')}
            />
            {/* <Container fluid>
              <Row>
                {this.state.isTableLoading && (
                  
                )}
              </Row>
            </Container> */}
          </>
          <div className="h-100">
            <Row className="align-items-center justify-content-center login-form-row">
              <Col sm={8}>
                <Formik
                  initialValues={{
                    email: username,
                    password: ''
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={values => {
                    clearAlert();
                    submitRequest(values);
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                >
                  {formikProps => {
                    injected = formikProps;
                    return (
                      <InnerForm
                        {...formikProps}
                        loggingIn={loggingIn}
                        alert={alert}
                        translation={this.translation}
                        history={this.props.history}
                      />
                    );
                  }}
                </Formik>
              </Col>
            </Row>
            {/* <LanguageSelector className="text-center text-sm-center text-md-right" /> */}
          </div>
        </>
      );
    }
  }
}

const mapStatesToProps = state => {
  const { authentication, alert } = state;
  const { loggedIn, loggingIn, user } = authentication;
  const { username } = user;
  return { loggedIn, loggingIn, username, alert };
};

const mapDispatchToProps = dispatch => ({
  login: values => dispatch(authenticationActions.login(values)),
  userVerify: values => dispatch(authenticationActions.userVerify(values)),
  clearAlert: () => dispatch(alertActions.clear())
});

export default withTranslation()(
  connect(
    mapStatesToProps,
    mapDispatchToProps
  )(DeleteRequestForm)
);
