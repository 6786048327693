// @flow
import * as React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import {
  switchMap,
  tap,
  debounceTime,
  map,
  concatMap,
  mergeMap
} from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { FormGroup, Icon, ConfirmDialog } from '../../../_shared/components';
import { itemService, ImageService } from '../../../_shared/services';
import { tableConstants } from '../../../_shared/constants';
import { kitItemTableConstants } from './table.constants';
import { loaderActions } from '../../../_shared/redux/actions';
import { ReactRouterHistory, KitItem } from '../../../models';
import { errorParser } from '../../../_shared/helpers';

import AddKitItemModal from './AddKitItemModal';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  history: ReactRouterHistory,
  startLoading(): void,
  stopLoading(): void,
  t: i18next.TFunction
};

type State = {
  isTableLoading: boolean,
  itemsArrived: boolean,
  showConfirmDelete: boolean,
  deleteConfirmMessage: React.Element<any>,
  addDetails: boolean,
  editDetails: boolean,
  showAddItemModal: boolean,
  itemIdToBeUpdated: string,
  items: KitItem[],
  searchText: string,
  columns: any,
  options: any,
  showAlert: boolean,
  currentIndex: number,
  sizePerPage: number,
  sortField: string,
  sortOrder: 'asc' | 'desc',
  page: number
};

export class KitItems extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Called when user clicks on edit icon */
  startEditKitItem = (itemIdToBeUpdated: string) => {
    this.setState((curr: State) => ({
      ...curr,
      itemIdToBeUpdated,
      addDetails: false,
      editDetails: true
    }));
    // let's open modal to edit the document
    this.setState({ showAddItemModal: true });
  };
  /**Present confirm delete */
  presentDeleteConfirm = (id: string, name: string, number: number) => {
    const deleteConfirmMessage = (
      <>
        <p>
          {this.translation('deleteItem')}
          <br />{' '}
          <b>
            {this.translation('name')}: {name}
          </b>
          ,<br />
          <b>
            {this.translation('itemNumber')}: {number}
          </b>
        </p>
      </>
    );
    this.setState({
      showConfirmDelete: true,
      deleteConfirmMessage,
      itemIdToBeUpdated: id
    });
  };
  /**Component's state */
  state = {
    isTableLoading: false,
    itemsArrived: false,
    showConfirmDelete: false,
    deleteConfirmMessage: <></>,
    addDetails: false,
    editDetails: false,
    showAddItemModal: false,
    itemIdToBeUpdated: '',
    items: [],
    searchText: '',
    columns: kitItemTableConstants.itemColumns(
      this.startEditKitItem,
      this.presentDeleteConfirm
    ),
    options: {},
    showAlert: true,
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'name',
    sortOrder: 'asc',
    page: 1
  };
  /**Call apis when component mounted */
  componentDidMount() {
    // this.getKitItems();
    this.onSearchChange();
  }
  /**Free up resources when component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$: Observable<Event> = fromEvent(
      document.getElementById('kit-items-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            page: 1,
            currentIndex: 0,
            searchText: inputEvent.target.value,
            isTableLoading: true
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Confirm dialog cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };
  /**Confirm dialog ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    this.deleteKitItem();
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type: any,
    {
      sortField,
      sortOrder,
      tableData,
      page,
      sizePerPage
    }: {
      sortField: string,
      sortOrder: 'asc' | 'desc',
      tableData: any,
      page: number,
      sizePerPage: number
    }
  ) => {
    const currentIndex: number =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getKitItems(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    sortField: string,
    sortOrder: 'asc' | 'desc',
    searchText: string
  ) => {
    return itemService.getKitItems(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    );
  };
  /**Subscriber-response handler */
  handelResponse = () => ({
    next: (response: { data: { kitItems: KitItem[], count: number } }) => {
      if (this.cleanup) {
        const {
          sizePerPage,
          page
        }: { sizePerPage: number, page: number } = this.state;
        const {
          kitItems: items,
          count
        }: { kitItems: KitItem[], count: number } = response.data;
        this.setState({ itemsArrived: true });
        const options: any = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          items,
          isTableLoading: false,
          itemIdToBeUpdated: '',
          addDetails: false,
          editDetails: false
        });
      }
    },
    error: (errorResponse: any) => {
      if (this.cleanup) {
        this.setState({ itemsArrived: true, isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Once confirmed, delete user */
  deleteKitItem() {
    const deleteKitItem$: Observable<any> = itemService
      .delete(this.state.itemIdToBeUpdated)
      .subscribe({
        next: (response: { data: { message: string } }) => {
          if (this.cleanup) {
            //let's make the id null, to avoid leaks
            this.setState({
              itemIdToBeUpdated: '',
              addDetails: false,
              editDetails: false
            });
            this.props.stopLoading();
            toastr.success(this.translation('success'), response.data.message);
            this.setState({ page: 1, currentIndex: 0 }, () => {
              this.getKitItems();
            });
          }
        },
        error: (errorResponse: any) => {
          this.props.stopLoading();
          if (this.cleanup) {
            this.setState({ isTableLoading: false, itemsArrived: true });
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
    this.cleanup = () => {
      deleteKitItem$.unsubscribe();
    };
  }
  /**Get customers from server */
  getKitItems(
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    sortField: string = this.state.sortField,
    sortOrder: 'asc' | 'desc' = this.state.sortOrder,
    searchText: string = this.state.searchText
  ) {
    this.setState({ isTableLoading: true });
    const itemService$: Subscription = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      itemService$.unsubscribe();
    };
  }
  /**Submit detail to add/edit */
  onSubmit = (values: KitItem, imageFile?: File | any = undefined) => {
    this.setState({ showAddItemModal: false });
    this.props.startLoading();
    const stateItem = this.state.items.filter(
      (item: KitItem) => item._id === this.state.itemIdToBeUpdated
    )[0];
    let observable$, subscription$;
    if (!values.imageSrc && stateItem && stateItem.imageSrc) {
      observable$ = ImageService.deleteImage(
        'kit_item',
        this.state.itemIdToBeUpdated
      ).pipe(
        mergeMap(() => this.completeOnSubmit(observable$, values, imageFile))
      );
    } else observable$ = this.completeOnSubmit(observable$, values, imageFile);
    subscription$ = observable$.subscribe(this.handleResponseOnSubmit());
    this.cleanup = () => {
      if (subscription$) {
        subscription$.unsubscribe();
      }
    };
  };
  /**Handle response on submit */
  handleResponseOnSubmit = () => {
    return {
      next: (response: any) => {
        if (this.cleanup) {
          toastr.success(
            this.translation('kitItem') +
              ' ' +
              (!this.state.addDetails
                ? this.translation('update', { context: 'past' })
                : this.translation('add', { context: 'past' })),
            response.data.message
          );
          this.setState({
            addDetails: false,
            itemIdToBeUpdated: '',
            editDetails: false
          });
          this.props.stopLoading();
          // once updated, let's get the user back to fist page
          this.setState({ page: 1, currentIndex: 0 }, () => {
            this.getKitItems();
          });
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          toastr.error(this.translation('error'), errorParser(errorResponse));
          this.props.stopLoading();
        }
      }
    };
  };
  completeOnSubmit(
    observable$: Observable,
    values: KitItem,
    imageFile?: File
  ): Observable<any> {
    if (imageFile) {
      // let's upload image first
      observable$ = ImageService.startUploadImage(imageFile).pipe(
        map(uploadMageResponse => uploadMageResponse.responseData.imageSrc),
        concatMap(imageSrc => {
          const updatedKitItem = Object.assign(values, {
            imageSrc
          });
          if (this.state.addDetails) {
            return itemService.add(updatedKitItem);
          } else if (this.state.editDetails) {
            return itemService.update(
              this.state.itemIdToBeUpdated,
              updatedKitItem
            );
          }
        })
      );
    } else if (this.state.addDetails) {
      observable$ = itemService.add(values);
    } else if (this.state.editDetails) {
      observable$ = itemService.update(this.state.itemIdToBeUpdated, values);
    }
    return observable$;
  }

  render() {
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn={this.translation('no')}
          okBtn={this.translation('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.state.deleteConfirmMessage}
        />
        <AddKitItemModal
          show={this.state.showAddItemModal}
          itemId={this.state.itemIdToBeUpdated}
          onClose={() =>
            this.setState({
              showAddItemModal: false,
              itemIdToBeUpdated: '',
              addDetails: false,
              editDetails: false
            })
          }
          onSubmit={(kitItem, imageFile) => {
            this.onSubmit(kitItem, imageFile);
          }}
        />
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={8} md={4} className="mb-3">
              <FormGroup
                title={this.translation(
                  'searchByNameNumberManufacturerDescription'
                )}
                type="text"
                label={this.translation(
                  'searchByNameNumberManufacturerDescription'
                )}
                icon="search"
                iconPosition="right"
                classes="mb-2"
                formControlId="kit-items-search-input"
              />
            </Col>
            <Col className="text-right mb-3">
              <Button
                variant=""
                onClick={() => {
                  this.setState({
                    addDetails: true,
                    editDetails: false,
                    showAddItemModal: true
                  });
                }}
                className="mb-2 rounded-0"
              >
                {this.translation('addNewKitItem')}
                <Icon iconName="add" size="26" classes="ml-2" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BootstrapTable
                classes="with-gap"
                wrapperClasses="table-responsive-sm"
                remote
                bordered={false}
                bootstrap4
                keyField="_id"
                data={this.state.items}
                columns={this.state.columns}
                pagination={
                  this.state.items.length
                    ? paginationFactory(this.state.options)
                    : null
                }
                noDataIndication={
                  this.state.isTableLoading
                    ? this.translation('pleaseWait')
                    : this.translation('noData')
                }
                rowClasses="colored-background"
                onTableChange={this.onTableChange}
                loading={this.state.isTableLoading}
                overlay={tableConstants.overlay()}
                defaultSorted={[
                  {
                    dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
                    order: 'asc' // desc or asc
                  }
                ]}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(KitItems)
);
