import React, { Component } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';

import { ColorConstants, tableConstants } from '../../../_shared/constants';
import { Icon, ConfirmDialog } from '../../../_shared/components';

import { servicePlanService, emailService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';

import { expiringTableConstants } from './table.constants';
import { loaderActions } from '../../../_shared/redux/actions';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { withTranslation, Trans } from 'react-i18next';
class ExpiringServicePlans extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = null;
  /**It will present confirm dialog before sending mail */
  presentEmailConfirm = (serialNumber, name, email, sendMailIndex) => {
    const emailConfirmMessage = (
      // eslint-disable-next-line react-intl/string-is-marked-for-translation
      <Trans i18nKey="sendMailConfirmationServicePlan">
        Do you really want to send email to <strong>{{ name }}</strong> (
        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
        {{ email }}) regarding Expiration of{' '}
        {/* eslint-disable-next-line react-intl/string-is-marked-for-translation */}
        <strong>Service Plan with Serial Number {{ serialNumber }}</strong>?
      </Trans>
    );
    const showEmailConfirm = true;
    this.setState({
      emailConfirmMessage,
      showEmailConfirm,
      sendMailIndex,
      userName: name
    });
  };
  /**Component's state */
  state = {
    isTableLoading: true,
    isFileLoading: false,
    itemsArrived: false,
    showEmailConfirm: false,
    emailConfirmMessage: '',
    sendMailIndex: null,
    items: [],
    userName: '',
    columns: expiringTableConstants.serviceColumns(this.presentEmailConfirm),
    sortField: 'serviceExpiryDate',
    sortOrder: 'asc'
  };
  /**Call apis when component mounted */
  componentDidMount() {
    this.getExpiringServicePlans();
  }
  /**Free up resources when component un-mounts. */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Get service plans from server */
  getExpiringServicePlans(
    sortField = this.state.sortField,
    sortOrder = this.state.sortOrder
  ) {
    this.setState({ isTableLoading: true, itemsArrived: false });
    const servicePlanService$ = servicePlanService
      .get(0, 10, sortField, sortOrder)
      .subscribe({
        next: response => {
          if (this.cleanup) {
            const { products } = response.data;
            this.setState({
              items: products,
              isTableLoading: false,
              itemsArrived: true,
              sortField,
              sortOrder
            });
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.setState({ itemsArrived: true, isTableLoading: false });
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
    this.cleanup = () => {
      servicePlanService$.unsubscribe();
    };
  }
  /**Table change handler */
  onTableChange = (type, { sortField, sortOrder }) => {
    this.setState({ sortField, sortOrder }, () => {
      this.getExpiringServicePlans(sortField, sortOrder);
    });
  };
  /**Email confirm cancel click handler */
  emailCancelClicked = () => {
    const showEmailConfirm = false;
    this.setState({ showEmailConfirm });
  };
  /**Email confirm ok click handler */
  emailOkClicked = () => {
    const showEmailConfirm = false;
    this.setState({ showEmailConfirm });
    this.props.startLoading();
    emailService.sendMail(this.state.sendMailIndex, 'product').subscribe({
      next: (response: any) => {
        const sendMailIndex = this.state.sendMailIndex;
        let newItems = this.state.items.slice();
        newItems = newItems.map(item => {
          if (item._id === sendMailIndex) {
            return {
              ...item,
              mailStatus: 'sent'
            };
          }
          return item;
        });
        this.setState(curr => ({ ...curr, items: newItems }));
        toastr.success(
          this.translation('mailSent'),
          this.translation('mailSentToTheUser', {
            userName: this.state.userName
          })
        );
        this.props.stopLoading();
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          toastr.error(
            this.translation('errorWhileSendingMail'),
            errorParser(errorResponse)
          );
          this.setState({ isExporting: false });
        }
      }
    });
  };
  exportData = () => {
    this.setState({ isFileLoading: true });
    const servicePlanService$ = servicePlanService
      .get(0, 10, this.state.sortField, this.state.sortOrder, true)
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.setState({
              isFileLoading: false
            });
            fileDownload(
              response.data,
              this.translation('unit', { count: 0 }) +
                '-' +
                moment().format('MM-DD-Y:HH-mm-ss') +
                '.csv'
            );
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            toastr.error(
              this.translation('errorWhileExporting'),
              errorParser(errorResponse)
            );
            this.setState({ isExporting: false });
          }
        }
      });
    this.cleanup = () => {
      servicePlanService$.unsubscribe();
    };
  };
  render() {
    const { t: translation } = this.props;
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showEmailConfirm}
          cancelBtn={this.translation('no')}
          okBtn={this.translation('yes')}
          cancelClicked={() => this.emailCancelClicked()}
          okClicked={() => this.emailOkClicked()}
          message={this.state.emailConfirmMessage}
        />
        <Card>
          <Card.Body>
            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <h5 className="card-title">
                  {this.translation('expiringServicePlans')}
                </h5>
              </Col>
              <Col xs="auto">
                <Button
                  variant=""
                  className="mb-3"
                  disabled={this.state.isTableLoading}
                  onClick={() => {
                    this.exportData();
                  }}
                >
                  {this.state.isFileLoading ? (
                    <>
                      {this.translation('processingYourFile')}
                      <MDSpinner singleColor={ColorConstants.PRIMARY} />
                    </>
                  ) : (
                    <>
                      {this.translation('exportToExcel')}
                      <Icon iconName="share" size="26" classes="ml-2" />
                    </>
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <BootstrapTable
                  remote
                  classes="border-bottom"
                  bordered={false}
                  bootstrap4
                  keyField="_id"
                  loading={this.state.isTableLoading}
                  onTableChange={this.onTableChange}
                  data={this.state.items}
                  columns={this.state.columns}
                  overlay={tableConstants.overlay()}
                  noDataIndication={
                    this.state.isTableLoading
                      ? translation('pleaseWait')
                      : translation('noData')
                  }
                  defaultSorted={[
                    {
                      dataField: this.state.sortField,
                      order: this.state.sortOrder
                    }
                  ]}
                />
              </Col>
            </Row>
            <button
              className="btn btn-link text-blue"
              onClick={() =>
                this.props.history.push(
                  '/admin/dashboard/expiring-service-plans'
                )
              }
            >
              {this.translation('showAllList')}
            </button>
          </Card.Body>
        </Card>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(ExpiringServicePlans)
);
