// @flow
import * as React from 'react';
import { Form, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Checkbox, FormGroup } from '../../../_shared/components';
import i18next from 'i18next';

type Props = {
  handleSubmit: () => void,
  errors: any,
  touched: any,
  handleChange: () => void,
  translation: i18next.TFunction
};

type State = {};

export class InnerForm extends React.Component<Props, State> {
  render() {
    const {
      handleSubmit,
      errors,
      touched,
      handleChange,
      translation
    } = this.props;
    return (
      <Form noValidate onSubmit={handleSubmit}>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip
              id={`tooltip-sendTo`}
              className={errors.sendTo ? '' : 'd-none'}
            >
              {errors.sendTo}
            </Tooltip>
          }
        >
          <Form.Group className="d-inline-block">
            <Checkbox
              inline
              required
              name="sendTo"
              label={translation('sendToSiteAdmins')}
              isInvalid={errors.sendTo}
              id="sendTo-site"
              data-testid="sendTo-site"
              value="site"
            />
            <Checkbox
              custom
              inline
              required
              name="sendTo"
              label={translation('sendToMobileAppUsers')}
              isInvalid={errors.sendTo}
              id="sendTo-mobile"
              data-testid="sendTo-mobile"
              value="mobile"
            />
          </Form.Group>
        </OverlayTrigger>
        <FormGroup
          classes="mb-4"
          formControlName="title"
          type="text"
          label={translation('title')}
          handleChange={handleChange}
          touched={touched['title']}
          error={errors['title']}
          required
        />
        <FormGroup
          classes="mb-4"
          as="textarea"
          rows="3"
          formControlName="description"
          handleChange={handleChange}
          label={translation('description')}
          error={errors['description']}
          touched={touched['description']}
          required
        />
        <Button
          type="submit"
          variant="success"
          size="lg"
          className="rounded-0 float-right"
        >
          {translation('sendAlertMessage')}
        </Button>
      </Form>
    );
  }
}
export default InnerForm;
