import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Button, Form, Row } from 'react-bootstrap';
import { forkJoin } from 'rxjs';
import { toastr } from 'react-redux-toastr';

import { FormGroup } from '../../../_shared/components';
import { ProductService } from '../../../_shared/services';
import { withTranslation, Translation } from 'react-i18next';

/**Validation schema for formik */
const AddByRangeFormSchema = Yup.object().shape({
  prefix: Yup.string()
    .required()
    .test(
      'valid-prefix',
      <Translation>{t => <>{t('formValidations.prefixError')}</>}</Translation>,
      value => {
        return /^[A-Z]{2}[0-9]{2}[A-Z]{1}$/.test(value);
      }
    )
    .length(5),
  fromRange: Yup.string()
    .required()
    .test(
      'is-digits',
      <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
      value => !isNaN(value)
    )
    .length(
      6,
      <Translation>
        {t => <>{t('formValidations.exactly6Digits')}</>}
      </Translation>
    ),
  toRange: Yup.string()
    .required()
    .test(
      'is-digits',
      <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
      value => !isNaN(value)
    )
    .length(
      6,
      <Translation>
        {t => <>{t('formValidations.exactly6Digits')}</>}
      </Translation>
    )
});

class AddByRange extends Component {
  translation = this.props.t;
  state = {
    serialNumbersRange: [],
    count: 0,
    fromRange: 0,
    toRange: 0,
    prefix: ''
  };
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  /**Call api to add product by range */
  addProductsByRange = () => {
    this.props.startLoading();
    let observables$ = [];
    //make an array of all observables which will check validity of serial numbers
    this.state.serialNumbersRange.forEach(serialNumber => {
      observables$.push(ProductService.checkSerialNumber(serialNumber));
    });
    // check all serial numbers sequentially. if any fails, next requests won't go.
    forkJoin(...observables$).subscribe({
      next: _response => {
        // all serial numbers are valid
        ProductService.registerProduct(this.state.serialNumbersRange).subscribe(
          {
            next: () => {
              this.props.stopLoading();
              toastr.success(
                this.translation('unitAdded'),
                this.translation('unitsAreRegisteredWith')
              );
              this.props.goTo('/my-kits');
            },
            error: errorResponse => {
              this.props.stopLoading();
              toastr.error(
                this.translation('error'),
                errorResponse.data.message
              );
            }
          }
        );
      },
      error: () => {
        this.props.stopLoading();
        toastr.error(
          this.translation('error'),
          this.translation('someOfProvidedSerialNumbersAreInvalid')
        );
      }
    });
  };
  countSerialNumbers = inputChangeEvent => {
    this.setState(
      { [inputChangeEvent.target.name]: inputChangeEvent.target.value },
      () => {
        const fromRange = isNaN(this.state.fromRange)
          ? 0
          : this.state.fromRange;
        const toRange = isNaN(this.state.toRange) ? 0 : this.state.toRange;
        const count = toRange - fromRange < 0 ? 0 : toRange - fromRange + 1;
        this.setState({ count });
      }
    );
  };
  render() {
    return (
      <Col className="ml-3">
        <Formik
          ref={this.formRef}
          initialValues={{
            fromRange: '',
            toRange: ''
          }}
          validate={values => {
            let errors = {};
            if (values.toRange.length === 8 && values.fromRange.length === 8) {
              if (+values.toRange <= +values.fromRange) {
                errors.toRange = this.translation('toRangeCNTLEFromRange');
              }
              if (+values.fromRange >= +values.toRange) {
                errors.fromRange = this.translation('fromRangeCNTEMToRange');
              }
              if (+values.toRange > +values.fromRange + 50) {
                errors.toRange = this.translation('only50Units');
              }
            }
            return errors;
          }}
          validationSchema={AddByRangeFormSchema}
          onSubmit={values => {
            const fromRange = +values.fromRange,
              toRange = +values.toRange;
            const serialNumberRangeLength = toRange - fromRange + 1;
            // make array with values from fromRange to toRange
            const serialNumbersRange = [...Array(serialNumberRangeLength)].map(
              (_, index) =>
                values.prefix + '000000'.concat(index + fromRange).slice(-6)
            );
            this.setState({
              serialNumbersRange
            });
            this.addProductsByRange();
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors, touched, handleChange, handleSubmit }) => {
            return (
              <Form className="custom-form-control" onSubmit={handleSubmit}>
                <Form.Row className="align-items-center">
                  <Col sm="12" md={11}>
                    <Row>
                      <Col xs={12} sm={12} md={4}>
                        <Form.Group
                          as={Row}
                          controlId="prefixId"
                          className="align-items-center"
                        >
                          <Form.Label column className="mb-3">
                            {this.translation('prefix')}
                          </Form.Label>
                          <Col xs sm md="7">
                            <FormGroup
                              formControlName="prefix"
                              type="text"
                              label="MF20A"
                              handleChange={changeEvent => {
                                handleChange(changeEvent);
                              }}
                              touched={touched['prefix']}
                              error={errors['prefix']}
                              required
                              alertPositionAbsolute
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={4}>
                        <Form.Group
                          as={Row}
                          controlId="fromRangeId"
                          className="align-items-center"
                        >
                          <Form.Label column xs="auto" className="mb-3">
                            {this.translation('startingFrom')}
                          </Form.Label>
                          <Col xs sm md="8">
                            <FormGroup
                              formControlName="fromRange"
                              type="text"
                              label="XXXXXX"
                              handleChange={inputChangeEvent => {
                                this.countSerialNumbers(inputChangeEvent);
                                handleChange(inputChangeEvent);
                              }}
                              touched={touched['fromRange']}
                              error={errors['fromRange']}
                              alertPositionAbsolute
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={4}>
                        <Form.Group
                          as={Row}
                          controlId="toRangeId"
                          className="align-items-center"
                        >
                          <Form.Label column xs="auto" className="mb-3">
                            {this.translation('to')}
                          </Form.Label>
                          <Col xs sm md="8">
                            <FormGroup
                              formControlName="toRange"
                              type="text"
                              label="XXXXXX"
                              handleChange={inputChangeEvent => {
                                this.countSerialNumbers(inputChangeEvent);
                                handleChange(inputChangeEvent);
                              }}
                              touched={touched['toRange']}
                              error={errors['toRange']}
                              alertPositionAbsolute
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="5" />
                </Form.Row>
                <p className="font-size-lg float-left">
                  {this.translation('quantity')}:{' ' + this.state.count}
                </p>
                <Button
                  type="submit"
                  variant="success"
                  size="lg"
                  className="rounded-0 px-5 float-right"
                >
                  {this.translation('add')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Col>
    );
  }
}

export default withTranslation()(AddByRange);
