import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  InputGroup,
  FormControl
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import MDSpinner from 'react-md-spinner';

import { ColorConstants, tableConstants } from '../../../_shared/constants';
import { ProductService } from '../../../_shared/services';
import { kitDetailsTableConstants } from './table.constants';
import ConfirmDialog from '../../../_shared/components/ConfirmDialog';
import { alertActions, loaderActions } from '../../../_shared/redux/actions';
import { Icon } from '../../../_shared/components';
import { errorParser, getFormattedDate } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';

export class KitDetails extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = [];
  /** State of component */
  state = {
    isLoading: true,
    product: {
      _id: '',
      items: [],
      kit: {
        name: ''
      },
      kit_items: [],
      serialNumber: '',
      productCreatedDate: '',
      registrationDate: '',
      serviceExpiryDate: '',
      servicePlanStatus: '',
      location: '',
      maxActivation: 1,
      totalActivation: 1
    },
    productId: null,
    isError: false,
    columns: kitDetailsTableConstants.itemColumns,
    options: {},
    showConfirmDelete: false,
    isEditingLocation: false,
    updatedLocation: '',
    isUpdatingLocation: false
  };
  constructor(props) {
    super(props);
    const productId = props.match.params.productId;
    this.state.productId = productId;
  }
  /**Call api to get details */
  componentDidMount() {
    this.getProductById();
  }
  /**Free up resources when component un mounts */
  componentWillUnmount() {
    if (this.cleanup.length) {
      this.cleanup.forEach(obs => obs.unsubscribe());
    }
    this.cleanup = [];
  }
  /**Confirm whether user really want to delete the product */
  confirmDeleteKit = () => {
    this.setState({ showConfirmDelete: true });
  };
  /**Cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };
  /**Ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    const unRegisterProduct$ = ProductService.unRegisterProduct(
      this.state.productId
    ).subscribe({
      next: response => {
        if (this.cleanup.length) {
          this.props.history.push('/my-kits');
          this.props.stopLoading();
          toastr.success(
            this.translation('success'),
            this.translation('deletedSuccessfully', {
              what: this.translation('kit')
            })
          );
        }
      },
      error: errorResponse => {
        if (this.cleanup.length) {
          this.props.stopLoading();
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup.push(unRegisterProduct$);
  };
  /**Get product details */
  getProductById() {
    const getProductById$ = ProductService.getProductById(
      this.state.productId
    ).subscribe({
      next: response => {
        if (this.cleanup.length) {
          let product = response.data.product;
          product.items.forEach((item, index) => {
            item.name = product.kit_items[index].name;
            item.number = product.kit_items[index].number;
            item.imageSrc = product.kit_items[index].imageSrc;
          });
          const options = tableConstants.paginationOptions(
            product.items.length
          );
          this.setState({ isLoading: false, options, product });
        }
      },
      error: errorResponse => {
        if (this.cleanup.length) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup.push(getProductById$);
  }
  /**Location Change Handler */
  handleLocationChange = inputChangeEvent => {
    this.setState({ updatedLocation: inputChangeEvent.target.value.trim() });
  };
  /**Update location */
  updateLocation = () => {
    this.setState({ isUpdatingLocation: true });
    const updateLocation$ = ProductService.updateLocation(
      this.state.productId,
      this.state.updatedLocation ? this.state.updatedLocation : ' '
    ).subscribe({
      next: response => {
        if (this.cleanup.length) {
          const product = { ...this.state.product };
          product.location = this.state.updatedLocation;
          this.setState({ product });
        }
        this.setState({ isUpdatingLocation: false, isEditingLocation: false });
      },
      error: errorResponse => {
        this.setState({ isUpdatingLocation: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    });
    this.cleanup.push(updateLocation$);
  };
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <>
            <ConfirmDialog
              backdrop="static"
              showConfirm={this.state.showConfirmDelete}
              cancelBtn={this.translation('no')}
              okBtn={this.translation('yes')}
              cancelClicked={() => this.deleteCancelClicked()}
              okClicked={() => this.deleteOkClicked()}
              message={this.translation(
                'siteAdmin.kitDetailsComponent.deleteMsg'
              )}
            />
            <Container fluid>
              <Row>
                <Col xs={12} className="text-right mb-1">
                  <Button
                    variant=""
                    disabled={this.state.isLoading}
                    onClick={() => this.confirmDeleteKit()}
                  >
                    {this.translation('deleteThisKit')}
                    <Icon iconName="delete" size="26" classes="ml-2" />
                  </Button>
                </Col>
                <Col xs={12} className="mb-3">
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        {this.translation('kit')} :{' '}
                        {this.state.product.kit.name}
                      </Card.Title>
                      <Row>
                        {/* TODO @Dharmen: suggestion to add image in kit details page */}
                        {/* <Col md={6} className="align-self-center mb-3">
                          <ImageContainer
                            src={this.state.product.kit.imageSrc}
                            className="img-fluid"
                          />
                        </Col> */}
                        <Col md className="mb-3">
                          <Row>
                            <Col md={12} className="mb-3">
                              {/* <Card.Title>Basic Details</Card.Title> */}
                              <ListGroup variant="flush">
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('model')}
                                  </span>
                                  <span className="float-right">
                                    {this.state.product.kit.name}
                                  </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('serialNumber')}
                                  </span>
                                  <span className="float-right">
                                    {this.state.product.serialNumber}
                                  </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('creationDate')}
                                  </span>
                                  <span className="float-right">
                                    {getFormattedDate(
                                      this.state.product.productCreatedDate
                                    )}
                                  </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('registrationDate')}
                                  </span>
                                  <span className="float-right">
                                    {getFormattedDate(
                                      this.state.product.registrationDate
                                    )}
                                  </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('registered')}
                                  </span>
                                  <span className="float-right">
                                    {this.translation('yes')}
                                  </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('maxActivation')}
                                  </span>
                                  <span className="float-right">
                                    {this.state.product.maxActivation}
                                  </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('totalActivation', {
                                      count: 0
                                    })}
                                  </span>
                                  <span className="float-right">
                                    {this.state.product.totalActivation}
                                  </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <span className="float-left font-weight-md">
                                    {this.translation('location')}
                                  </span>
                                  {this.state.isEditingLocation ? (
                                    <InputGroup className="float-right w-25">
                                      <FormControl
                                        placeholder={this.translation(
                                          'location'
                                        )}
                                        defaultValue={
                                          this.state.product.location
                                        }
                                        className="rounded-0"
                                        onChange={this.handleLocationChange}
                                        disabled={this.state.isUpdatingLocation}
                                        size="sm"
                                      />
                                      <InputGroup.Append>
                                        <Button
                                          variant="secondary"
                                          onClick={() =>
                                            this.setState({
                                              isEditingLocation: false
                                            })
                                          }
                                          disabled={
                                            this.state.isUpdatingLocation
                                          }
                                          size="sm"
                                        >
                                          {this.translation('cancel')}
                                        </Button>
                                        <Button
                                          variant="success"
                                          className="rounded-0"
                                          onClick={() => {
                                            this.updateLocation();
                                          }}
                                          // style={{ width: '70px' }}
                                          disabled={
                                            this.state.isUpdatingLocation
                                          }
                                          size="sm"
                                        >
                                          {this.state.isUpdatingLocation ? (
                                            <MDSpinner
                                              size="20"
                                              singleColor="#FFFFFF"
                                            />
                                          ) : (
                                            this.translation('save')
                                          )}
                                        </Button>
                                      </InputGroup.Append>
                                    </InputGroup>
                                  ) : (
                                    <span className="float-right">
                                      {this.state.product.location.trim() ? (
                                        this.state.product.location
                                      ) : (
                                        <span className="text-primary">
                                          {this.translation('n/a')}
                                        </span>
                                      )}
                                      <Icon
                                        iconName="edit"
                                        size="26"
                                        classes="ml-2 cursor-pointer"
                                        onClick={() =>
                                          this.setState({
                                            isEditingLocation: true
                                          })
                                        }
                                      />
                                    </span>
                                  )}
                                </ListGroup.Item>
                              </ListGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <Card.Title>
                                {this.translation('servicePlan')}
                              </Card.Title>
                              {this.state.product.serviceExpiryDate ? (
                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <span className="float-left font-weight-md">
                                      {this.translation('servicePlanEnroll')}
                                    </span>
                                    <span className="float-right">
                                      {this.state.product.servicePlanStatus ===
                                      'active'
                                        ? this.translation('active')
                                        : this.translation('inactive')}
                                    </span>
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <span className="float-left font-weight-md">
                                      {this.translation('expiringDate')}
                                    </span>
                                    <span className="float-right">
                                      {getFormattedDate(
                                        this.state.product.serviceExpiryDate
                                      )}
                                    </span>
                                  </ListGroup.Item>
                                </ListGroup>
                              ) : (
                                <h5 className="text-center">
                                  {this.translation(
                                    'siteAdmin.kitDetailsComponent.noServicePlan'
                                  )}
                                </h5>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        {this.translation('itemsInThisKit')}
                      </Card.Title>
                      <Row>
                        <Col xs={12}>
                          <div className="table-responsive-sm">
                            <BootstrapTable
                              classes="border-bottom with-gap"
                              bordered={false}
                              bootstrap4
                              keyField="_id"
                              data={this.state.product.items}
                              columns={this.state.columns}
                              noDataIndication={this.translation('noData')}
                              rowClasses="colored-background"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  successAlert: msg => dispatch(alertActions.success(msg)),
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(KitDetails)
);
