import { API_ENDPOINTS } from '../constants';
import { Observable } from 'rxjs';
import { stringify } from 'query-string';
import { authHeader, AxiosSubscriber } from '../helpers';

export const SuperAdminService = {
  getSuperAdmins,
  delete: _delete,
  addSuperAdmin
};

/**@function getSuperAdmins
 * Get super admins
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='name'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @param {string} [search=''] - The string on which search to be done
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getSuperAdmins(
  skip = 0,
  limit = 10,
  sort = 'name',
  order = 'asc',
  search = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        search,
        sort,
        order,
        type: 'super-super'
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.SUPER_ADMIN.GET_SUPER_ADMIN
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

// prefixed function name with underscore because delete is a reserved word in javascript
/**@function _delete
 * Delete Super Admin
 * @param {string} superAdminId - Super-Admin's ID, which will be deleted
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _delete(superAdminId) {
  return new Observable(observer => {
    const requestType = 'delete',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.SUPER_ADMIN.DELETE_SUPER_ADMIN
      }/${superAdminId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function addSuperAdmin
 * Add super admin from super-super admin
 * @param {object} user - Super admin to be added
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function addSuperAdmin(user) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.USERS.POST_USERS
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      user
    );
  });
}
