// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';

type Props = {
  text: string
};

type State = {};

class ReactComment extends React.Component<Props, State> {
  componentDidMount() {
    let el: any = ReactDOM.findDOMNode(this);
    ReactDOM.unmountComponentAtNode(el);
    el.outerHTML = this.createComment();
  }

  createComment() {
    let text = this.props.text;
    return `<!-- ${text} -->`;
  }

  render() {
    return <div />;
  }
}

export default ReactComment;
