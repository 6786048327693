import { authHeader, AxiosSubscriber, authHeaderWithJSON } from '../helpers';
import { API_ENDPOINTS } from '../constants';
import { Observable } from 'rxjs';
import { stringify } from 'query-string';

export const devicesService = {
  getDevicesById
};

/**@function get
 * Get products for super-admin
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='license Number'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @param {string} [search=''] - Maximum Numbers of records to be fetched
 * @param {object} [filter=null] - Maximum Numbers of records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getDevicesById(
  skip = 0,
  limit = 10,
  sort = 'name',
  order = 'asc',
  search = '',
  idName = '',
  Id = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        sort,
        order,
        search,
        idName,
        Id
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DEVICES.GET_DEVICES
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
