import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Icon from './Icon';
import { ColorConstants } from '../constants';
import { withTranslation } from 'react-i18next';

class PasswordChangedSuccessfulModal extends Component {
  translation = this.props.t;
  state = {};
  render() {
    return (
      <Modal show={this.props.show} backdrop="static" centered>
        <Modal.Body>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center p-5">
            <Icon
              iconName="check-circle"
              size="80"
              classes="mb-4 scaleUp"
              fill={ColorConstants.PRIMARY}
            />
            <p>{this.translation('passwordChangedSuccess')}</p>
            <Button
              variant="primary"
              size="lg"
              className="rounded-0 px-5"
              onClick={this.props.logout}
            >
              {this.translation('ok')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(PasswordChangedSuccessfulModal);
