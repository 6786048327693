import i18next from 'i18next';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

export const LANGUAGE_SERVICE = {
  changeLanguage
};

async function changeLanguage(lang) {
  await i18next.changeLanguage(lang);
  let currentUser = AuthenticationService.getCurrentUser();
  if (currentUser && currentUser.token) {
    currentUser.language = lang;
    const mobilizeLocalStorage = JSON.parse(localStorage.getItem('mobilize'));
    mobilizeLocalStorage.user.language = lang;
    localStorage.setItem('mobilize', JSON.stringify(mobilizeLocalStorage));
    UserService.updateCurrentUser(currentUser).subscribe();
  }
}
