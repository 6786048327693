export * from './alert.constants';
export * from './user.constants';
export * from './table.constants';
export * from './admin.constants';
export * from './api-endpoints.constants';
export * from './color.constants';
export * from './import.constants';
export * from './icon-name.constants';
export * from './form-validation-messages.constants';
export * from './regexp.constants';

export const LoaderConstants = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING'
};

export const SCSSConstants = {
  INPUT_HEIGHT: '3.28rem'
};

export const urlConstants = {
  SITE_ADMIN: '',
  SUPER_ADMIN: '/admin'
};

export const TIME_OUT = 30000;

/**Allowed image size to be uploaded 1024*1024
 * If you change KIT.HEIGHT, make sure to change the same in src\styles\pages\super-admin\kits\kit-details.scss
 */
export const ALLOWED_IMAGE = {
  USER_PROFILE: {
    SIZE: 1048576,
    HEIGHT: 128,
    WIDTH: 128
  },
  DOCUMENT: {
    PDF: {
      SIZE: 10485760
    },
    IMAGE: {
      SIZE: 5242880,
      HEIGHT: 128,
      WIDTH: 128
    }
  },
  KIT: {
    SIZE: 1048576,
    HEIGHT: 128,
    WIDTH: 128
  },
  KIT_ITEM: {
    SIZE: 1048576,
    HEIGHT: 25,
    WIDTH: 25
  }
};
