import React from 'react';
import { headerFormatterWithSort } from '../../../_shared/helpers';
import i18next from 'i18next';
import { Button } from 'react-bootstrap';

export const customersTableConstants = {
  customerColumns: (presentDeleteConfirm, goTo) => [
    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('firstName')),
      sort: true,
      formatter: (cell, row) => {
        return row.name;
      },
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },
    {
      dataField: 'lastName',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('lastName')),
      sort: true,
      formatter: (cell, row) => {
        return row.lastName ? row.lastName : 'N/A';
      },
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },
    {
      dataField: 'email',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('email')),
      sort: true,
      headerStyle: { width: '30%' },
      style: { width: '30%' }
    },
    {
      dataField: 'action',
      text: '',
      isDummyField: true,
      classes: 'text-right',
      formatter: (cell, row) => {
        return (
          <>
            <Button
              variant="outline-danger"
              className="mr-2"
              onClick={() =>
                presentDeleteConfirm(row._id, row.name, row.email, 'decline')
              }
              title={i18next.t('decline')}
            >
              {i18next.t('decline')}
            </Button>
            <Button
              variant="danger"
              className=""
              onClick={() =>
                presentDeleteConfirm(row._id, row.name, row.email, 'approve')
              }
              title={i18next.t('approve')}
            >
              {i18next.t('approve')}
            </Button>
          </>
        );
      }
    }
  ]
};
