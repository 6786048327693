export const API_ENDPOINTS = {
  ExportURL: 'https://ec2api.mobilize-dev.qspldev.com',
  URL: process.env.REACT_APP_API_URL,
  ALERTS: {
    GET_ALERTS: '/alerts',
    POST_ALERT: '/alerts'
  },
  AUTH: {
    POST_LOGIN: '/auth/login',
    POST_FORGOT_PASSWORD: '/auth/forgotPassword',
    PATCH_RESET_PASSWORD: '/auth/resetPassword',
    USER_VERIFY: '/auth/userVerify',
    PATCH_DELETE_USER: '/auth',
    PATCH_DELETE_REQUEST: '/deleteRequest'
  },
  CONFIG: {
    GET_CONFIG: '/config',
    PATCH_CONFIG: '/config'
  },
  DEVICES: {
    GET_DEVICES: '/device'
  },
  CURRENT_USER: {
    GET_CURRENT_USER: '/currentUser',
    PATCH_CURRENT_USER: '/currentUser',
    PATCH_CHANGE_PASSWORD: '/currentUser/changePassword',
    PATCH_LAST_CLICK: '/currentUser/lastClickOnAlert'
  },
  DASHBOARD: {
    GET_CHARTS_DATA: '/dashboard',
    GET_APPLICATION_STATISTICS: '/dashboard/applicationData'
  },
  DOCUMENT: {
    GET_DOCUMENTS: '/documents',
    POST_DOCUMENT: '/documents',
    PATCH_DOCUMENT: '/documents',
    DELETE_DOCUMENT: '/documents'
  },
  IMAGE: {
    PUT_UPLOAD_IMAGE: '/uploadImage',
    DELETE_IMAGE: '/image'
  },
  KIT_ITEMS: {
    GET_KIT_ITEMS: '/kitItems',
    POST_KIT_ITEMS: '/kitItems',
    PATCH_KIT_ITEMS: '/kitItems'
  },
  KITS: {
    GET_KITS: '/kits',
    POST_KITS: '/kits',
    DELETE_KIT: '/kits',
    PATCH_KIT: '/kits'
  },
  NOTES: {
    GET: {
      BY_USER_ID: '/notes'
    },
    POST_NOTE: '/notes',
    DELETE_NOTE: '/notes',
    PATCH_NOTE: '/notes'
  },
  PRODUCT_ITEMS: {
    GET_PRODUCT_ITEMS: '/productItems',
    PATCH_ITEMS: '/productItems'
  },
  PRODUCTS: {
    GET_PRODUCTS: '/products',
    GET_EXPIRY_PRODUCTS_ITEMS: '/products/expiryProductItems',
    GET_CHECK_SERIAL_NUMBER: '/products/checkSerialNumber',
    GET_SERIAL_NUMBER_AVAILABLE: '/products/serialNumberAvailable',
    PATCH_REGISTER_PRODUCT: '/products/registerProduct',
    PATCH_SERVICE_PLAN: '/products/servicePlan',
    PATCH_UNREGISTER_PRODUCT: '/products/unregisterProduct',
    POST_NEW_PRODUCT: '/products',
    IMPORT: '/products/import',
    DELETE_PRODUCT: '/products'
  },
  MAIL: {
    SEND_MAIL: '/sendMail'
  },
  SITE_ADMIN: {
    POST_REGISTER: '/auth/registration'
  },
  SUMMARY: {
    GET_SUMMARY: '/summary',
    ABSOLUTE_MEDIA: process.env.REACT_APP_TREATMENT_MEDIA_URL
  },
  SUPER_ADMIN: {
    GET_SUPER_ADMIN: '/users',
    PATCH_SUPER_ADMIN: '/users',
    DELETE_SUPER_ADMIN: '/users',
    POST_SUPER_ADMIN: '/users'
  },
  USERS: {
    GET_USERS: '/users',
    PATCH_USERS: '/users',
    DELETE_USERS: '/users',
    POST_USERS: '/users',
    PATCH_ACTIVATE: '/activate',
    PATCH_DEACTIVATE: '/deactivate',
    GET_DELETE_REQUEST_USERS: '/users/getDeleteRequestUser'
  },
  LICENSE: {
    GET_LICENSE: '/licenses',
    PATCH_LICENSE: '/licenses',
    POST_LICENSE: '/licenses'
  },
  PDF: {
    POST_GENERATE_PDF_FROM_HTML: '/pdf'
  }
};
