// @flow
import * as React from 'react';
import Step1 from './Step1/';
// import Step2 from './Step2/';
import { Container, Button, Row, Col, Card } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { kitService, ProductService } from '../../../_shared/services';
import { Kit, ReactRouterHistory } from '../../../models';
import MDSpinner from 'react-md-spinner';
import { ColorConstants } from '../../../_shared/constants';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { loaderActions } from '../../../_shared/redux/actions';
import { ConfirmDialog } from '../../../_shared/components';

type Props = {
  history: ReactRouterHistory,
  t: i18next.TFunction,
  startLoading(): void,
  stopLoading(): void
};

type State = {
  isLoading: boolean,
  kits: Kit[],
  validSerialNumbers: string[],
  // step: 'step1' | 'step2',
  selectedKit: Kit,
  createDate: Date | typeof undefined,
  showConfirmDelete: boolean
};

export class NewProduct extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Component's State */
  state = {
    isLoading: true,
    kits: [],
    validSerialNumbers: [],
    // step: 'step1',
    selectedKit: {
      name: '',
      description: '',
      _id: ''
    },
    createDate: undefined,
    showConfirmDelete: false
  };
  /**Once component mounts, call apis */
  componentDidMount() {
    this.getKitTypes();
  }
  /**Free up resources once component unmounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Get kit types */
  getKitTypes = () => {
    const getKitTypes$ = kitService
      .getKitTypes(0, 50, 'name', 'asc')
      .subscribe({
        next: (response: { data: { kits: Kit[], count: number } }) => {
          if (this.cleanup) {
            const kits = response.data.kits;
            this.setState({ kits, isLoading: false, selectedKit: kits[0] });
          }
        },
        error: (errorResponse: any) => {
          if (this.cleanup) {
            toastr.error(this.translation('error'), errorParser(errorResponse));
            this.setState({ isLoading: false });
          }
        }
      });
    this.cleanup = () => {
      getKitTypes$.unsubscribe();
    };
  };
  // /**Move to step 2 */
  // moveToStep2 = () => {
  //   if (
  //     this.state.validSerialNumbers.length &&
  //     this.state.selectedKit.name &&
  //     this.state.createDate
  //   ) {
  //     this.setState({ step: 'step2' });
  //   }
  // };

  /**Submit Unit Final */
  onSubmit = (validSerialNumbers: any) => {
    this.props.startLoading();
    const product = {
      kit: this.state.selectedKit._id,
      productCreatedDate: this.state.createDate,
      serialNumber: validSerialNumbers,
      kitItems: []
    };
    const createProduct$ = ProductService.createProduct(product).subscribe({
      next: (response: { data: { message: string } }) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          this.props.stopLoading();
          this.props.history.push('/admin/units');
          toastr.success(
            this.translation('unitCreated'),
            response.data.message
          );
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      createProduct$.unsubscribe();
    };
  };

  /**Redirects from child component */
  goTo = (path: string) => this.props.history.push(path);
  /**Confirm whether user wants to really go back or not */
  confirmGoBack = () => {
    this.setState({ showConfirmDelete: true });
  };
  /**Delete modal cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };
  /**Delete modal ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false }, () => {
      this.goBack();
    });
  };
  render() {
    return (
      <Container fluid>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn="No"
          okBtn="Yes"
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.translation('saveWarning')}
        />
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <>
            {/* <Row>
            <Col className="mb-3">
              <Button
                variant={this.state.step === 'step1' ? 'link' : ''}
                size="lg"
                onClick={() => this.confirmGoBack()}
              >
                {i18next.t('step-1')}
              </Button>
              <span className="font-size-lg">|</span>
              <Button
                variant={this.state.step === 'step2' ? 'link' : ''}
                size="lg"
                title={i18next.t('pleaseClickNext')}
                disabled={this.state.step !== 'step2'}
              >
                {i18next.t('step-2')}
              </Button>
            </Col>
          </Row> */}
            <Card>
              <Card.Body>
                {this.state.kits.length ? (
                  // this.state.step === 'step1' ? (
                  <Step1
                    createDate={this.state.createDate}
                    kits={this.state.kits}
                    onValidSerialNumbers={(validSerialNumbers: string[]) =>
                      this.setState({ validSerialNumbers }, () => {
                        // this.moveToStep2();
                        this.onSubmit(validSerialNumbers);
                      })
                    }
                    onKitTypeSelected={(selectedKit: Kit) => {
                      this.setState({ selectedKit });
                    }}
                    onCreateDateChange={(createDate: Date) => {
                      this.setState({ createDate });
                    }}
                  />
                ) : (
                  // ) : (
                  //   <Step2
                  //     validSerialNumbers={this.state.validSerialNumbers}
                  //     selectedKit={this.state.selectedKit}
                  //     productCreatedDate={this.state.createDate}
                  //     goTo={(path: string) => this.goTo(path)}
                  //     goBack={() => this.confirmGoBack()}
                  //   />
                  // )
                  <div className="w-100 text-center">
                    {this.translation('noKitTypesFound')}
                    <br />
                    <Button
                      variant="link"
                      className="text-blue"
                      onClick={() => this.goTo('/admin/kits/add-kit-type')}
                    >
                      {this.translation('addKitType')}
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </>
        )}
      </Container>
    );
  }

  goBack() {
    return this.setState(
      {
        validSerialNumbers: [],
        selectedKit: {
          name: '',
          description: '',
          _id: ''
        },
        createDate: new Date()
      },
      this.getKitTypes()
    );
  }
}
const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

// export default withTranslation()(NewProduct);
export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(NewProduct)
);
