import { authHeader, AxiosSubscriber, authHeaderWithJSON } from '../helpers';
import { API_ENDPOINTS } from '../constants';
import { Observable } from 'rxjs';
import { stringify } from 'querystring';

export const DocumentService = {
  add,
  delete: _delete,
  getDocuments,
  getById,
  update
};

/**@function add
 * Add document
 * @param {IDocument} document - Document to be added
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function add(document) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DOCUMENT.POST_DOCUMENT
      }`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      document
    );
  });
}

/**@function getDocuments
 * Get documents from server
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [search=''] - Search text
 * @param {'Image'|'Video'|'Document'|''} [type=''] - Search text
 * @param {string} [sort='title'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='asc'] - Order of sorting
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getDocuments(
  skip = 0,
  limit = 10,
  search = '',
  type = '',
  sort = 'title',
  order = 'asc',
  banner = false
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({
        skip,
        limit,
        search,
        type,
        sort,
        order,
        banner
      })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DOCUMENT.GET_DOCUMENTS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function _delete
 * Delete a document
 * @param {string} documentId - Document ID, which will be deleted
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function _delete(documentId) {
  return new Observable(observer => {
    const requestType = 'delete',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DOCUMENT.DELETE_DOCUMENT
      }/${documentId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getById
 * Get document by id from server
 * @param {string} documentId - Document ID, which will be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function getById(documentId) {
  return new Observable(observer => {
    const requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DOCUMENT.GET_DOCUMENTS
      }/${documentId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function update
 * Update document
 * @param {string} documentId - Document ID
 * @param {IDocument} document - Document to be updated
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function update(documentId, document) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.DOCUMENT.PATCH_DOCUMENT
      }/${documentId}`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      document
    );
  });
}
