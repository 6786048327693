import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toastr } from 'react-redux-toastr';

import { FormGroup } from '../../../_shared/components';
import { tableConstants } from '../../../_shared/constants';
import { TreatmentSummariesTableConstants } from './table.constants';
import { TreatmentSummaryService } from '../../../_shared/services';
import { tick_then, errorParser } from '../../../_shared/helpers';
import StepsViewer from './StepsViewer';
import { withTranslation } from 'react-i18next';
import { fromEvent } from 'rxjs';
import { debounceTime, tap, switchMap } from 'rxjs/operators';

export class TreatmentSummaries extends Component {
  constructor(props) {
    super(props);
    /**Let's hide size per page dropdown in pagination */
    this.showSizePerPageDD = false;
    this.cleanup = null;
    this.translation = this.props.t;
  }
  getColumns = (activeTreatmentId = null) => {
    return TreatmentSummariesTableConstants.columns(activeTreatmentId);
  };
  state = {
    isLoading: true,
    activeTreatment: {},
    summaries: [],
    columns: this.getColumns(),
    options: {},
    sortField: 'timeStamp',
    sortOrder: 'desc',
    currentIndex: 0,
    searchText: '',
    page: 1,
    isTableLoading: true,
    sizePerPage: 10
  };
  componentDidMount() {
    this.getSummaries();
  }
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$ = fromEvent(
      document.getElementById('treatment-summaries-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            searchText: inputEvent.target.value,
            isTableLoading: true,
            page: 1,
            currentIndex: 0
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText
          );
        })
      )
      .subscribe(this.handleResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    { sortField, sortOrder, tableData, page, sizePerPage }
  ) => {
    const currentIndex =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getSummaries(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Handle observable subscribe response */
  handleResponse = () => ({
    next: response => {
      if (this.cleanup) {
        const {
          currentIndex,
          sizePerPage,
          sortField,
          sortOrder,
          page,
          searchText
        } = this.state;
        const { summaries, totalCount } = response.data;
        const options = tableConstants.paginationOptions(
          totalCount,
          this.showSizePerPageDD,
          page,
          sizePerPage
        );
        this.setState(
          {
            options,
            summaries,
            activeTreatment: Object.assign({}, summaries[0]),
            isTableLoading: false,
            currentIndex,
            sizePerPage,
            sortField,
            sortOrder,
            page,
            searchText
          },
          () => {
            this.updateColumns();
          }
        );
      }
    },
    error: errorResponse => {
      if (this.cleanup) {
        this.setState({ isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  getObservable = (
    currentIndex,
    sizePerPage,
    sortField,
    sortOrder,
    searchText
  ) => {
    return TreatmentSummaryService.getTimeStamps(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    );
  };
  getSummaries(
    currentIndex = this.state.currentIndex,
    sizePerPage = this.state.sizePerPage,
    sortField = this.state.sortField,
    sortOrder = this.state.sortOrder,
    searchText = this.state.searchText
  ) {
    this.setState({ isTableLoading: true });
    const getSummaries$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    ).subscribe(this.handleResponse());
    this.cleanup = () => {
      getSummaries$.unsubscribe();
    };
  }

  updateColumns() {
    // we have to make sure that state is updated properly before this, that's why tick_then
    tick_then(() => {
      const columns = this.getColumns(this.state.activeTreatment._id);
      this.setState({ columns });
    });
  }

  render() {
    return (
      <Container fluid>
        <Row className="justify-content-start align-items-center">
          <Col sm={12} md={5} className="mb-3">
            <FormGroup
              type="text"
              label={this.translation('search')}
              icon="search"
              iconPosition="right"
              disabled={this.state.isLoading}
              classes="mb-2"
              formControlId="treatment-summaries-search-input"
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="5">
            <BootstrapTable
              remote
              classes="with-gap"
              bordered={false}
              bootstrap4
              keyField="_id"
              data={this.state.summaries}
              columns={this.state.columns}
              pagination={
                this.state.summaries.length
                  ? paginationFactory(this.state.options)
                  : null
              }
              noDataIndication={
                this.state.isTableLoading
                  ? this.translation('pleaseWait')
                  : this.translation('noData')
              }
              rowClasses="colored-background cursor-pointer"
              rowEvents={{
                onClick: (clickEvent, row) => {
                  const activeTreatment = this.state.summaries.filter(
                    summary => summary._id === row._id
                  )[0];
                  this.setState({
                    activeTreatment
                  });
                  this.updateColumns();
                }
              }}
              defaultSorted={[
                {
                  dataField: this.state.sortField,
                  order: this.state.sortOrder
                }
              ]}
              onTableChange={this.onTableChange}
              loading={this.state.isTableLoading}
              overlay={tableConstants.overlay()}
              wrapperClasses="table-responsive-sm"
            />
          </Col>
          <Col sm="12" md="7">
            <StepsViewer
              treatment={this.state.activeTreatment}
              isLoading={this.state.isTableLoading}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(TreatmentSummaries);
