import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Button, Form, Row } from 'react-bootstrap';
import { forkJoin } from 'rxjs';
import { toastr } from 'react-redux-toastr';

import { FormGroup } from '../../../../_shared/components';
import { ProductService } from '../../../../_shared/services';
import { errorParser } from '../../../../_shared/helpers';
import { withTranslation, Translation } from 'react-i18next';

/**Validation schema for formik */
const AddByRangeFormSchema = Yup.object().shape({
  prefix: Yup.string(),
  // .test(
  //   'valid-prefix',
  //   <Translation>{t => <>{t('formValidations.prefixError')}</>}</Translation>,
  //   value => {
  //     return /^[A-Z]{2}[0-9]{2}[A-Z]{1}$/.test(value);
  //   }
  // )
  // .length(5)
  fromRange: Yup.string().required(),
  // .test(
  //   'is-digits',
  //   <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
  //   value => !isNaN(value)
  // )
  // .length(6)
  // toRange: Yup.string().required()
  // .test(
  //   'is-digits',
  //   <Translation>{t => <>{t('formValidations.onlyDigits')}</>}</Translation>,
  //   value => !isNaN(value)
  // )
  // .length(6)
  count: Yup.string().required()
});

class AddByRange extends Component {
  translation = this.props.t;
  state = {
    serialNumbersRange: [],
    count: 0,
    prefix: '',
    fromRange: '',
    toRange: ''
  };
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  /**Call api to add product by range */
  addProductsByRange = () => {
    this.props.startLoading();
    let observables$ = [];
    //make an array of all observables which will check validity of serial numbers
    // this.state.serialNumbersRange.forEach(serialNumber => {
    observables$.push(
      ProductService.serialNumberAvailable(
        this.state.prefix,
        this.state.fromRange,
        this.state.count
      )
    );
    // });
    // check all serial numbers first. if any fails, don't add.
    forkJoin(...observables$).subscribe({
      next: response => {
        // all serial numbers are valid
        this.props.onValidSerialNumbers(this.state.serialNumbersRange);
      },
      error: errorResponse => {
        this.props.stopLoading();
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    });
  };
  countSerialNumbers = changeEvent => {
    this.setState(
      { [changeEvent.target.name]: changeEvent.target.value },
      () => {
        const fromRange = isNaN(this.state.fromRange)
          ? 0
          : this.state.fromRange;
        const count = isNaN(this.state.count) ? 0 : this.state.count;
        const toRange = String(
          this.state.count >= 1
            ? Number(count) + Number(fromRange) - 1
            : Number(count) + Number(fromRange)
        ).padStart(fromRange.length, fromRange);
        this.setState({
          toRange: fromRange !== '' && fromRange !== undefined ? toRange : ''
        });
      }
    );
  };
  render() {
    return (
      <Col className="pl-0">
        <Formik
          ref={this.formRef}
          initialValues={{
            fromRange: '',
            toRange: '',
            prefix: '',
            count: ''
          }}
          validate={values => {
            let errors = {};
            // if (values.toRange.length === 8 && values.fromRange.length === 8) {
            //   if (+values.toRange <= +values.fromRange) {
            //     errors.toRange = this.translation(
            //       'formValidations.toRangeCNTLEFromRange'
            //     );
            //   }
            //   if (+values.fromRange >= +values.toRange) {
            //     errors.fromRange = this.translation(
            //       'formValidations.fromRangeCNTEMToRange'
            //     );
            //   }
            //   if (+values.toRange > +values.fromRange + 10000) {
            //     errors.toRange = this.translation(
            //       'formValidations.only10000Units'
            //     );
            //   }
            // }
            if (this.state.count <= 0) {
              errors.count = this.translation(
                'formValidations.onlyPositiveUnits'
              );
            }
            if (this.state.toRange > +values.fromRange + 10000) {
              errors.count = this.translation('formValidations.only10000Units');
            }
            return errors;
          }}
          validationSchema={AddByRangeFormSchema}
          onSubmit={values => {
            if (this.props.createDate) {
              const fromRange = values.fromRange,
                toRange = +this.state.toRange;
              const serialNumberRangeLength = toRange - fromRange + 1;
              const serialNumbersRange = [
                ...Array(serialNumberRangeLength)
              ].map(
                (_, index) =>
                  values.prefix +
                  String(Number(fromRange) + index).padStart(
                    fromRange.length,
                    fromRange
                  )
              );
              this.setState({
                serialNumbersRange
              });
              this.addProductsByRange();
            } else {
              this.props.triggerDateRequired();
            }
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors, touched, handleChange, handleSubmit }) => {
            return (
              <Form className="custom-form-control" onSubmit={handleSubmit}>
                <Form.Row className="align-items-center">
                  <Col sm="12" md="9">
                    <Row>
                      <Col xs={12} sm={12} md={4}>
                        <Form.Group
                          as={Row}
                          controlId="prefixId"
                          className="align-items-center"
                        >
                          <Form.Label column className="mb-3">
                            {this.translation('prefix')}
                          </Form.Label>
                          <Col xs sm md="7">
                            <FormGroup
                              formControlName="prefix"
                              type="text"
                              label="MF20A"
                              handleChange={changeEvent => {
                                this.countSerialNumbers(changeEvent);
                                handleChange(changeEvent);
                              }}
                              touched={touched['prefix']}
                              error={errors['prefix']}
                              alertPositionAbsolute
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={4}>
                        <Form.Group
                          as={Row}
                          controlId="fromRangeId"
                          className="align-items-center"
                        >
                          <Form.Label column className="mb-3">
                            {this.translation('startingFrom')}
                          </Form.Label>
                          <Col xs sm md="7">
                            <FormGroup
                              formControlName="fromRange"
                              type="text"
                              label="XXXXXX"
                              handleChange={changeEvent => {
                                this.countSerialNumbers(changeEvent);
                                handleChange(changeEvent);
                              }}
                              touched={touched['fromRange']}
                              error={errors['fromRange']}
                              required
                              alertPositionAbsolute
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={4}>
                        <Form.Group
                          as={Row}
                          controlId="toRangeId"
                          className="align-items-center"
                        >
                          <Form.Label column className="mb-3">
                            {this.translation('Quantity')}
                          </Form.Label>
                          <Col xs sm md="7">
                            <FormGroup
                              formControlName="count"
                              type="text"
                              label="XXXXXX"
                              handleChange={changeEvent => {
                                this.countSerialNumbers(changeEvent);
                                handleChange(changeEvent);
                              }}
                              touched={touched['count']}
                              error={errors['count']}
                              required
                              alertPositionAbsolute
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="5" />
                </Form.Row>
                <p className="font-size-lg float-left">
                  {this.translation('LastSerialNumber') +
                    ' ' +
                    this.state.prefix +
                    this.state.toRange}
                </p>
                <Button
                  type="submit"
                  variant="success"
                  size="lg"
                  className="rounded-0 px-5 float-right"
                >
                  {this.translation('submit')}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Col>
    );
  }
}

export default withTranslation()(AddByRange);
