import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import XLSX from 'xlsx';
import { connect } from 'react-redux';

import { Icon } from '../../../_shared/components';
import { ColorConstants, SHEET_CONSTANT } from '../../../_shared/constants';
import { loaderActions } from '../../../_shared/redux/actions';
import { ProductService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
class ImportCSVModal extends Component {
  translation = this.props.t;
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }
  state = {
    fileError: '',
    file: undefined,
    fileName: ''
  };

  handleFiles = files => {
    const file = files[0];
    if (/\.(xlsx|xls)$/i.test(file.name)) {
      const reader = new FileReader();
      reader.onload = readerOnloadEvent => {
        this.setState({
          file: readerOnloadEvent.target.result,
          fileName: file.name
        });
      };
      reader.readAsBinaryString(file);
    } else {
      const fileError = this.translation('allowedFileTypes', {
        fileTypes: 'excel (.xls, .xlsx)'
      });
      this.setState({ fileError });
      toastr.warning(this.translation('warning'), fileError);
    }
  };

  ImportData(serialNumberBox, kitNameCell, [], customerNameBox) {
    ProductService.importData(
      serialNumberBox,
      kitNameCell,
      [],
      customerNameBox
    ).subscribe({
      next: response => {
        toastr.success(
          this.translation('unit') +
            ' ' +
            this.translation('create', { context: 'past' }),
          response.data.message
        );
        this.props.onSuccess();
        this.close();
        return;
      },
      error: errorResponse => {
        this.close();
        toastr.error(this.translation('error'), errorParser(errorResponse));
        return;
      }
    });
  }

  handleSubmit() {
    this.props.onHide();
    this.props.startLoading();
    try {
      let workBook = XLSX.read(this.state.file, { type: 'binary' });
      Object.keys(workBook.Sheets).map(sheetName => {
        let serialNumberBox = [];
        let customerNameBox = [];
        var kitNameCell = '';
        var ws = workBook.Sheets[sheetName];
        var aoa = XLSX.utils
          .sheet_to_json(ws, { header: 0, raw: false })
          .slice(0);
        aoa.forEach(function(serialNumber) {
          if (serialNumber['Customer Name*']) {
            customerNameBox.push(serialNumber['Customer Name*']);
          }
          if (serialNumber['Serial Number*']) {
            serialNumberBox.push(serialNumber['Serial Number*']);
          }
          kitNameCell = sheetName;
        });
        if (serialNumberBox.length > 0) {
          this.ImportData(serialNumberBox, kitNameCell, [], customerNameBox);
        }
      });
    } catch (handleSubmitError) {
      toastr.error(
        this.translation('somethingWentWrong'),
        this.translation('somethingWentWrong')
      );
      this.close();
      return;
    }
  }

  close() {
    this.setState({
      file: undefined,
      fileName: ''
    });
    this.props.stopLoading();
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.close();
          this.props.onHide();
        }}
        centered
      >
        <Modal.Body>
          <h5 className="mb-4">
            {this.translation('import') +
              ' ' +
              this.translation('from') +
              ' ' +
              this.translation('excel')}
            <Icon
              iconName="cancel"
              classes="float-right cursor-pointer"
              onClick={() => {
                this.close();
                this.props.onHide();
              }}
            />
          </h5>
          <div
            ref={this.imagePreviewer}
            className={[
              'upload-box',
              'border',
              'd-flex',
              'align-items-center',
              'justify-content-center',
              'flex-column',
              'p-2',
              'mb-4',
              this.state.dragEnter
                ? 'border-dashed border-thick bg-primary-rgba-1'
                : ''
            ].join(' ')}
            onDragEnter={dragEnterEvent => {
              dragEnterEvent.stopPropagation();
              dragEnterEvent.preventDefault();
              this.setState({ dragEnter: true, dragLeave: false });
            }}
            onDragOver={dragOverEvent => {
              dragOverEvent.stopPropagation();
              dragOverEvent.preventDefault();
              this.setState({ dragEnter: true, dragLeave: false });
            }}
            onDragLeave={dragLeaveEvent => {
              dragLeaveEvent.stopPropagation();
              dragLeaveEvent.preventDefault();
              this.setState({ dragEnter: false, dragLeave: true });
            }}
            onDrop={dropEvent => {
              dropEvent.stopPropagation();
              dropEvent.preventDefault();
              const dt = dropEvent.dataTransfer;
              const files = dt.files;
              this.handleFiles(files);
              this.setState({ dragEnter: false, dragLeave: true });
            }}
          >
            {!this.state.file ? (
              <>
                <Icon
                  iconName="upload"
                  size="20"
                  fill={ColorConstants.TEXT_MUTED}
                />
                <p className="text-muted mb-1">
                  {this.translation('dropExcelFileHere')}
                </p>
                <Button
                  variant="link"
                  className="text-blue my-0"
                  onClick={() => this.fileInput.current.click()}
                >
                  {this.translation('browse') +
                    ' ' +
                    this.translation('local') +
                    ' ' +
                    this.translation('file', { count: 0 })}
                </Button>
                <input
                  type="file"
                  ref={this.fileInput}
                  className="d-none"
                  onChange={changeEvent =>
                    this.handleFiles(changeEvent.target.files)
                  }
                />
              </>
            ) : (
              <>
                <Icon
                  iconName="xlsx"
                  size="50"
                  fill={ColorConstants.TEXT_MUTED}
                />
                <p className="text-muted mb-1">{this.state.fileName}</p>
              </>
            )}
          </div>
          <div className="w-100 text-center">
            <Button
              variant="success"
              size="lg"
              className="px-5 rounded-0"
              disabled={!this.state.file}
              onClick={this.handleSubmit.bind(this)}
            >
              {this.translation('submit')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(ImportCSVModal)
);
