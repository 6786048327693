// @flow

import * as React from 'react';
import * as ReactDatePicker from 'react-datepicker';
import Icon from './Icon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { randomString } from '../helpers';

type Props = {
  name: string,
  id: string,
  minDate: Date | string | void,
  maxDate: Date | string | void,
  selected: Date | string | void,
  className: string,
  onChange(e: any): void,
  placeholder: string,
  readOnly: boolean,
  error: string,
  showError: 'tooltip',
  wrapperClassName: string,
  disabled?: boolean,
  title?: boolean
};

type State = {};

/**@class DatePicker
 * We should use this date-picker in whole application, so that behavior of the same remains same.
 * @property {string} name - HTML Element name
 * @property {string} id - HTML Element ID
 * @property {Date | string | undefined} minDate - Minimum date
 * @property {Date | string | undefined} maxDate - Maximum date
 * @property {Date | string | undefined} selected - Selected date
 * @property {string} className - CSS Classes
 * @property {function} onChange - Called when value is changed
 * @property {string} [placeholder='MMM DD, YYYY'] - Placeholder text
 * @property {boolean} readOnly - Whether date-picker is readonly or not
 * @property {string} error - Error to be displayed in tooltip
 * @property {showError} 'tooltip' | undefined - Whether to show error or not
 * @property { wrapperClassName } string - Classes to be assigned to ReactDatePicker's parent div
 * @property { disabled } boolean
 * @property { title } string
 */
class DatePicker extends React.Component<Props, State> {
  datepicker: any = React.createRef();
  /**Component's state */
  state = {};
  render() {
    const {
      name,
      minDate,
      maxDate,
      selected,
      className,
      onChange,
      placeholder = 'MMM DD, YYYY',
      readOnly,
      id,
      error,
      showError,
      wrapperClassName,
      disabled,
      title
    } = this.props;
    return (
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip
            id={'tooltip-' + (id ? id : randomString())}
            className={error && showError === 'tooltip' ? '' : 'd-none'}
          >
            {error}
          </Tooltip>
        }
      >
        <div
          className={`d-inline-block position-relative ${
            wrapperClassName ? wrapperClassName : ''
          }`}
        >
          <ReactDatePicker.default
            ref={this.datepicker}
            name={name}
            disabledKeyboardNavigation={true}
            onKeyDown={onKeyDownEvent => onKeyDownEvent.preventDefault()}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="MMM dd, yyyy"
            minDate={minDate}
            maxDate={maxDate}
            selected={selected}
            className={['rounded-0', className ? className : ''].join(' ')}
            onChange={onChange}
            placeholderText={placeholder}
            readOnly={readOnly}
            isClearable={selected && !disabled ? true : false}
            clearButtonTitle="Clear date"
            id={id ? id : randomString()}
            disabled={disabled}
            title={title}
          />
          {!selected && (
            <Icon
              iconName="calendar"
              classes={[
                'position-absolute',
                'datepicker-icon',
                className && className.indexOf('form-control-sm') > -1
                  ? 'sm'
                  : ''
              ].join(' ')}
              size={
                className && className.indexOf('form-control-sm') > -1 ? 16 : 18
              }
              fill="none"
              style={{}}
              onClick={() => {}}
            />
          )}
        </div>
      </OverlayTrigger>
    );
  }
}

export default DatePicker;
