import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import fileDownload from 'js-file-download';
import moment from 'moment';

import { ColorConstants, tableConstants } from '../../../_shared/constants';
import { FormGroup, Icon } from '../../../_shared/components';

import { ProductService, itemService } from '../../../_shared/services';

import { columns } from './table.constants';
import { withTranslation } from 'react-i18next';
import { errorParser } from '../../../_shared/helpers';

export class ExpiringItems extends Component {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup = null;
  /**Component state */
  state = {
    isTableLoading: true,
    isFileLoading: false,
    itemsArrived: false,
    items: [],
    columns: columns,
    options: {},
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'expiryDate',
    sortOrder: 'asc',
    page: 1,
    searchText: ''
  };
  /**Call service apis when component mounted */
  componentDidMount() {
    this.getItems();
    this.onSearchChange();
  }
  /**Free up resources when unmounting */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$ = fromEvent(
      document.getElementById('expiring-items-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            searchText: inputEvent.target.value,
            isTableLoading: true,
            page: 1,
            currentIndex: 0
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.page,
            this.state.searchText
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type,
    { sortField, sortOrder, tableData, page, sizePerPage }
  ) => {
    const currentIndex =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getItems(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Observable */
  getObservable = (
    currentIndex,
    sizePerPage,
    sortField,
    sortOrder,
    page,
    searchText
  ) => {
    return ProductService.getExpiryProductItems(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    );
  };
  /**Subscriber-response handler */
  handelResponse = () => ({
    next: response => {
      if (this.cleanup) {
        const { sizePerPage, page } = this.state;
        const { productItems: items, count } = response.data;
        const options = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          items,
          isTableLoading: false,
          itemsArrived: true
        });
      }
    },
    error: errorResponse => {
      if (this.cleanup) {
        this.setState({ isTableLoading: false, itemsArrived: true });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Get expiring items */
  getItems = (
    currentIndex = this.state.currentIndex,
    sizePerPage = this.state.sizePerPage,
    sortField = this.state.sortField,
    sortOrder = this.state.sortOrder,
    searchText = this.state.searchText
  ) => {
    this.setState({ isTableLoading: true });
    const getItems$ = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      getItems$.unsubscribe();
    };
  };
  exportData = () => {
    this.setState({ isExporting: true });
    const export$ = itemService
      .export(
        this.state.currentIndex,
        this.state.sizePerPage,
        this.state.sortField,
        this.state.sortOrder,
        this.state.searchText
      )
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.setState({ isExporting: false });
            fileDownload(
              response.data,
              this.translation('fileNames.unitItem') +
                '-' +
                moment().format('MM-DD-Y:HH-mm-ss') +
                '.csv'
            );
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            toastr.error(
              this.translation('errorWhileExporting'),
              errorParser(errorResponse)
            );
            this.setState({ isExporting: false });
          }
        }
      });
    this.cleanup = () => {
      export$.unsubscribe();
    };
  };
  render() {
    return (
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col sm={8} md={4} className="mb-3">
            <FormGroup
              type="text"
              label={this.translation('searchExpiringItemsSiteAdmin')}
              title={this.translation('searchExpiringItemsSiteAdmin')}
              icon="search"
              iconPosition="right"
              formControlId="expiring-items-search-input"
              classes="mb-2"
            />
          </Col>
          <Col className="text-right mb-4">
            <Button
              variant=""
              className="mb-2"
              onClick={() => this.exportData()}
              disabled={this.state.isExporting}
            >
              {this.state.isExporting ? (
                <>
                  {this.translation('pleaseWait')}...
                  <MDSpinner
                    singleColor={ColorConstants.PRIMARY}
                    className="ml-2"
                  />
                </>
              ) : (
                <>
                  {this.translation('exportData')}
                  <Icon iconName="share" size="26" classes="ml-2" />
                </>
              )}
            </Button>
          </Col>
        </Row>
        {this.state.isTableLoading && !this.state.itemsArrived ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <Row>
            <Col xs={12}>
              <BootstrapTable
                remote
                classes="with-gap"
                bordered={false}
                bootstrap4
                keyField="_id"
                data={this.state.items}
                columns={this.state.columns}
                pagination={
                  this.state.items.length
                    ? paginationFactory(this.state.options)
                    : null
                }
                wrapperClasses="table-responsive-sm"
                noDataIndication={
                  this.state.isTableLoading
                    ? this.translation('pleaseWait')
                    : this.translation('noData')
                }
                rowClasses="colored-background"
                loading={this.state.isTableLoading}
                overlay={tableConstants.overlay()}
                onTableChange={this.onTableChange}
                defaultSorted={[
                  {
                    dataField: this.state.sortField,
                    order: this.state.sortOrder
                  }
                ]}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default withTranslation()(ExpiringItems);
