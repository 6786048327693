import { userConstants, LoaderConstants } from '../../constants';
import { AuthenticationService, UserService } from '../../services';
import { alertActions } from './alert.actions';
import { errorParser } from '../../helpers';
import i18next from 'i18next';

export const authenticationActions = {
  login,
  logout,
  update,
  userVerify
};

function login({ email, password }) {
  return dispatch => {
    dispatch(request({ email }));
    AuthenticationService.login(email, password).subscribe({
      next: response => {
        let mobilizeLocalStorage = JSON.parse(localStorage.getItem('mobilize'));
        let responseUser = response.data.user;
        let responseToken = response.data.token;
        const localStorageUser = {
          token: responseToken,
          ...responseUser
        };
        mobilizeLocalStorage = {
          ...mobilizeLocalStorage,
          user: localStorageUser
        };
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('mobilize', JSON.stringify(mobilizeLocalStorage));
        if (i18next.language === responseUser.language) {
          dispatch(success(localStorageUser));
        } else {
          const lang = (responseUser.language = i18next.language);
          UserService.updateCurrentUser(responseUser).subscribe({
            next: updateUserResponse => {
              mobilizeLocalStorage.user.language = lang;
              localStorage.setItem(
                'mobilize',
                JSON.stringify(mobilizeLocalStorage)
              );
              dispatch(success(localStorageUser));
            },
            error: updateUserError => {
              dispatch(failure({ email, password }));
              dispatch(alertActions.error(errorParser(updateUserError)));
            }
          });
        }
      },
      error: errorResponse => {
        dispatch(failure({ email, password }));
        dispatch(alertActions.error(errorParser(errorResponse)));
      }
    });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(user) {
    return { type: userConstants.LOGIN_FAILURE, user };
  }
}

function userVerify({ email, password }) {
  return dispatch => {
    dispatch(request({ email }));
    AuthenticationService.userVerify(email, password).subscribe({
      next: response => {
        let responseUser = response.data.user;
        let responseToken = response.data.token;
        return response;
      },
      error: errorResponse => {
        dispatch(failure({ email, password }));
        dispatch(alertActions.error(errorParser(errorResponse)));
      }
    });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(user) {
    return { type: userConstants.LOGIN_FAILURE, user };
  }
}

function logout(adminLogout = undefined) {
  return dispatch => {
    dispatch(startLoading());
    AuthenticationService.logout(adminLogout).then(
      () => {
        dispatch(success());
        dispatch(stopLoading());
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
        dispatch(stopLoading());
      }
    );
  };

  function startLoading() {
    return { type: LoaderConstants.START_LOADING };
  }
  function stopLoading() {
    return { type: LoaderConstants.STOP_LOADING };
  }
  function success() {
    return { type: userConstants.LOGOUT_SUCCESS };
  }
  function failure() {
    return { type: userConstants.LOGOUT_FAILURE };
  }
}

function update(user) {
  return dispatch => {
    let mobilizeLocalStorage = JSON.parse(localStorage.getItem('mobilize'));
    let localStorageUser = mobilizeLocalStorage.user;
    localStorageUser = { ...localStorageUser, ...user };
    mobilizeLocalStorage = { ...mobilizeLocalStorage, user: localStorageUser };
    // store updated user details
    localStorage.setItem('mobilize', JSON.stringify(mobilizeLocalStorage));
    dispatch({ type: userConstants.UPDATE_USER, user: localStorageUser });
  };
}
