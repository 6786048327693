import { AxiosSubscriber, authHeader } from '../helpers';
import { API_ENDPOINTS } from '../constants';
import { stringify } from 'query-string';
import { Observable } from 'rxjs';

export const TreatmentSummaryService = {
  getTimeStamps,
  getSummaryById,
  getPDFById
};

/**@function getTimeStamps
 * Get treatment summary for currently logged in user
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @param {string} [sort='timeStamp'] - The field name on which sorting should be done
 * @param {'asc'|'desc'} [order='desc'] - Order of sorting
 * @param {string} [search=''] - String to be searched
 * @returns {Observable} observable - Observable to which we can subscribe.
 */
function getTimeStamps(
  skip = 0,
  limit = 10,
  sort = 'timeStamp',
  order = 'desc',
  search = ''
) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit, search, sort, order })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.SUMMARY.GET_SUMMARY
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getSummaryById
 * Get Treatment Summary by Treatment Summary ID
 * @param {string} treatmentId - String to be searched
 * @returns {Observable} observable - Observable to which we can subscribe.
 */
function getSummaryById(treatmentId) {
  return new Observable(observer => {
    const requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.SUMMARY.GET_SUMMARY
      }/${treatmentId}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}

/**@function getSummaryById
 * Get Treatment Summary by Treatment Summary ID
 * @param {string} treatmentId - String to be searched
 * @returns {Observable} observable - Observable to which we can subscribe.
 */
function getPDFById(treatmentId, fileName) {
  return new Observable(observer => {
    const QUERY_PARAMS = `?${stringify({
        fileName,
        timeZone: new Date().getTimezoneOffset()
      })}`,
      requestType = 'get',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.SUMMARY.GET_SUMMARY
      }/${treatmentId}/pdf${QUERY_PARAMS}`,
      axiosOptions = {
        headers: { ...authHeader() }
      };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
