// @flow
import * as React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { mergeMap, map, concatMap } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';

import UserAccountDetails from './Details';

import { ColorConstants } from '../../../_shared/constants';
import { UserService, ImageService } from '../../../_shared/services';
import { Icon } from '../../../_shared/components';
import {
  loaderActions,
  authenticationActions
} from '../../../_shared/redux/actions';
import { UserAccountModel } from '../../../models';
import { toCapitalize } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type State = {
  account: UserAccountModel,
  isLoading: boolean,
  editDetails: boolean
};

type Props = {
  startLoading(): void,
  stopLoading(): void,
  updateUser(user: any): void,
  history: any,
  t: i18next.TFunction,
  logout(): void
};

export class UserAccount extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any;
  /**Submit form handler */
  submitDetailForm: any;
  /**Component's state */
  state = {
    account: {
      _id: '',
      email: '',
      name: '',
      lastName: '',
      userType: 'individual',
      isVerified: false,
      type: 'super',
      isDeleted: false,
      isPasswordReset: false,
      imageSrc: ''
    },
    isLoading: true,
    editDetails: false
  };
  /**Perform network calls, once component is mounted */
  componentDidMount() {
    this.getAccountDetails();
  }
  /**Free up resources on unmount */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Call api to get current user's account details */
  getAccountDetails() {
    this.setState({ isLoading: true });
    const getCurrentUser$ = UserService.getCurrentUser().subscribe({
      next: (response: {
        data: {
          currentUser: UserAccountModel
        }
      }) => {
        if (this.cleanup) {
          const account = Object.assign(
            this.state.account,
            response.data.currentUser
          );
          this.setState({ account, isLoading: false }, () => {
            this.updateLocalStorageUser();
          });
        }
      },
      error: (errorResponse: { data: { message: string } }) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorResponse.data.message);
        }
      }
    });
    this.cleanup = () => {
      getCurrentUser$.unsubscribe();
    };
  }

  /**Submit form handler for each form */
  handleSubmitForm = (whichForm: any) => {
    switch (whichForm) {
      case 'detail':
        if (this.submitDetailForm) {
          this.submitDetailForm();
        }
        break;
      default:
        break;
    }
  };

  /**Submit form binder for each form, so that we can call onSubmit from parent */
  bindSubmitForm = (whichForm: string, submitForm: any) => {
    switch (whichForm) {
      case 'detail':
        this.submitDetailForm = submitForm;
        break;
      default:
        break;
    }
  };

  /**Submit form to update details */
  onSubmit = (values: any, imageFile?: File) => {
    this.props.startLoading();
    this.setState({
      editDetails: false
    });
    let observable$: Observable;
    let subscription$: Subscription;
    // check if user has removed/changed image
    if (!values.imageSrc && this.state.account.imageSrc) {
      observable$ = ImageService.deleteImage(
        'user',
        this.state.account._id
      ).pipe(mergeMap(() => this.completeOnSubmit(values, imageFile)));
    } else {
      observable$ = this.completeOnSubmit(values, imageFile);
    }
    subscription$ = observable$.subscribe(this.handleResponse());
    this.cleanup = () => {
      if (subscription$) {
        subscription$.unsubscribe();
      }
    };
  };

  completeOnSubmit(account: any, imageFile?: File) {
    let observable$;
    if (imageFile) {
      // let's upload image first
      observable$ = ImageService.startUploadImage(imageFile).pipe(
        map(
          (uploadMageResponse: {
            responseData: {
              imageSrc: string
            }
          }) => uploadMageResponse.responseData.imageSrc
        ),
        concatMap((imageSrc: string) => {
          const updatedAccount = Object.assign(this.state.account, {
            ...account,
            imageSrc
          });
          return UserService.updateCurrentUser(updatedAccount);
        })
      );
    } else {
      observable$ = UserService.updateCurrentUser(account);
    }
    return observable$;
  }

  handleResponse() {
    return {
      next: () => {
        this.props.stopLoading();
        toastr.success(
          this.translation('account') +
            ' ' +
            toCapitalize(this.translation('update', { context: 'past' })),
          this.translation('detailsOfTheAccountAreUpdatedSuccessfully') + '.'
        );
        this.getAccountDetails();
      },
      error: (errorResponse: any) => {
        this.props.stopLoading();
        toastr.error(this.translation('error'), errorResponse.data.message);
      }
    };
  }

  updateLocalStorageUser(
    imageSrc: string = this.state.account.imageSrc,
    name: string = this.state.account.name,
    lastName: string = this.state.account.lastName,
    email: string = this.state.account.email
  ) {
    this.props.updateUser({
      name,
      lastName,
      email,
      imageSrc
    });
  }

  render() {
    return (
      <Container fluid>
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Row className="justify-content-between">
                <Col className="text-right">
                  {this.state.editDetails ? (
                    <>
                      <Button
                        variant="secondary"
                        className="mr-3 rounded-0"
                        onClick={() => this.setState({ editDetails: false })}
                      >
                        {this.translation('cancel')}
                      </Button>
                      <Button
                        variant="success"
                        className="rounded-0"
                        onClick={() => this.handleSubmitForm('detail')}
                      >
                        {toCapitalize(
                          this.translation('save') +
                            ' ' +
                            this.translation('change', { count: 0 })
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant=""
                      className="rounded-0"
                      onClick={() => this.setState({ editDetails: true })}
                    >
                      {toCapitalize(this.translation('edit'))}
                      <Icon iconName="edit" size="26" classes="ml-2" />
                    </Button>
                  )}
                </Col>
              </Row>
              <UserAccountDetails
                details={Object.assign({}, this.state.account)}
                editDetails={this.state.editDetails}
                bindSubmitForm={this.bindSubmitForm}
                onSubmit={(
                  values: UserAccountModel,
                  imageFile: File,
                  isDiffThanPrevious: boolean
                ) => {
                  if (isDiffThanPrevious) {
                    this.onSubmit(values, imageFile);
                  } else {
                    this.setState({ editDetails: false });
                  }
                }}
                goTo={path => this.props.history.push(path)}
                startImageEdit={() => this.setState({ editDetails: true })}
                logout={() => {
                  this.props.logout();
                  this.props.history.push('/');
                }}
              />
            </Card.Body>
          </Card>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop()),
  updateUser: user => dispatch(authenticationActions.update(user)),
  logout: () => dispatch(authenticationActions.logout())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(UserAccount)
);
