import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Icon, ImageContainer } from '../../../_shared/components';
import { ColorConstants } from '../../../_shared/constants';
import { headerFormatterWithSort } from '../../../_shared/helpers';
import i18next from 'i18next';

let quantityArray = new Array(20).fill(1);
quantityArray = quantityArray.map((item, index) => item * (index + 1));

const itemImageFormatter = (cell, row) => {
  const imageSrc = cell
    ? cell
    : row.item
    ? row.item.imageSrc
      ? row.item.imageSrc
      : ''
    : '';
  const alt = row.name ? row.name : row.item.name;
  return (
    <ImageContainer src={imageSrc} alt={alt} height="40px" className="h-100" />
  );
};

export const expiringTableConstants = {
  itemColumns: (presentDeleteConfirm, setQuantity) => [
    {
      dataField: 'imageSrc',
      text: '',
      formatter: itemImageFormatter,
      classes: 'py-0 align-middle',
      headerStyle: { width: '5%' },
      style: { width: '5%' }
    },
    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemName')),
      formatter: (cell, row) => {
        if (cell) return cell;
        return row.item.name;
      },
      sort: true,
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },
    {
      dataField: 'quantityEditor',
      isDummyField: true,
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('quantity')),
      classes: 'py-0 align-middle no-text-truncate',
      formatter: (cell, row) => (
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="border rounded-0 my-2"
            id={row._id + 'quantityEditor'}
          >
            {row.quantity}
          </Dropdown.Toggle>

          <Dropdown.Menu className="quantity-dropdown">
            {quantityArray.map((item, index) => (
              <Dropdown.Item
                key={row._id + index}
                onClick={() => setQuantity({ ...row, quantity: item })}
              >
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      sort: true,
      headerStyle: { width: '10%' },
      style: { width: '10%' }
    },
    {
      dataField: 'number',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemNumber')),
      formatter: (cell, row) => {
        if (cell) return cell;
        return row.item.number;
      },
      sort: true,
      headerStyle: { width: '10%' },
      style: { width: '10%' }
    },
    {
      dataField: 'description',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('description')),
      formatter: (cell, row) => {
        if (cell) return <span title={cell}>{cell}</span>;
        return <span title={row.item.description}>{row.item.description}</span>;
      },
      classes: 'text-truncate',
      headerStyle: { width: '35%' },
      style: { width: '35%' }
    },
    {
      dataField: 'actions',
      text: '',
      classes: 'text-right py-0',
      formatter: (cell, row) => (
        <Icon
          iconName="delete-inline"
          size="29"
          fill={ColorConstants.DEFAULT_FONT}
          classes="cursor-pointer"
          onClick={() =>
            presentDeleteConfirm(
              row._id,
              row.name || row.item.name,
              row.number || row.item.number
            )
          }
        />
      ),
      headerStyle: { width: '5%' },
      style: { width: '5%' }
    }
  ],
  newItemColumns: setItem => [
    {
      dataField: 'imageSrc',
      text: '',
      formatter: itemImageFormatter,
      classes: 'py-0 align-middle',
      headerStyle: { width: '8%' },
      style: { width: '8%' }
    },
    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('name')),
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      classes: 'text-right',
      text: '',
      formatter: (cell, row) => {
        return (
          <Button
            variant="success"
            size="sm"
            className="rounded-0 px-3"
            onClick={() => setItem(row)}
          >
            {i18next.t('add')}
          </Button>
        );
      },
      headerStyle: { width: '10%' },
      style: { width: '10%' }
    }
  ]
};
