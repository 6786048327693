// @flow
import * as React from 'react';
import MDSpinner from 'react-md-spinner';
import { ColorConstants } from '../constants';
import DefaultImage from '../../../assets/images/default_image.png';
// import ErrorImage from '../../../assets/icons/baseline-report_problem-24px.svg';
import { logger } from '../helpers';
import i18next from 'i18next';
type Props = {
  src: string,
  alt: string,
  height: string,
  width: string,
  minHeight: string,
  className: string,
  style: any,
  file: File,
  title: string,
  containerClassName: string
};

type State = {
  loaded: boolean,
  errored: boolean,
  imgSrc: string | ArrayBuffer
};

class ImageContainer extends React.Component<Props, State> {
  cleanup: any = null;
  state = {
    loaded: false,
    errored: false,
    imgSrc: ''
  };
  componentDidMount() {
    // let's hide loader if image not found
    this.initDetails();
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.file !== this.props.file) {
      this.initDetails();
    }
  }
  initDetails() {
    if (!this.props.src) {
      if (!this.props.file) {
        this.setState({ imgSrc: DefaultImage });
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(this.props.file);
        reader.onload = () => {
          this.setState({ imgSrc: reader.result, errored: false });
        };
        this.cleanup = () => reader.abort();
      }
    } else {
      this.setState({ imgSrc: this.props.src });
    }
  }

  componentWillUnmount() {
    if (this.cleanup) {
      this.cleanup();
      this.cleanup = null;
    }
  }
  render() {
    const {
      alt,
      height,
      width,
      className,
      style,
      title,
      containerClassName
    } = this.props;
    return (
      <div
        className={[
          'position-relative image-container',
          containerClassName ? containerClassName : '',
          this.state.errored ? 'errored' : 'd-inline-block'
        ].join(' ')}
        style={{ height, width: this.state.loaded ? width : height }}
        title={this.state.errored ? 'Error while loding the image' : title}
      >
        <img
          src={this.state.imgSrc}
          alt={alt}
          className={[className, this.state.loaded ? 'fadeIn' : 'd-none'].join(
            ' '
          )}
          style={this.state.errored ? {} : style}
          onLoad={() => {
            this.setState({ loaded: true });
          }}
          onError={(ev: any) => {
            logger.error(
              i18next.t('errorWhileLoadingImageFile') +
                ' ' +
                (this.props.file ? this.props.file.name : this.props.src)
            );
            this.setState({
              loaded: true,
              errored: true,
              imgSrc: DefaultImage
            });
            ev.persist();
          }}
        />
        {!this.state.loaded && (
          <span className="position-absolute image-loader fadeIn top-left-50">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </span>
        )}
      </div>
    );
  }
}

export default ImageContainer;
