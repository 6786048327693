import React, { Component } from 'react';
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import { LicenseService } from '../../../../_shared/services/';
import { toastr } from 'react-redux-toastr';

import { FormGroup, DatePicker } from '../../../../_shared/components';
import { LicenseSchema } from './form.schema.js';

import { randomString, errorParser } from '../../../../_shared/helpers';
import { withTranslation } from 'react-i18next';

class AddLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      maxActivation: ''
    };
  }

  cleanup: any = null;

  handleChangeDate = (whichDate, date) => {
    this.setState({
      [whichDate]: date
    });
  };

  componentWillUnmount() {
    if (this.cleanup) {
      this.cleanup();
    }
  }

  onSubmit = values => {
    values.customerId = this.props.match.params.customerId;
    const addLicense$ = LicenseService.addLicense(values).subscribe({
      next: (response: any) => {
        if (this.cleanup) {
          this.props.history.push(
            `/admin/customers/details/${values.customerId}`
          );
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      addLicense$.unsubscribe();
    };
  };

  render() {
    const translation = this.props.t;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          code: this.state.code,
          maxActivation: this.state.maxActivation
        }}
        validationSchema={LicenseSchema}
        onSubmit={this.onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, touched, handleChange, handleSubmit, values }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Card>
                <Card.Body>
                  <Row className="justify-content-between align-items-center">
                    <Col className="mb-4">
                      <h4>{translation('addNewLicense')}</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup
                        formControlName="code"
                        type="text"
                        label={translation('licenseCode')}
                        handleChange={changeEvent => {
                          this.setState({ code: changeEvent.target.value });
                          handleChange(changeEvent);
                        }}
                        touched={touched['code']}
                        error={errors['code']}
                        value={values.code}
                        required
                      />
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="success"
                        size="lg"
                        className="rounded-0 input-height"
                        onClick={() =>
                          this.setState({
                            ...values,
                            code: randomString(8).toUpperCase()
                          })
                        }
                      >
                        {translation('generateCode')}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup
                        formControlName="maxActivation"
                        type="text"
                        label={translation('maxActivations')}
                        handleChange={changeEvent => {
                          this.setState({
                            maxActivation: changeEvent.target.value
                          });
                          handleChange(changeEvent);
                        }}
                        touched={touched['maxActivation']}
                        error={errors['maxActivation']}
                        value={values.maxActivation}
                        required
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Button
                type="submit"
                variant="success"
                size="lg"
                className="rounded-0 px-5 float-right mt-3"
              >
                {translation('add')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withTranslation()(AddLicense);
