import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withTranslation } from 'react-i18next';
import { ColorConstants } from '../../../../_shared/constants';
import { TreatmentSummaryService } from '../../../../_shared/services';
import { Icon } from '../../../../_shared/components';
import moment from 'moment';
import StepsListProvider from './StepsListProvider';
import { errorParser } from '../../../../_shared/helpers';

class StepsViewer extends Component {
  translation = this.props.t;
  cleanup = null;
  downloadRef = React.createRef();
  state = {
    isLoading: true,
    summary: {},
    isPDFLoading: false
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.treatment &&
      this.props.treatment._id !== prevProps.treatment._id
    ) {
      this.getSummaryById();
    }
  }
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  getSummaryById() {
    this.setState({ isLoading: true });
    const getSummaryById$ = TreatmentSummaryService.getSummaryById(
      this.props.treatment._id
    ).subscribe({
      next: response => {
        if (this.cleanup) {
          const summary = response.data.summary;
          this.setState({ isLoading: false, summary });
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getSummaryById$.unsubscribe();
    };
  }
  downloadPDF = () => {
    if (!this.props.isLoading) {
      this.setState({ isPDFLoading: true });
      const downloadPDFSubscriber$ = TreatmentSummaryService.getPDFById(
        this.props.treatment._id,
        this.translation('fileNames.treatmentSummary') +
          '-' +
          moment().format('MM-DD-Y:HH-mm-ss') +
          '.pdf'
      ).subscribe({
        next: response => {
          if (this.cleanup) {
            let link = this.downloadRef.current;
            link.href = response.data.url;
            link.dispatchEvent(new MouseEvent('click'));
            this.setState({ isPDFLoading: false });
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.setState({ isPDFLoading: false });
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
      this.cleanup = () => {
        downloadPDFSubscriber$.unsubscribe();
      };
    }
  };
  render() {
    return (
      <>
        <Card className="h-100 mt-2 border-0">
          <Card.Header className="bg-primary text-white">
            <span className="float-left pl-3">
              {this.translation('summary')}
            </span>
            {this.props.treatment && this.props.treatment._id && (
              <span
                className="float-right cursor-pointer"
                tabIndex="0"
                onKeyPress={() => {}}
                onClick={() => {
                  if (this.props.treatment && this.props.treatment._id)
                    this.downloadPDF();
                }}
                role="button"
              >
                {this.state.isPDFLoading ? (
                  <MDSpinner singleColor="#fff" />
                ) : (
                  <>
                    {this.translation('download') +
                      ' ' +
                      this.translation('pdf')}
                    <Icon
                      iconName="download"
                      size="24"
                      fill="#FFFFFF"
                      classes="align-bottom ml-2"
                    />
                  </>
                )}
              </span>
            )}
          </Card.Header>
          <Card.Body className="border pl-0">
            {!this.props.isLoading &&
            !(this.props.treatment && this.props.treatment._id) ? (
              <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                {this.translation('noData')}
              </div>
            ) : this.state.isLoading ? (
              <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                <MDSpinner singleColor={ColorConstants.PRIMARY} />
              </div>
            ) : (
              <StepsListProvider steps={this.state.summary.summary} />
            )}
          </Card.Body>
        </Card>
        <a ref={this.downloadRef} className="d-none" href="new">
          {this.translation('download')}
        </a>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
};

export default withTranslation()(connect(mapStateToProps)(StepsViewer));
