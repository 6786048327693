import React, { Component } from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import headerLogo from '../../../assets/images/header-logo.png';
import headerZollLogo from '../../../assets/images/zoll_logo.png';
import userImage from '../../../assets/images/user.png';
import { Icon } from '../../_shared/components';
import { authenticationActions } from '../../_shared/redux/actions';
import { ColorConstants } from '../../_shared/constants';
import { tick_then } from '../../_shared/helpers';

export class NavBar extends Component {
  render() {
    const { t: translation } = this.props;
    const { pathname } = this.props.history.location;
    return (
      <Navbar sticky="top" bg="white" expand="md" className="main-nav shadow">
        <Navbar.Brand
          className="py-0 ml-4 cursor-pointer"
          onClick={() => this.props.history.push('/admin/')}
        >
          <img
            src={headerLogo}
            height="50"
            className="d-inline-block align-top"
            alt={translation('mobilizeCompanyLogo')}
          />
          <img
            src={headerZollLogo}
            height="50"
            className="d-inline-block align-top"
            alt={translation('mobilizeCompanyLogo')}
          />
        </Navbar.Brand>
        {!this.props.onlyLogo && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link
                  onClick={() => this.props.history.push('/admin/dashboard')}
                  className={[
                    'mx-lg-4',
                    pathname === '/admin' ||
                    pathname === '/admin/' ||
                    pathname === '/' ||
                    pathname.startsWith('/admin/dashboard')
                      ? 'active'
                      : ''
                  ].join(' ')}
                >
                  {translation('dashboard')}
                </Nav.Link>
                <Nav.Link
                  onClick={() => this.props.history.push('/admin/customers')}
                  className={[
                    'mx-lg-4',
                    pathname.startsWith('/admin/customers') ? 'active' : ''
                  ].join(' ')}
                >
                  {translation('customer', { count: 0 })}
                </Nav.Link>
                <Nav.Link
                  onClick={() => this.props.history.push('/admin/users')}
                  className={[
                    'mx-lg-4',
                    pathname.startsWith('/admin/users') ? 'active' : ''
                  ].join(' ')}
                >
                  {translation('users', { count: 0 })}
                </Nav.Link>
                <Nav.Link
                  onClick={() => this.props.history.push('/admin/units')}
                  className={[
                    'mx-lg-4',
                    pathname.startsWith('/admin/units') ? 'active' : ''
                  ].join(' ')}
                >
                  {translation('Units', { count: 0 })}
                </Nav.Link>
                <Nav.Link
                  onClick={() => this.props.history.push('/admin/kits')}
                  className={[
                    'mx-lg-4',
                    pathname.startsWith('/admin/kits') ? 'active' : ''
                  ].join(' ')}
                >
                  {translation('kits')}
                </Nav.Link>
                <Nav.Link
                  onClick={() =>
                    this.props.history.push('/admin/deletionRequests')
                  }
                  className={[
                    'mx-lg-4',
                    pathname.startsWith('/admin/deletionRequests')
                      ? 'active'
                      : ''
                  ].join(' ')}
                >
                  {translation('deletionRequests')}
                </Nav.Link>
                {/* <Nav.Link
                  onClick={() =>
                    this.props.history.push('/admin/notifications')
                  }
                  className={[
                    'mx-lg-4',
                    'pt-2',
                    pathname.startsWith('/admin/notifications') ? 'active' : ''
                  ].join(' ')}
                >
                  <Icon
                    iconName="bell"
                    size="22"
                    fill={
                      pathname.startsWith('/admin/notifications')
                        ? ColorConstants.PRIMARY
                        : ColorConstants.DEFAULT_FONT
                    }
                  />
                </Nav.Link> */}
                <Dropdown className="mx-lg-4 align-self-center">
                  <Dropdown.Toggle
                    variant=""
                    id="user-menu"
                    className="pl-5 rounded-0"
                  >
                    <img
                      src={
                        this.props.imageSrc ? this.props.imageSrc : userImage
                      }
                      height="36"
                      width="36"
                      alt={translation('userProfile')}
                      className="position-absolute user-image"
                    />
                    {this.props.firstName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-right px-3 rounded-0 shadow-sm">
                    <Dropdown.Item
                      onClick={() =>
                        this.props.history.push('/admin/dashboard/user-account')
                      }
                      className="py-3"
                    >
                      {translation('userAccount')}
                    </Dropdown.Item>
                    <Dropdown.Divider className="my-0" />
                    {/* {this.props.userType === 'super-super' && (
                      <Dropdown.Item
                        onClick={() =>
                          this.props.history.push('/admin/dashboard/documents')
                        }
                        className="py-3"
                      >
                        {translation('document', { count: 0 })}
                      </Dropdown.Item>
                    )} */}
                    {/* <Dropdown.Divider className="my-0" /> */}
                    {/* <Dropdown.Item
                      onClick={() =>
                        this.props.history.push('/admin/dashboard/send-message')
                      }
                      className="py-3"
                    >
                      {translation('send') + ' ' + translation('message')}
                    </Dropdown.Item>
                    <Dropdown.Divider className="my-0" /> */}
                    {this.props.userType === 'super-super' && (
                      <>
                        <Dropdown.Item
                          onClick={() =>
                            this.props.history.push(
                              '/admin/dashboard/manage-super-super-admin'
                            )
                          }
                          className="py-3"
                        >
                          {translation('manageAdmin')}
                        </Dropdown.Item>
                        <Dropdown.Divider className="my-0" />
                      </>
                    )}
                    {/* {this.props.userType === 'super-super' && (
                      <Dropdown.Item
                        onClick={() =>
                          this.props.history.push(
                            '/admin/dashboard/manage-banner'
                          )
                        }
                        className="py-3"
                      >
                        {translation('manage') + ' ' + translation('banner')}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider className="my-0" /> */}
                    <Dropdown.Item
                      onClick={() => {
                        this.props.logout();
                        tick_then(() => this.props.history.push('/admin'));
                      }}
                      className="py-3"
                    >
                      {translation('logout')}
                    </Dropdown.Item>
                    <Dropdown.Divider className="my-0" />
                    <Dropdown.Item>
                      <small>
                        {translation('appVersion') +
                          ': ' +
                          process.env.REACT_APP_VERSION}
                      </small>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authenticationActions.logout(true))
});

export default withTranslation()(
  withRouter(
    connect(
      null,
      mapDispatchToProps
    )(NavBar)
  )
);
