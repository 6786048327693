// @flow
import * as React from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { ColorConstants } from '../../../_shared/constants';
import { Icon, ConfirmDialog } from '../../../_shared/components';
import { kitService, ImageService } from '../../../_shared/services';
import Details from './Details';
import AddItemModal from './AddItemModal';
import {
  ReactRouterMatch,
  Kit,
  ReactRouterHistory,
  KitItem
} from '../../../models';
import { loaderActions } from '../../../_shared/redux/actions';
import { Subscription, Observable } from 'rxjs';
import { errorParser } from '../../../_shared/helpers';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ToggleButton from 'react-toggle-button';

type Props = {
  match: ReactRouterMatch,
  history: ReactRouterHistory,
  startLoading(): void,
  stopLoading(): void,
  t: i18next.TFunction
};

type State = {
  isLoading: boolean,
  kit: Kit,
  showConfirmDelete: boolean,
  deleteConfirmMessage: React.Element<any>,
  kitIdToBeDeleted: string,
  showAddItemModal: boolean,
  editDetails: boolean,
  addDetails: boolean
};

export class KitDetail extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Submit form caller */
  submitDetailForm: any = null;
  /**Component's State */
  state = {
    isLoading: true,
    kit: {
      _id: '',
      active: true,
      name: '',
      description: '',
      kitItems: [],
      imageSrc: ''
    },
    showAddItemModal: false,
    editDetails: false,
    addDetails: false,
    showConfirmDelete: false,
    deleteConfirmMessage: <></>,
    kitIdToBeDeleted: ''
  };
  componentDidMount() {
    this.initKitDetails();
  }
  /**Confirm dialog ok click handler */
  deactivateKit = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    this.deactivatedKit();
  };

  deactivatedKit() {
    const deactivateKit$: Subscription = kitService
      .deactivatedKit(this.state.kitIdToBeDeleted)
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.success(this.translation('success'), response.data.message);
            // this.props.history.push('/admin/kits');
            this.setState({
              kit: {
                active: !this.state.kit.active
              }
            });
            this.initKitDetails();
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
    this.cleanup = () => {
      deactivateKit$.unsubscribe();
    };
  }

  /**Confirm dialog cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };

  /**Present confirm delete */
  presentDeleteConfirm = (id: string, name: string, active: boolean) => {
    if (active != true) {
      this.setState({
        showConfirmDelete: false,
        kitIdToBeDeleted: id
      });
      this.props.startLoading();
      this.activatedKit(id, active);
    } else {
      const deleteConfirmMessage = (
        <p>
          {this.translation('confirmDeactivateThisKit')}
          <br /> <br />{' '}
          <b>
            {this.translation('kitName')}: {name}
          </b>
        </p>
      );
      this.setState({
        showConfirmDelete: true,
        deleteConfirmMessage,
        kitIdToBeDeleted: id
      });
    }
  };

  activatedKit(id: string, activate: boolean) {
    const deleteKit$: Subscription = kitService
      .activatedKit(id, activate)
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.success(this.translation('success'), response.data.message);
            // this.props.history.push('/admin/kits');
            this.setState({
              kit: {
                active: !this.state.kit.active
              }
            });
            this.initKitDetails();
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
    this.cleanup = () => {
      deleteKit$.unsubscribe();
    };
  }

  /**Once confirmed, delete user */
  deleteKit() {
    const deleteKit$: Subscription = kitService
      .delete(this.state.kitIdToBeDeleted)
      .subscribe({
        next: response => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.success(this.translation('success'), response.data.message);
            this.props.history.push('/admin/kits');
          }
        },
        error: errorResponse => {
          if (this.cleanup) {
            this.props.stopLoading();
            toastr.error(this.translation('error'), errorParser(errorResponse));
          }
        }
      });
    this.cleanup = () => {
      deleteKit$.unsubscribe();
    };
  }
  initKitDetails() {
    if (this.props.match.params.kitTypeId) {
      const getKitTypeById$ = kitService
        .getKitTypeById(this.props.match.params.kitTypeId)
        .subscribe({
          next: (response: {
            data: {
              kit: Kit
            }
          }) => {
            if (this.cleanup) {
              this.setState({
                isLoading: false,
                kit: response.data.kit
              });
            }
          },
          error: (errorResponse: {
            data: {
              message: string
            }
          }) => {
            if (this.cleanup) {
              toastr.error(
                this.translation('error'),
                errorParser(errorResponse)
              );
              this.setState({ isLoading: false });
            }
          }
        });
      this.cleanup = () => {
        getKitTypeById$.unsubscribe();
      };
    } else {
      this.setState({ isLoading: false, addDetails: true });
    }
  }

  /**Free up resources once component unmounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  handleSubmitForm = () => {
    if (this.submitDetailForm) {
      this.submitDetailForm();
    }
  };
  bindSubmitForm = (submitForm: any) => {
    this.submitDetailForm = submitForm;
  };
  /**Add Kit Items */
  addKitItems = (kitItems: KitItem[]) => {
    const kit = Object.assign({}, this.state.kit);
    kit.kitItems = kitItems;
    this.setState((curr: State) => ({
      ...curr,
      kit
    }));
  };
  /**Submit detail to add/edit */
  onSubmit = (values: Kit, imageFile?: File) => {
    this.props.startLoading();
    let observable$: Observable;
    let subscription$: Subscription;
    // check if user has removed/changed image
    if (!values.imageSrc && this.state.kit.imageSrc) {
      // let's remove old image from s3
      observable$ = ImageService.deleteImage('kit', this.state.kit._id).pipe(
        mergeMap(() => this.completeOnSubmit(values, imageFile))
      );
    } else observable$ = this.completeOnSubmit(values, imageFile);
    subscription$ = observable$.subscribe(this.handleResponse());
    this.cleanup = () => {
      if (subscription$) {
        subscription$.unsubscribe();
      }
    };
  };
  /**Handle response */
  handleResponse = () => {
    return {
      next: (response: { data: { message: string } }) => {
        if (this.cleanup) {
          if (this.state.addDetails) {
            this.props.history.push('/admin/kits');
          }
          toastr.success(
            this.translation('kit') +
              ' ' +
              (this.state.addDetails
                ? this.translation('add', { context: 'past' })
                : this.translation('update', { context: 'past' })),
            response.data.message
          );
          this.props.stopLoading();
          if (this.state.editDetails) {
            this.setState({ editDetails: false, isLoading: true }, () => {
              this.initKitDetails();
            });
          }
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          toastr.error(this.translation('error'), errorParser(errorResponse));
          this.props.stopLoading();
        }
      }
    };
  };
  /** Update quantity */
  updateKitItems = (kitItems: KitItem[]) => {
    const newKit = Object.assign({}, this.state.kit);
    newKit.kitItems = kitItems;
    this.setState({ kit: newKit }, () => {
      if (!this.state.addDetails) {
        this.props.startLoading();
        const observable$: Subscription = kitService
          .update(this.state.kit._id, this.state.kit)
          .subscribe(this.handleResponse());
        this.cleanup = () => observable$.unsubscribe();
      }
    });
  };
  completeOnSubmit(values: Kit, imageFile?: File): Observable<any> {
    let observable$;
    if (imageFile) {
      // let's upload image first
      observable$ = ImageService.startUploadImage(imageFile).pipe(
        map(
          (uploadMageResponse: {
            responseData: {
              imageSrc: string
            }
          }) => uploadMageResponse.responseData.imageSrc
        ),
        concatMap((imageSrc: string) => {
          const updatedKit = Object.assign(this.state.kit, {
            ...values,
            imageSrc
          });
          if (this.state.addDetails) {
            return kitService.add(updatedKit);
          } else {
            return kitService.update(this.state.kit._id, updatedKit);
          }
        })
      );
    } else if (this.state.addDetails) {
      const updatedKit = Object.assign(this.state.kit, values);
      observable$ = kitService.add(updatedKit);
    } else {
      const updatedKit = Object.assign(this.state.kit, values);
      observable$ = kitService.update(this.state.kit._id, updatedKit);
    }
    return observable$;
  }

  render() {
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn={this.translation('no')}
          okBtn={this.translation('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deactivateKit()}
          message={this.state.deleteConfirmMessage}
        />
        <AddItemModal
          onHide={() => this.setState({ showAddItemModal: false })}
          show={this.state.showAddItemModal}
          addKitItems={(kitItems: KitItem[]) => {
            if (this.state.addDetails) {
              this.addKitItems(kitItems);
            } else {
              this.updateKitItems(kitItems);
            }
          }}
          existingItems={this.state.kit.kitItems}
        />
        <Container fluid>
          {this.state.isLoading || this.state.addDetails ? (
            ''
          ) : (
            <div className="mb-3 text-right ToggleButton-color">
              {/* <Button
                variant={
                  this.state.kit.active === false ? 'success' : 'primary'
                }
                size="lg"
                onClick={() => {
                  this.presentDeleteConfirm(
                    this.state.kit._id,
                    this.state.kit.name,
                    this.state.kit.active
                  );
                }}
                className="rounded-0"
              >
                {this.state.kit.active === false
                  ? i18next.t('active')
                  : i18next.t('disable')}
              </Button> */}
              <div className="h4">
                {this.state.kit.active ? 'Active' : 'Inactive'}
              </div>
              <ToggleButton
                value={this.state.kit.active}
                inactiveLabel={''}
                activeLabel={''}
                colors={{
                  activeThumb: {
                    base: 'rgb(250,250,250)'
                  },
                  inactiveThumb: {
                    base: 'rgb(250,250,250)'
                  },
                  active: {
                    base: 'rgb(22, 184, 108)',
                    hover: 'rgb(177, 191, 215)'
                  },
                  inactive: {
                    base: 'rgb(177	30	41)',
                    hover: 'rgb(95,96,98)'
                  }
                }}
                onToggle={value =>
                  this.presentDeleteConfirm(
                    this.state.kit._id,
                    this.state.kit.name,
                    this.state.kit.active
                  )
                }
              />
            </div>
          )}
          {this.state.isLoading ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <>
              <Card className="mb-4">
                <Card.Body>
                  <Row className="justify-content-between align-items-center">
                    <Col className="mb-4">
                      <h4>
                        {this.state.kit.name
                          ? this.state.kit.name
                          : this.translation('addNewKitType')}
                      </h4>
                    </Col>
                    <Col className="text-right mb-4">
                      <>
                        {this.state.addDetails ? (
                          ''
                        ) : this.state.editDetails ? (
                          <>
                            <Button
                              variant="secondary"
                              className="mr-3 rounded-0"
                              onClick={() => {
                                const kit = Object.assign({}, this.state.kit);
                                this.setState({ editDetails: false, kit });
                              }}
                            >
                              {this.translation('cancel')}
                            </Button>
                            <Button
                              variant="success"
                              className="rounded-0"
                              onClick={() => this.handleSubmitForm()}
                            >
                              {this.translation('save') +
                                ' ' +
                                this.translation('change_plural')}
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant=""
                            className="rounded-0"
                            onClick={() => this.setState({ editDetails: true })}
                          >
                            {this.translation('edit')}
                            <Icon iconName="edit" size="26" classes="ml-2" />
                          </Button>
                        )}
                      </>
                    </Col>
                  </Row>
                  <Details
                    details={this.state.kit}
                    editDetails={
                      this.state.editDetails || this.state.addDetails
                    }
                    bindSubmitForm={this.bindSubmitForm}
                    onSubmit={(
                      values: Kit,
                      imageFile: File,
                      isDiffThanPrevious: boolean
                    ) => {
                      if (isDiffThanPrevious) {
                        this.onSubmit(values, imageFile);
                      } else {
                        this.setState({ editDetails: false });
                      }
                    }}
                    startImageEdit={() => this.setState({ editDetails: true })}
                  />
                </Card.Body>
              </Card>
            </>
          )}
          {this.state.addDetails ? (
            <div className="text-right mb-4">
              <Button
                variant="success"
                size="lg"
                className="rounded-0 px-5"
                onClick={() => this.handleSubmitForm()}
              >
                {this.translation('submit')}
              </Button>
            </div>
          ) : (
            ''
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(KitDetail)
);
