import React from 'react';
import { Translation } from 'react-i18next';
import {
  Home,
  ExpiringItems,
  MyKits,
  KitDetails,
  NewKit,
  DocumentsComponent,
  Notifications,
  UserAccount,
  TreatmentSummaries,
  LicenseCode
} from './site-admin/pages';
import {
  Dashboard,
  Customers,
  DeletionRequests,
  MobileUsers,
  Products,
  Kits,
  AdminUserAccount,
  SendMessage,
  AdminDocuments,
  AdminNotifications,
  AdminExpiringItems,
  AdminExpiringServicePlans,
  AdminCustomerDetails,
  AdminCustomerNewKit,
  AdminNewCustomer,
  AdminProductDetails,
  AdminNewProduct,
  AdminKitDetail,
  ManageSuperAdmin,
  NewSuperAdmin,
  ManageBanner,
  UserDetails,
  AdminCustomerNewLicense
} from './super-admin/pages';

export const siteAdminRoutes = [
  {
    path: '/',
    component: Home,
    breadcrumb: <Translation>{t => <>{t('home')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/home',
    component: Home,
    breadcrumb: <Translation>{t => <>{t('home')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/home/expiring-items',
    component: ExpiringItems,
    breadcrumb: <Translation>{t => <>{t('expiringItems')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/my-kits/add-new',
    component: NewKit,
    breadcrumb: <Translation>{t => <>{t('addNewKit')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/my-kits/details',
    breadcrumb: null,
    exact: 'true'
  },
  {
    path: '/my-kits/details/:productId',
    component: KitDetails,
    breadcrumb: <Translation>{t => <>{t('kitDetails')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/my-kits',
    component: MyKits,
    breadcrumb: <Translation>{t => <>{t('myKits')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/documents',
    component: DocumentsComponent,
    breadcrumb: (
      <Translation>{t => <>{t('document', { count: 0 })}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/notifications',
    component: Notifications,
    breadcrumb: (
      <Translation>{t => <>{t('notification', { count: 0 })}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/home/license-code',
    component: LicenseCode,
    breadcrumb: <Translation>{t => <>{t('licenseCode')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/home/user-account',
    component: UserAccount,
    breadcrumb: <Translation>{t => <>{t('userAccount')}</>}</Translation>,
    exact: 'true'
  },
  // {
  //   path: '/home/treatment-summaries',
  //   component: TreatmentSummaries,
  //   breadcrumb: (
  //     <Translation>{t => <>{t('treatmentSummaries')}</>}</Translation>
  //   ),
  //   exact: 'true'
  // },
  {
    path: '/',
    component: Home,
    breadcrumb: <Translation>{t => <>{t('home')}</>}</Translation>,
    exact: 'true'
  }
];
// add super super admin routes here when you design super super admin page
export const superSuperAdminRoutes = [];

export const superAdminRoutes = [
  {
    path: '/',
    component: Dashboard,
    breadcrumb: <Translation>{t => <>{t('dashboard')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/admin',
    component: Dashboard,
    breadcrumb: <Translation>{t => <>{t('dashboard')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/admin/dashboard',
    component: Dashboard,
    breadcrumb: <Translation>{t => <>{t('dashboard')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/admin/dashboard/expiring-items',
    component: AdminExpiringItems,
    breadcrumb: <Translation>{t => <>{t('expiringItems')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/admin/dashboard/expiring-service-plans',
    component: AdminExpiringServicePlans,
    breadcrumb: (
      <Translation>{t => <>{t('expiringServicePlans')}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/admin/customers',
    component: Customers,
    breadcrumb: (
      <Translation>{t => <>{t('customer', { count: 0 })}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/admin/deletionRequests',
    component: DeletionRequests,
    breadcrumb: (
      <Translation>
        {t => <>{t('deleteMyAccount', { count: 0 })}</>}
      </Translation>
    ),
    exact: 'true'
  },
  {
    path: '/admin/users',
    component: MobileUsers,
    breadcrumb: (
      <Translation>{t => <>{t('users', { count: 0 })}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/admin/customers/new-customer',
    component: AdminNewCustomer,
    breadcrumb: (
      <Translation>{t => <>{t('new') + ' ' + t('customer')}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/admin/customers/details',
    breadcrumb: null,
    exact: 'true'
  },
  {
    path: '/admin/customers/details/:customerId',
    breadcrumb: <Translation>{t => <>{t('customerDetails')}</>}</Translation>,
    component: AdminCustomerDetails,
    exact: 'true'
  },
  {
    path: '/admin/users/details',
    breadcrumb: null,
    exact: 'true'
  },
  {
    path: '/admin/users/details/:userId',
    breadcrumb: <Translation>{t => <>{t('usersDetails')}</>}</Translation>,
    component: UserDetails,
    exact: 'true'
  },
  {
    path: '/admin/customers/details/:customerId/add-kit',
    breadcrumb: <Translation>{t => <>{t('addUnit')}</>}</Translation>,
    component: AdminCustomerNewKit,
    exact: 'true'
  },
  {
    path: '/admin/customers/details/:customerId/add-license',
    breadcrumb: <Translation>{t => <>{t('addLicense')}</>}</Translation>,
    component: AdminCustomerNewLicense,
    exact: 'true'
  },
  {
    path: '/admin/units',
    component: Products,
    breadcrumb: (
      <Translation>{t => <>{t('unit', { count: 0 })}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/admin/units/new-unit',
    breadcrumb: (
      <Translation>{t => <>{t('new') + ' ' + t('unit')}</>}</Translation>
    ),
    component: AdminNewProduct,
    exact: 'true'
  },
  {
    path: '/admin/units/details',
    breadcrumb: null,
    exact: 'true'
  },
  {
    path: '/admin/units/details/:productId',
    component: AdminProductDetails,
    breadcrumb: (
      <Translation>{t => <>{t('detail', { count: 0 })}</>}</Translation>
    ),
    exact: 'true'
  },
  {
    path: '/admin/kits',
    component: Kits,
    breadcrumb: <Translation>{t => <>{t('kits')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/admin/kits/details',
    breadcrumb: null,
    exact: 'true'
  },
  {
    path: '/admin/kits/details/:kitTypeId',
    component: AdminKitDetail,
    breadcrumb: <Translation>{t => <>{t('kitDetail')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/admin/kits/add-kit-type/',
    component: AdminKitDetail,
    breadcrumb: <Translation>{t => <>{t('addNewKitType')}</>}</Translation>,
    exact: 'true'
  },
  {
    path: '/admin/notifications',
    component: AdminNotifications,
    exact: 'true',
    breadcrumb: (
      <Translation>{t => <>{t('notification', { count: 0 })}</>}</Translation>
    )
  },
  {
    path: '/admin/dashboard/user-account',
    component: AdminUserAccount,
    exact: 'true',
    breadcrumb: <Translation>{t => <>{t('userAccount')}</>}</Translation>
  },
  {
    path: '/admin/dashboard/documents',
    component: AdminDocuments,
    exact: 'true',
    guard: 'super-super',
    breadcrumb: (
      <Translation>{t => <>{t('document', { count: 0 })}</>}</Translation>
    )
  },
  // {
  //   path: '/admin/dashboard/send-message',
  //   component: SendMessage,
  //   exact: 'true',
  //   breadcrumb: <Translation>{t => <>{t('sendAlertMessage')}</>}</Translation>
  // },
  {
    path: '/admin/dashboard/manage-super-super-admin',
    component: ManageSuperAdmin,
    breadcrumb: <Translation>{t => <>{t('manageAdmin')}</>}</Translation>,
    exact: 'true',
    guard: 'super-super'
  },
  {
    path: '/admin/dashboard/add-admin',
    component: NewSuperAdmin,
    breadcrumb: <Translation>{t => <>{t('add-admin')}</>}</Translation>,
    exact: 'true',
    guard: 'super-super'
  },
  // {
  //   path: '/admin/dashboard/manage-banner',
  //   component: ManageBanner,
  //   guard: 'super-super',
  //   breadcrumb: (
  //     <Translation>{t => <>{t('manage') + ' ' + t('banner')}</>}</Translation>
  //   ),
  //   exact: 'true'
  // },
  {
    path: '/',
    component: Dashboard,
    breadcrumb: <Translation>{t => <>{t('dashboard')}</>}</Translation>,
    exact: 'true'
  }
];
