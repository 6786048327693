import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../../_shared/helpers';
import i18next from 'i18next';

const itemNameFormatter = (cell, row) => {
  return `${cell} (${row.quantity})`;
};

export const columns = [
  {
    dataField: 'item.name',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('itemNameQty')),
    formatter: itemNameFormatter,
    sort: true,
    headerStyle: { width: '25%' },
    style: { width: '25%' }
  },
  {
    dataField: 'productSerialNumber',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('kitSerialNumber')),
    sort: true,
    headerStyle: { width: '15%' },
    style: { width: '15%' }
  },
  {
    dataField: 'item.number',
    text: '',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('itemNumber')),
    sort: true
  },
  {
    dataField: 'lotNumber',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('lotNumber')),
    sort: true
  },
  {
    dataField: 'expiryDate',
    headerFormatter: (...theArgs) =>
      headerFormatterWithSort(...theArgs, i18next.t('expiringDate')),
    formatter: cell => getFormattedDate(cell, 60),
    sort: true
  }
];
