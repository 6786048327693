import React, { Component } from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { UserService } from '../../_shared/services/user.service';
import headerLogo from '../../../assets/images/header-logo.png';
import userImage from '../../../assets/images/user.png';
import { Icon } from '../../_shared/components';
import { authenticationActions } from '../../_shared/redux/actions';
import { withTranslation } from 'react-i18next';
import { ColorConstants } from '../../_shared/constants';
import { tick_then } from '../../_shared/helpers';

export class NavBar extends Component {
  render() {
    const { pathname } = this.props.history.location;
    const { t: translation } = this.props;
    return (
      <Navbar sticky="top" bg="white" expand="md" className="main-nav shadow">
        <Navbar.Brand
          className="py-0 ml-4 cursor-pointer"
          onClick={() => this.props.history.push('/')}
        >
          <img
            src={headerLogo}
            height="50"
            width="139.55"
            className="d-inline-block align-top"
            alt={translation('mobilizeCompanyLogo')}
          />
        </Navbar.Brand>
        {!this.props.onlyLogo && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link
                  onClick={() => this.props.history.push('/home')}
                  className={[
                    'mx-lg-4',
                    pathname === '/' || pathname.startsWith('/home')
                      ? 'active'
                      : ''
                  ].join(' ')}
                >
                  {translation('home')}
                </Nav.Link>
                <Nav.Link
                  onClick={() => this.props.history.push('/my-kits')}
                  className={[
                    'mx-lg-4',
                    pathname.startsWith('/my-kits') ? 'active' : ''
                  ].join(' ')}
                >
                  {translation('myKits')}
                </Nav.Link>
                <Nav.Link
                  onClick={() => this.props.history.push('/documents')}
                  className={[
                    'mx-lg-4',
                    pathname.startsWith('/documents') ? 'active' : ''
                  ].join(' ')}
                >
                  {translation('document', { count: 0 })}
                </Nav.Link>
                <Nav.Link
                  href="https://reorder.mobilizerescue.com/"
                  target="_blank"
                  className="mx-lg-4"
                >
                  {translation('store')}
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    UserService.lastClickOnAlert().subscribe();
                    this.props.updateUser({ alert: false });
                    this.setState({
                      alert: false
                    });
                    this.props.history.push('/notifications');
                  }}
                  className="mx-lg-4 pt-2"
                >
                  {this.props.alert ? (
                    <Icon iconName="red-bell-dot" size="25" />
                  ) : (
                    <Icon
                      iconName="bell"
                      fill={ColorConstants.DEFAULT_FONT}
                      size="25"
                    />
                  )}
                </Nav.Link>
                <Dropdown className="mx-lg-4 align-self-center">
                  <Dropdown.Toggle
                    variant=""
                    id="user-menu"
                    className="pl-5 rounded-0"
                    title={this.props.firstName}
                  >
                    <img
                      src={
                        this.props.imageSrc ? this.props.imageSrc : userImage
                      }
                      height="36"
                      width="36"
                      alt={translation('userProfile')}
                      className="position-absolute user-image"
                    />
                    {this.props.firstName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-right px-3 rounded-0 shadow-sm">
                    <Dropdown.Item
                      onClick={() =>
                        this.props.history.push('/home/user-account')
                      }
                      className="py-3"
                    >
                      {translation('userAccount')}
                    </Dropdown.Item>
                    <Dropdown.Divider className="my-0" />
                    <Dropdown.Item
                      onClick={() =>
                        this.props.history.push('/home/license-code')
                      }
                      className="py-3"
                    >
                      {translation('licenseCode')}
                    </Dropdown.Item>
                    {/* <Dropdown.Divider className="my-0" />
                    <Dropdown.Item
                      onClick={() =>
                        this.props.history.push('/home/treatment-summaries')
                      }
                      className="py-3"
                    >
                      {translation('treatmentSummaries')}
                    </Dropdown.Item> */}
                    <Dropdown.Divider className="my-0" />
                    <Dropdown.Item
                      onClick={() => {
                        this.props.logout();
                        tick_then(() => this.props.history.push('/'));
                      }}
                      className="py-3"
                    >
                      {translation('logout')}
                    </Dropdown.Item>
                    {process.env.REACT_APP_ENV !== 'production' && (
                      <>
                        <Dropdown.Divider className="my-0" />
                        <Dropdown.Item>
                          <small>
                            {translation('appVersion') +
                              ': ' +
                              process.env.REACT_APP_VERSION}
                          </small>
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authenticationActions.logout()),
  updateUser: user => dispatch(authenticationActions.update(user))
});

export default withTranslation()(
  withRouter(
    connect(
      null,
      mapDispatchToProps
    )(NavBar)
  )
);
