// @flow
import * as React from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import BasicDetails from './BasicDetails';
import { loaderActions } from '../../../_shared/redux/actions';
import { ReactRouterMatch, ReactRouterHistory } from '../../../models';

type Props = {
  match: ReactRouterMatch,
  history: ReactRouterHistory,
  startLoading(): void,
  stopLoading(): void
};

type State = {
  productId: string,
  showConfirmDelete: boolean,
  deleteConfirmMessage: React.Element<any>
};

export class ProductDetails extends React.Component<Props, State> {
  /**Component's state */
  state = {
    productId: '',
    showConfirmDelete: false,
    deleteConfirmMessage: <></>
  };
  constructor(props: Props) {
    super(props);
    const productId = props.match.params.productId;
    this.state.productId = productId;
  }
  /**Redirect from child and this component */
  goTo = (path: string) => this.props.history.push(path);
  render() {
    return (
      <Container fluid>
        <BasicDetails
          productId={this.state.productId}
          goTo={this.goTo}
          startLoading={() => this.props.startLoading()}
          stopLoading={() => this.props.stopLoading()}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default connect(
  null,
  mapDispatchToProps
)(ProductDetails);
