import React from 'react';
import { Row, Col } from 'react-bootstrap';

import CompanyLogo from '../../../../assets/images/company_logo.png';
import { withTranslation } from 'react-i18next';

export class LoginBanner extends React.Component {
  translation = this.props.t;
  render() {
    return (
      <React.Fragment>
        <div className="login-background d-none d-sm-none d-md-block">
          <div className="layer">
            <Row className="align-items-center align-content-center h-100">
              <Col xs={12} className="text-center mb-md-3">
                <img
                  src={CompanyLogo}
                  className="company-logo"
                  alt={this.translation('mobilizeCompanyLogo')}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="d-block d-sm-block d-md-none">
          <Row className="justify-content-center align-items-center">
            <Col xs="auto">
              <div
                style={{ height: '150px' }}
                className="bg-danger rounded p-2 m-3"
              >
                <img
                  src={CompanyLogo}
                  alt={this.translation('mobilizeCompanyLogo')}
                  className="h-100"
                />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(LoginBanner);
