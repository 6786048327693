// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work

import React from 'react';
import { Route } from 'react-router-dom';

function RouteWithSubRoutes(route) {
  return (
    <Route
      exact={!!route.exact}
      path={route.path}
      render={props => {
        // pass the sub-routes down to keep nesting
        return <route.component {...props} routes={route.routes} />;
      }}
    />
  );
}

export default RouteWithSubRoutes;
