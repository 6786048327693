import React, { Component } from 'react';
import { Col, Button, Collapse, Alert } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { ChipInput } from '../../../_shared/components';
import { ProductService } from '../../../_shared/services';
import { withTranslation } from 'react-i18next';

class AddByNumbers extends Component {
  translation = this.props.t;
  state = {
    serialNumbers: [],
    checkingValidity: false,
    isValueValid: false,
    valueValidationMessage: ''
  };
  /**Handler to add chip */
  addChip = value => {
    const serialNumbers = this.state.serialNumbers.slice();
    serialNumbers.push(value);
    this.setState({ serialNumbers });
  };
  /**Handler to remove chip */
  removeChip = index => {
    const serialNumbers = this.state.serialNumbers.slice();
    serialNumbers.splice(index, 1);
    this.setState({ serialNumbers, valueValidationMessage: '' });
  };
  /**Validate value entered by user, it should have exactly 8 digits and it should be valid */
  validateValue = value => {
    this.setState({ checkingValidity: true });
    const isExist = this.state.serialNumbers.indexOf(value);
    if (isExist > -1) {
      this.setState({
        isValueValid: false,
        checkingValidity: false,
        valueValidationMessage: this.translation('serialNumberAlreadyAdded')
      });
    } else {
      let isValueValid = value.trim().length === 11;
      if (isValueValid) {
        this.validateSerialNumber(value);
      } else {
        this.setState({
          isValueValid,
          checkingValidity: false,
          valueValidationMessage: value
            ? this.translation('formValidations.invalidSerialNumber')
            : this.translation('addAtLeastOneSerialNumber')
        });
      }
    }
  };
  /**Call api to check serial number */
  validateSerialNumber(value) {
    if (/^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{6}$/.test(value)) {
      ProductService.checkSerialNumber(value).subscribe({
        next: response => {
          const { status, message } = response.data;
          this.setState({ checkingValidity: false });
          if (status) {
            this.addChip(value);
            this.setState({ isValueValid: status, valueValidationMessage: '' });
          } else {
            this.setState({
              isValueValid: status,
              valueValidationMessage: message
            });
          }
        },
        error: errorResponse => {
          const { status, message } = errorResponse.data;
          this.setState({
            checkingValidity: false,
            isValueValid: status,
            valueValidationMessage: message
          });
        }
      });
    } else {
      this.setState({
        checkingValidity: false,
        isValueValid: false,
        valueValidationMessage: this.translation(
          'formValidations.invalidSerialNumber'
        )
      });
    }
  }
  /**Call api to add product */
  addProducts = () => {
    this.props.startLoading();
    ProductService.registerProduct(this.state.serialNumbers).subscribe({
      next: () => {
        this.props.stopLoading();
        toastr.success(
          this.translation('success'),
          this.translation('unitsAreRegisteredWith')
        );
        this.props.goTo('/my-kits');
      },
      error: errorResponse => {
        this.props.stopLoading();
        toastr.error(this.translation('error'), errorResponse.data.message);
      }
    });
  };
  render() {
    return (
      <Col xs={12} className="mb-3">
        <ChipInput
          classes="ml-3"
          chips={this.state.serialNumbers}
          onSubmit={value => this.validateValue(value)}
          onRemove={index => this.removeChip(index)}
          label={
            this.state.checkingValidity
              ? this.translation('pleaseWait') + '...'
              : this.translation('enterSerialNumber')
          }
          disabled={this.state.checkingValidity}
          loading={this.state.checkingValidity}
          helpText={
            <span className="pl-3">
              {this.translation('chipInputHelpTexts.text1')}
              <kbd className="py-0 mx-2 font-size-lg">↩</kbd>
              {this.translation('chipInputHelpTexts.text2')}.
            </span>
          }
        />
        <Collapse
          in={
            !this.state.checkingValidity &&
            !this.state.isValueValid &&
            !!this.state.valueValidationMessage
          }
        >
          <div>
            {!!this.state.valueValidationMessage && (
              <Alert variant="danger" className="ml-3 w-auto">
                {this.state.valueValidationMessage}
              </Alert>
            )}
          </div>
        </Collapse>
        <p className="font-size-lg ml-3">
          {this.translation('quantity')}:{' ' + this.state.serialNumbers.length}
        </p>
        <Button
          variant="success"
          size="lg"
          className="rounded-0 px-5 float-right"
          disabled={!this.state.serialNumbers.length}
          onClick={() => this.addProducts()}
        >
          {this.translation('add')}
        </Button>
      </Col>
    );
  }
}

export default withTranslation()(AddByNumbers);
