import React from 'react';
import { ImageContainer } from '../../../_shared/components';
import i18next from 'i18next';
import {
  getFormattedDate,
  headerFormatterWithSort
} from '../../../_shared/helpers';

const itemImageFormatter = (cell, row) => {
  return (
    <ImageContainer src={cell} alt={row.name} height="40px" className="h-100" />
  );
};

export const kitDetailsTableConstants = {
  itemColumns: [
    {
      dataField: 'imageSrc',
      text: '',
      formatter: itemImageFormatter,
      classes: 'py-0 align-middle',
      headerStyle: { width: '8%' },
      style: { width: '8%' }
    },
    {
      dataField: 'name',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemName')),
      sort: true,
      headerStyle: { width: '25%' },
      style: { width: '25%' }
    },
    {
      dataField: 'quantity',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('quantity')),
      sort: true
    },
    {
      dataField: 'number',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('itemNumber')),
      sort: true
    },
    {
      dataField: 'lotNumber',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('lotNumber')),
      sort: true
    },
    {
      dataField: 'expiryDate',
      text: '',
      headerFormatter: (...theArgs) =>
        headerFormatterWithSort(...theArgs, i18next.t('expiryDate')),
      sort: true,
      formatter: cell => getFormattedDate(cell)
    }
  ]
};
