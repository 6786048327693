import { API_ENDPOINTS } from '../constants/api-endpoints.constants';
import { AxiosSubscriber, authHeader } from '../helpers';
import { Observable } from 'rxjs';

export const emailService = {
  sendMail
};

function sendMail(id, type) {
  return new Observable(observer => {
    const requestType = 'post';
    let urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.MAIL.SEND_MAIL
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { id, type }
    );
  });
}
