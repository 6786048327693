import React, { Component } from 'react';
import { Col, Button, Collapse, Alert } from 'react-bootstrap';

import { ChipInput, Icon } from '../../../../_shared/components';
import { ProductService } from '../../../../_shared/services';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

class AddByNumbers extends Component {
  translation = i18next.t;
  state = {
    serialNumbers: [],
    checkingValidity: false,
    isValueValid: false,
    valueValidationMessage: ''
  };
  /**Handler to add chip */
  addChip = value => {
    const serialNumbers = this.state.serialNumbers.slice();
    serialNumbers.push(value);
    this.setState({ serialNumbers });
  };
  /**Handler to remove chip */
  removeChip = index => {
    const serialNumbers = this.state.serialNumbers.slice();
    serialNumbers.splice(index, 1);
    this.setState({ serialNumbers, valueValidationMessage: '' });
  };
  /**Validate value entered by user, it should have exactly 8 digits and it should be valid */
  validateValue = value => {
    let isMatch = false;
    this.setState({ checkingValidity: true });
    const isExist = this.state.serialNumbers.indexOf(value);
    if (this.state.serialNumbers.length > 0) {
      this.state.serialNumbers.map(serial => {
        if (
          (serial.localeCompare(value, undefined, { sensitivity: 'base' }) ===
            0) ===
          true
        ) {
          isMatch = true;
        }
      });
    }
    if (
      isExist > -1 ||
      (isMatch === true && this.state.serialNumbers.length != 0)
    ) {
      this.setState({
        isValueValid: false,
        checkingValidity: false,
        valueValidationMessage: i18next.t('serialNumberAlreadyAdded')
      });
    } else {
      let isValueValid = value.trim().length >= 1;
      if (isValueValid) {
        this.validateSerialNumber(value);
      } else {
        this.setState({
          isValueValid,
          checkingValidity: false,
          valueValidationMessage: value
            ? i18next.t('formValidations.invalidSerialNumber')
            : i18next.t('addAtLeastOneSerialNumber')
        });
      }
    }
  };
  /**Call api to check serial number */
  validateSerialNumber(value) {
    if (value) {
      ProductService.serialNumberAvailableSingle(value).subscribe({
        next: response => {
          const { status, message } = response.data;
          this.setState({ checkingValidity: false });
          if (status) {
            this.addChip(value);
            this.setState({ isValueValid: status, valueValidationMessage: '' });
          } else {
            this.setState({
              isValueValid: status,
              valueValidationMessage: message
            });
          }
        },
        error: errorResponse => {
          const { status, message } = errorResponse.data;
          this.setState({
            checkingValidity: false,
            isValueValid: status,
            valueValidationMessage: message
          });
        }
      });
    } else {
      this.setState({
        checkingValidity: false,
        isValueValid: false,
        valueValidationMessage: i18next.t('formValidations.invalidSerialNumber')
      });
    }
  }
  /**Call api to add product */
  addProducts = () => {
    if (this.props.createDate) {
      this.props.onValidSerialNumbers(this.state.serialNumbers);
    } else {
      this.props.triggerDateRequired();
    }
  };
  render() {
    return (
      <Col xs={12} className="mb-3 pl-0">
        <ChipInput
          chips={this.state.serialNumbers}
          onSubmit={value => this.validateValue(value)}
          onRemove={index => this.removeChip(index)}
          label={
            this.state.checkingValidity
              ? `${i18next.t('pleaseWait')}...`
              : i18next.t('enterSerialNumber')
          }
          disabled={this.state.checkingValidity}
          loading={this.state.checkingValidity}
          helpText={
            <Trans i18nKey="pressEnterToSubmit">
              Press
              {/* <kbd className=""> */}
              <Icon
                iconName="keyboard-return"
                fill="#ffffff"
                classes="bg-dark rounded p-1"
                size="22"
              />
              {/* </kbd> */}
              Enter/Return to add value
            </Trans>
          }
        />
        <Collapse
          in={
            !this.state.checkingValidity &&
            !this.state.isValueValid &&
            !!this.state.valueValidationMessage
          }
        >
          <div>
            {!!this.state.valueValidationMessage && (
              <Alert variant="danger" className="w-auto">
                {this.state.valueValidationMessage}
              </Alert>
            )}
          </div>
        </Collapse>
        <p className="font-size-lg">
          {i18next.t('quantity')}:{' ' + this.state.serialNumbers.length}
        </p>
        <Button
          variant="success"
          size="lg"
          className="rounded-0 px-5 float-right"
          disabled={!this.state.serialNumbers.length}
          onClick={() => this.addProducts()}
        >
          {i18next.t('submit')}
        </Button>
      </Col>
    );
  }
}

export default AddByNumbers;
