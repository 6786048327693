import { API_ENDPOINTS } from '../constants/api-endpoints.constants';
import { Observable } from 'rxjs';
import { AxiosSubscriber, authHeader, authHeaderWithJSON } from '../helpers';
import { stringify } from 'querystring';

export const NotificationService = {
  create,
  get
};

/**@function create
 * Create alert for super admin
 * @param {{title: string, description: string, sendTo: string[]}} alert - Alert to be added
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function create(alert) {
  alert = Object.assign(alert, {
    site: alert.sendTo.includes('site'),
    mobile: alert.sendTo.includes('mobile')
  });
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.ALERTS.POST_ALERT
      }`,
      axiosOptions = { headers: authHeaderWithJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      alert
    );
  });
}

/**@function get
 * 'Get data from server'
 * @param {number} [skip=0] - Starting index of the records to be fetched
 * @param {number} [limit=10] - Maximum Numbers of records to be fetched
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function get(skip = 0, limit = 10) {
  return new Observable(observer => {
    const requestType = 'get',
      QUERY_PARAMS = `?${stringify({ skip, limit })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.ALERTS.GET_ALERTS
      }${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
