// @flow
import * as React from 'react';
import { Container, Card, Button, Row, Col, Form } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import UserAccountDetails from './Details';
import UserAccountPrimaryContact from './PrimaryContact';
import UserAccountSecondaryContact from './SecondaryContact';
import UserAccountAddress from './Address';

import { ColorConstants } from '../../../../_shared/constants';
import { UserService, NoteService } from '../../../../_shared/services';
import { Icon } from '../../../../_shared/components';
import {
  loaderActions,
  authenticationActions
} from '../../../../_shared/redux/actions';
import { UserAccountModel } from '../../../../models';
import { errorParser } from '../../../../_shared/helpers';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

type State = {
  account: UserAccountModel,
  note: {
    _id: '',
    note: '',
    user: ''
  },
  isNoteLoading: boolean,
  noteShouldBeCreated: boolean,
  isLoading: boolean,
  editDetails: boolean,
  editPrimaryContact: boolean,
  editSecondaryContact: boolean,
  editAddress: boolean
};

type Props = {
  customerId: string,
  startLoading(): void,
  stopLoading(): void,
  updateUser(user: any): void,
  addNewKit(): void,
  history: any,
  goTo(path: string): void,
  isDeleted(isDeleted: boolean): void,
  t: i18next.TFunction
};

class BasicDetails extends React.Component<Props, State> {
  translation: i18next.TFunction = this.props.t;
  /**Resource cleaner */
  cleanup: any;
  /**Form submit handlers */
  submitDetailForm: any;
  submitPrimaryContactForm: any;
  submitSecondaryContactForm: any;
  submitAddressForm: any;
  /**Component's state */
  state = {
    account: {
      _id: '',
      email: '',
      name: '',
      lastName: '',
      userType: 'individual',
      isVerified: false,
      type: 'site',
      isDeleted: false,
      isPasswordReset: false,
      imageSrc: '',
      phoneNumber: '',
      primaryContact: { name: '', lastName: '', phoneNumber: '', email: '' },
      secondaryContact: { name: '', lastName: '', phoneNumber: '', email: '' },
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        zipCode: ''
      }
    },
    isLoading: true,
    editDetails: false,
    editPrimaryContact: false,
    editSecondaryContact: false,
    editAddress: false,
    note: {
      _id: '',
      note: '',
      user: ''
    },
    isNoteLoading: true,
    noteShouldBeCreated: true
  };
  /**Resource cleaner */
  cleanup = [];
  /**Perform network calls, once component is mounted */
  componentDidMount() {
    this.getAccountDetails();
    this.getNote();
  }
  /**Free up resources on unmount */
  componentWillUnmount() {
    if (this.cleanup.length) this.cleanup.forEach(obs => obs.unsubscribe());
    this.cleanup = [];
  }
  /**Redirects */
  goTo = (path: string) => this.props.goTo(path);
  /**Call api to get current user's account details */
  getAccountDetails() {
    this.setState({ isLoading: true });
    const getAccountDetails$ = UserService.getById(
      this.props.customerId
    ).subscribe({
      next: (response: {
        data: {
          user: UserAccountModel
        }
      }) => {
        if (this.cleanup) {
          const user = response.data.user;
          const account = Object.assign(this.state.account, user);
          this.setState({ account, isLoading: false });
          this.props.isDeleted(user.isDeleted);
        }
      },
      error: (errorResponse: { data: { message: string } }) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getAccountDetails$.unsubscribe();
    };
  }
  handleNoteService = () => ({
    next: response => {
      toastr.success(this.translation('success'), response.data.message);
      this.getNote();
    },
    error: errorResponse => {
      this.setState({ isNoteLoading: false });
      toastr.error(this.translation('error'), errorParser(errorResponse));
    }
  });
  getNote = () => {
    const noteService$ = NoteService.getByUserId(
      this.props.customerId
    ).subscribe({
      next: response => {
        const note = response.data.notes[0]
          ? response.data.notes[0]
          : this.state.note;
        this.setState({
          note,
          isNoteLoading: false,
          noteShouldBeCreated: !note.note
        });
      }
    });
    this.cleanup = () => {
      noteService$.unsubscribe();
    };
    // this.cleanup.push(noteService$);
  };

  saveNote = () => {
    this.setState({ isNoteLoading: true });
    if (this.state.note.note) {
      if (this.state.noteShouldBeCreated) {
        // create note
        const noteService$ = NoteService.create(
          this.state.note.note,
          this.props.customerId
        ).subscribe(this.handleNoteService());
        this.cleanup = () => {
          noteService$.unsubscribe();
        };
        // this.cleanup.push(noteService$);
      } else {
        // update note
        const noteService$ = NoteService.update(
          this.state.note._id,
          this.state.note.note
        ).subscribe(this.handleNoteService());
        this.cleanup = () => {
          noteService$.unsubscribe();
        };
        // this.cleanup.push(noteService$);
      }
    } else {
      // delete note
      const noteService$ = NoteService.delete(this.state.note._id).subscribe(
        this.handleNoteService()
      );
      this.cleanup = () => {
        noteService$.unsubscribe();
      };
      // this.cleanup.push(noteService$);
    }
  };

  /**Submit form handler for each form */
  handleSubmitForm = (whichForm: any) => {
    switch (whichForm) {
      case 'detail':
        if (this.submitDetailForm) {
          this.submitDetailForm();
        }
        break;
      case 'primaryContact':
        if (this.submitPrimaryContactForm) {
          this.submitPrimaryContactForm();
        }
        break;
      case 'secondaryContact':
        if (this.submitSecondaryContactForm) {
          this.submitSecondaryContactForm();
        }
        break;
      case 'address':
        if (this.submitAddressForm) {
          this.submitAddressForm();
        }
        break;
      default:
        break;
    }
  };

  /**Submit form binder for each form, so that we can call onSubmit from parent */
  bindSubmitForm = (whichForm: string, submitForm: any) => {
    switch (whichForm) {
      case 'detail':
        this.submitDetailForm = submitForm;
        break;
      case 'primaryContact':
        this.submitPrimaryContactForm = submitForm;
        break;
      case 'secondaryContact':
        this.submitSecondaryContactForm = submitForm;
        break;
      case 'address':
        this.submitAddressForm = submitForm;
        break;
      default:
        break;
    }
  };

  /**Submit form to update details */
  onSubmit = (values: any) => {
    this.props.startLoading();
    this.setState({
      editDetails: false,
      editPrimaryContact: false,
      editSecondaryContact: false,
      editAddress: false
    });
    const { _id, ...restValues } = values;
    const account = Object.assign(this.state.account, restValues);
    UserService.updateUserById(this.props.customerId, account).subscribe({
      next: () => {
        this.props.stopLoading();
        toastr.success(
          this.translation('accountUpdated'),
          this.translation('accountDetailsUpdated')
        );
        this.getAccountDetails();
      },
      error: errorResponse => {
        this.props.stopLoading();
        toastr.success(this.translation('error'), errorParser(errorResponse));
      }
    });
  };

  render() {
    return (
      <Container fluid>
        {this.state.isLoading ? (
          <div className="w-100 text-center">
            <MDSpinner singleColor={ColorConstants.PRIMARY} />
          </div>
        ) : (
          <>
            <Card className="mb-4">
              <Card.Body>
                <h3 className="mb-4">
                  {this.translation('customer')}:{' '}
                  {this.state.account.name + ' ' + this.state.account.lastName}
                </h3>
                <Row className="justify-content-between">
                  <Col>
                    <h4 className="mb-4">{this.translation('details')}</h4>
                  </Col>
                  <Col className="text-right">
                    {this.state.editDetails ? (
                      <>
                        <Button
                          variant="secondary"
                          className="mr-3 rounded-0"
                          onClick={() => this.setState({ editDetails: false })}
                        >
                          {this.translation('cancel')}
                        </Button>
                        <Button
                          variant="success"
                          className="rounded-0"
                          onClick={() => this.handleSubmitForm('detail')}
                        >
                          {`${this.translation('save')} ${this.translation(
                            'change',
                            { count: 0 }
                          )}`}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant=""
                        onClick={() => this.setState({ editDetails: true })}
                      >
                        {this.translation('edit')}
                        <Icon iconName="edit" size="26" classes="ml-2" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <UserAccountDetails
                  details={{
                    name: this.state.account.name,
                    lastName: this.state.account.lastName,
                    email: this.state.account.email,
                    userType: this.state.account.userType,
                    phoneNumber: this.state.account.phoneNumber
                  }}
                  editDetails={this.state.editDetails}
                  bindSubmitForm={this.bindSubmitForm}
                  onSubmit={values => this.onSubmit(values)}
                  goTo={path => this.props.history.push(path)}
                />
              </Card.Body>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col>
                    <h4 className="mb-4">
                      {this.translation('primaryContact')}
                    </h4>
                  </Col>
                  <Col className="text-right">
                    {this.state.editPrimaryContact ? (
                      <>
                        <Button
                          variant="secondary"
                          className="mr-3 rounded-0"
                          onClick={() =>
                            this.setState({ editPrimaryContact: false })
                          }
                        >
                          {this.translation('cancel')}
                        </Button>
                        <Button
                          variant="success"
                          className="rounded-0"
                          onClick={() =>
                            this.handleSubmitForm('primaryContact')
                          }
                        >
                          {`${this.translation('save')} ${this.translation(
                            'change',
                            { count: 0 }
                          )}`}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant=""
                        onClick={() =>
                          this.setState({ editPrimaryContact: true })
                        }
                      >
                        {this.translation('edit')}
                        <Icon iconName="edit" size="26" classes="ml-2" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <UserAccountPrimaryContact
                  details={this.state.account.primaryContact}
                  editPrimaryContact={this.state.editPrimaryContact}
                  bindSubmitForm={this.bindSubmitForm}
                  onSubmit={values => this.onSubmit(values)}
                />
              </Card.Body>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col>
                    <h4 className="mb-4">
                      {this.translation('secondaryContact')}
                    </h4>
                  </Col>
                  <Col className="text-right">
                    {this.state.editSecondaryContact ? (
                      <>
                        <Button
                          variant="secondary"
                          className="mr-3 rounded-0"
                          onClick={() =>
                            this.setState({ editSecondaryContact: false })
                          }
                        >
                          {this.translation('cancel')}
                        </Button>
                        <Button
                          variant="success"
                          className="rounded-0"
                          onClick={() =>
                            this.handleSubmitForm('secondaryContact')
                          }
                        >
                          {`${this.translation('save')} ${this.translation(
                            'change',
                            { count: 0 }
                          )}`}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant=""
                        onClick={() =>
                          this.setState({ editSecondaryContact: true })
                        }
                      >
                        {this.translation('edit')}
                        <Icon iconName="edit" size="26" classes="ml-2" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <UserAccountSecondaryContact
                  details={this.state.account.secondaryContact}
                  editSecondaryContact={this.state.editSecondaryContact}
                  bindSubmitForm={this.bindSubmitForm}
                  onSubmit={values => this.onSubmit(values)}
                />
              </Card.Body>
              <Card.Body>
                <Row className="justify-content-between">
                  <Col>
                    <h4 className="mb-4">
                      {this.translation('address_label')}
                    </h4>
                  </Col>
                  <Col className="text-right">
                    {this.state.editAddress ? (
                      <>
                        <Button
                          variant="secondary"
                          className="mr-3 rounded-0"
                          onClick={() => this.setState({ editAddress: false })}
                        >
                          {this.translation('cancel')}
                        </Button>
                        <Button
                          variant="success"
                          className="rounded-0"
                          onClick={() => this.handleSubmitForm('address')}
                        >
                          {`${this.translation('save')} ${this.translation(
                            'change',
                            { count: 0 }
                          )}`}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant=""
                        onClick={() => this.setState({ editAddress: true })}
                      >
                        {this.translation('edit')}
                        <Icon iconName="edit" size="26" classes="ml-2" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <UserAccountAddress
                  details={this.state.account.address}
                  editAddress={this.state.editAddress}
                  bindSubmitForm={this.bindSubmitForm}
                  onSubmit={values => this.onSubmit(values)}
                />
              </Card.Body>
            </Card>
            <Row>
              <Col>
                <h4>{this.translation('note', { count: 0 })}</h4>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder={this.translation('enterNote')}
                    className="rounded-0 mb-3"
                    onChange={changeEvent => {
                      const note = { ...this.state.note };
                      note.note = changeEvent.target.value;
                      this.setState({ note });
                    }}
                    value={this.state.note.note}
                    disabled={this.state.isNoteLoading}
                  />
                </Form.Group>
                <Button
                  variant="success"
                  size="lg"
                  className="rounded-0 px-5 float-right"
                  onClick={() => this.saveNote()}
                  disabled={
                    this.state.isNoteLoading ||
                    (this.state.noteShouldBeCreated && !this.state.note.note)
                  }
                >
                  {this.state.isNoteLoading ? (
                    <MDSpinner singleColor="#ffffff" />
                  ) : (
                    (this.state.noteShouldBeCreated
                      ? this.translation('create')
                      : this.translation('save')) +
                    ' ' +
                    this.translation('note')
                  )}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop()),
  updateUser: user => dispatch(authenticationActions.update(user))
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(BasicDetails)
);
