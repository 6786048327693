// @flow
import * as React from 'react';

import { iconName } from '../constants';

// import all icon svg files below
import { ReactComponent as ProfileSVG } from '../../../assets/icons/profile.svg';
import { ReactComponent as PasswordSVG } from '../../../assets/icons/password.svg';
import { ReactComponent as ClosedEnvelopeSVG } from '../../../assets/icons/closed-envelope.svg';
import { ReactComponent as PhoneReceiverSVG } from '../../../assets/icons/phone-receiver.svg';
import { ReactComponent as SearchSVG } from '../../../assets/icons/search.svg';
import { ReactComponent as RightArrowCircleOSVG } from '../../../assets/icons/right-arrow-circle-o.svg';
import { ReactComponent as CancelSVG } from '../../../assets/icons/cancel.svg';
import { ReactComponent as BellSVG } from '../../../assets/icons/grey-bell.svg';
import { ReactComponent as EmptyBellSVG } from '../../../assets/icons/grey-bell-o.svg';
import { ReactComponent as CheckAllSVG } from '../../../assets/icons/check-all.svg';
import { ReactComponent as CheckSVG } from '../../../assets/icons/check.svg';
import { ReactComponent as CheckAllCircleSVG } from '../../../assets/icons/check-all-circle.svg';
import { ReactComponent as CheckCircleSVG } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as DownloadSVG } from '../../../assets/icons/download.svg';
import { ReactComponent as SendMailSVG } from '../../../assets/icons/send-mail.svg';
import { ReactComponent as ChartSliderLeftArrow } from '../../../assets/icons/chart-slider-left-arrow.svg';
import { ReactComponent as ChartSliderRightArrow } from '../../../assets/icons/chart-slider-right-arrow.svg';
import { ReactComponent as DocumentSVG } from '../../../assets/icons/document.svg';
import { ReactComponent as PictureSVG } from '../../../assets/icons/picture.svg';
import { ReactComponent as PlayButtonSVG } from '../../../assets/icons/play-button.svg';
import { ReactComponent as UploadSVG } from '../../../assets/icons/upload.svg';
import { ReactComponent as DropdownDownArrowSVG } from '../../../assets/icons/baseline-arrow_drop_down-24px.svg';
import { ReactComponent as PaperPlaneSVG } from '../../../assets/icons/paper-plane.svg';
import { ReactComponent as MailSendSVG } from '../../../assets/icons/mail-send.svg';
import { ReactComponent as CancelFilled } from '../../../assets/icons/baseline-cancel-24px.svg';
import { ReactComponent as XLSX } from '../../../assets/icons/xlsx.svg';
import { ReactComponent as HelpSVG } from '../../../assets/icons/baseline-help-24px.svg';
import { ReactComponent as PdfBoxSVG } from '../../../assets/icons/pdf-box.svg';
import { ReactComponent as EarthSVG } from '../../../assets/icons/earth.svg';
import { ReactComponent as CellphoneSVG } from '../../../assets/icons/cellphone.svg';
import { ReactComponent as KeyboardReturnSVG } from '../../../assets/icons/keyboard-return.svg';

// import all png files below
import AddPNG from '../../../assets/icons/add.png';
import EditPNG from '../../../assets/icons/edit.png';
import DeletePNG from '../../../assets/icons/delete.png';
import SharePNG from '../../../assets/icons/share.png';
import RedBellWithDotPNG from '../../../assets/icons/red-bell-with-dot.png';
import DeleteInlinePNG from '../../../assets/icons/delete-inline.png';
import EditInlinePNG from '../../../assets/icons/edit-inline.png';
import CalendarPNG from '../../../assets/icons/calendar.png';

/**@class Icon
 * Shared component for icons
 * @property {string} iconName - Name of the icon
 * @property {number} size - Height and width of the icon
 * @property {string} classes - CSS CLasses
 * @property {string} fill - Fill color, works only in svg. Recommended to use from src\app\\_shared\constants\color.constants.js
 * @property {Function} onClick - On click handler
 * @returns {React.Component} - React Component containing relative png/svg file
 * @see https://css-tricks.com/creating-svg-icon-system-react/
 */

type Props = {
  iconName: iconName,
  size?: number,
  classes?: string,
  fill?: string,
  onClick?: () => void,
  style?: any,
  title?: string | typeof undefined
};

type State = {};
class Icon extends React.Component<Props, State> {
  /**Below classes are needed for svg-icon */
  neededClasses: string = 'svg-icon d-inline-block mw-100 ';

  /**The JSON map of name vs Icon Component */
  IconMap: {
    [key: string]: any
  } = {
    profile: ProfileSVG,
    password: PasswordSVG,
    'closed-envelope': ClosedEnvelopeSVG,
    'phone-receiver': PhoneReceiverSVG,
    search: SearchSVG,
    add: AddPNG,
    edit: EditPNG,
    delete: DeletePNG,
    share: SharePNG,
    right: RightArrowCircleOSVG,
    cancel: CancelSVG,
    document: DocumentSVG,
    'media-player-play': PlayButtonSVG,
    picture: PictureSVG,
    'empty-bell': EmptyBellSVG,
    bell: BellSVG,
    'red-bell-dot': RedBellWithDotPNG,
    check: CheckSVG,
    'check-all': CheckAllSVG,
    'check-circle': CheckCircleSVG,
    'check-all-circle': CheckAllCircleSVG,
    download: DownloadSVG,
    'send-mail': SendMailSVG,
    'chart-slider-left': ChartSliderLeftArrow,
    'chart-slider-right': ChartSliderRightArrow,
    'edit-inline': EditInlinePNG,
    'delete-inline': DeleteInlinePNG,
    upload: UploadSVG,
    'dropdown-down-arrow': DropdownDownArrowSVG,
    'paper-plane': PaperPlaneSVG,
    'mail-send': MailSendSVG,
    'cancel-filled': CancelFilled,
    xlsx: XLSX,
    help: HelpSVG,
    'pdf-box': PdfBoxSVG,
    calendar: CalendarPNG,
    earth: EarthSVG,
    cellphone: CellphoneSVG,
    'keyboard-return': KeyboardReturnSVG
  };

  render() {
    const {
      iconName,
      size = 18,
      classes = '',
      fill,
      onClick,
      style,
      title
    } = this.props;
    const typeOfIcon = typeof this.IconMap[iconName];
    // if typeOfIcon is string, it's image-src, else it's svg element
    if (typeOfIcon === 'string') {
      return (
        <img
          src={this.IconMap[iconName]}
          alt={iconName}
          {...this.getProps(size, classes, fill, onClick, style, title)}
        />
      );
    } else {
      return React.createElement(
        this.IconMap[iconName],
        this.getProps(size, classes, fill, onClick, style, title)
      );
    }
  }

  /**Returns props object for {@link Icon} */
  getProps(
    size: number,
    classes?: string,
    fill?: string,
    onClick?: () => void,
    style?: any,
    title?: string | typeof undefined
  ) {
    const className = this.neededClasses + (classes ? classes : '');
    return {
      height: size + 'px',
      width: size + 'px',
      className,
      fill,
      onClick,
      style,
      title
    };
  }
}
export default Icon;
