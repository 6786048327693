// @flow
import * as React from 'react';
import { Container, Row, Col, Dropdown, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { DatePicker } from '../../../../_shared/components';
import { ReactRouterHistory, Kit } from '../../../../models';
import AddByNumbers from './AddByNumbers';
import AddByRange from './AddByRange';
import { loaderActions } from '../../../../_shared/redux/actions';
import i18next from 'i18next';

type Props = {
  kits: Kit[],
  history: ReactRouterHistory,
  startLoading(): void,
  stopLoading(): void,
  onValidSerialNumbers(serialNumbers: string[]): void,
  onKitTypeSelected(kit: Kit): void,
  onCreateDateChange(createDate: Date): void,
  createDate: Date | typeof undefined
};

type State = {
  kitType: Kit,
  createDate: Date | void,
  addByRange: boolean,
  dateChanged: boolean
};

class NewProductStep1 extends React.Component<Props, State> {
  /**State of the component */
  state = {
    kitType: { name: '', description: '' },
    createDate: undefined,
    addByRange: false,
    dateChanged: false
  };
  /**Select first kit type once component mounts */
  componentDidMount() {
    let i = 1;
    this.props.kits.map((kit: Kit, index: number) => {
      if (kit.active && i === 1) {
        i = i + 1;
        this.setState({
          kitType: this.props.kits[index],
          createDate: this.props.createDate
        });
      }
    });
  }
  /**Handler for datepicker*/
  handleChangeDate = createDate => {
    this.setState({ dateChanged: true, createDate });
    this.props.onCreateDateChange(createDate);
  };
  /**Serial numbers togglers */
  toggleAddByRange = addByRange => {
    this.setState({ addByRange });
  };
  render() {
    return (
      <Container fluid>
        <h4>{i18next.t('newUnit')}</h4>
        <Row className="align-item-center">
          <Col md="3" className="align-self-center pl-4 mb-3 pt-3">
            <p>{i18next.t('selectKitType')}</p>
          </Col>
          <Col md="9" className="mb-3">
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="mb-2 custom-arrow-icon input-height border rounded-0 w-100 text-left"
              >
                {this.state.kitType.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-right rounded-0 shadow-sm w-100 scrolled-height">
                {this.props.kits.map((kit: Kit, index: number) => (
                  <React.Fragment key={index}>
                    {kit.active === true && (
                      <Dropdown.Item
                        className="py-3"
                        onClick={() => {
                          this.setState({ kitType: kit });
                          this.props.onKitTypeSelected(kit);
                        }}
                      >
                        {kit.name}
                      </Dropdown.Item>
                    )}
                    {kit.active === true && (
                      <Dropdown.Divider className="my-0" />
                    )}
                  </React.Fragment>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-item-center">
          <Col md="3" className="pl-4 mb-3 pt-3">
            <p>{i18next.t('createDate')}</p>
          </Col>
          <Col md="9" className="mb-3">
            <DatePicker
              selected={this.state.createDate}
              onChange={this.handleChangeDate}
              className={
                !this.state.createDate && this.state.dateChanged
                  ? 'is-invalid'
                  : ''
              }
              placeholder="MMM DD, YYYY"
              error={i18next.t('formValidations.required')}
              showError={
                !this.state.createDate && this.state.dateChanged && 'tooltip'
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" className="align-self-start pl-3 mb-3 mt-2">
            <h4>{i18next.t('serialNumber', { count: 0 })}</h4>
          </Col>
          <Col md="9" className="mb-3">
            <Button
              variant={!this.state.addByRange ? 'link' : ''}
              size="lg"
              onClick={() => this.toggleAddByRange(false)}
            >
              {i18next.t('addIndividual')}
            </Button>
            <span className="font-size-lg">|</span>
            <Button
              variant={this.state.addByRange ? 'link' : ''}
              size="lg"
              onClick={() => this.toggleAddByRange(true)}
            >
              {i18next.t('addByRange')}
            </Button>
            <div className="w-100 mb-4" />
            {!this.state.addByRange ? (
              <AddByNumbers
                createDate={this.state.createDate}
                goTo={path => this.props.history.push(path)}
                onValidSerialNumbers={(serialNumbers: string[]) =>
                  this.props.onValidSerialNumbers(serialNumbers)
                }
                triggerDateRequired={() => {
                  this.setState({ dateChanged: true });
                }}
              />
            ) : (
              <AddByRange
                createDate={this.state.createDate}
                goTo={path => this.props.history.push(path)}
                onValidSerialNumbers={(serialNumbers: string[]) =>
                  this.props.onValidSerialNumbers(serialNumbers)
                }
                startLoading={() => this.props.startLoading()}
                stopLoading={() => this.props.stopLoading()}
                triggerDateRequired={() => {
                  this.setState({ dateChanged: true });
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default connect(
  null,
  mapDispatchToProps
)(NewProductStep1);
