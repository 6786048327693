// @flow

import * as React from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import { toastr } from 'react-redux-toastr';
import { Subscription } from 'rxjs';
import { connect } from 'react-redux';

import { ColorConstants } from '../../../_shared/constants';
import {
  Icon,
  ConfirmDialog,
  ImageContainer
} from '../../../_shared/components';
import { SuperAdminService } from '../../../_shared/services';
import { UserAccountModel, ReactRouterHistory } from '../../../models';
import { errorParser } from '../../../_shared/helpers';

import DefaultUserImg from '../../../../assets/images/user.png';
import { loaderActions } from '../../../_shared/redux/actions';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  history: ReactRouterHistory,
  startLoading: any,
  stopLoading: any,
  t: i18next.TFunction
};

type State = {
  isLoading: boolean,
  superAdmins: UserAccountModel[],
  showConfirmDelete: boolean,
  deleteConfirmMessage: any,
  superAdminIdToBeDeleted: string
};

export class ManageSuperAdmin extends React.Component<Props, State> {
  translation = this.props.t;
  cleanup: any = null;
  state = {
    isLoading: true,
    superAdmins: [],
    showConfirmDelete: false,
    deleteConfirmMessage: <></>,
    superAdminIdToBeDeleted: ''
  };
  /**Call apis once component mounted */
  componentDidMount() {
    this.getSuperAdmins();
  }
  /**Free up resources once component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  } /**Get kits from server */
  getSuperAdmins() {
    this.setState({ isLoading: true });
    const getSuperAdmins$ = SuperAdminService.getSuperAdmins().subscribe({
      next: (response: { data: { users: UserAccountModel[] } }) => {
        if (this.cleanup) {
          const superAdmins = response.data.users;
          this.setState({ superAdmins, isLoading: false });
        }
      },
      error: (errorResponse: { data: { message: string } }) => {
        if (this.cleanup) {
          this.setState({ isLoading: false });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      getSuperAdmins$.unsubscribe();
    };
  }
  /**Present confirm delete */
  presentDeleteConfirm = (_id: string, name: string) => {
    const deleteConfirmMessage = (
      <p>
        {i18next.t('deleteSuperAdmin')}:
        <br />{' '}
        <b>
          {i18next.t('name')}: {name}
        </b>
        ?
      </p>
    );
    this.setState({
      showConfirmDelete: true,
      deleteConfirmMessage,
      superAdminIdToBeDeleted: _id
    });
  };
  /**Confirm dialog cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false });
  };
  /**Confirm dialog ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    this.deleteSuperAdmin();
  };
  /**Once confirmed, delete user */
  deleteSuperAdmin() {
    const deleteSuperAdmin$: Subscription = SuperAdminService.delete(
      this.state.superAdminIdToBeDeleted
    ).subscribe({
      next: response => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.success(this.translation('success'), response.data.message);
          this.getSuperAdmins();
        }
      },
      error: errorResponse => {
        if (this.cleanup) {
          this.props.stopLoading();
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      deleteSuperAdmin$.unsubscribe();
    };
  }
  render() {
    return (
      <>
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn={i18next.t('no')}
          okBtn={i18next.t('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.state.deleteConfirmMessage}
        />
        <Container fluid>
          <Row>
            <Col md="auto" className="mb-3 ml-auto">
              <Button
                variant=""
                onClick={() => {
                  this.props.history.push('/admin/dashboard/add-admin');
                }}
                className="mb-2 rounded-0"
              >
                {i18next.t('addNewAdmin')}
                <Icon iconName="add" size="26" classes="ml-2" />
              </Button>
            </Col>
          </Row>
          {this.state.isLoading ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <>
              {this.state.superAdmins.length ? (
                this.state.superAdmins.map(
                  (superAdmin, index) =>
                    !superAdmin.isDeleted && (
                      <Row
                        className="justify-content-between align-items-center"
                        key={index}
                      >
                        <Col
                          xs="auto"
                          className="bg-light mb-3 align-self-stretch"
                        >
                          <div className="h-100 d-flex align-items-center justify-content-center super-admin-image-container">
                            <ImageContainer
                              src={
                                superAdmin.imageSrc
                                  ? superAdmin.imageSrc
                                  : DefaultUserImg
                              }
                              alt={superAdmin.name}
                              className="img-fluid rounded-circle"
                              width="48px"
                            />
                          </div>
                        </Col>
                        <Col className="bg-light mb-3 align-self-stretch">
                          <div className="h-100 d-flex flex-column pt-3">
                            <h5 className="mb-0 text-dark">
                              {superAdmin.name + ' ' + superAdmin.lastName}
                            </h5>
                            <p className="text-muted">{superAdmin.email}</p>
                          </div>
                        </Col>
                        <Col className="mb-3 bg-light align-self-stretch">
                          <div className="h-100 d-flex align-items-center float-right">
                            <Icon
                              classes="mx-2 cursor-pointer"
                              iconName="delete-inline"
                              size="29"
                              fill={ColorConstants.DEFAULT_FONT}
                              onClick={() => {
                                this.presentDeleteConfirm(
                                  superAdmin._id,
                                  superAdmin.name + ' ' + superAdmin.lastName
                                );
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )
                )
              ) : (
                <div className="w-100 text-center">
                  {i18next.t('noSuperAdminsFound')}
                  <br />
                  <Button
                    variant="link"
                    className="text-blue"
                    onClick={() =>
                      this.props.history.push('/admin/dashboard/add-admin')
                    }
                  >
                    {i18next.t('addNewAdmin')}
                  </Button>
                </div>
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(ManageSuperAdmin)
);
