import React from 'react';
import { Chart } from 'react-charts';
import { withTranslation } from 'react-i18next';

function LineChart(props) {
  const chartData = React.useMemo(
    () => [
      {
        label: 'Downloads',
        // eslint-disable-next-line id-blacklist
        data: props.data,
        color: props.color
      }
    ],
    [props.color, props.data]
  );

  const series = React.useMemo(
    () => ({
      showPoints: false,
      width: '5px'
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom', show: false },
      {
        type: 'linear',
        position: 'left',
        show: false,
        format: d => `${parseInt(d)}`
      }
    ],
    []
  );

  return (
    // A react-chart hyper-responsively and continuusly fills the available
    // space of its parent element automatically
    <Chart data={chartData} axes={axes} series={series} tooltip />
  );
}

export default withTranslation()(LineChart);
