// @flow
import * as React from 'react';
import { Container, Row, Col, Dropdown, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import {
  switchMap,
  tap,
  debounceTime,
  map,
  concatMap,
  mergeMap
} from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { FormGroup, ConfirmDialog, Icon } from '../../../_shared/components';
import { ImageService, DocumentService } from '../../../_shared/services';
import { tableConstants } from '../../../_shared/constants';
import { DocumentTableConstants } from './table.constants';
import { loaderActions } from '../../../_shared/redux/actions';
import { ReactRouterHistory, IDocument } from '../../../models';
import { errorParser } from '../../../_shared/helpers';
import AddDocumentModal from './AddDocumentModal';
import DocumentPreviewModal from './DocumentPreviewModal';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

type Props = {
  history: ReactRouterHistory,
  startLoading(): void,
  stopLoading(): void,
  t: i18next.TFunction
};

type State = {
  isTableLoading: boolean,
  documentsArrived: boolean,
  showConfirmDelete: boolean,
  deleteConfirmMessage: React.Element<any>,
  addDetails: boolean,
  editDetails: boolean,
  showAddDocumentModal: boolean,
  documentIdToBeUpdated: string,
  documents: IDocument[],
  searchText: string,
  columns: any,
  options: any,
  documentType: 'Image' | 'Video' | 'Document' | '',
  currentIndex: number,
  sizePerPage: number,
  sortField: string,
  sortOrder: 'asc' | 'desc',
  page: number,
  showPreviewModal: boolean,
  previewDocument: IDocument
};

export class Documents extends React.Component<Props, State> {
  translation = this.props.t;
  /**Resource cleaner */
  cleanup: any = null;
  /**Called when user clicks on edit icon */
  startEditDocument = (itemIdToBeUpdated: string) => {
    this.setState((curr: State) => ({
      ...curr,
      documentIdToBeUpdated: itemIdToBeUpdated,
      addDetails: false,
      editDetails: true
    }));
    // let's open modal to edit the document
    this.setState({ showAddDocumentModal: true });
  };
  /**Present confirm delete */
  presentDeleteConfirm = (id: string, name: string) => {
    const deleteConfirmMessage = (
      <>
        <p>
          {this.translation('deleteDocument')},
          <br />{' '}
          <b className="text-wrap">
            {this.translation('name')}: {name}
          </b>
          ?
        </p>
      </>
    );
    this.setState({
      showConfirmDelete: true,
      deleteConfirmMessage,
      documentIdToBeUpdated: id
    });
  };
  /**Component's state */
  state = {
    isTableLoading: false,
    documentsArrived: false,
    showConfirmDelete: false,
    deleteConfirmMessage: <></>,
    addDetails: false,
    editDetails: false,
    showAddDocumentModal: false,
    documentIdToBeUpdated: '',
    documents: [],
    searchText: '',
    columns: DocumentTableConstants.documentColumns(
      this.startEditDocument,
      this.presentDeleteConfirm
    ),
    options: {},
    documentType: '',
    currentIndex: 0,
    sizePerPage: 10,
    sortField: 'title',
    sortOrder: 'asc',
    page: 1,
    showPreviewModal: false,
    previewDocument: {
      _id: '',
      title: '',
      type: 'Image',
      url: ''
    }
  };
  /**Call apis when component mounted */
  componentDidMount() {
    this.getDocuments();
    this.onSearchChange();
  }
  /**Free up resources when component un-mounts */
  componentWillUnmount() {
    if (this.cleanup) this.cleanup();
    this.cleanup = null;
  }
  /**Handle Search Input */
  onSearchChange = () => {
    const searchInput$: Observable<Event> = fromEvent(
      document.getElementById('documents-search-input'),
      'input'
    )
      .pipe(
        debounceTime(500),
        tap(inputEvent => {
          this.setState({
            searchText: inputEvent.target.value,
            isTableLoading: true,
            page: 1,
            currentIndex: 0
          });
        }),
        switchMap(() => {
          return this.getObservable(
            this.state.currentIndex,
            this.state.sizePerPage,
            this.state.sortField,
            this.state.sortOrder,
            this.state.searchText,
            this.state.documentType
          );
        })
      )
      .subscribe(this.handelResponse());
    this.cleanup = () => {
      searchInput$.unsubscribe();
    };
  };
  /**Confirm dialog cancel click handler */
  deleteCancelClicked = () => {
    this.setState({ showConfirmDelete: false, documentIdToBeUpdated: '' });
  };
  /**Confirm dialog ok click handler */
  deleteOkClicked = () => {
    this.setState({ showConfirmDelete: false });
    this.props.startLoading();
    this.deleteDocument();
  };
  /**Handle pagination and sorting */
  onTableChange = (
    type: any,
    {
      sortField,
      sortOrder,
      tableData,
      page,
      sizePerPage
    }: {
      sortField: string,
      sortOrder: 'asc' | 'desc',
      tableData: any,
      page: number,
      sizePerPage: number
    }
  ) => {
    const currentIndex: number =
      page !== undefined && sizePerPage !== undefined
        ? (page - 1) * sizePerPage
        : 0;
    this.setState(
      { page, sizePerPage, sortField, sortOrder, currentIndex },
      () => {
        this.getDocuments(currentIndex, sizePerPage, sortField, sortOrder);
      }
    );
  };
  /**Observable */
  getObservable = (
    currentIndex: number,
    sizePerPage: number,
    sortField: string,
    sortOrder: 'asc' | 'desc',
    searchText: string,
    documentType: 'Image' | 'Video' | 'Document' | ''
  ) => {
    return DocumentService.getDocuments(
      currentIndex,
      sizePerPage,
      searchText,
      documentType,
      sortField,
      sortOrder
    );
  };
  /**Subscriber-response handler */
  handelResponse = () => ({
    next: (response: { data: { documents: IDocument[], count: number } }) => {
      if (this.cleanup) {
        const {
          sizePerPage,
          page
        }: { sizePerPage: number, page: number } = this.state;
        const {
          documents,
          count
        }: { documents: IDocument[], count: number } = response.data;
        this.setState({ documentsArrived: true });
        const options: any = tableConstants.paginationOptions(
          count,
          true,
          page,
          sizePerPage
        );
        this.setState({
          options,
          documents,
          isTableLoading: false,
          addDetails: false,
          editDetails: false,
          documentIdToBeUpdated: ''
        });
      }
    },
    error: (errorResponse: any) => {
      if (this.cleanup) {
        this.setState({ documentsArrived: true, isTableLoading: false });
        toastr.error(this.translation('error'), errorParser(errorResponse));
      }
    }
  });
  /**Once confirmed, delete user */
  deleteDocument() {
    const deleteDocument$: Observable<any> = DocumentService.delete(
      this.state.documentIdToBeUpdated
    ).subscribe({
      next: (response: { data: { message: string } }) => {
        if (this.cleanup) {
          //let's make the id null, to avoid leaks
          this.setState({
            documentIdToBeUpdated: '',
            addDetails: false,
            editDetails: false
          });
          this.props.stopLoading();
          toastr.success(this.translation('success'), response.data.message);
          this.setState({ page: 1, currentIndex: 0 }, () => {
            this.getDocuments();
          });
        }
      },
      error: (errorResponse: any) => {
        this.props.stopLoading();
        if (this.cleanup) {
          this.setState({ isTableLoading: false, documentsArrived: true });
          toastr.error(this.translation('error'), errorParser(errorResponse));
        }
      }
    });
    this.cleanup = () => {
      deleteDocument$.unsubscribe();
    };
  }
  /**Get customers from server */
  getDocuments(
    currentIndex: number = this.state.currentIndex,
    sizePerPage: number = this.state.sizePerPage,
    sortField: string = this.state.sortField,
    sortOrder: 'asc' | 'desc' = this.state.sortOrder,
    searchText: string = this.state.searchText,
    documentType: 'Image' | 'Video' | 'Document' | '' = this.state.documentType
  ) {
    this.setState({ isTableLoading: true });
    const documentService$: Subscription = this.getObservable(
      currentIndex,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      documentType
    ).subscribe(this.handelResponse());
    this.cleanup = () => {
      documentService$.unsubscribe();
    };
  }
  /**Submit detail to add/edit */
  onSubmit = (values: any, uploadedFile: any = undefined) => {
    this.setState({ showAddDocumentModal: false });
    this.props.startLoading();
    const stateDoc = this.state.documents.filter(
      (doc: IDocument) => doc._id === this.state.documentIdToBeUpdated
    )[0];
    let observable$, subscription$;
    if (uploadedFile && stateDoc && stateDoc.url) {
      observable$ = ImageService.deleteImage(
        'document',
        this.state.documentIdToBeUpdated
      ).pipe(mergeMap(() => this.completeOnSubmit(uploadedFile, values)));
    } else {
      observable$ = this.completeOnSubmit(uploadedFile, values);
    }
    subscription$ = observable$.subscribe(this.handleResponseOnSubmit());
    this.cleanup = () => {
      if (subscription$) {
        subscription$.unsubscribe();
      }
    };
  };
  /**Handle response on submit */
  handleResponseOnSubmit = () => {
    return {
      next: (response: { data: { message: string } }) => {
        if (this.cleanup) {
          toastr.success(
            `${this.translation('document')} ` +
              (!this.state.addDetails
                ? this.translation('update', { context: 'past' })
                : this.translation('add', { context: 'past' })),
            response.data.message
          );
          this.setState({
            addDetails: false,
            editDetails: false,
            documentIdToBeUpdated: ''
          });
          this.props.stopLoading();
          // once updated, let's get the user back to fist page
          this.setState({ page: 1, currentIndex: 0 }, () => {
            this.getDocuments();
          });
        }
      },
      error: (errorResponse: any) => {
        if (this.cleanup) {
          toastr.error(this.translation('error'), errorParser(errorResponse));
          this.props.stopLoading();
        }
      }
    };
  };
  /**Set user type */
  setDocumentType = (documentType: 'Image' | 'Video' | 'Document' | '') => {
    this.setState({ documentType }, () => {
      this.getDocuments();
    });
  };
  /**Show document preview */
  showPreview = (document: IDocument) => {
    this.setState(
      { previewDocument: document },
      this.setState({ showPreviewModal: true })
    );
  };
  completeOnSubmit(uploadedFile: any, values: any) {
    let observable$;
    if (uploadedFile) {
      // let's upload image first
      observable$ = ImageService.startUploadImage(uploadedFile).pipe(
        map(uploadMageResponse => uploadMageResponse.responseData.imageSrc),
        concatMap(url => {
          const updatedDocument = Object.assign(values, {
            url
          });
          if (this.state.addDetails) {
            return DocumentService.add(updatedDocument);
          } else if (this.state.editDetails) {
            return DocumentService.update(
              this.state.documentIdToBeUpdated,
              updatedDocument
            );
          }
        })
      );
      // .subscribe(this.handleResponseOnSubmit());
    } else if (this.state.addDetails) {
      observable$ = DocumentService.add(values);
      // .subscribe(this.handleResponseOnSubmit());
    } else {
      observable$ = DocumentService.update(
        this.state.documentIdToBeUpdated,
        values
      );
      // .subscribe(this.handleResponseOnSubmit());
    }
    return observable$;
  }

  render() {
    const translation = this.props.t;
    return (
      <>
        <DocumentPreviewModal
          show={this.state.showPreviewModal}
          onHide={() => this.setState({ showPreviewModal: false })}
          onHidden={() => this.setState({ previewDocument: null })}
          document={this.state.previewDocument}
        />
        <ConfirmDialog
          backdrop="static"
          showConfirm={this.state.showConfirmDelete}
          cancelBtn={translation('no')}
          okBtn={translation('yes')}
          cancelClicked={() => this.deleteCancelClicked()}
          okClicked={() => this.deleteOkClicked()}
          message={this.state.deleteConfirmMessage}
        />
        <AddDocumentModal
          show={this.state.showAddDocumentModal}
          documentId={this.state.documentIdToBeUpdated}
          onClose={() =>
            this.setState({
              showAddDocumentModal: false,
              documentIdToBeUpdated: '',
              addDetails: false,
              editDetails: false
            })
          }
          onSubmit={(document, uploadedFile) => {
            this.onSubmit(document, uploadedFile);
          }}
        />
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={8} md={4} className="mb-3">
              <FormGroup
                title={translation('searchByDocumentTitleDescription') + '...'}
                type="text"
                label={translation('searchByDocumentTitleDescription') + '...'}
                icon="search"
                iconPosition="right"
                classes="mb-2"
                formControlId="documents-search-input"
              />
            </Col>
            <Col sm="4" md="3" className="mb-3">
              <Dropdown>
                <Dropdown.Toggle
                  className="border rounded-0 input-height mt-0 mb-2"
                  variant=""
                >
                  {translation('document') + ' ' + translation('type')}:{' '}
                  {this.state.documentType || 'All'}
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right px-3 rounded-0 shadow-sm">
                  <Dropdown.Item
                    className="py-3"
                    onClick={() => this.setDocumentType('')}
                  >
                    {translation('all')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="py-3"
                    onClick={() => this.setDocumentType('Document')}
                  >
                    {translation('document')}
                  </Dropdown.Item>
                  <Dropdown.Divider className="my-0" />
                  <Dropdown.Item
                    className="py-3"
                    onClick={() => this.setDocumentType('Video')}
                  >
                    {translation('video')}
                  </Dropdown.Item>
                  <Dropdown.Divider className="my-0" />
                  <Dropdown.Item
                    className="py-3"
                    onClick={() => this.setDocumentType('Image')}
                  >
                    {translation('image')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="text-right mb-3">
              <Button
                variant=""
                onClick={() => {
                  this.setState({
                    addDetails: true,
                    editDetails: false,
                    showAddDocumentModal: true
                  });
                }}
                className="mb-2 rounded-0"
              >
                {translation('add') + ' ' + translation('document')}
                <Icon iconName="add" size="26" classes="ml-2" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BootstrapTable
                classes="with-gap"
                wrapperClasses="table-responsive-sm"
                remote
                bordered={false}
                bootstrap4
                keyField="_id"
                data={this.state.documents}
                columns={this.state.columns}
                pagination={
                  this.state.documents.length
                    ? paginationFactory(this.state.options)
                    : null
                }
                noDataIndication={
                  this.state.isTableLoading
                    ? translation('pleaseWait')
                    : translation('noData')
                }
                rowClasses="colored-background cursor-pointer"
                onTableChange={this.onTableChange}
                loading={this.state.isTableLoading}
                overlay={tableConstants.overlay()}
                rowEvents={{
                  onClick: (event, row) => {
                    this.showPreview(row);
                  }
                }}
                defaultSorted={[
                  {
                    dataField: this.state.sortField,
                    order: this.state.sortOrder
                  }
                ]}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(loaderActions.start()),
  stopLoading: () => dispatch(loaderActions.stop())
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(Documents)
);
