// @flow
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Col, Card, Row } from 'react-bootstrap';
import { Icon } from '../../../_shared/components';
import i18next from 'i18next';

function DraggableComponent(props: any) {
  const document = props.document;
  const index = props.index;
  const ref = useRef(null);
  let [, drag] = useDrag({
    item: { id: props.document._id, type: 'card' },
    canDrop: () => false,
    end: () => {
      props.setDraggingIndex(-1);
    }
  });
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  drag(drop(ref));
  return (
    <Card
      className="h-100 cursor-move hoverable banner-card"
      onClick={() => props.showPreview(document)}
      ref={ref}
    >
      <Card.Img
        variant="top"
        src={document.type === 'Image' ? document.url : document.thumbnail}
        className="object-fit-cover"
        height="200"
      />
      <div className="banner-indicator-container w-100">
        <Row>
          <Col>
            <span className="rounded-circle border border-primary text-center shadow-sm bg-white banner-index mx-2 d-inline-block">
              {index + 1}
            </span>
          </Col>
          <Col className="text-right">
            <Icon
              iconName="delete"
              size="27"
              onClick={event => {
                event.stopPropagation();
                props.deleteDocument(document);
              }}
              classes="mx-2 cursor-pointer"
              title={i18next.t('delete')}
            />
            <Icon
              iconName="edit"
              size="27"
              onClick={event => {
                event.stopPropagation();
                props.editDocument(document);
              }}
              classes="mx-2 cursor-pointer"
              title={i18next.t('edit')}
            />
          </Col>
        </Row>
      </div>
      <div className="document-thumb-overlay bg-dark-rgba-7 d-flex justify-content-center align-items-center">
        <Icon
          iconName={
            document.type === 'Document'
              ? 'document'
              : document.type === 'Video'
              ? 'media-player-play'
              : 'picture'
          }
          size="75"
          fill="#FFFFFF"
        />
      </div>
      <Card.Body>
        <Card.Title className="text-center mb-0">{document.title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

export default DraggableComponent;
