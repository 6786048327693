import React, { Component } from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import MDSpinner from 'react-md-spinner';

import { FormGroup, Icon, ImageContainer } from '../../../_shared/components';
import { ColorConstants, ALLOWED_IMAGE } from '../../../_shared/constants';
import { itemService } from '../../../_shared/services';
import { errorParser } from '../../../_shared/helpers';
import { withTranslation } from 'react-i18next';

const KitItemFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  manufacture: Yup.string().required(),
  number: Yup.string().required(),
  description: Yup.string().required()
});

class AddKitItemModal extends Component {
  translation = this.props.t;
  imageAnchor = React.createRef();
  /**Resource cleaner */
  cleanup = null;
  /**Placeholder image ref */
  placeHolderImageRef = React.createRef();
  fileInput = React.createRef();
  imagePreviewer = React.createRef();
  /**Component's state */
  state = {
    isLoading: false,
    item: {
      name: '',
      manufacture: '',
      number: undefined,
      description: '',
      imageSrc: ''
    },
    imageAdded: false,
    userUploadedFile: undefined,
    imageError: '',
    placeHolderImage: null,
    oldImage: null,
    fileName: ''
  };
  onEntering = () => {
    this.setState({ isLoading: true });
  };
  /**Set placeholder image value */
  onEntered = () => {
    if (this.props.itemId) {
      itemService.getById(this.props.itemId).subscribe({
        next: response => {
          const item = response.data.kitItem;
          this.setState(
            {
              item,
              isLoading: false,
              fileName: item.imageSrc.split('/')[
                item.imageSrc.split('/').length - 1
              ]
            },
            () => this.setImage()
          );
        },
        error: errorResponse => {
          toastr.error(this.translation('error'), errorParser(errorResponse));
          this.setState(() => this.setImage());
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          item: {
            name: '',
            manufacture: '',
            number: '',
            description: '',
            imageSrc: ''
          }
        },
        () => {
          this.setImage();
        }
      );
    }
  };

  /**Handle user input files and preview image */
  handleFiles = files => {
    const file = files[0];
    if (/\.(jpe?g|png)$/i.test(file.name)) {
      if (file.size > ALLOWED_IMAGE.KIT_ITEM.SIZE) {
        const imageError = this.translation('fileSizeShouldBeLessThan', {
          size: '1 MB'
        });
        this.setState({ imageError });
        toastr.warning(this.translation('warning'), imageError);
      } else {
        const reader = new FileReader();
        reader.onload = readerOnloadEvent => {
          var image = new Image();
          image.style.maxHeight = '150px'; // 50px less than imagePreviewer, i.e. 200-50=150
          image.style.minHeight = ALLOWED_IMAGE.KIT_ITEM.HEIGHT + 'px';
          image.style.minWidth = ALLOWED_IMAGE.KIT_ITEM.WIDTH + 'px';
          image.classList.add('img-fluid');
          image.title = file.name;
          image.src = readerOnloadEvent.target.result;
          image.onload = imageOnloadEvent => {
            const target = imageOnloadEvent.target;
            if (
              target.height >= ALLOWED_IMAGE.KIT_ITEM.HEIGHT &&
              target.width >= ALLOWED_IMAGE.KIT_ITEM.WIDTH
            ) {
              this.setState({
                imageAdded: false,
                userUploadedFile: file,
                fileName: file.name
              });
            } else {
              const imageError = this.translation('imageDimensionShouldBe', {
                context: 'minimum',
                dimension: `${ALLOWED_IMAGE.KIT_ITEM.HEIGHT}px X ${
                  ALLOWED_IMAGE.KIT_ITEM.WIDTH
                }px`
              });
              this.setState({ imageError });
              toastr.warning(this.translation('warning'), imageError);
            }
          };
        };
        reader.readAsDataURL(file);
      }
    } else {
      const imageError = this.translation('allowedFileTypes', {
        fileTypes: 'image(.jpg, .jpeg and .png)'
      });
      this.setState({ imageError });
      toastr.warning(this.translation('warning'), imageError);
    }
  };
  /**User removes the image */
  removeImage = () => {
    this.setState({ imageAdded: false, userUploadedFile: undefined });
    const imageHolder = this.imagePreviewer.current.querySelector(
      '#imageHolder'
    );
    const oldImageElement = imageHolder.children[0];
    imageHolder.removeChild(oldImageElement);
    if (this.state.oldImage) {
      imageHolder.appendChild(this.state.oldImage);
    } else {
      imageHolder.appendChild(this.state.placeHolderImage);
    }
  };
  setImage() {
    const imageHolder = this.imagePreviewer.current.querySelector(
      '#imageHolder'
    );
    if (this.state.item.imageSrc) {
      this.setState({ imageAdded: true });
    } else {
      const oldImage = imageHolder.children[0];
      this.setState({ oldImage });
    }
    const placeHolderImage = this.placeHolderImageRef.current;
    this.setState({ placeHolderImage });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onExited={() => {
          this.onExited();
        }}
        onEntered={() => this.onEntered()}
        onEntering={() => this.onEntering()}
        backdrop="static"
        size="lg"
        aria-labelledby="add-update-document-modal"
        centered
      >
        <Modal.Body>
          {this.state.isLoading ? (
            <div className="w-100 text-center">
              <MDSpinner singleColor={ColorConstants.PRIMARY} />
            </div>
          ) : (
            <>
              <h4 className="mb-3">
                {this.props.itemId
                  ? this.translation('edit') + ' ' + this.state.item.name
                  : this.translation('createNewKitItem')}
                <Icon
                  iconName="cancel"
                  classes="float-right cursor-pointer"
                  onClick={() => this.props.onClose()}
                />
              </h4>
              <Row>
                <Col md="3" className="pr-0">
                  <div className="d-none">
                    <div ref={this.placeHolderImageRef}>
                      <Icon
                        iconName="upload"
                        classes={[this.state.dragEnter ? 'floating' : ''].join(
                          ' '
                        )}
                        size="50"
                        fill={
                          this.state.dragEnter
                            ? ColorConstants.PRIMARY
                            : ColorConstants.TEXT_MUTED
                        }
                      />
                      <p
                        className={[
                          this.state.dragEnter
                            ? 'text-primary font-weight-bold'
                            : 'text-muted',
                          'mb-1'
                        ].join(' ')}
                      >
                        {this.translation('dropFileToUpload')}
                      </p>
                    </div>
                  </div>
                  <div
                    ref={this.imagePreviewer}
                    style={{ height: '238px' }}
                    className={[
                      'upload-box',
                      'd-flex',
                      'align-items-center',
                      'flex-column',
                      'px-2',
                      'text-center',
                      'position-relative',
                      this.state.dragEnter
                        ? 'border-dashed border-thick bg-primary-rgba-1'
                        : 'border',
                      this.state.imageAdded
                        ? 'border-0'
                        : 'justify-content-center'
                    ].join(' ')}
                    onDragEnter={dragEnterEvent => {
                      dragEnterEvent.stopPropagation();
                      dragEnterEvent.preventDefault();
                      this.setState({ dragEnter: true, dragLeave: false });
                    }}
                    onDragOver={dragOverEvent => {
                      dragOverEvent.stopPropagation();
                      dragOverEvent.preventDefault();
                      this.setState({ dragEnter: true, dragLeave: false });
                    }}
                    onDragLeave={dragLeaveEvent => {
                      dragLeaveEvent.stopPropagation();
                      dragLeaveEvent.preventDefault();
                      this.setState({ dragEnter: false, dragLeave: true });
                    }}
                    onDragEnd={dragEndEvent => {
                      dragEndEvent.stopPropagation();
                      dragEndEvent.preventDefault();
                      this.setState({ dragEnter: false, dragLeave: true });
                    }}
                    onDrop={dropEVent => {
                      dropEVent.stopPropagation();
                      dropEVent.preventDefault();
                      const dt = dropEVent.dataTransfer;
                      const files = dt.files;
                      this.handleFiles(files);
                      this.setState({ dragEnter: false, dragLeave: true });
                    }}
                  >
                    <div id="imageHolder" className="w-100">
                      {this.state.userUploadedFile ? (
                        <div
                          className="rounded hoverable border"
                          title={this.state.fileName}
                        >
                          <ImageContainer
                            key="user-uploaded-image"
                            alt={this.state.fileName || this.state.item.name}
                            file={this.state.userUploadedFile}
                            height={167}
                            width="100%"
                            className="w-100 object-fit-contain h-100"
                          />
                          <div className="border-top text-truncate pl-2 py-2">
                            <Icon iconName="picture" /> {this.state.fileName}
                          </div>
                        </div>
                      ) : this.state.item.imageSrc ? (
                        <>
                          <div
                            className="rounded hoverable border cursor-pointer"
                            onClick={() => {
                              this.imageAnchor.current.click();
                            }}
                            role="presentation"
                          >
                            <ImageContainer
                              key="src-image"
                              alt={this.state.item.name}
                              src={this.state.item.imageSrc}
                              height={167}
                              width="100%"
                              className="w-100 object-fit-contain h-100"
                              title={`Click to see ${this.state.fileName}`}
                            />
                            <div className="border-top text-truncate pl-2 py-2">
                              <Icon iconName="picture" /> {this.state.fileName}
                            </div>
                          </div>
                          <a
                            className="text-truncate text-blue d-none"
                            target="_blank"
                            title="Click to see the document"
                            rel="noopener noreferrer"
                            href={this.state.item.imageSrc}
                            ref={this.imageAnchor}
                          >
                            {this.state.fileName}
                          </a>
                        </>
                      ) : (
                        <div>
                          <Icon
                            iconName="upload"
                            classes={[
                              this.state.dragEnter ? 'floating' : ''
                            ].join(' ')}
                            size="50"
                            fill={
                              this.state.dragEnter || this.state.fileNeeded
                                ? ColorConstants.PRIMARY
                                : ColorConstants.TEXT_MUTED
                            }
                          />
                          <p
                            className={[
                              this.state.dragEnter
                                ? 'text-primary font-weight-bold'
                                : 'text-muted',
                              'mb-1'
                            ].join(' ')}
                          >
                            {this.translation('dropFileToUpload')}
                          </p>
                        </div>
                      )}
                    </div>
                    {this.state.imageAdded ? (
                      <div>
                        <Button
                          variant="link"
                          className={[
                            this.state.dragEnter ? 'd-none' : '',
                            'text-blue',
                            'my-0',
                            'pl-0'
                          ].join(' ')}
                          onClick={() => this.fileInput.current.click()}
                        >
                          {this.translation('change')}
                        </Button>
                        <Button
                          variant="link"
                          className={[
                            this.state.dragEnter ? 'd-none' : '',
                            'text-blue',
                            'my-0',
                            'pr-0'
                          ].join(' ')}
                          onClick={() => this.removeImage()}
                        >
                          {this.translation('remove')}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        variant="link"
                        className={[
                          this.state.dragEnter ? 'd-none' : '',
                          'text-blue',
                          'my-0'
                        ].join(' ')}
                        onClick={() => this.fileInput.current.click()}
                      >
                        {this.translation('browse')}
                      </Button>
                    )}

                    <input
                      type="file"
                      ref={this.fileInput}
                      className="d-none"
                      onChange={changeEvent =>
                        this.handleFiles(changeEvent.target.files)
                      }
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </Col>
                <Col md="9">
                  <Formik
                    initialValues={{
                      name: this.state.item.name,
                      manufacture: this.state.item.manufacture,
                      number: this.state.item.number,
                      description: this.state.item.description
                    }}
                    validationSchema={KitItemFormSchema}
                    onSubmit={values => {
                      if (!this.state.imageAdded) {
                        values = Object.assign(values, { imageSrc: '' });
                      } else {
                        values = Object.assign(values, {
                          imageSrc: this.state.item.imageSrc
                        });
                      }
                      this.props.onSubmit(values, this.state.userUploadedFile);
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize={true}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      values
                    }) => {
                      return (
                        <Form noValidate onSubmit={handleSubmit}>
                          <Row>
                            <Col md="6">
                              <FormGroup
                                formControlName="name"
                                type="text"
                                label={this.translation('itemName')}
                                handleChange={handleChange}
                                touched={touched['name']}
                                error={errors['name']}
                                value={values['name']}
                                required
                              />
                            </Col>
                            <Col md="6">
                              <FormGroup
                                formControlName="manufacture"
                                type="text"
                                label={this.translation('manufacturer')}
                                handleChange={handleChange}
                                touched={touched['manufacture']}
                                error={errors['manufacture']}
                                value={values['manufacture']}
                                required
                              />
                            </Col>
                          </Row>
                          <FormGroup
                            formControlName="number"
                            type="text"
                            label={this.translation('itemNumber')}
                            handleChange={handleChange}
                            touched={touched['number']}
                            error={errors['number']}
                            value={values['number']}
                            required
                            disabled={this.props.itemId}
                          />
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip
                                id="tooltip-description"
                                className={
                                  !errors['description'] ? 'd-none' : ''
                                }
                              >
                                {errors['description']}
                              </Tooltip>
                            }
                          >
                            <Form.Group>
                              <Form.Control
                                as="textarea"
                                rows="3"
                                name="description"
                                placeholder={this.translation(
                                  'itemDescription'
                                )}
                                className={[
                                  errors['description'] ? 'is-invalid' : '',
                                  'rounded-0'
                                ].join(' ')}
                                onChange={handleChange}
                                value={values['description']}
                              />
                            </Form.Group>
                          </OverlayTrigger>
                          <Row className="justify-content-end">
                            <Col xs="auto">
                              <Button
                                type="submit"
                                variant="success"
                                size="lg"
                                className="rounded-0 px-5"
                              >
                                {this.props.itemId
                                  ? this.translation('update')
                                  : this.translation('upload')}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  onExited() {
    this.setState({
      isLoading: true,
      item: {
        name: '',
        manufacture: '',
        number: '',
        description: '',
        imageSrc: ''
      },
      imageAdded: false,
      userUploadedFile: undefined,
      imageError: '',
      placeHolderImage: null,
      oldImage: null
    });
  }
}

export default withTranslation()(AddKitItemModal);
