import { API_ENDPOINTS } from '../constants';
import { Observable } from 'rxjs';
import { AxiosSubscriber, headerJSON } from '../helpers';

export const AuthenticationService = {
  login,
  logout,
  forgotPassword,
  isLoggedIn,
  getCurrentUser,
  userVerify,
  deleteRequest
};

function login(email, password) {
  const requestType = 'post',
    options = { headers: { 'Content-Type': 'application/json' } },
    body = JSON.stringify({ email, password }),
    url = `${API_ENDPOINTS.URL}${API_ENDPOINTS.AUTH.POST_LOGIN}`;

  return new Observable(observer => {
    return new AxiosSubscriber(observer, url, requestType, options, body);
  });
}
function userVerify(email, password) {
  const requestType = 'post',
    options = { headers: { 'Content-Type': 'application/json' } },
    body = JSON.stringify({ email, password }),
    url = `${API_ENDPOINTS.URL}${API_ENDPOINTS.AUTH.USER_VERIFY}`;

  return new Observable(observer => {
    return new AxiosSubscriber(observer, url, requestType, options, body);
  });
}

function logout(adminLogout = undefined) {
  return new Promise((resolve, reject) => {
    try {
      // change the url of browser address-bar
      // window.location.href =
      //   process.env.REACT_APP_PUBLIC_URL +
      //   '/' +
      //   (adminLogout ? 'admin' : 'login');
      // remove user from local storage to log user out
      let mobilizeLocalStorage = JSON.parse(localStorage.getItem('mobilize'));
      const language = mobilizeLocalStorage.user.language;
      mobilizeLocalStorage = { ...mobilizeLocalStorage, user: { language } };
      localStorage.setItem('mobilize', JSON.stringify(mobilizeLocalStorage));
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

/**@function forgotPassword
 * Forgot Password
 * @param {string} emailId - User's Email ID
 * @returns {Observable} observable - Observable to which we can subscribe to
 */
function forgotPassword(emailId) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.AUTH.POST_FORGOT_PASSWORD
      }`,
      axiosOptions = { headers: headerJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { email: emailId }
    );
  });
}

function isLoggedIn() {
  let isLoggedIn = false;
  const mobilizeLocalStorage = JSON.parse(localStorage.getItem('mobilize'));
  isLoggedIn = mobilizeLocalStorage.user && mobilizeLocalStorage.user.token;
  return isLoggedIn;
}

function getCurrentUser() {
  const mobilizeLocalStorage = JSON.parse(localStorage.getItem('mobilize'));
  const userLocalStorage = mobilizeLocalStorage.user;
  return userLocalStorage;
}

function deleteRequest(userId) {
  return new Observable(observer => {
    const requestType = 'patch',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.AUTH.PATCH_DELETE_USER
      }/${userId}${API_ENDPOINTS.AUTH.PATCH_DELETE_REQUEST}`,
      axiosOptions = { headers: headerJSON() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
