import { API_ENDPOINTS } from '../constants';
import { authHeader, AxiosSubscriber } from '../helpers';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { stringify } from 'querystring';

export const ImageService = {
  startUploadImage,
  uploadImage,
  deleteImage
};

/**@function startUploadImage
/* Get link and Upload image to s3 server
* @param {File} file - Image file to be uploaded
* @returns {Observable} Observable to which we can subscribe to Upload image to s3 server
*/
function startUploadImage(file) {
  return getLinkForImage(file.name, file.type).pipe(
    mergeMap(response => {
      const url = response.data.url;
      return uploadImage(file, url).pipe(
        map(response => {
          return Object.assign(response, {
            responseData: { imageSrc: url.split('?')[0] }
          });
        })
      );
    })
  );
}

/**@function getLinkForImage
/* Get link to which image can be uploaded
* @param {string} fileName - Name of the file
* @param {string} fileType - Type of the file
* @returns {Observable} Observable to which we can subscribe to Get link to which image can be uploaded
*/
function getLinkForImage(fileName, fileType) {
  return new Observable(observer => {
    const requestType = 'post',
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.IMAGE.PUT_UPLOAD_IMAGE
      }`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      { name: fileName, type: fileType }
    );
  });
}

/**@function uploadImage
/*  Upload image to s3
* @param {File} file - Image file to be uploaded
* @param {string} url - URL to which image should be uploaded
* @returns {Observable} Observable to which we can subscribe to Upload image to s3
*/
export function uploadImage(file, url) {
  return new Observable(observer => {
    const requestType = 'put',
      urlWithQueryParams = url,
      axiosOptions = { headers: { 'Content-Type': file.type } };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions,
      file
    );
  });
}

/**@function deleteImage
 * Delete file from s3
 * @param {'user'|'kit'|'kit_item'|'document'} [type='user'|'kit'|'kit_item'|'document'] - Type of image which should be delete
 * @param {string} id - ID of user'|'kit'|'kit_item'|'document'
 * @returns {Observable} observable - Observable to which we can subscribe.
 */
function deleteImage(type, id) {
  return new Observable(observer => {
    const requestType = 'delete',
      QUERY_PARAMS = `?${stringify({ type })}`,
      urlWithQueryParams = `${API_ENDPOINTS.URL}${
        API_ENDPOINTS.IMAGE.DELETE_IMAGE
      }/${id}${QUERY_PARAMS}`,
      axiosOptions = { headers: authHeader() };
    new AxiosSubscriber(
      observer,
      urlWithQueryParams,
      requestType,
      axiosOptions
    );
  });
}
